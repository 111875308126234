import { EventEmitter, OnInit } from "@angular/core";
import { WorkflowhttpService } from "@app/workflow-common/services/workflowhttp.service";
var ConditionalDelayBlockComponent = /** @class */ (function () {
    function ConditionalDelayBlockComponent(confirmationBoxHelper, ajsScope, workflowService) {
        this.confirmationBoxHelper = confirmationBoxHelper;
        this.ajsScope = ajsScope;
        this.workflowService = workflowService;
        this.name = 'conditionalDelayBlock';
        this.activeField = {};
        this.isConditional = true;
        this.isEdit = false;
        this.isMarketPlaceView = false;
        this.workflowType = 'Custom';
        this.diagramOptions = {};
        this.outputModel = new EventEmitter();
        this.close = new EventEmitter();
        this.isReadOnlyMode = false;
        this.conditionalFields = [];
        this.units = [];
        this.minutesInterval = Array.from(Array(60).keys());
        this.hoursInterval = Array.from(Array(24).keys());
        this.daysInterval = Array.from(Array(31).keys());
        this.monthsInterval = Array.from(Array(13).keys());
        this.saving = false;
    }
    ConditionalDelayBlockComponent.prototype.ngOnInit = function () {
        this.oldModel = JSON.stringify(Object.assign({}, this.inputModel));
        this.showDeleteBtn = this.isMarketPlaceView ? false : this.isEdit;
        this.units = this.inputModel.config.units.filter(function (unit) { return unit == 'days' || unit == 'months'; });
        this.conditionalFields = this.inputModel.config.fields.filter(function (item) { return item.type === 'date'; });
    };
    ConditionalDelayBlockComponent.prototype.onChangeUnit = function (event) {
        if ((this.inputModel.model.unit == 'months' && this.inputModel.model.interval > 12) || (this.inputModel.model.unit == 'days' && this.inputModel.model.interval > 30) || (this.inputModel.model.unit == 'hours' && this.inputModel.model.interval > 23)) {
            this.inputModel.model.interval = 0;
        }
    };
    ConditionalDelayBlockComponent.prototype.validateItems = function () {
        if (this.workflowType == 'Sequence' && this.diagramOptions['mode'] == 'sequence_config') {
            return false;
        }
        if (this.isConditional) {
            var condValue = void 0;
            if (typeof this.inputModel.condition['value'] == "number") {
                condValue = false;
            }
            else {
                condValue = this.inputModel.condition.value == '';
            }
            return this.inputModel.condition.operator === '' || condValue || this.inputModel.model.interval == 0;
        }
        else
            return this.inputModel.model.col_name === '' || this.inputModel.model.interval == 0;
    };
    ConditionalDelayBlockComponent.prototype.addValue = function () {
        var _this = this;
        this.saving = true;
        this.newModel = JSON.stringify(Object.assign({}, this.inputModel));
        if (this.oldModel === this.newModel) {
            this.workflowService.triggerEvent({ 'isUpdated': false, 'isEdit': this.isEdit, 'action': 'valueChanged' });
        }
        else {
            this.workflowService.triggerEvent({ 'isUpdated': true, 'isEdit': this.isEdit, 'action': 'valueChanged' });
        }
        setTimeout(function () {
            _this.outputModel.emit(_this.inputModel);
        }, 0);
    };
    ConditionalDelayBlockComponent.prototype.closeModal = function () {
        this.close.emit(this.isEdit);
    };
    ConditionalDelayBlockComponent.prototype.removeCell = function () {
        var _this = this;
        this.confirmationBoxHelper
            .getConfirmation(this.deleteMessage, this.ajsScope)
            .then(function () {
            _this.close.emit('remove');
        });
    };
    return ConditionalDelayBlockComponent;
}());
export { ConditionalDelayBlockComponent };
