<div class="filter-container">
    <div *ngFor="let filter of viewableFilters; let i = index;">
        <div class="applied-filter-box">
            <div class="filter-text">
                <span class="filter-label">{{filter.fieldLabel}}</span>
                <span class="condition-label">{{filter.conditionLabel}}</span>
                <span class="filter-value" *ngIf="filter.valueLabelText">{{filter.valueLabelText}}</span>
            </div>
            <svg style="cursor: pointer;" (click)="removeFilter(i, 'view')" xmlns="http://www.w3.org/2000/svg"
                width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path
                    d="M3.59417 3.59429C3.76128 3.42722 3.9879 3.33337 4.2242 3.33337C4.4605 3.33337 4.68712 3.42722 4.85423 3.59429L8.0053 6.74535L11.1564 3.59429C11.3244 3.43196 11.5495 3.34214 11.7832 3.34417C12.0168 3.3462 12.2404 3.43992 12.4056 3.60514C12.5708 3.77037 12.6645 3.99388 12.6666 4.22753C12.6686 4.46118 12.5788 4.68629 12.4164 4.85436L9.26537 8.00542L12.4164 11.1565C12.5788 11.3246 12.6686 11.5497 12.6666 11.7833C12.6645 12.017 12.5708 12.2405 12.4056 12.4057C12.2404 12.5709 12.0168 12.6646 11.7832 12.6667C11.5495 12.6687 11.3244 12.5789 11.1564 12.4166L8.0053 9.26549L4.85423 12.4166C4.68616 12.5789 4.46106 12.6687 4.22741 12.6667C3.99375 12.6646 3.77024 12.5709 3.60502 12.4057C3.4398 12.2405 3.34608 12.017 3.34405 11.7833C3.34202 11.5497 3.43184 11.3246 3.59417 11.1565L6.74523 8.00542L3.59417 4.85436C3.4271 4.68724 3.33325 4.46062 3.33325 4.22432C3.33325 3.98802 3.4271 3.7614 3.59417 3.59429Z"
                    fill="#3883C1" />
            </svg>
        </div>
    </div>
    <div id="more-filter-dropdown" *ngIf="moreFilters && moreFilters.length" (click)="openMoreFilterPopup()">
        <span class="count-value">+{{moreFilters.length}}</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
                d="M8.95672 11.1064C8.62201 11.4337 8.07844 11.4337 7.74373 11.1064L2.60259 6.07956C2.26789 5.75228 2.26789 5.2208 2.60259 4.89352C2.9373 4.56625 3.48087 4.56625 3.81558 4.89352L8.35156 9.32871L12.8875 4.89614C13.2223 4.56887 13.7658 4.56887 14.1005 4.89614C14.4352 5.22341 14.4352 5.7549 14.1005 6.08217L8.95939 11.1091L8.95672 11.1064Z"
                fill="#3883C1" />
        </svg>
    </div>
    <div [hidden]="!isMoreFilterPopupOpened" class="dropdown-menu more-filter-list">
        <span class="aero aero-more"></span>
        <div class="filters-list">
            <div class="applied-filter-box" *ngFor="let filter of moreFilters; let i = index;">
                <div class="filter-text">
                    <span class="filter-label">{{filter.fieldLabel}}</span>
                    <span class="condition-label">{{filter.conditionLabel}}</span>
                    <span class="filter-value" *ngIf="filter.valueLabelText">{{filter.valueLabelText}}</span>
                </div>
                <svg style="cursor: pointer;" (click)="removeFilter(i, 'more')" xmlns="http://www.w3.org/2000/svg"
                    width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                        d="M3.59417 3.59429C3.76128 3.42722 3.9879 3.33337 4.2242 3.33337C4.4605 3.33337 4.68712 3.42722 4.85423 3.59429L8.0053 6.74535L11.1564 3.59429C11.3244 3.43196 11.5495 3.34214 11.7832 3.34417C12.0168 3.3462 12.2404 3.43992 12.4056 3.60514C12.5708 3.77037 12.6645 3.99388 12.6666 4.22753C12.6686 4.46118 12.5788 4.68629 12.4164 4.85436L9.26537 8.00542L12.4164 11.1565C12.5788 11.3246 12.6686 11.5497 12.6666 11.7833C12.6645 12.017 12.5708 12.2405 12.4056 12.4057C12.2404 12.5709 12.0168 12.6646 11.7832 12.6667C11.5495 12.6687 11.3244 12.5789 11.1564 12.4166L8.0053 9.26549L4.85423 12.4166C4.68616 12.5789 4.46106 12.6687 4.22741 12.6667C3.99375 12.6646 3.77024 12.5709 3.60502 12.4057C3.4398 12.2405 3.34608 12.017 3.34405 11.7833C3.34202 11.5497 3.43184 11.3246 3.59417 11.1565L6.74523 8.00542L3.59417 4.85436C3.4271 4.68724 3.33325 4.46062 3.33325 4.22432C3.33325 3.98802 3.4271 3.7614 3.59417 3.59429Z"
                        fill="#3883C1" />
                </svg>
            </div>
        </div>
    </div>
    <div [hidden]="!isPopupOpened" id="add-filter-dropdown" (click)="openFilterPopup()">
        <span class="filter-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path
                    d="M1.10651 2.62625C1.287 2.24339 1.66986 2 2.09374 2H13.9076C14.3315 2 14.7144 2.24339 14.8948 2.62625C15.0753 3.0091 15.0206 3.46033 14.7526 3.78849L9.75088 9.90053V13.3763C9.75088 13.7072 9.56492 14.0108 9.26684 14.1585C8.96876 14.3061 8.61598 14.276 8.35072 14.0764L6.60051 12.7638C6.379 12.5997 6.25047 12.3399 6.25047 12.0637V9.90053L1.24598 3.78576C0.980714 3.46033 0.923286 3.00637 1.10651 2.62625Z"
                    fill="#3883C1" />
            </svg>
        </span>Add filter
    </div>
    <div class="dropdown-menu filter-popup">
        <span class="aero"></span>
        <form class="filter-form" [formGroup]="filterForm" (submit)="addFilter()">
            <div class="form-fields">
                <select id="filter_field_select" class="w-100" formControlName="field" csSelect2
                    [select2Config]="select2Config" (selected)="onSelectingField($event)">
                    <option value="" class="select-field-lable" [disabled]="true" translate>-- Select field --</option>
                    <option *ngFor="let field of availableFields" [disabled]="field.isSelected" [value]="field.ID">
                        {{field.filterInterfaceLabel}}
                    </option>
                </select>
                <select id="filter_condition_select" class="w-100" formControlName="condition" csSelect2
                    [select2Config]="select2Config" (selected)="onSelectingCondition($event)" *ngIf="showCondition">
                    <option value="" class="select-field-lable" [disabled]="true" translate>-- Select condition --
                    </option>
                    <ng-container *ngIf="selectedFilter">
                        <option *ngFor="let ac of conditions[selectedFilter.filterType]" [value]="ac.condition">
                            {{ac.replaceText}}
                        </option>
                    </ng-container>
                </select>
                <ng-container *ngIf="selectedFilter">
                    <ng-container [ngSwitch]="selectedFilter.filterType">
                        <ng-container *ngSwitchCase="'staticSingleSelect'">
                            <select id="static_single_select" class="w-100" formControlName="value" csSelect2
                                [select2Config]="select2Config">
                                <option *ngFor="let option of selectedFilter.filterOptions" [value]="option.id">
                                    {{option.text}}
                                </option>
                            </select>
                        </ng-container>
                        <ng-container *ngSwitchCase="'text'">
                            <div class="clearable-input w-100">
                                <input formControlName="value" placeholder="Fill value" [type]="filterValueType"
                                    class="filter-input-text-box m-0" />
                                <span data-clear-input (click)="clearValueInput($event)" *ngIf="filterForm.value.value">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                        fill="none">
                                        <path
                                            d="M8 15C9.85652 15 11.637 14.2625 12.9497 12.9497C14.2625 11.637 15 9.85652 15 8C15 6.14348 14.2625 4.36301 12.9497 3.05025C11.637 1.7375 9.85652 1 8 1C6.14348 1 4.36301 1.7375 3.05025 3.05025C1.7375 4.36301 1 6.14348 1 8C1 9.85652 1.7375 11.637 3.05025 12.9497C4.36301 14.2625 6.14348 15 8 15ZM5.78516 5.78516C6.04219 5.52812 6.45781 5.52812 6.71211 5.78516L7.99727 7.07031L9.28242 5.78516C9.53945 5.52812 9.95508 5.52812 10.2094 5.78516C10.4637 6.04219 10.4664 6.45781 10.2094 6.71211L8.92422 7.99727L10.2094 9.28242C10.4664 9.53945 10.4664 9.95508 10.2094 10.2094C9.95234 10.4637 9.53672 10.4664 9.28242 10.2094L7.99727 8.92422L6.71211 10.2094C6.45508 10.4664 6.03945 10.4664 5.78516 10.2094C5.53086 9.95234 5.52812 9.53672 5.78516 9.28242L7.07031 7.99727L5.78516 6.71211C5.52812 6.45508 5.52812 6.03945 5.78516 5.78516Z"
                                            fill="#8E8E8E" />
                                    </svg>
                                </span>
                            </div>
                        </ng-container>
                        <ng-container *ngSwitchCase="'numeric'">
                            <input appDigitDecimaNumber formControlName="value" placeholder="Fill value" [type]="filterValueType"
                                    class="filter-input-text-box m-0" />
                        </ng-container>
                        <ng-container *ngSwitchCase="'date'">
                            <ng-container *ngIf="showDatePicker">
                                <ng-container *ngIf="filterForm.value.condition != 'range'">
                                    <input #stInvDate="bsDatepicker"
                                        class="bsdatepicker-filter-input filter-input-text-box" formControlName="value"
                                        type="text" bsDatepicker [bsConfig]="bsDateConfig" [placement]="'bottom'"
                                        [(ngModel)]="date" [(bsValue)]="date" [maxDate]="currentDate">
                                </ng-container>
                                <ng-container *ngIf="filterForm.value.condition == 'range'">
                                    <input class="filter-input-text-box" type="text" #daterangepicker="bsDaterangepicker" bsDaterangepicker
                                        [maxDate]="currentDate" formControlName="value" [bsConfig]="bsDateConfig" />
                                </ng-container>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'staticMultiSelect'">
                            <input id="static_multi_select" class="w-100 filter-input-text-box" formControlName="value"
                                csSelect2 (selected)="onSelectMultipleValues($event)"
                                [select2Config]="select2ConfigStaticMultipleFilter" appDebounceTimer />
                        </ng-container>
                        <ng-container *ngSwitchCase="'dynamicMultipleSelect'">
                            <input id="dynamic_multi_select" class="w-100 filter-input-text-box" formControlName="value"
                                csSelect2 [select2Config]="select2ConfigDynamicMultiFilter" appDebounceTimer
                                (selected)="onSelectMultipleValues($event)" />
                        </ng-container>
                        <ng-container *ngSwitchCase="'dynamic_filter'">
                            <input id="dynamic_filter_type" class="w-100 filter-input-text-box" formControlName="value"
                                csSelect2 [select2Config]="select2ConfigDynamicFilter" appDebounceTimer />
                        </ng-container>
                        <ng-container *ngSwitchCase="'dynamicFilterWithSearch'">
                            <ng-container *ngIf="showValueOption">
                                <input id="dynamic_multiple_filter_type" class="w-100 filter-input-text-box"
                                    (selected)="onSelectMultipleValues($event)" formControlName="value" csSelect2
                                    [select2Config]="select2ConfigDynamicMultipleFilter" appDebounceTimer />
                            </ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'multipleOwnFilter'">
                            <input (selected)="onSelectMultipleValues($event)"
                                [select2Config]="select2MultipleOwnFilterConfig" class="full-width-select2 filter-input-text-box" csSelect2
                                formControlName="value" id="multiple_own_filter" />
                        </ng-container>
                        <ng-container *ngSwitchCase="'subStringSearch'">
                            <input (selected)="onSelectMultipleValues($event)"
                                [select2Config]="select2SearchLabelConfig"
                                class="full-width-select2 filter-input-text-box" csSelect2 formControlName="value"
                                id="multiple_select_search" />
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            <div class="clearable-input w-100">
                                <input formControlName="value" placeholder="Fill value" [type]="filterValueType"
                                    class="m-0 filter-input-text-box" />
                                <span data-clear-input (click)="clearValueInput($event)" *ngIf="filterForm.value.value">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                        fill="none">
                                        <path
                                            d="M8 15C9.85652 15 11.637 14.2625 12.9497 12.9497C14.2625 11.637 15 9.85652 15 8C15 6.14348 14.2625 4.36301 12.9497 3.05025C11.637 1.7375 9.85652 1 8 1C6.14348 1 4.36301 1.7375 3.05025 3.05025C1.7375 4.36301 1 6.14348 1 8C1 9.85652 1.7375 11.637 3.05025 12.9497C4.36301 14.2625 6.14348 15 8 15ZM5.78516 5.78516C6.04219 5.52812 6.45781 5.52812 6.71211 5.78516L7.99727 7.07031L9.28242 5.78516C9.53945 5.52812 9.95508 5.52812 10.2094 5.78516C10.4637 6.04219 10.4664 6.45781 10.2094 6.71211L8.92422 7.99727L10.2094 9.28242C10.4664 9.53945 10.4664 9.95508 10.2094 10.2094C9.95234 10.4637 9.53672 10.4664 9.28242 10.2094L7.99727 8.92422L6.71211 10.2094C6.45508 10.4664 6.03945 10.4664 5.78516 10.2094C5.53086 9.95234 5.52812 9.53672 5.78516 9.28242L7.07031 7.99727L5.78516 6.71211C5.52812 6.45508 5.52812 6.03945 5.78516 5.78516Z"
                                            fill="#8E8E8E" />
                                    </svg>
                                </span>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="!selectedFilter">
                    <div class="clearable-input w-100">
                        <input formControlName="value" placeholder="Fill value" [type]="filterValueType"
                            class="m-0 filter-input-text-box" />
                        <span data-clear-input (click)="clearValueInput($event)" *ngIf="filterForm.value.value">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                fill="none">
                                <path
                                    d="M8 15C9.85652 15 11.637 14.2625 12.9497 12.9497C14.2625 11.637 15 9.85652 15 8C15 6.14348 14.2625 4.36301 12.9497 3.05025C11.637 1.7375 9.85652 1 8 1C6.14348 1 4.36301 1.7375 3.05025 3.05025C1.7375 4.36301 1 6.14348 1 8C1 9.85652 1.7375 11.637 3.05025 12.9497C4.36301 14.2625 6.14348 15 8 15ZM5.78516 5.78516C6.04219 5.52812 6.45781 5.52812 6.71211 5.78516L7.99727 7.07031L9.28242 5.78516C9.53945 5.52812 9.95508 5.52812 10.2094 5.78516C10.4637 6.04219 10.4664 6.45781 10.2094 6.71211L8.92422 7.99727L10.2094 9.28242C10.4664 9.53945 10.4664 9.95508 10.2094 10.2094C9.95234 10.4637 9.53672 10.4664 9.28242 10.2094L7.99727 8.92422L6.71211 10.2094C6.45508 10.4664 6.03945 10.4664 5.78516 10.2094C5.53086 9.95234 5.52812 9.53672 5.78516 9.28242L7.07031 7.99727L5.78516 6.71211C5.52812 6.45508 5.52812 6.03945 5.78516 5.78516Z"
                                    fill="#8E8E8E" />
                            </svg>
                        </span>
                    </div>
                </ng-container>
            </div>
            <div class="form-buttons">
                <a class="clear-btn" (click)="clearForm()">Clear</a>
                <button class="btn btn-primary" [disabled]="!filterForm.valid" type="submit">Add</button>
            </div>
        </form>
    </div>
</div>