<div class="with-left-sidbar main-field d-flex h-100">
    <div class="in-aside-flex-base flex-shrink-0 pos-relative" *ngIf = "activeMenu == 'history'">
        <app-dagruns
            [data]="data"
            (activeDagRunItem)="setActiveDagRun($event)"
        ></app-dagruns>
    </div>
    <div class="flex-grow-1 cs-pos-relative diagram-container">
        <div class="pos-abt-full" *ngIf="isEmpty">
            <div class="cs-d-flex align-items-center justify-content-center h-100">
                <div class="color" style="color:rgba(80, 91, 101, 0.5)">Please select a record from the left to see the complete history of that workflow</div>
            </div>
        </div>
        <div class="pos-abt cs-d-footer-divider">
            <div class="d-flex h-100 align-items-center">
                <div class="ml-1">
                    <span class="color-box color-box-status-completed"></span>
                    <span> Complete</span>
                </div>
                <div class="ml-1">
                    <span class="color-box color-box-status-running"></span>
                    <span> Running</span>
                </div>
                <div class="ml-1">
                    <span class="color-box color-box-status-skipped"></span>
                    <span> Skipped</span>
                </div>
                <div class="ml-1">
                    <span class="color-box color-box-status-failed"></span>
                    <span>Failed</span>
                </div>
                <div class="ml-1">
                    <span class="color-box color-box-status-pending"></span>
                    <span>Pending</span>
                </div>
            </div>
        </div>
        <div #mx_container class="mx-container cur-grab" style="position:relative;overflow:hidden;margin-left:10px"></div>
        <div #outlineContainer
             style="z-index:1;
         position:absolute;
         overflow:hidden;top:10px;right:10px;width:160px;height:120px;
         background:#fff;border-style:solid;border-color:lightgray;">
        </div>
    </div>
</div>
