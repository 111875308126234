import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { WorkflowhttpService } from "@app/workflow-common/services/workflowhttp.service";
var AddjobBlockComponent = /** @class */ (function () {
    function AddjobBlockComponent(workflowService, confirmationBoxHelper, ajsScope) {
        this.workflowService = workflowService;
        this.confirmationBoxHelper = confirmationBoxHelper;
        this.ajsScope = ajsScope;
        this.name = 'addjobBlock';
        this.activeField = {};
        this.isConditional = false;
        this.isEdit = false;
        this.isMarketPlaceView = false;
        this.isReadOnlyMode = false;
        this.outputModel = new EventEmitter();
        this.close = new EventEmitter();
        this.jobDescriptions = [];
        this.jobDescriptionsList = {};
        this.isLoading = true;
    }
    AddjobBlockComponent.prototype.ngOnInit = function () {
        this.oldModel = JSON.stringify(Object.assign({}, this.inputModel));
        this.showDeleteBtn = this.isMarketPlaceView ? false : this.isEdit;
        //console.log('input', this.inputModel)
        this.resolveData();
    };
    AddjobBlockComponent.prototype.addValue = function () {
        //console.log('save',this.inputModel);
        var jobId = this.inputModel.model.jobDescriptionId;
        this.inputModel.model['jobDescriptionDisplay'] = this.jobDescriptionsList[jobId]['description'];
        this.newModel = JSON.stringify(Object.assign({}, this.inputModel));
        if (this.oldModel === this.newModel) {
            this.workflowService.triggerEvent({ 'isUpdated': false, 'isEdit': this.isEdit, 'action': 'valueChanged' });
        }
        else {
            this.workflowService.triggerEvent({ 'isUpdated': true, 'isEdit': this.isEdit, 'action': 'valueChanged' });
        }
        this.outputModel.emit(this.inputModel);
    };
    AddjobBlockComponent.prototype.closeModal = function () {
        this.close.emit(this.isEdit);
    };
    AddjobBlockComponent.prototype.removeCell = function () {
        var _this = this;
        this.confirmationBoxHelper
            .getConfirmation(this.deleteMessage, this.ajsScope)
            .then(function () {
            _this.close.emit('remove');
        });
    };
    AddjobBlockComponent.prototype.validateItems = function () {
        if (this.isConditional)
            return this.inputModel.condition.operator === '' || _.isEmpty(this.inputModel.condition.value) || !this.inputModel.model.jobDescriptionId;
        else
            return !this.inputModel.model.jobDescriptionId;
    };
    AddjobBlockComponent.prototype.getJobDescriptions = function () {
        var _this = this;
        var promise = new Promise(function (resolve) {
            _this.workflowService.getJobDescriptions({
                ___noPageloading: true,
                limit: 9999
            })
                .subscribe(function (resp) {
                //console.log(resp);
                var jobDescriptions = resp['jobDescriptions'];
                _this.jobDescriptions = jobDescriptions.map(function (item) {
                    return { id: item.id, text: item.description };
                });
                _this.jobDescriptionsList = _.indexBy(jobDescriptions, 'id');
                resolve();
            });
        });
        return promise;
    };
    AddjobBlockComponent.prototype.resolveData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLoading = true;
                        return [4 /*yield*/, this.getJobDescriptions()];
                    case 1:
                        _a.sent();
                        this.isLoading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    return AddjobBlockComponent;
}());
export { AddjobBlockComponent };
