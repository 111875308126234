import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {WorkflowhttpService} from "@app/workflow-common/services/workflowhttp.service";

declare var _;

@Component({
    selector: 'app-delay-block',
    templateUrl: './delay-block.component.html',
    styles: ['.subnav .nav_wrapper .nav_bar ul li.active { background: #f9f9f9 !important; }']
})
export class DelayBlockComponent implements OnInit {
    name:string = 'delayBlock';
    showDeleteBtn:boolean;
    @Input() inputModel:any;
    @Input() activeField:any = {};
    @Input() isConditional:boolean = false;
    @Input() isEdit:boolean = false;
    @Input() operators:any;
    @Input() isMarketPlaceView:boolean = false;
    @Input() deleteMessage:string;
    @Input() warningMessage:string;
    @Input() workflowType: string = 'Custom';
    @Input() diagramOptions: any = {};
    @Input() status: any;

    @Output() outputModel:EventEmitter<any> = new EventEmitter<any>();
    @Output() close:EventEmitter<any> = new EventEmitter<any>();
    @Input() isReadOnlyMode:boolean = false;

    conditionalFields:any = [];
    units:any = [];
    minutesInterval: any[] = Array.from(Array(60).keys());
    hoursInterval: any[] = Array.from(Array(24).keys());
    saving: boolean = false;
    oldModel: any;
    newModel: any

    constructor(
        @Inject('confirmationBoxHelper') private confirmationBoxHelper,
        @Inject('$scope') private ajsScope,
        public workflowService: WorkflowhttpService,
    ) { }

    ngOnInit() {
        //results: data.ppmDropdown.filter(p => existingItemIds.indexOf(p['id']) < 0)
        this.oldModel = JSON.stringify(Object.assign({}, this.inputModel));
        this.showDeleteBtn = this.isMarketPlaceView ? false : this.isEdit;
        if(this.workflowType =='Sequence' && this.diagramOptions.mode == 'sequence_config') {
            this.showDeleteBtn = false;
        }
        this.conditionalFields = this.inputModel.config.fields.filter( item => item.type === 'date');

        // this.units = this.inputModel.config.units;
        this.filterUnits();
        // console.log(this.conditionalFields, this.inputModel.config.fields)
    }

    addValue() {
        this.saving=true;
        this.newModel = JSON.stringify(Object.assign({}, this.inputModel));
        if(_.isEqual(this.oldModel, this.newModel) ){
            this.workflowService.triggerEvent({'isUpdated' : false ,'isEdit' : this.isEdit,'action' : 'valueChanged'});
        }else{
            this.workflowService.triggerEvent({'isUpdated' : true ,'isEdit' : this.isEdit,'action' : 'valueChanged'});
        }

        setTimeout(() =>{
            this.outputModel.emit(this.inputModel);
        },0)
    }

    filterUnits() {
        if(this.inputModel.model.type == 'default')
            this.units = this.inputModel.config.units;
        else
            this.units = this.inputModel.config.units.filter( unit => unit == 'days' || unit == 'months')
    }

    removeCell() {
        this.confirmationBoxHelper
            .getConfirmation(this.deleteMessage, this.ajsScope)
            .then(() => {
                this.close.emit('remove');
            });
    }

    validateItems() {
        if(this.workflowType == 'Sequence' && this.diagramOptions['mode'] == 'sequence_config') {
            return false;
        }
        if(this.isConditional) {
            let condValue;
            if(typeof this.inputModel.condition['value'] == "number") {
                condValue = false;
            }
            else {
                condValue = this.inputModel.condition.value==''
            }
            return this.inputModel.condition.operator ==='' || condValue || this.inputModel.model.interval == 0;
        }
        else
            return this.inputModel.model.type === 'conditional' ? this.inputModel.model.col_name === '' || this.inputModel.model.interval==0 : this.inputModel.model.interval==0;
    }

    onChangeUnit(event:any) {
        if((this.inputModel.model.unit == 'months' && this.inputModel.model.interval > 12) || (this.inputModel.model.unit == 'days' && this.inputModel.model.interval > 30) || (this.inputModel.model.unit == 'hours' && this.inputModel.model.interval > 23)){
            this.inputModel.model.interval = 0;
        }
    }

    closeModal() {
        this.close.emit(this.isEdit);
    }

}

