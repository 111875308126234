/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sequence-container.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "../diagram-view-sequence/diagram-view-sequence.component.ngfactory";
import * as i5 from "../diagram-view-sequence/diagram-view-sequence.component";
import * as i6 from "../services/mx-graph-sequence";
import * as i7 from "../services/workflowhttp.service";
import * as i8 from "../../services/utility-services/cs-toast-box.service";
import * as i9 from "../exit-condition-view/exit-condition-view.component.ngfactory";
import * as i10 from "../exit-condition-view/exit-condition-view.component";
import * as i11 from "../../sidepanel/services/sidepanel-modal.service";
import * as i12 from "@angular/forms";
import * as i13 from "../workflow-history/audit-history/audit-history.component.ngfactory";
import * as i14 from "../workflow-history/audit-history/audit-history.component";
import * as i15 from "../services/mx-graph.service";
import * as i16 from "./sequence-container.component";
var styles_SequenceContainerComponent = [i0.styles];
var RenderType_SequenceContainerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SequenceContainerComponent, data: {} });
export { RenderType_SequenceContainerComponent as RenderType_SequenceContainerComponent };
function View_SequenceContainerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "loading-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [["class", "circle1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "span", [["class", "circle2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "span", [["class", "circle3"]], null, null, null, null, null))], null, null); }
function View_SequenceContainerComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "nav-header"], ["translate", ""]], null, null, null, null, null)), i1.ɵdid(2, 8536064, null, 0, i2.TranslateDirective, [i2.TranslateService, i1.ElementRef, i1.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i1.ɵted(3, null, ["", ""]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.title; _ck(_v, 3, 0, currVal_1); }); }
function View_SequenceContainerComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "a", [["href", "javascript:void(0);"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setActiveMenu(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "active": 0, "disabled": 1 }), (_l()(), i1.ɵeld(4, 0, null, null, 0, "i", [["style", "margin-right: 10px;"]], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, (_v.parent.context.$implicit.id === _co.activeMenu), !_v.parent.context.$implicit.isActive); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.class, ""); _ck(_v, 4, 0, currVal_1); var currVal_2 = _v.parent.context.$implicit.title; _ck(_v, 5, 0, currVal_2); }); }
function View_SequenceContainerComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SequenceContainerComponent_5)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SequenceContainerComponent_6)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.isHeader; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_v.context.$implicit.isHeader; _ck(_v, 4, 0, currVal_1); }, null); }
function View_SequenceContainerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "aside-container no-border"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "aside-inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "ul", [["class", "fs-menu"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SequenceContainerComponent_4)), i1.ɵdid(4, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.menuItems; _ck(_v, 4, 0, currVal_0); }, null); }
function View_SequenceContainerComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["style", "height: calc(100% - 10px)"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-diagram-view-sequence", [], null, [[null, "beforeRenderDiagram"], [null, "afterRenderDiagram"], ["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onResize($event) !== false);
        ad = (pd_0 && ad);
    } if (("beforeRenderDiagram" === en)) {
        var pd_1 = (_co.beforeRenderedDiagram($event) !== false);
        ad = (pd_1 && ad);
    } if (("afterRenderDiagram" === en)) {
        var pd_2 = (_co.afterRenderedDiagram($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i4.View_DiagramViewSequenceComponent_0, i4.RenderType_DiagramViewSequenceComponent)), i1.ɵdid(2, 4440064, [[1, 4], ["workflowDiagram", 4]], 0, i5.DiagramViewSequenceComponent, [i6.MxGraphSequenceService, i7.WorkflowhttpService, "confirmationBoxHelper", "$scope", i8.CsToastBoxService], { data: [0, "data"], csFullModal: [1, "csFullModal"], tags: [2, "tags"], userRoles: [3, "userRoles"], viewMode: [4, "viewMode"], usersList: [5, "usersList"], scheduleActivityTypes: [6, "scheduleActivityTypes"], mode: [7, "mode"], additionalData: [8, "additionalData"], sequenceObjectName: [9, "sequenceObjectName"] }, { afterRenderDiagram: "afterRenderDiagram", beforeRenderDiagram: "beforeRenderDiagram" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; var currVal_1 = _co.csFullModal; var currVal_2 = _co.tagsList; var currVal_3 = _co.rolesList; var currVal_4 = false; var currVal_5 = _co.usersList; var currVal_6 = _co.scheduleActivityTypes; var currVal_7 = _co.sequenceMode; var currVal_8 = _co.additionalData; var currVal_9 = _co.sequenceObjectName; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, null); }
function View_SequenceContainerComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["style", "height: calc(100% - 10px)"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-exit-condition-view", [], null, [[null, "selectedExitCriteria"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedExitCriteria" === en)) {
        var pd_0 = (_co.selectedExitCriteria($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_ExitConditionViewComponent_0, i9.RenderType_ExitConditionViewComponent)), i1.ɵdid(2, 114688, [[1, 4], ["workflowDiagram", 4]], 0, i10.ExitConditionViewComponent, [i11.SidepanelModalService, i7.WorkflowhttpService, i12.FormBuilder], { exitCondition: [0, "exitCondition"], data: [1, "data"] }, { selectedExitCriteria: "selectedExitCriteria" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.exitConditions; var currVal_1 = _co.data; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_SequenceContainerComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["style", "height: calc(100% - 60px)"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-audit-history", [], null, [[null, "afterRenderDiagram"], [null, "beforeRenderDiagram"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("afterRenderDiagram" === en)) {
        var pd_0 = (_co.afterRenderedDiagram($event) !== false);
        ad = (pd_0 && ad);
    } if (("beforeRenderDiagram" === en)) {
        var pd_1 = (_co.beforeRenderedDiagram($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i13.View_AuditHistoryComponent_0, i13.RenderType_AuditHistoryComponent)), i1.ɵdid(2, 4308992, null, 0, i14.AuditHistoryComponent, [i15.MxGraphService, i6.MxGraphSequenceService], { activeMenu: [0, "activeMenu"], data: [1, "data"], tags: [2, "tags"], csFullModal: [3, "csFullModal"], userRoles: [4, "userRoles"], viewMode: [5, "viewMode"], usersList: [6, "usersList"], scheduleActivityTypes: [7, "scheduleActivityTypes"], isMarketPlaceView: [8, "isMarketPlaceView"], sequenceObjectName: [9, "sequenceObjectName"], additionalData: [10, "additionalData"], mode: [11, "mode"] }, { afterRenderDiagram: "afterRenderDiagram", beforeRenderDiagram: "beforeRenderDiagram" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activeMenu; var currVal_1 = _co.data; var currVal_2 = _co.tagsList; var currVal_3 = _co.csFullModal; var currVal_4 = _co.rolesList; var currVal_5 = true; var currVal_6 = _co.usersList; var currVal_7 = _co.scheduleActivityTypes; var currVal_8 = false; var currVal_9 = _co.sequenceObjectName; var currVal_10 = _co.additionalData; var currVal_11 = _co.sequenceMode; _ck(_v, 2, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11]); }, null); }
function View_SequenceContainerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SequenceContainerComponent_3)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "flex-grow flex-1"], ["style", "flex:1;box-sizing: border-box"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SequenceContainerComponent_7)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SequenceContainerComponent_8)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SequenceContainerComponent_9)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.sequenceMode == "sequence_setup"); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.data && (_co.activeMenu == "sequence_setup")); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.data && (_co.activeMenu == "exit_criteria_setup")); _ck(_v, 7, 0, currVal_2); var currVal_3 = (_co.data && (_co.activeMenu === "history_audit")); _ck(_v, 9, 0, currVal_3); }, null); }
export function View_SequenceContainerComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { content: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "ng-flex-container-main"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SequenceContainerComponent_1)), i1.ɵdid(3, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SequenceContainerComponent_2)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 3, 0, currVal_0); var currVal_1 = (!_co.isLoading && _co.isEnabled); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_SequenceContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-sequence-container", [], null, null, null, View_SequenceContainerComponent_0, RenderType_SequenceContainerComponent)), i1.ɵdid(1, 114688, null, 0, i16.SequenceContainerComponent, [i7.WorkflowhttpService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SequenceContainerComponentNgFactory = i1.ɵccf("app-sequence-container", i16.SequenceContainerComponent, View_SequenceContainerComponent_Host_0, { csFullModal: "csFullModal", activeMenu: "activeMenu", isEnabled: "isEnabled", sequenceMode: "sequenceMode", sequenceObjectName: "sequenceObjectName" }, { readyToView: "readyToView", activeMenuItem: "activeMenuItem" }, []);
export { SequenceContainerComponentNgFactory as SequenceContainerComponentNgFactory };
