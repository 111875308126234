import * as tslib_1 from "tslib";
import { EventEmitter, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { from } from "rxjs";
import { map } from "rxjs/operators";
// import {WorkflowSetupService} from "@app/features/system-settings/workflow-setup/services/workflow-setup.service";
import { WorkflowhttpService } from "@app/workflow-common/services/workflowhttp.service";
var format = function (item) {
    return item.text;
};
var ɵ0 = format;
var SmsBlockComponent = /** @class */ (function () {
    function SmsBlockComponent(cdr, workflowService, confirmationBoxHelper, ajsScope) {
        this.cdr = cdr;
        this.workflowService = workflowService;
        this.confirmationBoxHelper = confirmationBoxHelper;
        this.ajsScope = ajsScope;
        this.smsTemplateList = [];
        this.smsTemplates = [];
        this.loading = true;
        this.hidden = true;
        this.messages = {};
        this.isEdit = false;
        this.isConditional = false;
        this.activeField = {};
        this.isMarketPlaceView = false;
        this.workflowType = 'Custom';
        this.diagramOptions = {};
        this.isReadOnlyMode = false;
        this.outputModel = new EventEmitter();
        this.close = new EventEmitter();
        this.userList = [];
        this.allSmsTags = [];
        this.smsNumbers = [];
        this.toSmsDetails = [];
        this.data = {};
        this.userTags = [];
        this.previewMessage = '';
        this.dataFunction = function (item) { return item.text; };
        this.smsScreenLabel = 'sms.templates';
        this.activeTemplateTitle = 'Please.Choose';
        this.title = '';
        this.communicationTags = {
            'Custom': [
                { 'id': 't_0', 'text': '[customer_mobile]', 'name': 'TAGS' },
                { 'id': 't_3', 'text': '[job_contact_mobile]', 'name': 'TAGS' },
                { 'id': 't_4', 'text': '[invoice_address_mobile]', 'name': 'TAGS' },
                { 'id': 't_2', 'text': '[job_address_primary_contact_mobile]', 'name': 'TAGS' },
            ],
            'Sequence': [
                { 'id': 't_0', 'text': '[sequence_contact_mobile]', 'name': 'TAGS' },
            ]
        };
        this.tagTypes = [
        /* {'id' : 't_0', 'text' : '[customer_mobile]', 'name' : 'TAGS'},
         // {'id' : 't_1', 'text' : '[work_address_mobile]', 'name' : 'TAGS'},
         /!*{'id' : 't_2', 'text' : '[job_address_mobile]', 'name' : 'TAGS'},*!/
         {'id' : 't_3', 'text' : '[job_contact_mobile]', 'name' : 'TAGS'},
         {'id' : 't_4', 'text' : '[invoice_address_mobile]', 'name' : 'TAGS'},
         // {'id' : 't_5', 'text' : '[estimate_address_mobile]', 'name' : 'TAGS'},
         // {'id' : 't_6', 'text' : '[estimate_contact_mobile]', 'name' : 'TAGS'},*/
        ];
        this.saving = false;
    }
    SmsBlockComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.oldModel = JSON.stringify(Object.assign({}, this.inputModel));
                        this.tagTypes = this.communicationTags[this.workflowType];
                        if (this.diagramOptions.objectName == 'estimate') {
                            this.communicationTags.Custom.push({ 'id': 't_7', 'text': '[estimate_contact_mobile]', 'name': 'TAGS' });
                        }
                        if (this.diagramOptions.objectName == 'opportunity' || this.diagramOptions.objectName == 'proposal') {
                            this.communicationTags.Custom.push({ 'id': 't_8', 'text': '[opportunity_site_contact_mobile]', 'name': 'TAGS' });
                        }
                        if (!this.isEdit) {
                            this.previewMessage = this.inputModel.model.message;
                        }
                        this.showDeleteBtn = this.isMarketPlaceView ? false : this.isEdit;
                        if (this.workflowType == 'Sequence' && this.diagramOptions.mode == 'sequence_config') {
                            this.showDeleteBtn = false;
                        }
                        this.loading = true;
                        return [4 /*yield*/, this.getData()];
                    case 1:
                        _a.sent();
                        if (!this.inputModel.model.templateId) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getTemplateContent(this.inputModel.model.templateId)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3:
                        this.hidden = (this.inputModel.model.message == '') ? true : false;
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    SmsBlockComponent.prototype.getData = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.workflowService.getCommunicationTemplateConfig()
                .toPromise()
                .then(function (resp) {
                var data = resp['data'];
                if (data) {
                    _this.userList = data.users;
                    _this.userList.forEach(function (line, index) {
                        _this.userTags.push({
                            id: parseInt(line.id),
                            text: line.text,
                            name: 'USERS'
                        });
                        _this.allSmsTags.push(line);
                    });
                    _this.tagTypes.forEach(function (line, index) {
                        _this.allSmsTags.push(line);
                    });
                    _this.message = _this.previewMessage;
                }
                if (_this.inputModel.model.toSmsTag) {
                    var dropDownData = _this.inputModel.model.toSmsTag;
                    _this.inputModel.model.toSmsTag = [];
                    dropDownData.forEach(function (value, key) {
                        var selectedItem = _this.allSmsTags.find(function (items) { return items.id == value.value; });
                        if (_this.allSmsTags.indexOf(value.value) === -1) {
                            if (!selectedItem) {
                                var data_1 = { id: 'e_' + key, text: value.displayName };
                                _this.allSmsTags.push(data_1);
                                _this.smsNumbers.push(data_1);
                                _this.userTags.push(data_1);
                                _this.inputModel.model.toSmsTag.push('e_' + key);
                            }
                            else {
                                _this.inputModel.model.toSmsTag.push(value.value);
                            }
                        }
                    });
                }
                _this.select2LabelConfig = {
                    width: '100%',
                    data: _this.groupByTags(),
                    tags: true,
                    createSearchChoice: function (term, data) {
                        var exists = false;
                        data.forEach(function (idText) {
                            if (idText.text == term)
                                exists = true;
                        });
                        return term.indexOf(' ') >= 0 || exists ? null :
                            {
                                id: term + " (Create New)",
                                text: term + " (Create New)"
                            };
                    },
                    formatSelection: format,
                    formatResult: format,
                    multiple: true
                };
                resolve(true);
            }, function (err) {
                reject(err);
            });
        });
    };
    SmsBlockComponent.prototype.getTemplateContent = function (templateId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        _this.workflowService.getTemplateById(templateId)
                            .toPromise()
                            .then(function (resp) {
                            var data = resp['data'];
                            if (data) {
                                _this.activeTemplateTitle = data['title'];
                                _this.message = _this.previewMessage = data['message'];
                                _this.title = data['title'];
                            }
                            resolve(resp);
                        }, function (err) {
                            reject(err);
                        });
                    })];
            });
        });
    };
    SmsBlockComponent.prototype.setTemplateDetails = function (event, type) {
        this.inputModel.model.templateId = event['templateId'];
        this.previewMessage = event['message'];
        this.message = this.previewMessage;
        this.title = event['templateName'];
    };
    SmsBlockComponent.prototype.ngAfterViewInit = function () {
    };
    SmsBlockComponent.prototype.closeModal = function () {
        this.close.emit(this.isEdit);
    };
    SmsBlockComponent.prototype.validateItems = function () {
        if (this.workflowType == 'Sequence') {
            return !this.inputModel.model.templateId;
        }
        else {
            if (!this.inputModel.model.templateId || !this.inputModel.model.toSmsTag) {
                return true;
            }
        }
        return this.isConditional ? this.inputModel.condition.operator === '' || this.inputModel.condition.value === '' : false;
    };
    SmsBlockComponent.prototype.groupByTags = function () {
        var allSMSTag = [];
        var newItems = [];
        newItems.push(this.userTags);
        newItems.push(this.tagTypes);
        var source = from(newItems);
        var tags = source.pipe(map(function (groupItems) {
            var _item = { text: '', children: [] };
            var cnt = 0;
            groupItems.forEach(function (group) {
                if (cnt === 0) {
                    _item.text = group.name;
                }
                _item.children.push({
                    id: group.id,
                    text: group.text
                });
                cnt++;
            });
            return _item;
        }));
        var subscribe = tags.subscribe(function (val) { return allSMSTag.push(val); });
        return allSMSTag;
    };
    SmsBlockComponent.prototype.addValue = function () {
        var _this = this;
        this.saving = true;
        // let selectedItem = this.smsTemplates.find((item:any) => item.id == this.inputModel.model.templateId);
        this.inputModel.model.title = this.title;
        this.inputModel.model.message = this.previewMessage;
        this.inputModel.model.templateId = this.inputModel.model.templateId.toString();
        this.toSmsDetails = this.workflowType == 'Sequence' ? ['t_0'] : this.inputModel.model.toSmsTag;
        this.inputModel.model.toSmsTag = [];
        var that = this;
        this.toSmsDetails.forEach(function (smsData, key) {
            var selectedUser = _this.userList.find(function (items) { return items.id == smsData; });
            var selectedTag = _this.tagTypes.find(function (items) { return items.id == smsData; });
            var selectedNumbers = _this.smsNumbers.find(function (items) { return items.id == smsData; });
            if (!that.inputModel.model.toSmsTag.some(function (item) { return item.value === smsData; }) && selectedUser) {
                _this.data = { type: "user", value: smsData, displayName: selectedUser.text };
                _this.inputModel.model.toSmsTag.push(_this.data);
            }
            else if (!that.inputModel.model.toSmsTag.some(function (item) { return item.value === smsData; }) && selectedTag) {
                _this.data = { type: "tag", value: smsData, displayName: selectedTag.text };
                _this.inputModel.model.toSmsTag.push(_this.data);
            }
            else if (!that.inputModel.model.toSmsTag.some(function (item) { return item.value === smsData; }) || selectedNumbers) {
                smsData = (selectedNumbers) ? selectedNumbers.text : smsData;
                _this.data = { type: "normal", value: smsData, displayName: smsData };
                _this.inputModel.model.toSmsTag.push(_this.data);
            }
        });
        this.inputModel.model.templateId = Number(this.inputModel.model.templateId);
        this.newModel = JSON.stringify(Object.assign({}, this.inputModel));
        if (_.isEqual(this.oldModel, this.newModel)) {
            this.workflowService.triggerEvent({ 'isUpdated': false, 'isEdit': this.isEdit, 'action': 'valueChanged' });
        }
        else {
            this.workflowService.triggerEvent({ 'isUpdated': true, 'isEdit': this.isEdit, 'action': 'valueChanged' });
        }
        setTimeout(function () {
            _this.outputModel.emit(_this.inputModel);
        }, 0);
    };
    SmsBlockComponent.prototype.selectedSmsAddress = function (event, data) {
        if (event === void 0) { event = null; }
        if (data === void 0) { data = null; }
        var lab = event[event.length - 1];
        if (lab.includes(" (Create New)")) {
            lab = lab.replace(" (Create New)", '');
            var valueArray = $("#additional-sms").select2("data");
            if (valueArray) {
                valueArray[valueArray.length - 1]['id'] = lab;
                valueArray[valueArray.length - 1]['text'] = lab;
            }
            $("#additional-sms").select2('data', valueArray);
            event[event.length - 1] = lab;
        }
        this.inputModel.model.toSmsTag = event;
    };
    SmsBlockComponent.prototype.uploadTemplateDetails = function (id) {
        this.hidden = false;
        var selectedItem = this.smsTemplates.find(function (item) { return item.id == id; });
        this.inputModel.model.title = selectedItem.title;
        this.previewMessage = this.inputModel.model.message = selectedItem.message;
        this.message = this.previewMessage;
        return true;
    };
    SmsBlockComponent.prototype.setSmsTemplateList = function (data) {
        var _this = this;
        if (!data.length) {
            return;
        }
        data.forEach(function (item) {
            _this.smsTemplateList.push({
                id: parseInt(item.id),
                text: item.title
            });
            if (_this.isEdit && item.id == _this.inputModel.model.templateId) {
                _this.previewMessage = item.message;
                _this.inputModel.model.title = item.title;
            }
        });
    };
    SmsBlockComponent.prototype.removeCell = function () {
        var _this = this;
        this.confirmationBoxHelper
            .getConfirmation(this.deleteMessage, this.ajsScope)
            .then(function () {
            _this.close.emit('remove');
        });
    };
    return SmsBlockComponent;
}());
export { SmsBlockComponent };
export { ɵ0 };
