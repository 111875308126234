import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import { WorkflowhttpService } from "@app/workflow-common/services/workflowhttp.service";

declare var _;

@Component({
  selector: 'app-addjob-block',
  templateUrl: './addjob-block.component.html',
  styles: []
})
export class AddjobBlockComponent implements OnInit {

    name:string = 'addjobBlock';
    @Input() inputModel:any;
    @Input() activeField:any = {};
    @Input() isConditional:boolean = false;
    @Input() isEdit:boolean = false;
    @Input() operators:any;
    @Input() isMarketPlaceView:boolean = false;
    @Input() deleteMessage:string;
    @Input() warningMessage:string;
    @Input() status:any;
    @Input() isReadOnlyMode:boolean = false;

    @Output() outputModel:EventEmitter<any> = new EventEmitter<any>();
    @Output() close:EventEmitter<any> = new EventEmitter<any>();

    showDeleteBtn:boolean;
    jobDescriptions:any=[];
    jobDescriptionsList:any = {};
    isLoading:boolean = true;
    oldModel:any;
    newModel:any;
    constructor(
        private workflowService: WorkflowhttpService,
        @Inject('confirmationBoxHelper') private confirmationBoxHelper,
        @Inject('$scope') private ajsScope,
    ) { }

    ngOnInit() {
        this.oldModel = JSON.stringify(Object.assign({}, this.inputModel));
        this.showDeleteBtn = this.isMarketPlaceView ? false : this.isEdit;
        //console.log('input', this.inputModel)
        this.resolveData();
    }

    addValue() {
        //console.log('save',this.inputModel);
        const jobId = this.inputModel.model.jobDescriptionId;
        this.inputModel.model['jobDescriptionDisplay'] = this.jobDescriptionsList[jobId]['description'];
        this.newModel = JSON.stringify(Object.assign({}, this.inputModel));
        if(this.oldModel ===  this.newModel){
            this.workflowService.triggerEvent({'isUpdated' : false ,'isEdit' : this.isEdit,'action' : 'valueChanged'});
        }else{
            this.workflowService.triggerEvent({'isUpdated' : true ,'isEdit' : this.isEdit,'action' : 'valueChanged'});
        }
        this.outputModel.emit(this.inputModel);
    }

    closeModal() {
        this.close.emit(this.isEdit);
    }

    removeCell() {
        this.confirmationBoxHelper
            .getConfirmation(this.deleteMessage, this.ajsScope)
            .then(() => {
                this.close.emit('remove');
            });
    }

    validateItems() {
        if(this.isConditional)
            return this.inputModel.condition.operator ==='' || _.isEmpty(this.inputModel.condition.value) || !this.inputModel.model.jobDescriptionId;
        else
            return !this.inputModel.model.jobDescriptionId;
    }

    getJobDescriptions() {
      const promise = new Promise( (resolve) => {
        this.workflowService.getJobDescriptions({
            ___noPageloading:true,
            limit:9999
        })
        .subscribe(resp => {
           //console.log(resp);
           const jobDescriptions = resp['jobDescriptions'];
            this.jobDescriptions = jobDescriptions.map( (item) => {
                return { id: item.id, text: item.description }
            });
            this.jobDescriptionsList = _.indexBy(jobDescriptions, 'id');
            resolve();
        });
      });
      return promise;
    }

    async resolveData() {
        this.isLoading = true;
        await this.getJobDescriptions();
        this.isLoading = false;

    }

}
