<div class="modal-header">
    <h4 class="modal-title pull-left">Notification settings</h4>
</div>

<div class="modal-body">
    <div *ngIf="status && isEdit" class="wfe-block-notification-bar">
        <div cs-information-bar [icon]="'ss-info'" [message]="warningMessage"></div>
    </div>
    <app-condition-edge *ngIf="isConditional"
        [inputModel]="inputModel"
        [isMarketPlaceView]="isMarketPlaceView"
        [operators]="operators"
        [isReadOnlyMode]="isReadOnlyMode"
        [activeField] = "activeField">
    </app-condition-edge>
    <div class="item form-item p-15" [ngClass]="{'border-top': isConditional}">
        <div class="control">
            <label class="control-label required_field">Choose Role:</label>
            <div>
                <input
                   id="user-roles-input"
                   csSelect2
                   [(ngModel)]="inputModel.model.roles"
                   [dropdownvalue]="inputModel.model.roles"
                   [select2Config]="select2LabelConfig"
                   (selected)="selectedRoles($event)"
                   class="full-width-select2 cs-select2-wfe mb-10"/>
            </div>
        </div>
        <div class="control">
            <label class="control-label required_field">Message:</label>
            <ng-container >
                <div class="item clearfix">
                    <quill-editor
                        [modules]="editorOptions"
                        (onContentChanged)="onChange($event)"
                        placeholder="Assign the task using '@', tag the task using '#'"> </quill-editor>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<div class="modal-footer">
    <a href="javascript:void(0)" class="btn" (click)="closeModal()">Close</a>
    <button [hidden]="!showDeleteBtn" (click)="removeCell()" class="btn btn-danger btn-medium float-left" type="button">Delete </button>
    <button class="btn btn-primary" (click)="addValue()" [disabled]="saving || isLoading  || (!mentionContent && inputModel.model.roles.length === 0)">
        <span *ngIf="!saving" translate>Save</span>
        <span *ngIf="saving" translate>Saving</span>
    </button>
</div>
