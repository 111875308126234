import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { CsFullScreenModalComponent } from "@app/directives";
import { mxgraphFactory } from "mxgraph-factory";
import { MxGraphSequenceService } from "@app/workflow-common/services/mx-graph-sequence";
import { WorkflowhttpService } from "@app/workflow-common/services/workflowhttp.service";
import { DiagramViewComponent } from "@app/workflow-common/diagram-view/diagram-view.component";
import { noop } from "rxjs/index";
import { CsToastBoxService } from "@app/services";
var _a = mxgraphFactory({
    mxLoadResources: false,
    mxLoadStylesheets: true,
}), mxGraph = _a.mxGraph, mxCodec = _a.mxCodec, mxUtils = _a.mxUtils, mxGeometry = _a.mxGeometry;
var DiagramViewSequenceComponent = /** @class */ (function (_super) {
    tslib_1.__extends(DiagramViewSequenceComponent, _super);
    function DiagramViewSequenceComponent(diagramService, httpService, confirmationBoxHelper, ajsScope, toastBox) {
        var _this = _super.call(this, diagramService, httpService, confirmationBoxHelper, ajsScope, toastBox) || this;
        _this.diagramService = diagramService;
        _this.httpService = httpService;
        _this.confirmationBoxHelper = confirmationBoxHelper;
        _this.ajsScope = ajsScope;
        _this.toastBox = toastBox;
        _this.viewMode = false;
        _this.scheduleActivityTypes = [];
        _this.mode = 'sequence_setup'; // sequence_config
        _this.workflowType = 'Sequence';
        _this.additionalData = {};
        _this.afterRenderDiagram = new EventEmitter();
        _this.beforeRenderDiagram = new EventEmitter();
        _this.sourceXml = null;
        _this.showConfirmation = false;
        _this.confirmationAction = '';
        _this.confirmTextInputModel = '';
        _this.inValidConfirmText = false;
        _this.confirmText = '';
        return _this;
    }
    DiagramViewSequenceComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscription = this.httpService.workflowEventTrigger$.subscribe({
            next: function (content) {
                if (typeof content == 'string') {
                    if (content === 'autoSave' && _this.mode == 'sequence_setup') {
                        _this.saveXml(false);
                    }
                }
                else if (typeof content == 'object') {
                    var action = content.action, value = content.value;
                    if (action === 'showSkipConfirmation') {
                        _this.confirmationAction = 'skip';
                        _this.confirmText = 'SKIP';
                        _this.showConfirmation = true;
                        _this.activeCell = value;
                    }
                    else if (action === 'showUndoConfirmation') {
                        _this.confirmationAction = 'undo';
                        _this.confirmText = 'UNDO';
                        _this.showConfirmation = true;
                        _this.activeCell = value;
                    }
                    else if (content.actionName == 'beforeSave' && content.initializer != true) {
                        _this.updatedTaskId = content.data.block_name + '_' + content.data.id;
                    }
                }
            }
        });
    };
    DiagramViewSequenceComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.beforeRenderDiagram.emit(this);
        var options = {
            xmlSource: this.data.xmlsource,
            workflowId: this.data.id,
            workflowStatus: this.data.status == 1,
            isScheduler: false,
            outlineContainer: this.outlineContainer,
            viewMode: this.viewMode,
            userRoles: this.userRoles,
            tags: this.tags,
            scheduleActivityTypes: this.scheduleActivityTypes,
            usersList: this.usersList,
            workflowType: this.workflowType,
            additionalData: this.additionalData,
            mode: this.mode,
            sequenceObjectName: this.sequenceObjectName,
            mainObjectName: this.sequenceObjectName,
            confirmationBoxHelper: this.confirmationBoxHelper,
            ajsScope: this.ajsScope
        };
        if (this.mode == 'sequence_setup') {
            this.diagramService.initialize(this.container, options);
            this.diagramService.setDiagramComponent(this);
            this.mxGraph = this.diagramService.getGraph();
            this.diagramService.render(function (mxGraph) {
                _this.afterRenderDiagram.emit(mxGraph);
            });
        }
        else if (this.mode == 'sequence_config') {
            this.beforeInitialize(function (xmlSource) {
                options.xmlSource = xmlSource;
                _this.diagramService.initialize(_this.container, options);
                _this.diagramService.setDiagramComponent(_this);
                _this.mxGraph = _this.diagramService.getGraph();
                var c = _this.mxGraph.getModel().getCell('cs_initializer');
                _this.diagramService.render(function (mxGraph) {
                    _this.afterRenderDiagram.emit(mxGraph);
                    _this.mxGraph.updateCellSize(c, true);
                });
            });
        }
    };
    DiagramViewSequenceComponent.prototype.beforeInitialize = function (cb) {
        if (cb === void 0) { cb = noop; }
        var doc = mxUtils.parseXml(this.data.xmlsource);
        var codec = new mxCodec(doc);
        var $mxGraph = new mxGraph(this.container.nativeElement);
        codec.decode(doc.documentElement, $mxGraph.getModel());
        var initializerBlock = codec.getObject('cs_initializer');
        var data = {
            model: this.additionalData,
            action: 'sequence_save_send'
        };
        initializerBlock.setValue(data);
        var container = this.diagramService.prepareInitBlockContainer(data, initializerBlock);
        var current = initializerBlock.getGeometry();
        current.width = container['width'];
        current.height = container['height'];
        initializerBlock.setGeometry(new mxGeometry(current.x, current.y, current.width, current.height));
        var selectedContact = this.additionalData['selectedContact'];
        var contactHasMobile = !selectedContact['mobile'] ? false : selectedContact['mobile'].length > 0;
        var triggerData = {
            action: 'save:disabled',
            value: !(this.additionalData['displayContactInfo']['emailAddress'] || this.additionalData['displayContactInfo']['mobile'])
                || (contactHasMobile && !this.additionalData['displayContactInfo']['mobile']),
        };
        this.httpService.triggerCustomHeaderEvent(triggerData);
        var codec2 = new mxCodec();
        var result = codec2.encode($mxGraph.getModel());
        var newXmlSource = mxUtils.getXml(result);
        $mxGraph.destroy();
        cb(newXmlSource);
    };
    DiagramViewSequenceComponent.prototype.checkConfirmText = function () {
        this.inValidConfirmText = !(this.confirmTextInputModel.trim().toUpperCase() == this.confirmText);
    };
    DiagramViewSequenceComponent.prototype.onCloseConfirmation = function () {
        this.showConfirmation = false;
        this.confirmTextInputModel = '';
        this.confirmationAction = '';
        this.confirmText = '';
        this.activeCell = null;
    };
    DiagramViewSequenceComponent.prototype.setConfirmSkipUndo = function () {
        this.checkConfirmText();
        if (this.inValidConfirmText) {
            return false;
        }
        if (this.activeCell) {
            var cellValue = this.activeCell.getValue();
            if (!cellValue['model']['current_task_status']) {
                cellValue['model']['current_task_status'] = 'skip';
            }
            else {
                cellValue['model']['current_task_status'] = '';
            }
            this.diagramService.mxGraph.labelChanged(this.activeCell, cellValue);
            this.onCloseConfirmation();
        }
    };
    DiagramViewSequenceComponent.prototype.sendSequenceAction = function () {
        var _this = this;
        var triggerData = {
            action: 'save:disabled',
            value: true,
        };
        this.httpService.triggerCustomHeaderEvent(triggerData);
        var _a = this.additionalData, selectedSequenceId = _a.selectedSequenceId, objectId = _a.objectId, displayContactInfo = _a.displayContactInfo, selectedContact = _a.selectedContact, displaySelectedContactName = _a.displaySelectedContactName, displaySequenceName = _a.displaySequenceName, contactId = _a.contactId;
        var sequence = {};
        sequence['workflowId'] = selectedSequenceId;
        sequence['sequenceObjectName'] = this.sequenceObjectName;
        sequence['objectId'] = objectId;
        sequence['workflowType'] = 'Sequence';
        sequence['xmlSource'] = this.diagramService.getXml();
        sequence['dagSource'] = this.prepareDag();
        sequence['dagsource'] = JSON.parse(this.diagramService.diagramComponent.data.dagsource);
        sequence['sequenceData'] = {
            displayContactInfo: sequence['dagSource'].blockObjects.cs_initializer.model.displayContactInfo,
            selectedContact: sequence['dagSource'].blockObjects.cs_initializer.model.selectedContact,
            displaySelectedContactName: sequence['dagSource'].blockObjects.cs_initializer.model.displaySelectedContactName,
            displaySequenceName: sequence['dagSource'].blockObjects.cs_initializer.model.displaySequenceName,
            selectedSequenceId: sequence['dagSource'].blockObjects.cs_initializer.model.selectedSequenceId,
            contactId: sequence['dagSource'].blockObjects.cs_initializer.model.contactId,
            exitCriteria: sequence['dagsource'].blockObjects.exitCriteria
        };
        this.httpService.postSendSequenceRequest({ sequence: sequence }, this.sequenceObjectName, objectId)
            .subscribe({
            next: function (resp) {
                if (resp['status'] == 'success') {
                    _this.createDagRunAction(resp['data']);
                }
                else if (resp['status'] == 'error' && resp['warning']) {
                    // this.sendingSequenceText = 'Send'
                }
            }
        });
        //console.log(sequence);
    };
    DiagramViewSequenceComponent.prototype.createDagRunAction = function (data) {
        var _this = this;
        var objectId = this.additionalData.objectId;
        var sequence = {};
        sequence['workflowId'] = data['workflowId'];
        sequence['sequenceObjectName'] = this.sequenceObjectName;
        sequence['objectId'] = objectId;
        sequence['dagRunId'] = data['dagRunId'];
        sequence['dagStatus'] = data['dagStatus'];
        this.httpService.posSequenceDagRunCreateRequest({ sequence: sequence }, this.sequenceObjectName, objectId)
            .subscribe({
            next: function (resp) {
                if (resp['status'] == 'success') {
                    _this.csFullModal.close();
                    var respData = resp['data'];
                    respData['closePopup'] = true;
                    _this.popupModalOnClose.next(respData);
                }
                else if (resp['status'] == 'error' && resp['warning']) {
                    var triggerData = {
                        action: 'save:disabled',
                        value: false,
                    };
                    _this.httpService.triggerCustomHeaderEvent(triggerData);
                }
            }
        });
    };
    DiagramViewSequenceComponent.prototype.ngOnDestroy = function () {
        this.diagramService.mxOutline.destroy();
        this.diagramService.destroyMxGraph();
        this.data = {};
        this.subscription.unsubscribe();
        console.log('destroyed2 mxgraph');
    };
    DiagramViewSequenceComponent.prototype.onResize = function () {
    };
    return DiagramViewSequenceComponent;
}(DiagramViewComponent));
export { DiagramViewSequenceComponent };
