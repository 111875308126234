import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {WorkflowhttpService} from "@app/workflow-common/services/workflowhttp.service";
import {TimelineService} from "@app/shared/services/timeline.service";
import {Subject} from "rxjs";

@Component({
  selector: 'app-workflow-audit-list',
  templateUrl: './workflow-audit-list.component.html',
    styleUrls: ['./workflow-audit-list.component.scss'],
})
export class WorkflowAuditListComponent implements OnInit {

    loading:boolean = false;
    childCurrentPage:any;
    currentPage:number = 1;
    pageLimit:number = 10;
    firstTimeLoad:boolean = false;
    results: any;
    hasResult: any;
    dateToDisplay:any;
    selectedItem:any = {};
    totalEntriesCount: number;
    filterList: any;
    appliedViewsFilter: any[] = [];
    param: any;
    cursor: any;
    activeCursor: any;
    widthChangeEventSubject = new Subject<any>();



    @Input() data:any;
    @Input() objectField: any;
    @Output() activeAuditDataItem = new EventEmitter();
  constructor(  private workflowService: WorkflowhttpService,
                private ts:TimelineService) { }

  ngOnInit() {
      this.loading=true;
      this.childCurrentPage=1;
      if(this.data) {
          this.param = {
              page: this.currentPage,
              limit: this.pageLimit
          };
          this.getAuditData(true,true);
      }
  }
    ngAfterViewInit(){
        let left = $('#add-filter-dropdown').position().left - $('.aside-inner').width() + 'px';
        setTimeout(() => {
            $(".dropdown-menu").css("left", left +' !important');
        }, 200);
    }
    getAuditData(resetPage: boolean = false, isInitialCall: boolean = false){
        if(resetPage) {
            this.currentPage=1;
            this.firstTimeLoad=true;
        }
        this.workflowService.workflowAuditRecords(this.data['id'],this.data['type'], this.param
        ).subscribe(data =>{
            this.results = data['auditRecords'];
            this.totalEntriesCount = data['totalCount'];
            if(isInitialCall){
                this.filterList = data['defaultFilters'];
            }
            this.hasResult = this.results.length;
            this.loading=false;
        });
    }

    auditHistoryHandler(item){
        if(this.cursor == 'pointer'){
            this.selectedItem = item;
            this.activeCursor = 'pointer';
            if(item.eventType.includes('_task')){
                this.workflowService.getAuditDagsource(item.id).
                subscribe(resp => {
                    setTimeout(() => {
                        this.activeAuditDataItem.emit({
                            'auditRecord': item,
                            'xmlsource': this.data.xmlSource,
                            'dagSource': resp['dagSource'],
                        });
                    },300);
                });
            }else{
                this.activeAuditDataItem.emit({
                    'auditRecord': item,
                });
            }
        }
    }
    getDisplayDate(){
        this.dateToDisplay = this.ts.convertTimeLineDateTime(this.results.dateToDisplay);
    }
    fetchAuditRecord(event?: any){
        this.currentPage = event['page'];
        this.param.page = this.currentPage;
        if(this.firstTimeLoad && event['page'] == 1) {
            return;
        }
        this.firstTimeLoad = false;
        this.getAuditData( false)

    }

    addToAppliedFilters(filter: any){
        this.appliedViewsFilter.push(filter);
        this.setParam(this.appliedViewsFilter);
        this.getAuditData(true);
    }
    setParam(filters: any){
        this.param = {
            page: this.currentPage,
            limit: this.pageLimit,
        }
        this.param.filter = filters;
    }
    removeFilter(filter: any){
        this.appliedViewsFilter.splice(filter, 1);
        this.setParam(this.appliedViewsFilter);
        this.getAuditData(true);
    }
    onMouseover(data: any){
      if(data.eventType == 'activate_workflow' || data.eventType == 'deactivate_workflow' || data.eventType == 'enable_workflow' || data.eventType == 'disable_workflow' || data.eventType == 'add_workflow' || data.eventType == 'edit_workflow' || data.eventType == 'add_criteria' || data.eventType == 'delete_criteria'){
          this.cursor = 'not-allowed';
      }else{
          this.cursor = 'pointer';
      }
      return this.cursor;
    }
}
