import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ChangeDetectorRef,
    AfterViewInit,
    ViewChild, ElementRef, Inject
} from '@angular/core';
import { WorkflowhttpService } from "../../services/workflowhttp.service";
import {from} from "rxjs";
import {map} from "rxjs/operators";
import {forEach} from "@angular/router/src/utils/collection";
declare var $: any;
declare var _;
const format = (item) => {
    return item.text;
}
const removeTags = (str) => {
    if ((str===null) || (str===''))
        return '';
    else
        str = str.toString();
    return str.replace( /(<([^>]+)>)/ig, '');
}
@Component({
    selector: 'app-email-block',
    templateUrl: './email-block.component.html',
    styles: []
})
export class EmailBlockComponent implements OnInit, AfterViewInit {

    name: 'emailBlock';
    isRequiredDelay:false;
    emailTemplateList: any[] = [];
    emailTemplates: any[] = [];
    loading: boolean = true;
    isSmtp: boolean = false;
    hidden: boolean = true;
    editor: any;
    labels: any;
    ccShow: boolean = false;
    bccShow: boolean = false;
    messages = {};
    @Input() isEdit:boolean  = false;
    @Input() inputModel:any;
    @Input() isConditional:boolean = false;
    @Input() activeField:any = {};
    @Input() operators:any;
    @Input() isMarketPlaceView:boolean = false;
    @Input() deleteMessage: string;
    @Input() warningMessage:string;
    @Input() workflowType: string = 'Custom';
    @Input() diagramOptions: any = {};
    @Input() status:any;
    @Input() isReadOnlyMode:boolean = false;


    @Output() outputModel:EventEmitter<any> = new EventEmitter<any>();
    @Output() close:EventEmitter<any> = new EventEmitter<any>();

    select2TemplateList : any;
    select2LabelConfig : any;
    select2LabelConfig2: any;
    fromEmail : any;
    userList : any[]= [];
    allEmailTags : any[]= [];
    toEmailDetails : any[]= [];
    emailAddress: any[]= [];
    data :any={};
    showDeleteBtn:boolean;
    userTags : any[]= [];
    previewSubject: string = '';
    previewMessage: string = '';
    activeTemplateTitle:string;
    saving: boolean = false;
    subject: any;
    message: any;
    oldModel: any;
    newModel: any
    key:any[] = ['toEmailTag','ccEmailTag','bccEmailTag'];
    dataFunction = function(item){ return item.text; };
    communicationTags: any = {
        'Custom' : [
            {'id' : 't_0', 'text' : '[customer_email]', 'name' : 'TAGS'},
            {'id' : 't_3', 'text' : '[job_contact_email]', 'name' : 'TAGS'},
            {'id' : 't_4', 'text' : '[invoice_address_email]', 'name' : 'TAGS'},
            {'id' : 't_2', 'text' : '[job_address_primary_contact_email]','name' : 'TAGS'},
        ],
        'Sequence': [
            {'id' : 't_0', 'text' : '[sequence_contact_email]', 'name' : 'TAGS'},
            /*{'id' : 't_5', 'text': '[opportunity_address_email]', 'name': 'TAGS'},
            {'id' : 't_6', 'text': '[opportunity_site_contact_email]', 'name': 'TAGS'},*/
        ]
    };
    tagTypes = [];
    emailScreenLabel: string = 'email.templates';

    constructor(private cdr: ChangeDetectorRef,
        private workflowService: WorkflowhttpService,
        @Inject('confirmationBoxHelper') private confirmationBoxHelper,
        @Inject('$scope') private ajsScope,
    ) { }

    getData() {
        return new Promise( (resolve, reject) => {
            this.workflowService.getCommunicationTemplateConfig()
            .toPromise()
            .then( (resp) => {
                let data = resp['data'];
                if(data) {
                    this.isSmtp = data.smtpDetails.smtp;
                    this.fromEmail = data.smtpDetails.smtpList;
                    this.userList = data.users;
                    this.userList.forEach((line, index) => {
                        this.userTags.push({
                            id: parseInt(line.id),
                            text: line.text,
                            name: 'USERS'
                        });
                        this.allEmailTags.push(line);
                    });
                    this.tagTypes.forEach((line, index) => {
                        this.allEmailTags.push(line);
                    });

                    if(this.workflowType != 'Sequence' && this.inputModel.model.toEmailTag){
                        this.key.forEach(emailType => {
                            let dropDownData = this.inputModel.model[emailType];
                            if(dropDownData){
                                this.inputModel.model[emailType] = [];
                                dropDownData.forEach((value, key) => {
                                    let selectedItem = this.allEmailTags.find((items: any) => items.id == value.value);
                                    if (this.allEmailTags.indexOf(value.value) === -1) {
                                        if (!selectedItem) {
                                            let data = {id: 'e_' + key, text: value.displayName};
                                            this.allEmailTags.push(data);
                                            this.emailAddress.push(data);
                                            this.userTags.push(data);
                                            this.inputModel.model[emailType].push('e_' + key);
                                        } else {
                                            this.inputModel.model[emailType].push(value.value);
                                        }
                                    }
                                });
                            }
                        });
                    }
                    const createSearchChoiceFn = (term, data) => {
                        let exists = false;
                        data.forEach((idText) => {
                            if (idText.text == term)
                                exists = true;
                        });
                        let regex = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$', 'g');
                        return term.indexOf(' ') >= 0 || exists || !term.match(regex) ? null :
                            {
                                id: term + " (Create New)",
                                text: term + " (Create New)"
                            };
                    };

                    this.select2LabelConfig = {
                        width: '100%',
                        data: this.groupByTags(true),
                        tags: true,
                        createSearchChoice: createSearchChoiceFn,
                        formatSelection: format,
                        formatResult: format,
                        multiple: true
                    }

                    this.select2LabelConfig2 = {
                        width: '100%',
                        data: this.groupByTags(false),
                        tags: true,
                        createSearchChoice: createSearchChoiceFn,
                        formatSelection: format,
                        formatResult: format,
                        multiple: true
                    }
                    resolve(true);
                }
            }, (err) => {
                reject(err);
            })
        });
    }

    async ngOnInit() {
        this.oldModel = JSON.stringify(Object.assign({}, this.inputModel));
        if(this.diagramOptions.objectName == 'estimate'){
            this.communicationTags.Custom.push(  {'id' : 't_7', 'text' : '[estimate_contact_email]','name' : 'TAGS'});
        }
        if(this.diagramOptions.objectName == 'opportunity' || this.diagramOptions.objectName == 'proposal'){
            this.communicationTags.Custom.push(  {'id' : 't_8', 'text' : '[opportunity_site_contact_email]','name' : 'TAGS'});
        }
        this.tagTypes = this.communicationTags[this.workflowType];
        this.showDeleteBtn = this.isMarketPlaceView ? false : this.isEdit;
        if (this.workflowType == 'Sequence' && this.diagramOptions.mode == 'sequence_config') {
            this.showDeleteBtn = false;
        }
        if (!this.isEdit) {
            this.previewSubject = this.inputModel.model.subject;
            this.previewMessage = this.inputModel.model.message;
            this.activeTemplateTitle = 'Please choose';
        }

        //console.log('inputModel.model.templateId', this.inputModel.model.templateId);
        this.loading=true;
        await this.getData();
        if (this.inputModel.model.templateId) {
            await this.getTemplateContent(this.inputModel.model.templateId)
            this.subject = `<p>${this.previewSubject}</p>`
            this.message = this.previewMessage;
        }
        this.hidden = (this.inputModel.model.subject == '') ? true : false;
        this.loading = false;
    }

    async getTemplateContent(templateId:string|number) {
        return new Promise( (resolve, reject) => {
            this.workflowService.getTemplateById(templateId)
                .toPromise()
                .then( (resp) => {
                    let data = resp['data'];
                    if(data) {
                        this.previewSubject = data['subject'];
                        this.previewMessage = data['message'];
                        this.activeTemplateTitle = data['title'];
                    }
                    resolve(resp);
                },
                (err) => {
                    reject(err);
                });
        });
    }

    setTemplateDetails(event:any, type: string) {
        //console.log(event, type);
        this.inputModel.model.templateId = event['templateId'];
        this.previewSubject = event['subject'];
        this.previewMessage = event['message'];
        this.subject = `<p>${this.previewSubject}</p>`
        this.message = this.previewMessage;
    }

    ngAfterViewInit() {

    }

    groupByTags(includeTagTypes = false) {
        const allEmailTag = [];
        const newItems: any[] = [];
        newItems.push(this.userTags);
        if(includeTagTypes) {
            newItems.push(this.tagTypes);
        }
        const source = from(newItems);
        const tags = source.pipe(
            map(groupItems => {
                const _item: any = {text: '', children: []};
                let cnt: number = 0;
                groupItems.forEach( group => {
                    if (cnt === 0) {
                        _item.text = group.name;
                    }
                    _item.children.push({
                        id: group.id,
                        text: group.text
                    });
                    cnt++;
                });
                return _item;
            })
        );
        const subscribe = tags.subscribe(val => allEmailTag.push(val));
        return allEmailTag;
    }

    addValue() {
        this.saving=true;
        this.inputModel.model.templateId = this.inputModel.model.templateId.toString();
        //let selectedItem = this.emailTemplates.find((item:any) => item.id == this.inputModel.model.templateId);
        this.inputModel.model.subject = removeTags(this.previewSubject);
        this.inputModel.model.message = this.previewMessage;
        this.toEmailDetails = this.workflowType == 'Sequence' ? ['t_0'] : this.inputModel.model;
        let regex = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$','g');
        let that =this;
        this.key.forEach(emailType => {
            let emailTagType = this.toEmailDetails[emailType];
            this.inputModel.model[emailType] = [];
            this.data = {};
            if(emailTagType){
                emailTagType.forEach((emailData, key) => {
                    let selectedUser = this.userList.find((items: any) => items.id == emailData);
                    let selectedTag = this.tagTypes.find((items: any) => items.id == emailData);
                    let selectedEmail = this.emailAddress.find((items: any) => items.id == emailData);
                    if ((!that.inputModel.model.toEmailTag.some((item) => item.value === emailData) || emailData !== 'toEmailTag') && selectedUser) {
                        this.data = {type: "user", value: emailData, displayName: selectedUser.text};
                    } else if ((!that.inputModel.model.toEmailTag.some((item) => item.value === emailData) || emailData !== 'toEmailTag') && selectedTag) {
                        this.data = {type: "tag", value: emailData, displayName: selectedTag.text};
                    } else if ((!that.inputModel.model.toEmailTag.some((item) => item.value === emailData) || emailData !== 'toEmailTag') && (selectedEmail || emailData.match(regex))) {
                        emailData = (selectedEmail) ? selectedEmail.text : emailData;
                        this.data = {type: "normal", value: emailData, displayName: emailData};
                    }
                    if(this.data){
                        this.inputModel.model[emailType].push(this.data);
                    }
                });
            }
            this.inputModel.model.templateId = Number(this.inputModel.model.templateId);
            this.newModel = JSON.stringify(Object.assign({}, this.inputModel));
            if(_.isEqual(this.oldModel, this.newModel) ){
                this.workflowService.triggerEvent({'isUpdated' : false ,'isEdit' : this.isEdit,'action' : 'valueChanged'});
            }else{
                this.workflowService.triggerEvent({'isUpdated' : true ,'isEdit' : this.isEdit,'action' : 'valueChanged'});
            }
        });
        setTimeout(() =>{
            this.outputModel.emit(this.inputModel);
        },0);
    }

    closeModal() {
        this.close.emit(this.isEdit);
    }

    validateItems() {
        if(this.workflowType == 'Sequence'){
            return !this.inputModel.model.templateId;
        }
        else {
            if(!this.inputModel.model.templateId || !this.inputModel.model.toEmailTag || (this.isSmtp && !this.inputModel.model.fromEmail)){
                return true;
            }
        }
        return this.isConditional ? this.inputModel.condition.operator ==='' || this.inputModel.condition.value==='' : false;
    }

    uploadTemplateDetails(id) {
        this.hidden = false;
        let selectedItem = this.emailTemplates.find((item:any) => item.id == id);
        this.previewSubject = this.inputModel.model.subject = selectedItem.subject;
        this.previewMessage = this.inputModel.model.message = selectedItem.message;
        this.subject = `<p>${this.previewSubject}</p>`;
        this.message = this.previewMessage;
        return true;
    }

    selectedEmailAddress(event:any = null, data:any = null,type) {
        let lab = event[event.length - 1];
        if (lab && lab.includes(" (Create New)")) {
            lab = lab.replace(" (Create New)", '');
            let valueArray = $("#additional-email").select2("data");
            if (valueArray) {
                valueArray[valueArray.length - 1]['id'] = lab;
                valueArray[valueArray.length - 1]['text'] = lab;
            }
            $("#additional-email").select2('data', valueArray);
            event[event.length - 1] = lab;
        }
        if(type == 'to'){
            this.inputModel.model.toEmailTag = event;
        }else if (type == 'cc'){
            this.inputModel.model.ccEmailTag = event;
        }else{
            this.inputModel.model.bccEmailTag = event;
        }
    }

    removeCell() {
      this.confirmationBoxHelper
        .getConfirmation(this.deleteMessage, this.ajsScope)
        .then(() => {
            this.close.emit('remove');
        });
    }
    showAdditioalInput(type){
        if(type == 'cc'){
            this.ccShow = true;
        }
        if(type == 'bcc'){
            this.bccShow = true;
        }
    }
}

