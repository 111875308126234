import * as tslib_1 from "tslib";
import { OnInit, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { CsFullScreenModalComponent } from "@app/directives";
import { mxgraphFactory } from "mxgraph-factory";
import { WorkflowhttpService } from "../services/workflowhttp.service";
import { MxGraphService } from "../services/mx-graph.service";
import { CsToastBoxService } from "@app/services";
var mxGraph = mxgraphFactory({
    mxLoadResources: false,
    mxLoadStylesheets: true,
}).mxGraph;
var DiagramViewComponent = /** @class */ (function () {
    function DiagramViewComponent(diagramService, httpService, confirmationBoxHelper, ajsScope, toastBox) {
        this.diagramService = diagramService;
        this.httpService = httpService;
        this.confirmationBoxHelper = confirmationBoxHelper;
        this.ajsScope = ajsScope;
        this.toastBox = toastBox;
        this.tags = [];
        this.userRoles = [];
        this.isMarketPlaceView = false;
        this.viewMode = false;
        this.usersList = [];
        this.scheduleActivityTypes = [];
        this.workflowType = 'Custom';
        this.whitelist = {};
        this.sourceXml = null;
        this.updatedTaskId = {};
    }
    DiagramViewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscription = this.httpService.workflowEventTrigger$.subscribe(function (action) {
            if (typeof action == "object" && action.actionName == 'beforeSave' && action.initializer != true) {
                _this.updatedTaskId = action.data.block_name + '_' + action.data.id;
            }
            else if (action.initializer == true) {
                _this.updatedTaskId = 'cs_initializer';
            }
        });
    };
    DiagramViewComponent.prototype.ngAfterViewInit = function () {
        this.setContainerHeight();
        // console.log('tags', this.tags);
        this.diagramService.initialize(this.container, {
            xmlSource: this.data.xmlSource,
            workflowId: this.data.id,
            workflowStatus: this.data.status == 1,
            isScheduler: this.data.scheduler,
            outlineContainer: this.outlineContainer,
            mainObjectsFields: this.mainObjectsFields,
            viewMode: this.viewMode,
            isMarketPlaceView: this.isMarketPlaceView,
            tags: this.tags,
            userRoles: this.userRoles,
            scheduleActivityTypes: this.scheduleActivityTypes,
            usersList: this.usersList,
            workflowType: this.workflowType,
            whitelist: this.whitelist,
            confirmationBoxHelper: this.confirmationBoxHelper,
            ajsScope: this.ajsScope
        });
        this.diagramService.setDiagramComponent(this);
        this.mxGraph = this.diagramService.getGraph();
        this.diagramService.render();
    };
    DiagramViewComponent.prototype.setContainerHeight = function () {
        var _this = this;
        setTimeout(function () {
            var height = window.innerHeight - (_this.container.nativeElement.offsetTop + 10);
            _this.container.nativeElement.style.minHeight = height + 'px';
        }, 100);
    };
    DiagramViewComponent.prototype.xmlValidator = function () {
        return true;
    };
    DiagramViewComponent.prototype.prepareDag = function () {
        var mxGraphModel = this.diagramService.getGraph().getModel();
        var rootCell = mxGraphModel.getCell('1');
        /*let dag:any = {
            'cs_initializer': []
        };
        let firstLevel = _.where(rootCell.children, {parentId: "cs_initializer"});
        if(firstLevel.length) {
            _.forEach(firstLevel, (c:mxgraph.mxCell) => {
                dag['cs_initializer'].push(c.getId());
            })
        }*/
        var getBlockObject = function () {
            if (!rootCell.children.length) {
                return [];
            }
            var vertex = {};
            _.chain(rootCell.children)
                .filter(function (cell) {
                return cell.isVertex();
            })
                .map(function (cell) {
                /*_.forEach(cell.edges, (e:mxgraph.mxCell) => {
                    if(_.has(e,'block_name') && e['block_name'] === "condition_edge" && e['source']['id'] == cell.getId()) {
                        const value = {
                            name: 'conditionEdge',
                            model: e.getValue(),
                            parentId: ""+cell.getId(),
                            targetId: ""+e['target']['id'],
                            id: ""+e.getId(),
                        };
                        vertex[e.getId()] = value;
                    }
                })*/
                return vertex[cell.getId()] = tslib_1.__assign({}, cell.getValue(), { parentId: "" + cell['parentId'], id: cell.getId() === 'cs_initializer' ? 'cs_initializer' : "" + cell.getId() });
            })
                .value();
            var transformVertex = _.clone(vertex);
            _.chain(transformVertex)
                .filter(function (item) { return _.has(item, 'targetId'); })
                .map(function (item) {
                vertex[item['targetId']]['parentId'] = "" + item.id;
                return item;
            });
            //console.log('vertex', vertex)
            return vertex;
        };
        var processEndBlock = function () {
            if (!_.size(_dag)) {
                return [];
            }
            var endBlocks = [];
            var arrayValues = _.flatten(Object.values(_dag));
            var arrayKeys = _.flatten(Object.keys(_dag));
            endBlocks = _.filter(arrayValues, function (id) {
                return !_.contains(arrayKeys, id);
            });
            return endBlocks;
        };
        var _dag = {
            'cs_initializer': []
        };
        var blocksList = getBlockObject();
        var firstChildren = _.where(blocksList, { parentId: "cs_initializer" });
        if (firstChildren.length) {
            _.forEach(firstChildren, function (item) {
                _dag['cs_initializer'].push(item.id);
            });
        }
        var _generateDag = function (blockId) {
            if (_dag[blockId].length) {
                _.forEach(_dag[blockId], function (_id) {
                    var children = _.where(Object.values(blocksList), { parentId: _id });
                    if (children.length) {
                        _dag[_id] = [];
                        _.forEach(children, function (child) {
                            _dag[_id].push(child.id);
                        });
                        _generateDag(_id);
                    }
                    else {
                        return;
                    }
                });
            }
        };
        _generateDag('cs_initializer');
        var airObject = {
            dagObject: _dag,
            blockObjects: getBlockObject(),
            endBlocks: processEndBlock()
        };
        //console.log('air', airObject)
        var mainObjectName = '';
        var finalairDagMessage = {}, finalairEndDagMessage = [], finalairBlocksMessage = {};
        _.forEach(airObject['dagObject'], function (children, id) {
            var dagName = id === 'cs_initializer' ? 'cs_initializer' : airObject['blockObjects'][id]['name'] + '_' + id;
            finalairDagMessage[dagName] = [];
            _.forEach(children, function (child_id) {
                finalairDagMessage[dagName].push(airObject['blockObjects'][child_id]['name'] + '_' + child_id);
            });
        });
        _.forEach(airObject['endBlocks'], function (endId) {
            finalairEndDagMessage.push(airObject['blockObjects'][endId]['name'] + '_' + endId);
        });
        _.forEach(airObject['blockObjects'], function (block, id) {
            var _blockName = id === 'cs_initializer' ? 'cs_initializer' : block['name'] + '_' + id;
            if (id === 'cs_initializer') {
                mainObjectName = block.mainObject;
            }
            finalairBlocksMessage[_blockName] = block;
        });
        // console.log('dagName', finalairDagMessage, finalairEndDagMessage, finalairBlocksMessage, mainObjectName);
        return {
            dagObject: finalairDagMessage,
            blockObjects: finalairBlocksMessage,
            endBlocks: finalairEndDagMessage,
            mainObjectName: mainObjectName
        };
    };
    DiagramViewComponent.prototype.unSaveConfirm = function (close) {
        var _this = this;
        if (close === void 0) { close = true; }
        if (this.diagramService.unsaveConfirm) {
            this.confirmationBoxHelper
                .getConfirmation('Do you want to save your changes before close?', this.ajsScope)
                .then(function () {
                _this.saveXml(close);
            }, function () {
                _this.closeModal();
            });
        }
        else {
            this.closeModal();
        }
    };
    DiagramViewComponent.prototype.getDiagramService = function () {
        return this.diagramService;
    };
    DiagramViewComponent.prototype.saveXml = function (close, modelValueUpdated) {
        var _this = this;
        if (close === void 0) { close = true; }
        if (modelValueUpdated === void 0) { modelValueUpdated = {}; }
        if (!this.xmlValidator()) {
            return;
        }
        var dagSource = this.prepareDag();
        this.data.xmlSource = this.diagramService.getXml();
        var ObjName = dagSource.mainObjectName;
        if (this.workflowType == 'Custom')
            this.data.objectName = ObjName.charAt(0).toUpperCase() + ObjName.slice(1);
        else
            this.data.objectName = '';
        var promise = new Promise(function (resolve) {
            _this.httpService.updateXml({
                xmlSource: _this.data.xmlSource,
                dagSource: dagSource,
                objectName: dagSource.mainObjectName,
                doRegenerateDag: false,
                isValueUpdated: modelValueUpdated,
                isMarketPlaceView: _this.isMarketPlaceView,
                updatedTaskId: _this.updatedTaskId ? _this.updatedTaskId : 'cs_initializer',
            }, _this.data.id).subscribe(function (resp) {
                if (resp.data['newRevisionUpdated'] && resp.data['newRevisionUpdated'] > 1) {
                    _this.toastBox.show('Revision ' + resp.data['newRevisionUpdated'] + ' updated.', 3000);
                }
                _this.diagramService.unsaveConfirm = false;
                _this.diagramService.doRegenerateDag = false;
                resolve(true);
                !close && _this.httpService.triggerEvent('showActivationBtn');
                if (close)
                    _this.closeModal();
            });
        });
        return promise;
    };
    DiagramViewComponent.prototype.closeModal = function () {
        this.csFullModal.close();
        if (this.subscription) {
            this.updatedTaskId = {};
            this.subscription.unsubscribe();
        }
    };
    DiagramViewComponent.prototype.onResize = function () {
        this.setContainerHeight();
    };
    DiagramViewComponent.prototype.ngOnDestroy = function () {
        this.mxGraph.destroy();
        this.data = {};
        console.log('destroyed mxgraph');
    };
    DiagramViewComponent.prototype.grabEvent = function ($event) {
        var div = document.getElementById('xyz');
        /* console.log(div.getBoundingClientRect());
         console.log($event)*/
    };
    return DiagramViewComponent;
}());
export { DiagramViewComponent };
