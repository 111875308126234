import { ElementRef, ComponentFactoryResolver, Type, Injector, EmbeddedViewRef, ApplicationRef, SimpleChange } from '@angular/core';
import { mxgraphFactory } from "mxgraph-factory";
import { TriggerDatasetService } from "./trigger-dataset.service";
import { WorkflowCommonService } from "@app/workflow-common/services/workflow.common.service";
import { SidepanelModalService } from "@app/sidepanel/services/sidepanel-modal.service";
import { TriggerRulesPanelComponent } from "../trigger-rules-panel/trigger-rules-panel.component";
import { AddBlockPanelComponent } from "../add-block-panel/add-block-panel.component";
import { HistoryTimelinePanelComponent } from "../history-timeline-panel/history-timeline-panel.component";
import * as moment from 'moment';
import { Subject } from "rxjs/index";
import { WorkflowhttpService } from "@app/workflow-common/services/workflowhttp.service";
import { SequenceEmailWidgetComponent } from "@app/workflow-common/sequence-email-widget/sequence-email-widget.component";
import { SequenceSmsWidgetComponent } from "@app/workflow-common/sequence-sms-widget/sequence-sms-widget.component";
import * as i0 from "@angular/core";
import * as i1 from "../../sidepanel/services/sidepanel-modal.service";
import * as i2 from "./trigger-dataset.service";
import * as i3 from "./workflow.common.service";
import * as i4 from "./workflowhttp.service";
var _a = mxgraphFactory({
    mxLoadResources: false,
    mxLoadStylesheets: true,
}), mxGraph = _a.mxGraph, mxCodec = _a.mxCodec, mxImage = _a.mxImage, mxUtils = _a.mxUtils, mxCellOverlay = _a.mxCellOverlay, mxConstants = _a.mxConstants, mxPoint = _a.mxPoint, mxCell = _a.mxCell, mxEvent = _a.mxEvent, mxKeyHandler = _a.mxKeyHandler, mxHierarchicalLayout = _a.mxHierarchicalLayout, mxMorphing = _a.mxMorphing, mxEdgeStyle = _a.mxEdgeStyle, mxCompactTreeLayout = _a.mxCompactTreeLayout, mxLayoutManager = _a.mxLayoutManager, mxOutline = _a.mxOutline, mxDivResizer = _a.mxDivResizer, mxCellTracker = _a.mxCellTracker, mxCellHighlight = _a.mxCellHighlight, mxRubberband = _a.mxRubberband;
var HIGHLIGHT_COMPLETED = '#0FA90C';
var HIGHLIGTT_SKIPPED_OR_FAILED = '#A90C0C';
var HIGHLIGTT_SKIPPED = '#FFA500';
var HIGHLIGHT_RUNNING = '#00ff00';
var HIGHLIGHT_BLOCK_ADD = '#477596';
var HIGHLIGHT_BLOCK_EDIT = '#F5FD0DD7';
var HIGHLIGHT_BLOCK_DELETE = '#BE6D6D';
// const HIGHLIGHT_RESCHEDULE = '#30D5C8';
var addBtnUrl = '/images/mx-graph/add.svg';
var editBtnUrl = '/images/mx-graph/edit.svg';
var MxGraphService = /** @class */ (function () {
    function MxGraphService(modalService, triggerService, commonService, componentFactoryResolver, injector, appRef, workflowService) {
        var _this = this;
        this.modalService = modalService;
        this.triggerService = triggerService;
        this.commonService = commonService;
        this.componentFactoryResolver = componentFactoryResolver;
        this.injector = injector;
        this.appRef = appRef;
        this.workflowService = workflowService;
        this.options = {};
        this.xmlSource = null;
        this.selectedMainObjectFields = null;
        this.mxOutline = null;
        this.unsaveConfirm = false;
        this.blockWidth = 240;
        this.blockHeight = 100;
        this.emailBlockWidth = 450;
        // emailBlockHeight = 140;
        this.officeTaskBlockWidth = 450;
        /*officeTaskBlocHeight = 140;
        notificationBlockWidth = 450;
        notificationBlockHeight = 140;
    
        delayBlockWidth = 260;
        delayBlockHeight = 140;*/
        this.conditionBlockSize = 280;
        this.viewMode = false;
        this.isMarketPlaceView = false;
        //view diagram
        this.fieldsData = null;
        this.debugMode = false;
        this.tagName = [];
        this.cellTracker = null;
        this.cellHighlighter = {};
        this.doRegenerateDag = false;
        this.firstTimeRender = true;
        this.blocksSize = {
            initializer: {
                width: 280,
                height: 130
            },
            addjobBlock: {
                width: 280,
                height: 130
            },
            emailBlock: {
                width: 450,
                height: 160
            },
            officeTaskBlock: {
                width: 450,
                height: 150
            },
            delayBlock: {
                width: 260,
                height: 130
            },
            chatmessageBlock: {
                width: 450,
                height: 140
            },
            smsBlock: {
                width: 420,
                height: 160
            },
            untilConditionBlock: {
                width: 240,
                height: 240
            },
            notificationBlock: {
                width: 410,
                height: 160
            },
            setcustomfieldvalueBlock: {
                width: 260,
                height: 140
            },
            scheduledActivityBlock: {
                width: 410,
                height: 160
            },
            dummyBlock: {
                width: 200,
                height: 80,
            },
            conditionalDelayBlock: {
                width: 260,
                height: 130
            },
        };
        this.mainObjectName = null;
        this.blocksList = [];
        this.workflowType = 'Custom';
        this.workflowStatus = null;
        this.deactivateMessage = 'The workflow must be deactivated before adding or deleting items. Do you want to deactivate?';
        this.blocksMetaList = [];
        this.showScheduleActivityModel = false;
        this.onAfterRender = new Subject();
        this.onAfterRender.subscribe(function (cell) {
            if (cell && cell.isVertex()) {
                if (cell['block_name'] === 'elseBlock') {
                    _this.addDummyBlock(cell);
                }
                else {
                    var blockMeta = _this.blocksMetaList.find(function (block) { return cell['block_name'] == block['id']; });
                    var containerInfo = _this.commonService.blockHeightCalculation(cell, {
                        blockSize: _this.blocksSize,
                        title: blockMeta['title'],
                        mxGraphService: _this
                    });
                    if (!containerInfo) {
                        return false;
                    }
                    var current = cell.getGeometry();
                    current.width = containerInfo.width;
                    current.height = containerInfo.height;
                    cell.setGeometry(current);
                    _this.mxGraph.updateCellSize(cell, true);
                }
            }
        });
    }
    MxGraphService.prototype.setDiagramComponent = function (comp) {
        this.diagramComponent = comp;
    };
    MxGraphService.prototype.addComponent = function (componentClass, componentProps) {
        var _this = this;
        var componentRef = this.componentFactoryResolver
            .resolveComponentFactory(componentClass)
            .create(this.injector);
        if (componentProps && typeof componentRef.instance === 'object') {
            Object.assign(componentRef.instance, componentProps);
        }
        this.appRef.attachView(componentRef.hostView);
        var domElem = componentRef.hostView
            .rootNodes[0];
        document.body.appendChild(domElem);
        var changes = {
            showActivityModal: new SimpleChange(false, this.showScheduleActivityModel, false),
        };
        componentRef.instance.closeModal = function () {
            var closeCB = function (cb) {
                $('app-schedule-activity-modal .activityModal__').modal('hide');
                $("app-schedule-activity-modal .modal-dialog").removeClass("modal-dialog-open");
                setTimeout(cb, 300);
            };
            closeCB(function () {
                _this.appRef.detachView(componentRef.hostView);
                componentRef.destroy();
            });
        };
        componentRef.instance.ngOnChanges(changes);
    };
    MxGraphService.prototype.openScheduledActivity = function (show) {
        if (show === void 0) { show = false; }
        this.showScheduleActivityModel = show;
        /*setTimeout(()=>{
            this.addComponent(ScheduleActivityModalComponent, {
                showActivityModal: this.showScheduleActivityModel,
                listActivity: this.options['scheduleActivityTypes'],
                backdrop: true
            })
        },300);*/
    };
    /*prepareEmailTaskTemplate(cell:mxgraph.mxCell) {
        const value = cell.getValue();
        const {model} = value;
        let toTagName = [];
        _.forEach(model.toEmailTag, (item: any) => {
            toTagName.push(item.displayName);
        });
        let ccTagName = [];
        _.forEach(model.ccEmailTag, (item: any) => {
            ccTagName.push(item.displayName);
        });
        let bccTagName = [];
        _.forEach(model.bccEmailTag, (item: any) => {
            bccTagName.push(item.displayName);
        });
        let toTagAddress = toTagName.join(', ');
        let ccTagAddress = ccTagName.join(', ');
        let bccTagAddress = bccTagName.join(', ');
        let mainContainer = this.options.viewMode == true ? $('<div></div>').addClass('view-task-block email-task-block').css({width: this.emailBlockWidth, textAlign:'center', padding:'20px', fontSize:'14px'}) : $('<div></div>').addClass('task-block email-task-block').css({width: this.emailBlockWidth, textAlign:'center', padding:'20px', fontSize:'14px'});
        let titleDiv = $('<div></div>').css({fontSize:'14px', paddingBottom: 10}).text('What do you want to happen?');
        let taskDiv = $('<div></div>').addClass('task-title');
        let img = $('<img/>').addClass('mr-5p').attr('src', 'images/mx-graph/email.svg');
        let span = $('<span></span>').css({fontWeight:'bold'}).text('Send an email');
        let separator = $('<div/>').css({marginBottom: 10, height:10, borderBottom:'1px solid #DADADA'}).addClass('d-block');
        let bodyLine = $('<div></div>').addClass('text-truncate d-block').css('padding-bottom','5px');
        let bodyLineBcc = $('<div></div>').addClass('text-truncate d-block').css('padding-bottom','5px');
        let bodyLineCc = $('<div></div>').addClass('text-truncate d-block').css('padding-bottom','5px');
        let emailToTitle = $('<span></span>').text('To: ');
        let emailToContent = $('<span></span>').css({fontWeight:'bold', paddingLeft:5}).text(toTagAddress);
        let emailCcTitle = $('<span></span>').text('CC: ');
        let emailCcContent = $('<span></span>').css({fontWeight:'bold', paddingLeft:5}).text(ccTagAddress);
        let emailBccTitle = $('<span></span>').css({paddingLeft:10}).text('BCC: ');
        let emailBccContent = $('<span></span>').css({fontWeight:'bold', paddingLeft:5}).text(bccTagAddress);
        let bodyLine2 = $('<div></div>').addClass('text-truncate d-block');
        let subTitle = $('<span></span>').text('Subject: ');
        let subjectMessage = model['subject'];
        if(model['customMessage'] && typeof model['customMessage'] == 'object') {
            subjectMessage = model['customMessage']['displaySubjectMessage'];
        }
        let subContent = $('<span></span>').css({fontWeight:'bold', paddingLeft:5}).text(subjectMessage);
        if(this.workflowType == 'Sequence' && this.options['mode'] === 'sequence_config') {
            const initValue = this.getInitBlockValue();
            const enabled = initValue['model'] && initValue['model']['displayContactInfo']['emailAddress'];
            if(enabled && model['current_task_status'] && model['current_task_status'] == 'skip') {
                mainContainer.css('background', '#F2F2F2');
                mainContainer.css('border', '1px solid #DADADA');
            }
            if(enabled) {
                mainContainer.append(this.skipOrUndoButton(cell));
                emailToContent.text(initValue['model']['displayContactInfo']['emailAddress']);
            }
            else {
                mainContainer.css('background', '#ECC7C7');
                emailToContent.text('--not set--');
            }
        }
        bodyLine.append(emailToTitle);
        bodyLine.append(emailToContent);
        if(ccTagName.length>0) {
            bodyLineBcc.append(emailCcTitle);
            bodyLineBcc.append(emailCcContent);
        }
        if(bccTagName.length>0) {
            bodyLineBcc.append(emailBccTitle);
            bodyLineBcc.append(emailBccContent);
        }
        bodyLine2.append(subTitle);
        bodyLine2.append(subContent);
        taskDiv.append(img);
        taskDiv.append(span);
        mainContainer.append(titleDiv);
        mainContainer.append(taskDiv);
        mainContainer.append(separator);
        mainContainer.append(bodyLine);
        if(ccTagName.length>0 || bccTagName.length>0){
              mainContainer.append(bodyLineBcc);
        }
        mainContainer.append(bodyLine2);
        return mainContainer;
    }*/
    /*prepareSmsTaskTemplate(cell:mxgraph.mxCell){
        const value = cell.getValue();
        const {model} = value;
        let tagName = [];
        const { smsBlock } = this.blocksSize;
        _.forEach(model['toSmsTag'], (item: any) => {
            tagName.push(item.displayName);
        });

        let tagAddress = tagName.join(', ');
        let mainContainer = this.options.viewMode == true ? $('<div></div>').addClass('view-task-block email-task-block')
            .css({width: smsBlock['width'], textAlign:'center', padding:'20px', fontSize:'14px'}).attr('id', 'block-id-'+cell.getId()) :
            $('<div></div>').addClass('task-block email-task-block').css({width: smsBlock['width'], textAlign:'center', padding:'20px', fontSize:'14px'}).attr('id', 'block-id-'+cell.getId());
        let titleDiv = $('<div></div>').css({fontSize:'14px', paddingBottom: 10}).text('What do you want to happen?');
        let taskDiv = $('<div></div>').addClass('task-title');
        let img = '<i class="mr-10 far fa-sms fa-lg" aria-hidden="true"></i>';
        let span = $('<span></span>').css({fontWeight:'bold'}).text('Send SMS');
        let separator = $('<div/>').css({marginBottom: 10, height:10, borderBottom:'1px solid #DADADA'}).addClass('d-block');
        let bodyLine = $('<div></div>').addClass('text-truncate d-block').css('padding-bottom','5px');
        let emailToTitle = $('<span></span>').text('To: ');
        let emailToContent = $('<span></span>').css({fontWeight:'bold', paddingLeft:5}).text(tagAddress);
        let bodyLine2 = $('<div></div>').addClass('text-truncate d-block');
        let subTitle = $('<span></span>').text('Title: ');
        let subContent = $('<span></span>').css({fontWeight:'bold', paddingLeft:5}).text(model['title']);
        if(this.workflowType == 'Sequence' && this.options['mode'] === 'sequence_config') {
            const initValue = this.getInitBlockValue();
            const enabled = initValue['model'] && initValue['model']['displayContactInfo']['mobile'];
            if(enabled && model['current_task_status'] && model['current_task_status'] == 'skip') {
                mainContainer.css('background', '#F2F2F2');
                mainContainer.css('border', '1px solid #DADADA');
            }
            if(enabled) {
                mainContainer.append(this.skipOrUndoButton(cell));
                emailToContent.text(initValue['model']['displayContactInfo']['mobile']);
            }
            else {
                mainContainer.css('background', '#ECC7C7');
                emailToContent.text('--not set--');
            }
            if(model['customMessage'] && typeof model['customMessage'] == 'object') {
                subTitle.text('Message: ');
                subContent.text(model['customMessage']['displayContent']);
            }
        }

        bodyLine.append(emailToTitle);
        bodyLine.append(emailToContent);
        bodyLine2.append(subTitle);
        bodyLine2.append(subContent);
        taskDiv.append(img);
        taskDiv.append(span);
        mainContainer.append(titleDiv);
        mainContainer.append(taskDiv);
        mainContainer.append(separator);
        mainContainer.append(bodyLine);
        mainContainer.append(bodyLine2);
        return mainContainer;
    }*/
    MxGraphService.prototype.prepareConditionalEdageTemplate = function (cell) {
        var value = cell.getValue();
        var operatorsDisplayText = this.triggerService.getOperatorsDisplayText();
        var mainContainer = this.options.viewMode == true ? $('<div></div>').addClass('view-task-block condition-edge-task-block').css({
            textAlign: 'center', padding: '3px 8px', backgroundColor: '#fff', border: '1px dashed #ccc', fontSize: '14px'
        }) :
            $('<div></div>').addClass('task-block condition-edge-task-block').css({ textAlign: 'center', padding: '3px 8px', backgroundColor: '#fff', border: '1px dashed #ccc', fontSize: '14px' });
        var operatorsDisplay = value['text'].charAt(value['text'].length - 1) == '?' ? this.triggerService.opertatorSymbols[value['operator']] : operatorsDisplayText[value['operator']];
        var displayText = this.truncateMiddle(this.selectedMainObjectFields[value.col_name].text, 30) + ' ' + operatorsDisplay + ' ' + this.getLabelByText(value['col_name'], value['value']);
        mainContainer.text(displayText);
        return mainContainer;
    };
    ;
    MxGraphService.prototype.prepareAddJobTaskTemplate = function (cell) {
        var value = cell.getValue();
        var model = value.model;
        var addjobBlock = this.blocksSize.addjobBlock;
        var mainContainer = this.options.viewMode == true ? $('<div></div>').addClass('view-task-block email-task-block')
            .css({ width: addjobBlock['width'], textAlign: 'center', padding: '20px', fontSize: '14px' }) :
            $('<div></div>').addClass('task-block email-task-block').css({ width: addjobBlock['width'], textAlign: 'center', padding: '20px', fontSize: '14px' });
        var titleDiv = $('<div></div>').css({ fontSize: '14px', paddingBottom: 10 }).text('What do you want to happen?');
        var taskDiv = $('<div></div>').addClass('task-title');
        var img = '<i class="mr-10 far fa-truck fa-lg" aria-hidden="true"></i>';
        var span = $('<span></span>').css({ fontWeight: 'bold' }).text('Create a job');
        var separator = $('<div/>').css({ marginBottom: 10, height: 10, borderBottom: '1px solid #DADADA' }).addClass('d-block');
        var bodyLine = $('<div></div>').addClass('text-truncate d-block').css('padding-bottom', '5px');
        var contentTitle = $('<span></span>').text('Description: ');
        var Content = $('<span></span>').css({ fontWeight: 'bold', paddingLeft: 5 }).html(model['jobDescriptionDisplay']);
        bodyLine.append(contentTitle);
        bodyLine.append(Content);
        taskDiv.append(img);
        taskDiv.append(span);
        mainContainer.append(titleDiv);
        mainContainer.append(taskDiv);
        mainContainer.append(separator);
        mainContainer.append(bodyLine);
        return mainContainer;
    };
    MxGraphService.prototype.prepareUntilConditionTemplate = function (cell) {
        var value = cell.getValue();
        var isConditionalBlock = cell['block_name'] == 'conditionalBlock';
        var model = value.model;
        var $_column = this.selectedMainObjectFields[model['col_name']];
        var untilConditionBlock = this.blocksSize.untilConditionBlock;
        var intervalUnitFormat = value['model']['interval'] == 1 ? value['model']['unit'].slice(0, -1) : value['model']['unit'];
        var untilPeriod = '';
        if (model['untilType'] == '1') {
            untilPeriod = moment(model['untilPeriod']).format('MMM Do YYYY');
        }
        else {
            var untilManualPeriod = model['untilManualPeriod'] == 1 ? value['model']['untilUnit'].slice(0, -1) : value['model']['untilUnit'];
            untilPeriod = '+' + model['untilManualPeriod'] + ' ' + untilManualPeriod;
        }
        var mainContainer = this.options.viewMode == true ? $('<div></div>').addClass('view-task-block until-condition-block white-space-normal')
            .css({ width: untilConditionBlock['width'], textAlign: 'center', padding: '0 10px 15px 10px', fontSize: '14px' }) :
            $('<div></div>').addClass('task-block until-condition-block white-space-normal').css({ width: untilConditionBlock['width'], textAlign: 'center', padding: '0 10px 15px 10px', fontSize: '14px' });
        var taskDiv = $('<div></div>').addClass('task-title flex-col');
        var img = isConditionalBlock ? '<i class="mr-10 far fa-code-branch fa-rotate-180 fa-lg" aria-hidden="true"></i>' : '<i class="mr-10 far fa-solid fa-repeat-1 fa-lg" aria-hidden="true"></i>';
        var span = $('<span></span>').css({ fontWeight: 'normal' }).text(isConditionalBlock ? 'If statement' : 'If... until');
        var separator = $('<div/>').css({ marginBottom: 10, marginLeft: 30, marginRight: 30, height: 10, borderBottom: '1px solid #DADADA' }).addClass('d-block');
        var bodyLine = $('<div></div>').addClass('d-block').css('padding-bottom', '5px').css('font-weight', 'bold');
        var bodyLine1, bodyLine2;
        if (!isConditionalBlock) {
            bodyLine1 = $('<div></div>').addClass('d-block').css('padding-bottom', '5px');
            var bodySpan1 = $('<span></span>').css({ fontWeight: 'normal' }).text('Repeat every ');
            var bodySpan1A = $('<span></span>').css({ fontWeight: 'bold' }).text(value['model']['interval'] + ' ' + intervalUnitFormat);
            bodyLine1.append(bodySpan1);
            bodyLine1.append(bodySpan1A);
            bodyLine2 = $('<div></div>').addClass('d-block').css('padding-bottom', '5px');
            var bodySpan2 = $('<span></span>').css({ fontWeight: 'normal' }).text('until ');
            var bodySpan2A = $('<span></span>').css({ fontWeight: 'bold' }).text(untilPeriod);
            var bodySpan2B = $('<div></div>').css({ fontWeight: 'normal' }).html('after the execution <br/> date');
            bodyLine2.append(bodySpan2);
            bodyLine2.append(bodySpan2A);
            if (model['untilType'] == 2) {
                bodyLine2.append(bodySpan2B);
            }
        }
        taskDiv.append(img);
        taskDiv.append(span);
        bodyLine.text($_column['text']);
        mainContainer.append(taskDiv);
        mainContainer.append(separator);
        mainContainer.append(bodyLine);
        if (!isConditionalBlock) {
            mainContainer.append(bodyLine1);
            mainContainer.append(bodyLine2);
        }
        return mainContainer;
    };
    /*prepareChatmessageTemplate(cell:mxgraph.mxCell) {
        const value = cell.getValue();
        const {model} = value;
        let channelTo = model['channelName'];
        let mainContainer = $('<div></div>').addClass('task-block office-task-block').css({width: this.officeTaskBlockWidth, textAlign:'center', padding:'15px', fontSize:'14px'});
        let titleDiv = $('<div></div>').css({fontSize:'14px', paddingBottom: 10}).text('What do you want to happen?');
        let taskDiv = $('<div></div>').addClass('task-title');
        let img = $('<img/>').addClass('mr-10').attr('src', 'images/mx-graph/chatmessage.svg');
        let span = $('<span></span>').css({fontWeight:'bold'}).text('Create a chat message');
        let separator = $('<div/>').css({marginBottom: 10, height:10, borderBottom:'1px solid #DADADA'}).addClass('d-block');
        let bodyLine = $('<div></div>').addClass('text-truncate d-block').css('padding-bottom','5px');
        let assignTitle = $('<span></span>').text('Channel: ');
        let assignContent = $('<span></span>').css({fontWeight:'bold', paddingLeft:5}).text(channelTo);
        let bodyLine2 = $('<div></div>').addClass('text-truncate d-block');
        let tagTitle = $('<span></span>').text('Message: ');
        let tagContent = $('<span></span>').css({fontWeight:'bold', paddingLeft:5}).html(model['textMessage']);
        bodyLine.append(assignTitle);
        bodyLine.append(assignContent);
        bodyLine2.append(tagTitle);
        bodyLine2.append(tagContent);
        taskDiv.append(img);
        taskDiv.append(span);
        mainContainer.append(titleDiv);
        mainContainer.append(taskDiv);
        mainContainer.append(separator);
        mainContainer.append(bodyLine);
        mainContainer.append(bodyLine2);
        return mainContainer;
    }*/
    /*prepareNotificationTemplate(cell: mxgraph.mxCell) {
        const value = cell.getValue();
        let {model} = value;
        let displayRoles = model['rolesDisplay'].join(', ');
        const {notificationBlock} = this.blocksSize;
        let mainContainer = this.options.viewMode == true ? $('<div></div>').addClass('view-task-block office-task-block')
            .css({width: notificationBlock['width'], textAlign:'center', padding:'20px', fontSize:'14px'}) :
            $('<div></div>').addClass('task-block office-task-block').css({width: notificationBlock['width'], textAlign:'center', padding:'20px', fontSize:'14px'});
        let titleDiv = $('<div></div>').css({fontSize:'14px', paddingBottom: 10}).text('What do you want to happen?');
        let taskDiv = $('<div></div>').addClass('task-title');
        let img = '<i class="mr-10 far fa-globe-americas fa-lg" aria-hidden="true"></i>';
        let span = $('<span></span>').css({fontWeight:'bold'}).text('Notification task');
        let separator = $('<div/>').css({marginBottom: 10, height:10, borderBottom:'1px solid #DADADA'}).addClass('d-block');
        taskDiv.append(img);
        taskDiv.append(span);
        if(model['current_task_status'] && model['current_task_status'] == 'skip') {
            mainContainer.css('background', '#F2F2F2');
            mainContainer.css('border', '1px solid #DADADA');
        }
        let bodyLine = $('<div></div>').addClass('text-truncate d-block').css('padding-bottom','5px');
        let assignTitle = $('<span></span>').text('Roles: ');
        let assignContent = $('<span></span>').css({fontWeight:'bold', paddingLeft:5}).text(displayRoles);

        let bodyLine2 = $('<div></div>').addClass('text-truncate d-block');
        let tagTitle = $('<span></span>').text('Message: ');
        let tagContent = $('<span></span>').css({fontWeight:'bold', paddingLeft:5}).html(model['textMessage']);

        if(this.workflowType == 'Sequence' && this.options['mode'] === 'sequence_config') {
            mainContainer.append(this.skipOrUndoButton(cell));
        }

        bodyLine.append(assignTitle);
        bodyLine.append(assignContent);
        bodyLine2.append(tagTitle);
        bodyLine2.append(tagContent);
        mainContainer.append(titleDiv);
        mainContainer.append(taskDiv);
        mainContainer.append(separator);
        mainContainer.append(bodyLine);
        mainContainer.append(bodyLine2);
        return mainContainer;
    }*/
    /*prepareOfficeTaskTemplate(cell:mxgraph.mxCell) {
        const value = cell.getValue();
        const {model} = value;
        let assignedTo = model['assignToDisplay'];
        let tagsDisplay = '--';
        let mainContainer = this.options.viewMode == true ? $('<div></div>').addClass('view-task-block office-task-block')
            .css({width: this.officeTaskBlockWidth, textAlign:'center', padding:'20px', fontSize:'14px'}) : $('<div></div>')
            .addClass('task-block office-task-block')
            .css({width: this.officeTaskBlockWidth, textAlign:'center', padding:'20px', fontSize:'14px'});
        let titleDiv = $('<div></div>').css({fontSize:'14px', paddingBottom: 10}).text('What do you want to happen?');
        let taskDiv = $('<div></div>').addClass('task-title');
        let img = '<i class="mr-10 far fa-list fa-lg" aria-hidden="true"></i>';
        let span = $('<span></span>').css({fontWeight:'bold'}).text('Create a office task');
        let separator = $('<div/>').css({marginBottom: 10, height:10, borderBottom:'1px solid #DADADA'}).addClass('d-block');
        let bodyLine = $('<div></div>').addClass('text-truncate d-block').css('padding-bottom','5px');
        let assignTitle = $('<span></span>').text('Assigned to: ');
        let assignContent = $('<span></span>').css({fontWeight:'bold', paddingLeft:5}).text(assignedTo);
        let bodyLine2 = $('<div></div>').addClass('text-truncate d-block');
        let tagTitle = $('<span></span>').text('Message: ');
        let tagContent = $('<span></span>').css({fontWeight:'bold', paddingLeft:5}).html(model['textMessage']);
        bodyLine.append(assignTitle);
        bodyLine.append(assignContent);
        bodyLine2.append(tagTitle);
        bodyLine2.append(tagContent);
        taskDiv.append(img);
        taskDiv.append(span);
        mainContainer.append(titleDiv);
        mainContainer.append(taskDiv);
        mainContainer.append(separator);
        if(assignedTo) {
            mainContainer.append(bodyLine);
        }
        mainContainer.append(bodyLine2);
        return mainContainer;
    };*/
    MxGraphService.prototype.prepareInitBlockContainer = function (ModalData, callback) {
        if (callback === void 0) { callback = null; }
        var selectedMainObjectFields = this.selectedMainObjectFields;
        var operatorsDisplayText = this.triggerService.getOperatorsDisplayText();
        var rules = ModalData.rules;
        var padding = 20;
        var containerBottom = 5;
        var maxWidth = 400;
        var fixedWidth = 270;
        var data = {}, titleText = '';
        if (this.workflowType == 'Custom') {
            titleText = 'How do you want to trigger this ' + ModalData.mainObject + ' workflow?';
        }
        else {
            titleText = 'This workflow would be executed as a sequence';
        }
        var titleDiv = $('<div></div>').css({ fontSize: '14px', paddingBottom: containerBottom + 5 }).text(titleText);
        var mainContainer = $('<div></div>').css({
            //width: (text.length * 8) > maxWidth ? maxWidth : fixedWidth,
            whiteSpace: 'normal',
            padding: padding,
            textAlign: 'center',
            fontSize: '14px'
        }).addClass('box-sizing').attr('id', 'cs-init-block-container')
            .append(titleDiv);
        if (this.isScheduler) {
            var divHead = document.createElement('div');
            divHead.style.textAlign = 'center';
            divHead.className = 'init-block';
            divHead.style.fontWeight = 'bold';
            divHead.style.padding = '0 4px';
            mxUtils.write(divHead, 'Scheduled workflow');
            mainContainer.prepend(divHead);
        }
        var cnt = 0;
        var textCollection = [];
        _.forEach(rules, function (rule) {
            cnt++;
            var $fieldContainer = $('<div></div>').css('padding-bottom', cnt == rules.length ? 0 : containerBottom).css('font-weight', 'bold');
            var $andDiv = $('<div></div>').css('padding-bottom', containerBottom).text('and');
            var $fieldDisplay = $('<span></span>').css('padding-right', '5px');
            var $operatorDisplay = $('<span></span>').css('padding-right', '5px').css('font-weight', 'normal');
            var $valueDisplay = $('<span></span>');
            var valueDisplayText = rule['value'];
            var fieldData = selectedMainObjectFields[rule['col_name']];
            if (_.has(fieldData, 'options')) {
                var tmp_1 = [];
                if (_.isArray(rule['value'])) {
                    _.forEach(rule['value'], function (id) {
                        var activeOption = _.find(fieldData.options, function (item) {
                            return item.id == id;
                        });
                        if (activeOption) {
                            tmp_1.push(activeOption['text']);
                        }
                    });
                    valueDisplayText = tmp_1.join(', ');
                }
                else {
                    var activeOption = _.find(fieldData.options, function (item) {
                        return item.id == rule['value'];
                    });
                    if (activeOption) {
                        valueDisplayText = activeOption['text'];
                    }
                }
            }
            var tmpDisplayText = rule['text'] + ' ' + rule['operator'] + ' ' + valueDisplayText;
            textCollection.push(tmpDisplayText.length);
            $fieldDisplay.text(rule['text']);
            $operatorDisplay.text(operatorsDisplayText[rule['operator']]);
            $valueDisplay.text(valueDisplayText);
            $fieldContainer.append($fieldDisplay).append($operatorDisplay).append($valueDisplay);
            mainContainer.append($fieldContainer);
            if (cnt !== rules.length) {
                mainContainer.append($andDiv);
            }
        });
        var maxDisplayText = _.max(textCollection);
        textCollection = [];
        mainContainer.css('width', (maxDisplayText * 8) > maxWidth ? maxWidth : fixedWidth);
        // ModalData['displayData'] = data;
        if (typeof callback === 'function') {
            mainContainer.appendTo('body');
            data = {
                width: mainContainer.outerWidth(),
                height: mainContainer.outerHeight() - 10,
                container: mainContainer
            };
            // mainContainer.css('height', mainContainer.outerHeight());
            mainContainer.remove();
            callback(ModalData, data);
        }
        else {
            data['container'] = mainContainer;
        }
        return data;
    };
    MxGraphService.prototype.renderInitBlockContainer = function (cell, result) {
        var objValue = cell.getValue();
        var labelText = this.isScheduler ? objValue['cronText'] : objValue.mainObject + ' trigger';
        var titleText;
        if (this.workflowType == 'Custom') {
            titleText = 'How do you want to trigger this ' + labelText + ' workflow?';
        }
        else {
            titleText = 'This workflow would be executed as a sequence';
        }
        var updatedData = result['formData'];
        updatedData['block_name'] = 'initializer';
        var container = this.commonService.blockHeightCalculation(updatedData, {
            blockSize: this.blocksSize,
            selectedMainObjectFields: this.selectedMainObjectFields,
            getOperatorsDisplayText: this.triggerService.getOperatorsDisplayText(),
            mxGraphService: this,
            isScheduler: this.isScheduler,
            title: titleText,
            id: cell.getId()
        });
        if (container) {
            this.mxGraph.labelChanged(cell, result['formData'], event);
            var current = cell.getGeometry();
            current.width = container['width'];
            current.height = container['height'];
            cell.setGeometry(current);
            this.mxGraph.updateCellSize(cell, true);
        }
        /*this.prepareInitBlockContainer(result['formData'], (formData, displayInfo) => {
            this.mxGraph.labelChanged(cell, formData, event);
            let current = cell.getGeometry();
            current.width = displayInfo.width;
            current.height = displayInfo.height;
            cell.setGeometry(current);
            this.mxGraph.updateCellSize(cell, true);
        })*/
    };
    MxGraphService.prototype.initialize = function (container, options) {
        var _this = this;
        if (options === void 0) { options = {}; }
        this.element = container;
        this.options = options;
        this.unsaveConfirm = false;
        if (this.options.hasOwnProperty('workflowStatus')) {
            this.workflowStatus = this.options.workflowStatus;
        }
        if (!this.options.hasOwnProperty('workflowType')) {
            throw new Error("Workflow type not defined.");
        }
        this.workflowType = this.options.workflowType;
        this.blocksList = this.commonService.getBlocksList(this.workflowType);
        var blockMeta = [];
        this.blocksList.forEach(function (section) {
            blockMeta = blockMeta.concat(section.blocks);
        });
        this.blocksMetaList = blockMeta;
        // console.log('blockMeta', blockMeta);
        if (_.has(options, 'isMarketPlaceView')) {
            this.isMarketPlaceView = options['isMarketPlaceView'];
        }
        this.isScheduler = options['isScheduler'];
        if (this.options.hasOwnProperty('xmlSource')) {
            this.xmlSource = this.options.xmlSource;
        }
        if (this.options.hasOwnProperty('outlineContainer')) {
            this.outlineContainer = this.options.outlineContainer;
            //console.log('this.outlineContainer', this.outlineContainer)
        }
        if (this.options.hasOwnProperty('mainObjectsFields')) {
            this.fieldsData = this.options.mainObjectsFields;
        }
        mxEvent.disableContextMenu(this.element.nativeElement);
        this.mxGraph = new mxGraph(this.element.nativeElement);
        this.mxGraph.setCellsSelectable(false);
        this.mxGraph.setCellsMovable(!this.options.viewMode);
        this.mxGraph.setEdgeLabelsMovable(!this.options.viewMode);
        this.mxGraph.setVertexLabelsMovable(false);
        this.mxGraph.setEnabled(false);
        this.mxGraph.setCellsLocked(this.options.viewMode);
        //this.mxGraph.setAutoSizeCells(true);
        this.mxGraph.setPanning(true);
        this.mxGraph.centerZoom = false;
        this.mxGraph.panningHandler.useLeftButtonForPanning = true;
        this.mxGraph.setTooltips(!this.options.viewMode);
        this.mxGraph.setConnectable(true);
        this.mxGraph.connectionHandler.setCreateTarget(!this.options.viewMode);
        //this.mxGraph.setAllowDanglingEdges(false);
        //this.mxGraph.connectionHandler.select = false;
        //this.mxGraph.view.setTranslate(20, 20);
        var _mxOutline, _mxDivResizer;
        if (this.outlineContainer) {
            this.outlineContainer.nativeElement.innerHTML = '';
            this.mxOutline = new mxOutline(this.mxGraph, this.outlineContainer.nativeElement);
            this.mxOutline['sizer'].stroke = '#746ca6';
            this.mxOutline['sizer'].fill = '#746ca6';
            this.mxOutline['selectionBorder'].stroke = '#746ca6';
            this.mxOutline.setEnabled(false);
            setTimeout(function () {
                _this.mxOutline.setEnabled(true);
                _this.mxOutline.update(true);
                _this.mxOutline.refresh();
                var resizer = new mxDivResizer(_this.outlineContainer.nativeElement, _this.element.nativeElement);
                resizer.resize();
            }, 100);
        }
        new mxKeyHandler(this.mxGraph);
        this.overrides();
        this.setStyle();
        this.setEdgeStyle();
        this.setVertexElse();
        this.setEdgeElseStyle();
        this.treeLayout();
        // let event = new mxEvent();
        var layout = new mxCompactTreeLayout(this.mxGraph, false);
        //layout.useBoundingBox = false;
        layout.edgeRouting = false;
        layout.levelDistance = 60;
        layout.nodeDistance = 32;
        this.mxGraph.addListener(mxEvent.REFRESH, function () {
            console.log('refreshed');
        });
        this.mxGraph.addMouseListener({
            mouseDown: function (sender, evt) {
                $('.mx-container').addClass('cur-grab-active');
            },
            mouseMove: function (sender, evt) { },
            mouseUp: function (sender, evt) {
                $('.mx-container').removeClass('cur-grab-active');
            }
        });
        // Allows the layout to move cells even though cells
        // aren't movable in the graph
        layout.isVertexMovable = function (cell) {
            return true;
        };
        var layoutMgr = new mxLayoutManager(this.mxGraph);
        layoutMgr.getLayout = function (cell) {
            if (cell.getChildCount() > 0) {
                return layout;
            }
        };
        //new mxRubberband(this.mxGraph);
        this.mxGraph.addListener(mxEvent.UPDATE_CELL_SIZE, function (sender, evt) {
            var graph = _this.mxGraph;
            //const layout:mxgraph.mxHierarchicalLayout = new mxHierarchicalLayout(this.mxGraph);
            _this.mxLayout.execute(graph.getDefaultParent());
            graph.view.refresh();
        });
        this.mxGraph.addListener(mxEvent.CELLS_REMOVED, function (graph) {
            //this.mxLayout.execute(graph.getDefaultParent());
            graph.view.refresh();
        });
        if (!this.options.viewMode) {
            //this.cellTracker = new mxCellTracker(this.mxGraph, '#6E66A2', null);
            this.mxGraph.addListener(mxEvent.CLICK, mxUtils.bind(this, function (sender, evt) {
                var cell = evt.getProperty('cell');
                if (_this.workflowType === 'Sequence'
                    && _this.options['mode']
                    && _this.options['mode'] === 'sequence_config'
                    && cell && (cell.isVertex() && cell.getId() == 'cs_initializer' || (evt && evt['properties']['event']['target'].tagName == 'BUTTON'))) {
                    return false;
                }
                if (cell && cell.isVertex() && cell['block_name'] == 'dummyBlock') {
                    return false;
                }
                if (cell && cell.isVertex() && cell.getValue() !== '') {
                    if (cell.getId() === 'cs_initializer') {
                        var bsModal = _this.modalService.show(TriggerRulesPanelComponent, {
                            initialState: {
                                title: 'Update a trigger flow',
                                mxGraph: _this.mxGraph,
                                selectedCell: cell,
                                isEdit: true,
                                triggerObjects: _this.fieldsData,
                                isScheduler: _this.isScheduler,
                                isMarketPlaceView: _this.isMarketPlaceView,
                                diagramOptions: _this.options
                            },
                            'size': 'sm',
                            'class': 'workflow-sidepanel'
                        });
                        bsModal.content.onClose.subscribe(function (result) {
                            //console.log('updare result', result);
                            _this.workflowService.triggerEvent({ 'actionName': 'beforeSave', 'data': cell, 'initializer': true });
                            _this.selectedMainObjectFields = result['fields'];
                            _this.mainObjectName = result['formData']['mainObject'];
                            try {
                                _this.renderInitBlockContainer(cell, result);
                                _this.mxGraph.labelChanged(cell, result['formData']);
                            }
                            finally {
                                _this.mxGraph.getModel().endUpdate();
                                _this.workflowService.triggerEvent('autoSave');
                            }
                        });
                    }
                    else if (cell['block_name'] == 'emailBlock' && _this.workflowType == 'Sequence' && _this.options['mode'] == 'sequence_config') {
                        var bsModalEmailBlock = _this.modalService.show(SequenceEmailWidgetComponent, {
                            initialState: {
                                cell: cell,
                                mxGraph: _this.mxGraph,
                                sender: sender,
                                diagramOptions: _this.options,
                                workflowType: _this.workflowType,
                            },
                            size: 'xl',
                            'class': 'ng-addblock-sidepanel'
                        });
                        bsModalEmailBlock.content.onClose.subscribe(function (formData) {
                            var cellValue = cell.getValue();
                            cellValue['model']['customMessage'] = formData;
                            if (cellValue['model']['message']) {
                                cellValue['model']['message'] = '';
                            }
                            _this.mxGraph.labelChanged(cell, cellValue);
                        });
                    }
                    else if (cell['block_name'] == 'smsBlock' && _this.workflowType == 'Sequence' && _this.options['mode'] == 'sequence_config') {
                        var bsModalSmsBlock = _this.modalService.show(SequenceSmsWidgetComponent, {
                            initialState: {
                                cell: cell,
                                mxGraph: _this.mxGraph,
                                sender: sender,
                                diagramOptions: _this.options,
                                workflowType: _this.workflowType,
                            },
                            size: 'standard',
                            'class': 'ng-addblock-sidepanel'
                        });
                        bsModalSmsBlock.content.onClose.subscribe(function (formData) {
                            var cellValue = cell.getValue();
                            cellValue['model']['customMessage'] = formData;
                            _this.mxGraph.labelChanged(cell, cellValue);
                        });
                    }
                    else {
                        var bsModalBlock = _this.modalService.show(AddBlockPanelComponent, {
                            initialState: {
                                cell: cell,
                                mxGraph: _this.mxGraph,
                                sender: sender,
                                event: event,
                                selectedTriggerFields: _this.selectedMainObjectFields,
                                options: _this.options,
                                title: 'sc',
                                isEdit: true,
                                isScheduler: _this.isScheduler,
                                isMarketPlaceView: _this.isMarketPlaceView,
                                mainObjectName: _this.mainObjectName,
                                mxGraphService: _this,
                                blocksList: _this.blocksList,
                                workflowType: _this.workflowType,
                            },
                            size: 'sm',
                            'class': 'ng-addblock-sidepanel'
                        });
                        bsModalBlock.content.onClose.subscribe(function (result) {
                            _this.workflowService.triggerEvent({ 'actionName': 'beforeSave', 'data': cell, 'initializer': false });
                            //console.log('update block', result, cell.getEdgeAt(0));
                            try {
                                _this.unsaveConfirm = true;
                                _this.mxGraph.getModel().beginUpdate();
                                if (result === 'deleteBlock') {
                                    // console.log('remove block');
                                    var _cells_1 = [];
                                    var _edges_1 = [];
                                    var topParentCell = _this.mxGraph.getModel().getCell(cell['parentId']);
                                    _this.mxGraph.traverse(cell, true, function (vertex, edges) {
                                        _cells_1.push(vertex);
                                        _edges_1.push(edges);
                                        return true;
                                    });
                                    _this.mxGraph.removeCells(_cells_1, true);
                                    _this.doRegenerateDag = false;
                                    if (topParentCell['block_name'] == 'elseBlock') {
                                        _this.addDummyBlock(topParentCell);
                                    }
                                }
                                else {
                                    var vertexUpdatedValue = {};
                                    vertexUpdatedValue['name'] = result['name'];
                                    vertexUpdatedValue['model'] = result['model'];
                                    var ed = cell.getEdgeAt(0);
                                    if (_.has(result, 'condition') && result['condition']['col_name'] !== '' && (result['condition']['value'] || Object.is(result['condition']['value'], 0))) {
                                        vertexUpdatedValue['condition'] = result['condition'];
                                        ed.setValue(vertexUpdatedValue['condition']);
                                    }
                                    if (_.has(result, 'isConditional')) {
                                        vertexUpdatedValue['isConditional'] = result['isConditional'];
                                    }
                                    _this.mxGraph.labelChanged(cell, vertexUpdatedValue, event);
                                    //this.mxGraph.labelChanged(cell, formData, event);
                                    if (_this.blocksSize[result['name']]) {
                                        var scale = _.clone(_this.blocksSize[result['name']]);
                                        if (result['name'] === 'untilConditionBlock' || result['name'] === 'conditionalBlock') {
                                            scale['width'] = _this.conditionBlockSize;
                                            scale['height'] = _this.conditionBlockSize;
                                        }
                                        var current = cell.getGeometry();
                                        _this.onAfterRender.next(cell);
                                    }
                                    _this.refresh();
                                }
                            }
                            finally {
                                _this.mxGraph.getModel().endUpdate();
                                _this.workflowService.triggerEvent('autoSave');
                            }
                        });
                    }
                }
            }));
        }
    };
    MxGraphService.prototype.getLabelByText = function (fieldName, value, optionKeyName, optionTextName) {
        if (optionKeyName === void 0) { optionKeyName = 'id'; }
        if (optionTextName === void 0) { optionTextName = 'text'; }
        var fieldObject = this.selectedMainObjectFields[fieldName];
        if (_.has(fieldObject, 'options')) {
            var options = fieldObject['options'];
            if (typeof value == 'object') {
                var optionValue = options.filter(function (i) { return value.includes(String(i.id)); });
                return optionValue.map(function (t) { return t.text; });
            }
            var activeObj = _.find(options, function (item) {
                return item[optionKeyName] == value;
            });
            if (activeObj) {
                return activeObj[optionTextName];
            }
            if (typeof value == 'object') {
                return _.chain(fieldObject)
                    .filter(function (item) {
                    return value.indexOf(item.id) > -1;
                })
                    .map(function (item) { return item.text; })
                    .value()
                    .join(', ');
            }
            return value;
        }
        return value;
    };
    ;
    MxGraphService.prototype.overrides = function () {
        var _this = this;
        this.mxGraph.getLabel = function (cell) {
            var self = _this;
            //let c = mxGraph.prototype.getLabel.apply(this.mxGraph, arguments);
            var label = _this.mxGraph.labelsVisible ? _this.mxGraph.convertValueToString(cell) : '';
            var blockName = cell['block_name'];
            /*if(!blockName && cell.isVertex()) {
                return label;
            }*/
            //console.log('getLabel', label);
            var blockMeta = _this.blocksMetaList.find(function (block) { return blockName == block['id']; });
            if (blockName === 'initializer' && cell.getValue() == '') {
                var divP = document.createElement('div');
                divP.setAttribute('id', 'cs_initializer_01_container');
                var div = document.createElement('div');
                div.style.textAlign = 'center';
                div.style.fontSize = '14px';
                divP.style.width = _this.blockWidth + 'px';
                div.className = 'init-block';
                var labelText = _this.isScheduler ? 'How do you want to schedule  this workflow?' : 'How do you want to trigger  this workflow?';
                if (_this.workflowType == 'Sequence') {
                    labelText = 'This workflow would be executed as a sequence';
                    div.style.padding = '20px';
                    div.style.marginBottom = '0';
                    div.style.cursor = 'default';
                }
                mxUtils.write(div, labelText);
                divP.appendChild(div);
                if (_this.workflowType == 'Custom') {
                    var btnText = _this.isScheduler ? 'Set up scheduler' : 'Set up trigger';
                    var btn = document.createElement('button');
                    btn.className = 'btn btn-primary';
                    mxUtils.write(btn, btnText);
                    btn.addEventListener(mxEvent.CLICK, function (e) {
                        self.setupWorkflowTrigger(cell, e);
                    });
                    divP.appendChild(btn);
                }
                return divP;
            }
            else if (blockName === 'initializer' && typeof cell.getValue() == 'object') {
                // console.log('initializer2', cell.getValue(), this.options.additionalData)
                /*const objValue:any = cell.getValue();
                let labelContainer = document.getElementById('cs_initializer_01_container');
                if(labelContainer) {
                    labelContainer.remove();
                }
                let divP = document.createElement('div'), divId = 'cs_initializer_trigger';
                if(this.workflowType == 'Sequence') {
                    divId = 'cs_initializer_trigger_sequence'
                }else if(this.options.viewMode == true){
                    divId = 'dummy-task-block'
                }

                divP.setAttribute('id', divId);

                let div  = document.createElement('div');
                div.style.textAlign = 'center';
                div.className = 'init-block';
                const labelText = this.isScheduler ? objValue['cronText'] : objValue.mainObject + ' trigger';
                const a = this.prepareInitBlockContainer(objValue, cell)
                divP.appendChild(a.container.get(0));
                return divP;*/
                var objValue = cell.getValue();
                var labelText = _this.isScheduler ? objValue['cronText'] : objValue.mainObject + ' trigger';
                var titleText = void 0;
                if (_this.workflowType == 'Custom') {
                    titleText = 'How do you want to trigger this ' + labelText + ' workflow?';
                }
                else {
                    titleText = 'This workflow would be executed as a sequence';
                }
                var divP = document.createElement('div');
                var container = _this.commonService.renderInitializer(cell, {
                    blockSize: _this.blocksSize,
                    selectedMainObjectFields: _this.selectedMainObjectFields,
                    getOperatorsDisplayText: _this.triggerService.getOperatorsDisplayText(),
                    mxGraphService: _this,
                    isScheduler: _this.isScheduler,
                    title: titleText,
                    id: cell.getId()
                });
                divP.appendChild(container.get(0));
                return divP;
            }
            else if (blockName === 'officeTaskBlock' && typeof cell.getValue() == 'object') {
                var divP = document.createElement('div');
                var container = _this.commonService.renderOfficeTaskBlock(cell, {
                    blockSize: _this.blocksSize,
                    mxGraphService: _this,
                    title: blockMeta['title'],
                    id: cell.getId()
                });
                divP.appendChild(container.get(0));
                return divP;
            }
            /*else if(blockName === 'chatmessageBlock' && typeof cell.getValue() == 'object') {
                let divP = document.createElement('div');
                const container = this.prepareChatmessageTemplate(cell);
                divP.appendChild(container.get(0));
                return divP;
            }*/
            else if (blockName === 'addjobBlock' && typeof cell.getValue() == 'object') {
                var divP = document.createElement('div');
                var container = _this.prepareAddJobTaskTemplate(cell);
                divP.appendChild(container.get(0));
                return divP;
            }
            else if (blockName === 'conditionalBlock' && typeof cell.getValue() == 'object') {
                var divP = document.createElement('div');
                var container = _this.prepareUntilConditionTemplate(cell);
                divP.appendChild(container.get(0));
                return divP;
            }
            else if (blockName === 'untilConditionBlock' && typeof cell.getValue() == 'object') {
                var divP = document.createElement('div');
                var container = _this.prepareUntilConditionTemplate(cell);
                divP.appendChild(container.get(0));
                return divP;
            }
            else if (blockName === 'emailBlock' && typeof cell.getValue() == 'object') {
                var divP = document.createElement('div');
                var container = _this.commonService.renderEmailBlock(cell, {
                    blockSize: _this.blocksSize,
                    mxGraphService: _this,
                    title: blockMeta['title'],
                    id: cell.getId()
                });
                divP.appendChild(container.get(0));
                return divP;
            }
            else if (blockName === 'condition_edge' && cell.isEdge()) {
                var divP = document.createElement('div');
                var container = _this.prepareConditionalEdageTemplate(cell);
                divP.appendChild(container.get(0));
                return divP;
            }
            else if (blockName === 'else_edge' && cell.isEdge()) {
                var divP = document.createElement('div');
                var container = _this.commonService.renderElseBlockEdge(cell, {
                    'service': self,
                    id: cell.getId()
                });
                divP.appendChild(container.get(0));
                return divP;
            }
            else if (blockName === 'smsBlock' && typeof cell.getValue() == 'object') {
                var divP = document.createElement('div');
                var container = _this.commonService.renderSmsBlock(cell, {
                    blockSize: _this.blocksSize,
                    mxGraphService: _this,
                    title: blockMeta['title'],
                    id: cell.getId()
                });
                divP.appendChild(container.get(0));
                return divP;
            }
            else if (blockName === 'notificationBlock' && typeof cell.getValue() == 'object') {
                var divP = document.createElement('div');
                var container = _this.commonService.renderNotificationBlock(cell, {
                    blockSize: _this.blocksSize,
                    mxGraphService: _this,
                    title: blockMeta['title'],
                    id: cell.getId()
                });
                divP.appendChild(container.get(0));
                return divP;
            }
            else if (blockName === 'setcustomfieldvalueBlock' && typeof cell.getValue() == 'object') {
                var divP = document.createElement('div');
                var container = _this.commonService.renderSetcustomfieldvalueBlock(cell, {
                    blockSize: _this.blocksSize,
                    mxGraphService: _this,
                    title: blockMeta['title'],
                    id: cell.getId()
                });
                divP.appendChild(container.get(0));
                return divP;
            }
            else if (blockName === 'scheduledActivityBlock' && typeof cell.getValue() == 'object') {
                var divP = document.createElement('div');
                var container = _this.commonService.renderScheduledActivityBlock(cell, {
                    blockSize: _this.blocksSize,
                    title: blockMeta['title'],
                    mxGraphService: _this,
                    id: cell.getId()
                });
                divP.appendChild(container.get(0));
                return divP;
            }
            else if (blockName === 'dummyBlock' && typeof cell.getValue() == 'object') {
                var divP = document.createElement('div');
                var container = _this.commonService.renderDummyBlock(cell, {
                    blockSize: _this.blocksSize.dummyBlock,
                    title: 'End',
                    id: cell.getId()
                });
                divP.appendChild(container.get(0));
                return divP;
            }
            else if (blockName === 'conditionalDelayBlock' && typeof cell.getValue() == 'object') {
                var divP = document.createElement('div');
                var container = _this.commonService.renderConditionalDelayBlock(cell, {
                    'service': self,
                    title: blockMeta['title'],
                    blockSize: _this.blocksSize,
                    id: cell.getId()
                });
                divP.appendChild(container.get(0));
                return divP;
            }
            else if (blockName === 'delayBlock' && typeof cell.getValue() == 'object') {
                var divP = document.createElement('div');
                var value = cell.getValue();
                var container = _this.commonService.renderDelayBlock(cell, {
                    'service': self,
                    title: value['model']['type'] === 'conditional' ? 'Field based delay' : 'Delay',
                    blockSize: _this.blocksSize,
                    id: cell.getId()
                });
                divP.appendChild(container.get(0));
                return divP;
            }
            else if (blockName === 'elseBlock' && typeof cell.getValue() == 'object') {
                return '';
            }
            return label;
        };
        if (!this.options.viewMode) {
            this.mxGraph.getTooltipForCell = function (cell) {
                if (cell.isEdge() ||
                    (_this.workflowType == 'Sequence' && _this.options['mode'] && _this.options['mode'] == 'sequence_config' && cell['block_name'] !== "emailBlock") ||
                    (cell['block_name'] === "initializer" && cell.getValue() === "") ||
                    cell['block_name'] === "dummyBlock") {
                    _this.mxGraph.tooltipHandler.hide();
                    return;
                }
                var s = cell['block_name'] === "initializer" ? 'trigger' : cell['block_name'];
                return 'Click to edit ' + s.replace('Block', '') + ' block';
            };
        }
        var graphGetPreferredSizeForCell = this.mxGraph.getPreferredSizeForCell;
        this.mxGraph.getPreferredSizeForCell = function (cell) {
            var result = graphGetPreferredSizeForCell.apply(_this.mxGraph, [cell]);
            //let style = this.mxGraph.getCellStyle(cell);
            var current = cell.getGeometry();
            result['width'] = current.width;
            result['height'] = current.height;
            return result;
        };
    };
    MxGraphService.prototype.getGraph = function () {
        return this.mxGraph;
    };
    MxGraphService.prototype.truncateMiddle = function (fullStr, strLen, separator) {
        if (separator === void 0) { separator = '...'; }
        if (fullStr.length <= strLen)
            return fullStr;
        separator = separator || '...';
        var sepLen = separator.length, charsToShow = strLen - sepLen, frontChars = Math.ceil(charsToShow / 2), backChars = Math.floor(charsToShow / 2);
        return fullStr.substring(0, frontChars) +
            separator +
            fullStr.substring(fullStr.length - backChars);
    };
    MxGraphService.prototype.getContainerWidth = function () {
        return this.element.nativeElement.offsetWidth;
    };
    MxGraphService.prototype.addOverlay = function (cell, isElseBlock) {
        var _this = this;
        if (isElseBlock === void 0) { isElseBlock = false; }
        if (!cell.getGeometry()) {
            var geo_1 = this.mxGraph.getCellGeometry(cell);
            return;
        }
        var offsetY = !isElseBlock ? 0 : 24 / 2;
        var overLay = new mxCellOverlay(new mxImage(addBtnUrl, 24, 24), 'Add', mxConstants.ALIGN_CENTER, mxConstants.ALIGN_CENTER, new mxPoint(0, offsetY), 'pointer');
        var geo = this.mxGraph.getCellGeometry(cell);
        overLay.cursor = 'hand';
        overLay.addListener(mxEvent.CLICK, function (sender, event) {
            if (_this.options.hasOwnProperty('confirmationBoxHelper') && _this.options.hasOwnProperty('ajsScope') && _this.workflowStatus) {
                _this.options.confirmationBoxHelper.getConfirmation(_this.deactivateMessage, _this.options.ajsScope)
                    .then(function () {
                    _this.workflowService.triggerEvent('deactivate_action');
                });
            }
            else {
                var initialState = {
                    cell: cell,
                    mxGraph: _this.mxGraph,
                    sender: sender,
                    event: event,
                    selectedTriggerFields: _this.selectedMainObjectFields,
                    options: _this.options,
                    title: 'sc',
                    isMarketPlaceView: _this.isMarketPlaceView,
                    mainObjectName: _this.mainObjectName,
                    mxGraphService: _this,
                    blocksList: _this.blocksList,
                    workflowType: _this.workflowType,
                };
                _this.modalRef = _this.modalService.show(AddBlockPanelComponent, {
                    initialState: initialState,
                    size: 'sm',
                    'class': 'ng-addblock-sidepanel'
                });
                //let $parent = this.mxGraph.getDefaultParent();
                _this.modalRef.content.onClose.subscribe(function (result) {
                    _this.unsaveConfirm = true;
                    var blockSizes = _this.blocksSize;
                    var bn = result['name'];
                    _this.mxGraph.clearSelection();
                    var vertexOptions = {}, vertexModel = result['model'], delayBlock = {}, newVertex;
                    if (blockSizes[bn]) {
                        vertexOptions['width'] = blockSizes[bn]['width'];
                        vertexOptions['height'] = blockSizes[bn]['height'];
                    }
                    newVertex = _this.addChild(result, cell, vertexOptions, false);
                    _this.doRegenerateDag = true;
                    _this.refresh();
                });
            }
        });
        this.mxGraph.addCellOverlay(cell, overLay);
    };
    MxGraphService.prototype.executeLayout = function (change, post) {
        this.mxGraph.getModel().beginUpdate();
        var layout = new mxHierarchicalLayout(this.mxGraph);
        try {
            if (change != null) {
                change();
            }
            layout.execute(this.mxGraph.getDefaultParent());
        }
        catch (e) {
            throw e;
        }
        finally {
            var morph = new mxMorphing(this.mxGraph);
            morph.addListener(mxEvent.DONE, mxUtils.bind(this, function () {
                if (post != null) {
                    post();
                }
            }));
            morph.startAnimation();
            this.mxGraph.getModel().endUpdate();
            this.workflowService.triggerEvent('autoSave');
        }
    };
    MxGraphService.prototype.treeLayout = function (parent) {
        if (parent === void 0) { parent = null; }
        var layout = this.mxLayout = new mxCompactTreeLayout(this.mxGraph, false);
        //layout.useBoundingBox = false;
        layout.edgeRouting = false;
        layout.levelDistance = 40;
        layout.nodeDistance = 40;
        layout.isVertexMovable = function (cell) {
            return true;
        };
        var layoutMgr = new mxLayoutManager(this.mxGraph);
        var graph = this.mxGraph;
        //layout.execute(graph.getDefaultParent());
        layoutMgr.getLayout = function (cell) {
            //setTimeout(()  => {
            graph.view.refresh();
            //},300);
            if (cell.getChildCount() > 0) {
                return layout;
            }
        };
    };
    MxGraphService.prototype.getSourceEdge = function (edges, id) {
        var sourcedEdge = null;
        edges.forEach(function (edge) {
            if (edge.source.getId() === id) {
                sourcedEdge = edge;
                return true;
            }
        });
        return sourcedEdge;
    };
    MxGraphService.prototype.addDummyBlock = function (cell) {
        var vertexDummy;
        var cellValue = cell.getValue(), edgeDummy;
        var parent = this.mxGraph.getDefaultParent();
        var geo = this.mxGraph.getCellGeometry(cell);
        var _a = this.blocksSize.dummyBlock, width = _a.width, height = _a.height;
        try {
            vertexDummy = this.mxGraph.insertVertex(parent, null, {
                name: 'dummyBlock',
                model: cellValue['model']
            }, width, geo.y, width, height);
            vertexDummy['block_name'] = 'dummyBlock';
            vertexDummy['parentId'] = cell.getId();
            edgeDummy = this.mxGraph.insertEdge(parent, null, '', cell, vertexDummy);
            edgeDummy['block_name'] = 'dummy_edge';
            edgeDummy.geometry.x = 1;
            edgeDummy.geometry.y = 0;
            edgeDummy.geometry.offset = new mxPoint(0, -20);
        }
        finally {
            this.mxGraph.getModel().endUpdate();
            // this.workflowService.triggerEvent('autoSave');
        }
    };
    MxGraphService.prototype.addChild = function (data, cell, options, isHideOverlay) {
        if (options === void 0) { options = {}; }
        if (isHideOverlay === void 0) { isHideOverlay = false; }
        var parent = this.mxGraph.getDefaultParent();
        var vertex, vertexElse, vertexDummy;
        var blockName = data['name'], blockModel = data['model'], isConditional = data['isConditional'], vertexValue = {}, style = '';
        vertexValue['name'] = blockName;
        vertexValue['model'] = blockModel;
        vertexValue['isConditional'] = data['isConditional'];
        if (isConditional) {
            vertexValue['condition'] = data['condition'];
        }
        this.mxGraph.getModel().beginUpdate();
        var geo = this.mxGraph.getCellGeometry(cell);
        var vertexWidth = this.blockWidth, vertexHeight = this.blockHeight;
        if (_.has(options, 'width')) {
            vertexWidth = options['width'];
            vertexHeight = options['height'];
        }
        if (blockName === 'conditionalBlock' || blockName === 'untilConditionBlock') {
            style = 'shape=rhombus';
            vertexWidth = this.conditionBlockSize;
            vertexHeight = this.conditionBlockSize;
        }
        try {
            var blockMeta = this.blocksMetaList.find(function (block) { return blockName == block['id']; });
            var _container = void 0;
            if (blockMeta) {
                _container = this.commonService.blockHeightCalculation(data, {
                    blockSize: this.blocksSize,
                    title: blockMeta['title'],
                    mxGraphService: this
                });
                if (_container) {
                    vertexWidth = _container.width;
                    vertexHeight = _container.height;
                }
            }
            /*if(blockName === 'scheduledActivityBlock') {
                let $container = this.commonService.renderScheduledActivityBlock(data, {
                    blockSize: this.blocksSize,
                    title: 'Schedule activity',
                    mxGraphService: this,
                })
                $container.appendTo('body');
                vertexWidth = $container.outerWidth();
                vertexHeight = $container.outerHeight();
                $container.remove();
            }*/
            var edge = void 0, edgeElse = void 0;
            var $children = this.getChildren(cell);
            if ($children.length && $children.length == 1 && $children[0]['block_name'] == 'dummyBlock') {
                this.mxGraph.removeCells($children);
            }
            if (isConditional) {
                if ($children.length == 0) {
                    var conditionData = vertexValue['condition'];
                    var elseVertexModel = {
                        col_name: conditionData['col_name'],
                        text: conditionData['text'],
                        fieldType: conditionData['fieldType'],
                        pageType: conditionData['pageType']
                    };
                    vertexElse = this.mxGraph.insertVertex(parent, null, {
                        name: 'elseBlock',
                        isConditional: isConditional,
                        model: elseVertexModel
                    }, vertexWidth, geo.y, vertexWidth, 0, 'elseStyle');
                    vertexElse['block_name'] = 'elseBlock';
                    vertexElse['parentId'] = cell.getId();
                    edgeElse = this.mxGraph.insertEdge(parent, null, elseVertexModel, cell, vertexElse, 'elseEdgeStyle');
                    edgeElse['block_name'] = 'else_edge';
                    edgeElse.geometry.x = 1;
                    edgeElse.geometry.y = 0;
                    edgeElse.geometry.offset = new mxPoint(0, -20);
                    this.addOverlay(vertexElse, true);
                }
                vertex = this.mxGraph.insertVertex(parent, null, vertexValue, vertexWidth, geo.y, vertexWidth, vertexHeight, style);
                vertex['block_name'] = blockName;
                vertex['parentId'] = cell.getId();
                edge = this.mxGraph.insertEdge(parent, null, vertexValue['condition'], cell, vertex);
                edge['block_name'] = 'condition_edge';
            }
            else {
                vertex = this.mxGraph.insertVertex(parent, null, vertexValue, vertexWidth, geo.y, vertexWidth, vertexHeight, style);
                vertex['block_name'] = blockName;
                vertex['parentId'] = cell.getId();
                _.forEach($children, function (child) {
                    child['parentId'] = vertex.getId();
                });
                var cellEdges = this.mxGraph.getModel().getEdges(cell);
                var sourceEdge = this.getSourceEdge(cellEdges, cell.getId());
                edge = this.mxGraph.insertEdge(parent, null, '', cell, vertex);
                this.mxGraph.splitEdge(sourceEdge, [vertex], edge);
                // this.mxGraph.connectCell(sourceEdge, vertex, true);
                // this.mxGraph.view.refresh()
                //edge = this.mxGraph.insertEdge(parent, null, '', source, vertex);
            }
            this.workflowService.triggerEvent({ 'actionName': 'beforeSave', 'data': vertex, 'initializer': false });
            edge.geometry.x = 1;
            edge.geometry.y = 0;
            edge.geometry.offset = new mxPoint(0, -20);
            if (!isHideOverlay) {
                this.addOverlay(vertex);
                //if(blockName !== 'conditionalBlock') this.editOverlay(vertex);
            }
        }
        finally {
            this.mxGraph.getModel().endUpdate();
            this.onAfterRender.next(vertexElse);
            this.workflowService.triggerEvent('autoSave');
        }
        return vertex;
    };
    MxGraphService.prototype.setupWorkflowTrigger = function (cell, e) {
        var _this = this;
        var initialState = {
            title: this.isScheduler ? 'Setup a scheduler' : 'Setup a trigger flow',
            mxGraph: this.mxGraph,
            selectedCell: cell,
            triggerObjects: this.fieldsData,
            isScheduler: this.isScheduler,
            isMarketPlaceView: this.isMarketPlaceView,
            diagramOptions: this.options
        };
        this.modalRef = this.modalService.show(TriggerRulesPanelComponent, {
            'initialState': initialState,
            'size': 'sm',
            'class': 'workflow-sidepanel'
        });
        this.modalRef.content.onClose.subscribe(function (result) {
            //cell.setAttribute('mainObject', result);
            _this.selectedMainObjectFields = result['fields'];
            _this.mainObjectName = result['formData']['mainObject'];
            try {
                _this.addOverlay(cell);
                //this.editOverlay(cell);
                _this.renderInitBlockContainer(cell, result);
                //this.mxGraph.labelChanged(cell, result['formData'], e);
            }
            finally {
                _this.mxGraph.getModel().endUpdate();
                _this.workflowService.triggerEvent('autoSave');
            }
        });
    };
    MxGraphService.prototype.setVertexElse = function () {
        var style = new Object();
        style[mxConstants.STYLE_FONTSIZE] = '12';
        style[mxConstants.STYLE_FONTCOLOR] = '#505b65';
        style[mxConstants.STYLE_FILLCOLOR] = 'rgba(0,0,0,0)';
        style[mxConstants.STYLE_STROKECOLOR] = 'rgba(0,0,0,0)';
        style[mxConstants.STYLE_STROKEWIDTH] = 0;
        style[mxConstants.STYLE_SHAPE] = 'label';
        style[mxConstants.STYLE_SHADOW] = '0';
        style[mxConstants.STYLE_ROUNDED] = '0';
        style[mxConstants.STYLE_EDGE] = mxEdgeStyle.TopToBottom;
        style[mxConstants.EDGESTYLE_TOPTOBOTTOM] = 'topToBottomEdgeStyle';
        this.mxGraph.getStylesheet().putCellStyle('elseStyle', style);
    };
    MxGraphService.prototype.setEdgeElseStyle = function () {
        var style = new Object();
        style[mxConstants.STYLE_ARCSIZE] = 10;
        style[mxConstants.STYLE_ABSOLUTE_ARCSIZE] = 1;
        style[mxConstants.STYLE_SOURCE_PERIMETER_SPACING] = 'sourcePerimeterSpacing';
        style[mxConstants.STYLE_ROUNDED] = true;
        style[mxConstants.STYLE_STROKECOLOR] = '#DADADA';
        style[mxConstants.STYLE_STROKEWIDTH] = 6;
        style[mxConstants.STYLE_EXIT_X] = 0.5; // center
        style[mxConstants.STYLE_EXIT_Y] = 1.0; // bottom
        style[mxConstants.STYLE_EXIT_PERIMETER] = 0; // disabled
        style[mxConstants.STYLE_ENTRY_X] = 0.5; // center
        style[mxConstants.STYLE_ENTRY_Y] = 0; // top
        style[mxConstants.STYLE_ENTRY_PERIMETER] = 0; // disabled
        style[mxConstants.STYLE_EDGE] = mxEdgeStyle.TopToBottom;
        style[mxConstants.STYLE_ENDARROW] = mxConstants.NONE;
        style[mxConstants.STYLE_STARTARROW] = mxConstants.NONE;
        // style[mxConstants.EDGESTYLE_TOPTOBOTTOM] = 'topToBottomEdgeStyle';
        this.mxGraph.getStylesheet().putCellStyle('elseEdgeStyle', style);
        // style[mxConstants.STYLE_EDGE]
        //console.log('style', style);
    };
    MxGraphService.prototype.setStyle = function () {
        var style = this.mxGraph.getStylesheet().getDefaultVertexStyle();
        // mxConstants.RECTANGLE_ROUNDING_FACTOR = 0.03;
        mxConstants.SHADOWCOLOR = 'rgba(0, 0, 0, 0.05)';
        mxConstants.SHADOW_OFFSET_X = 0;
        mxConstants.SHADOW_OFFSET_Y = 4;
        style[mxConstants.STYLE_FONTSIZE] = '12';
        style[mxConstants.STYLE_FONTCOLOR] = '#505b65';
        style[mxConstants.STYLE_FILLCOLOR] = '#fff';
        style[mxConstants.STYLE_STROKECOLOR] = '#dadada';
        style[mxConstants.STYLE_STROKEWIDTH] = 1;
        style[mxConstants.STYLE_CURVED] = 0;
        style[mxConstants.STYLE_SHAPE] = 'label';
        style[mxConstants.STYLE_SHADOW] = '1';
        style[mxConstants.STYLE_ROUNDED] = '0';
    };
    MxGraphService.prototype.setEdgeStyle = function () {
        var style = this.mxGraph.getStylesheet().getDefaultEdgeStyle();
        style[mxConstants.STYLE_ARCSIZE] = 10;
        style[mxConstants.STYLE_ABSOLUTE_ARCSIZE] = 1;
        style[mxConstants.STYLE_SOURCE_PERIMETER_SPACING] = 'sourcePerimeterSpacing';
        style[mxConstants.STYLE_ROUNDED] = true;
        style[mxConstants.STYLE_STROKECOLOR] = '#DADADA';
        style[mxConstants.STYLE_STROKEWIDTH] = 6;
        style[mxConstants.STYLE_EXIT_X] = 0.5; // center
        style[mxConstants.STYLE_EXIT_Y] = 1.0; // bottom
        style[mxConstants.STYLE_EXIT_PERIMETER] = 0; // disabled
        style[mxConstants.STYLE_ENTRY_X] = 0.5; // center
        style[mxConstants.STYLE_ENTRY_Y] = 0; // top
        style[mxConstants.STYLE_ENTRY_PERIMETER] = 0; // disabled
        style[mxConstants.STYLE_EDGE] = mxEdgeStyle.TopToBottom;
        style[mxConstants.STYLE_ENDARROW] = mxConstants.NONE;
        style[mxConstants.STYLE_STARTARROW] = mxConstants.NONE;
        // style[mxConstants.STYLE_EDGE]
        //console.log('style', style);
    };
    MxGraphService.prototype.registerHistoryView = function (items) {
        var _this = this;
        var tasks = items.tasks;
        this.mxGraph.addListener(mxEvent.CLICK, function (sender, evt) {
            if (_.isUndefined(evt.getProperty('cell'))) {
                return false;
            }
            var cell = evt.getProperty('cell');
            if (!cell.isVertex()) {
                return false;
            }
            var blockId = cell['block_name'] === "initializer" ? cell.getId() : cell['block_name'] + '_' + cell.getId();
            if (_.has(tasks, blockId)) {
                var initialState = {
                    cell: cell,
                    mxGraph: _this.mxGraph,
                    taskItem: tasks[blockId],
                    title: 'sc'
                };
                _this.modalRefHistory = _this.modalService.show(HistoryTimelinePanelComponent, {
                    initialState: initialState,
                    size: 'sm',
                    'class': 'ng-taks-history-sidepanel'
                });
                _this.modalRefHistory.content.onClose.subscribe(function (result) {
                    // console.log(cell, result);
                });
            }
        });
    };
    MxGraphService.prototype.setHightlightCells = function (items, isAudit) {
        var _this = this;
        if (isAudit === void 0) { isAudit = false; }
        this.resetHightlightedCells(function () {
            if (isAudit) {
                var block_name_1 = items.auditRecord.taskId.split('_')[0];
                var block_id_1 = items.auditRecord.taskId.split('_')[1];
                var cells_1 = _this.mxGraph.getModel().cells;
                var key = void 0;
                if (items.auditRecord.taskId === 'cs_initializer') {
                    key = items.auditRecord.taskId;
                }
                else {
                    key = Object.keys(cells_1).find(function (k) { return cells_1[k].block_name === block_name_1 && cells_1[k].id === block_id_1; });
                }
                var cell_1 = cells_1[key];
                var blockId_1 = items.auditRecord.taskId === 'cs_initializer' ? cell_1.getId() : cell_1['block_name'] + '_' + cell_1.getId();
                var colorCode_1 = items.auditRecord.actionType == 'Add' ? HIGHLIGHT_BLOCK_ADD : items.auditRecord.actionType == 'Edit' ? HIGHLIGHT_BLOCK_EDIT : HIGHLIGHT_BLOCK_DELETE;
                if (cell_1['block_name'] === 'elseBlock') {
                    return;
                }
                if (_.has(_this.cellHighlighter, blockId_1)) {
                    _this.cellHighlighter[blockId_1].destroy();
                }
                setTimeout(function () {
                    if (colorCode_1) {
                        _this.cellHighlighter[blockId_1] = new mxCellHighlight(_this.mxGraph, colorCode_1, 2);
                        _this.cellHighlighter[blockId_1].highlight(_this.mxGraph.view.getState(cell_1));
                    }
                }, 100);
                console.log('audit highlighted cells');
            }
            else {
                _this.registerHistoryView(items);
                _.forEach(_this.mxGraph.getModel().cells, function (cell) {
                    var tasks = items.tasks;
                    if (!_.isUndefined(cell.getValue()) && cell.isVertex()) {
                        var cellValue = cell.getValue();
                        var blockId_2 = cell['block_name'] === "initializer" ? cell.getId() : cell['block_name'] + '_' + cell.getId();
                        /* overlap conditional delay */
                        if (cell['block_name'] == 'delayBlock' && cellValue['model']['type'] === 'conditional' && !_.has(tasks[blockId_2])) {
                            blockId_2 = 'conditionalDelayBlock_' + cell.getId();
                        }
                        var status_1 = _.has(tasks[blockId_2], 'status') ? tasks[blockId_2]['status'] : null;
                        var colorCode_2 = status_1 === 'success' ? HIGHLIGHT_COMPLETED : status_1 === 'running' ? HIGHLIGHT_RUNNING : status_1 === 'failed' ? HIGHLIGTT_SKIPPED_OR_FAILED : status_1 === 'skipped' ? HIGHLIGTT_SKIPPED : null;
                        if (cell['block_name'] === 'elseBlock') {
                            return;
                        }
                        if (_.has(_this.cellHighlighter, blockId_2)) {
                            _this.cellHighlighter[blockId_2].destroy();
                        }
                        setTimeout(function () {
                            if (colorCode_2) {
                                _this.cellHighlighter[blockId_2] = new mxCellHighlight(_this.mxGraph, colorCode_2, 2);
                                _this.cellHighlighter[blockId_2].highlight(_this.mxGraph.view.getState(cell));
                            }
                        }, 100);
                    }
                });
            }
        });
    };
    MxGraphService.prototype.resetHightlightedCells = function (setHighLight) {
        if (setHighLight === void 0) { setHighLight = function () { }; }
        if (_.size(this.cellHighlighter) > 0) {
            var highlights = Object.values(this.cellHighlighter);
            _.forEach(highlights, function (highlight) {
                highlight.destroy();
            });
        }
        this.mxGraph.removeListener(mxEvent.CLICK);
        return setHighLight();
    };
    MxGraphService.prototype.reset = function () {
        this.selectedMainObjectFields = null;
    };
    MxGraphService.prototype.destroyMxGraph = function (afterDestroy) {
        if (afterDestroy === void 0) { afterDestroy = _.noop; }
        if (this.mxGraph) {
            this.mxGraph.destroy();
        }
        this.firstTimeRender = true;
        afterDestroy();
    };
    MxGraphService.prototype.refresh = function (interval) {
        var _this = this;
        if (interval === void 0) { interval = 0; }
        setTimeout(function () {
            _this.mxGraph.view.refresh();
        }, interval);
    };
    MxGraphService.prototype.prepareActiveInput = function (activeMainObject, MainObjectValue, cb) {
        var _this = this;
        // console.log('prepareActiveInput', activeMainObject, MainObjectValue);
        this.selectedMainObjectFields = {};
        activeMainObject.fields.forEach(function (value) {
            _this.selectedMainObjectFields[value.col_name] = {
                input: value.input,
                type: value.type,
                text: value.text,
                operators: value.operators
            };
            if (value.hasOwnProperty('options') && value.options.length > 0) {
                _this.selectedMainObjectFields[value.col_name]['options'] = value.options;
            }
        });
        if (activeMainObject.id === 'certificates') {
            var selectedDependentId = MainObjectValue['dependentId'];
            if (Object.keys(activeMainObject['dynamic_fields']).length) {
                var dynamicFields = activeMainObject['dynamic_fields'][selectedDependentId]['fields'];
                dynamicFields.forEach(function (item, index) {
                    _this.selectedMainObjectFields[item.col_name] = {
                        input: item.input,
                        type: item.type,
                        text: item.text,
                        operators: item.operators,
                        fieldType: item.fieldType,
                        pageType: item.pageType,
                    };
                    if (item.hasOwnProperty('options') && item.options.length > 0) {
                        _this.selectedMainObjectFields[item.col_name]['options'] = item.options;
                    }
                });
            }
        }
        if (activeMainObject.hasOwnProperty('custom_fields')) {
            activeMainObject['custom_fields'].forEach(function (template) {
                template['fields'].forEach(function (value) {
                    _this.selectedMainObjectFields[value.col_name] = value;
                    if (value.hasOwnProperty('options') && value.options.length > 0) {
                        _this.selectedMainObjectFields[value.col_name]['options'] = value.options;
                    }
                });
            });
        }
        cb();
        // console.log('active', this.selectedMainObjectFields);
    };
    MxGraphService.prototype.getXml = function (uriEncode) {
        if (uriEncode === void 0) { uriEncode = false; }
        var codec = new mxCodec();
        var result = codec.encode(this.mxGraph.getModel());
        var xml = mxUtils.getXml(result);
        if (uriEncode) {
            return encodeURIComponent(xml);
        }
        return xml;
    };
    MxGraphService.prototype.parseXml = function (xml) {
        var doc = mxUtils.parseXml(xml);
        var codec = new mxCodec(doc);
    };
    MxGraphService.prototype.render = function (blockObjects) {
        var _this = this;
        if (blockObjects === void 0) { blockObjects = null; }
        if (!this.xmlSource) {
            this.mxGraph.getModel().beginUpdate();
            try {
                var parent_1 = this.mxGraph.getDefaultParent();
                var blockWidth = this.blockWidth, blockHeight = this.blockHeight;
                var x = (this.getContainerWidth() / 2) - (blockWidth / 2);
                //const x = 30;
                var y = 20;
                var startBlock = this.mxGraph.insertVertex(parent_1, 'cs_initializer', '', x, y, blockWidth, blockHeight);
                startBlock['block_name'] = 'initializer';
                startBlock['parentId'] = '0';
                //const endBlock = this.mxGraph.insertVertex(parent, 'cs_end_block', 'End block', x, 220, blockWidth, blockHeight);
                //const connector = this.mxGraph.insertEdge(parent, null, '', startBlock, endBlock);
                //this.addOverlay(startBlock, true);
            }
            finally {
                this.mxGraph.getModel().endUpdate();
            }
        }
        else {
            var doc_1 = mxUtils.parseXml(this.xmlSource);
            var codec_1 = new mxCodec(doc_1);
            var initializerBlock = codec_1.getObject('cs_initializer');
            var blockValue = initializerBlock.getValue();
            if (!this.fieldsData) {
                return;
            }
            var activeMainObject = this.fieldsData[blockValue.mainObject];
            this.mainObjectName = blockValue.mainObject;
            this.prepareActiveInput(activeMainObject, blockValue, function () {
                codec_1.decode(doc_1.documentElement, _this.mxGraph.getModel());
                var isInitialBlock = false, initializerTitle = '';
                var bounds = [];
                _.forEach(_this.mxGraph.getModel().cells, function (cell) {
                    if (blockObjects && cell.isVertex() && (blockObjects[cell['block_name'] + '_' + cell.getId()] || 'cs_initializer' == cell.getId())) {
                        if (cell.getId() == 'cs_initializer') {
                            cell.setValue(blockObjects['cs_initializer']);
                        }
                        else {
                            cell.setValue(blockObjects[cell['block_name'] + '_' + cell.getId()]);
                        }
                        var options = {
                            blockSize: _this.blocksSize,
                            mxGraphService: _this,
                            id: cell.getId()
                        };
                        var blockMeta = _this.blocksMetaList.find(function (block) { return cell['block_name'] == block['id']; });
                        if (!blockMeta && 'cs_initializer' == cell.getId()) {
                            //console.log('cccccccc', cell, options)
                            var objValue = cell.getValue();
                            var labelText = _this.isScheduler ? objValue['cronText'] : objValue.mainObject + ' trigger';
                            if (_this.workflowType == 'Custom') {
                                initializerTitle = 'How do you want to trigger this ' + labelText + ' workflow?';
                            }
                            else {
                                initializerTitle = 'This workflow would be executed as a sequence';
                            }
                            isInitialBlock = true;
                            options['title'] = initializerTitle;
                            options['selectedMainObjectFields'] = _this.selectedMainObjectFields;
                            options['getOperatorsDisplayText'] = _this.triggerService.getOperatorsDisplayText();
                            options['isScheduler'] = _this.isScheduler;
                        }
                        else if (!['elseBlock', 'dummyBlock'].includes(cell['block_name'])) {
                            options['title'] = blockMeta['title'];
                        }
                        if (_this.options['doAutoHeightAdjustment'] && _this.options['doAutoHeightAdjustment'] == true && (blockMeta || isInitialBlock)) {
                            var containerInfo = _this.commonService.blockHeightCalculation(cell, options);
                            // console.log('cccccccc', cell, options)
                            if (containerInfo) {
                                var current = cell.getGeometry();
                                current.width = containerInfo.width;
                                current.height = containerInfo.height;
                                cell.setGeometry(current);
                            }
                        }
                    }
                    if (cell.isVertex() && !_this.options.viewMode && !_this.isMarketPlaceView && (cell['block_name'] !== 'dummyBlock')) {
                        var isElseBlock = cell['block_name'] == 'elseBlock';
                        _this.addOverlay(cell, isElseBlock);
                    }
                });
                if (_this.options['doAutoHeightAdjustment']) {
                    isInitialBlock = false;
                    setTimeout(function () {
                        _this.mxGraph.resizeCells(_this.mxGraph.getModel().cells, [], true);
                        _this.mxGraph.refresh();
                    }, 300);
                }
            });
        }
        setTimeout(function () {
            _this.mxGraph.zoomActual();
        }, 300);
    };
    MxGraphService.prototype.xmlValidator = function () {
        return true;
    };
    MxGraphService.prototype.getChildren = function (cell) {
        var children = [];
        var edges = cell.edges;
        if (edges != null) {
            for (var i = 0; i < edges.length; i++) {
                if (edges[i].target.getId() != cell.getId()) {
                    var cellCopy = edges[i].target;
                    children.push(cellCopy);
                }
            }
        }
        return children;
    };
    MxGraphService.prototype.skipOrUndoButton = function (cell) { };
    MxGraphService.prototype.getInitBlockValue = function () {
        var initBlock = this.mxGraph.getModel().getCell('cs_initializer');
        if (initBlock) {
            return initBlock.getValue();
        }
        return {};
    };
    MxGraphService.ngInjectableDef = i0.defineInjectable({ factory: function MxGraphService_Factory() { return new MxGraphService(i0.inject(i1.SidepanelModalService), i0.inject(i2.TriggerDatasetService), i0.inject(i3.WorkflowCommonService), i0.inject(i0.ComponentFactoryResolver), i0.inject(i0.INJECTOR), i0.inject(i0.ApplicationRef), i0.inject(i4.WorkflowhttpService)); }, token: MxGraphService, providedIn: "root" });
    return MxGraphService;
}());
export { MxGraphService };
