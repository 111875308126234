import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CsFullScreenModalComponent} from "@app/directives";
import {DiagramViewSequenceComponent} from "@app/workflow-common/diagram-view-sequence/diagram-view-sequence.component";
import {delay, tap} from "rxjs/operators";
import {WorkflowhttpService} from "@app/workflow-common/services/workflowhttp.service";
import {SendSequencePopupComponent} from "@app/workflow-common/send-sequence-popup/send-sequence-popup.component";
import {MxGraphSequenceService} from "@app/workflow-common/services/mx-graph-sequence";

@Component({
  selector: 'app-sequence-container',
  templateUrl: './sequence-container.component.html',
  styleUrls: ['./sequence-container.component.scss']
})
export class SequenceContainerComponent implements OnInit {

  data: any = null;
  tagsList:any = [];
  rolesList:any = [];
  scheduleActivityTypes:any = [];
  usersList:any = [];
  isLoading: boolean = true;
  mainData: any;
  menuItems:any[];
  exitConditions: any;
  additionalData: any;
  conditions:any;
  private workflowDiagram: DiagramViewSequenceComponent;
  @Input() csFullModal:CsFullScreenModalComponent;
  @Input() activeMenu: string = 'sequence_setup'
  @Input() isEnabled:boolean = true;
  @Input() sequenceMode: string;
  @Input() sequenceObjectName: string = '';
  @Output() readyToView: EventEmitter<any> = new EventEmitter<any>();
  @Output() activeMenuItem: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('workflowDiagram') set content(content: DiagramViewSequenceComponent) {
    if(content)
        this.workflowDiagram = content;
  }

  constructor(
      private workflowService: WorkflowhttpService,
  ) { }

  ngOnInit() {
      this.menuItems = [
          {
              id: 'sequence_header',
              title: 'Sequence',
              isHeader:true
          },
          {
              id: 'sequence_setup',
              title: 'Sequence',
              class:'fas fa-share-alt rot30deg',
              isActive:true,
          },
          {
              id: 'exit_criteria_setup',
              title: 'Exit criteria',
              class:'fal fa-newspaper',
              isActive:true,
          },
          {
              id: 'history_header',
              title: 'History',
              isHeader:true
          },
          {
              id: 'history_audit',
              title: 'Workflow audit',
              class:'fas fa-file-search',
              isActive:true
          },
      ];
      console.log(this,this.csFullModal,'data')
  }

  afterRenderedDiagram(mxGraph) {

  }

  getMetaData(objectName:string) {
    this.isLoading=true;
    this.workflowService.getObjectFields({objectName})
    .pipe(
        tap( resp => {
            this.setRolesList(resp['rolesList']);
            this.setTagsList(resp['tagsList']);
            this.setScheduleActivityTypes(resp['activityTypes']);
            this.setUsersList(resp['assignee']);
            this.setExitCriteria(resp['exitCriteria']);
        })
    )
    .pipe(delay(300))
    .subscribe( resp => {
        this.mainData = resp;
        this.isLoading=false;
    });
  }

  beforeRenderedDiagram(instance: DiagramViewSequenceComponent) {
    this.workflowDiagram = instance;
      this.data.xmlsource = this.data.xmlSource ? this.data.xmlSource : this.data.xmlsource;
      this.data.type = 'sequence';
      this.readyToView.emit({
          data: this.data,
          mainData: this.mainData
      })
  }
    setActiveMenu(item){
      this.activeMenu = item.id;
        this.activeMenuItem.emit({
            activeMenu: this.activeMenu
        })
    }

  setSelectedData(data:any, additionalData:any = {}) {
    this.data=data;
    this.additionalData = additionalData;
  }

  getDiagramComponent() {
    return this.workflowDiagram;
  }

  setTagsList(tags) {
    this.tagsList = tags;
  }

  setUsersList(users) {
      this.usersList = users;
  }

  setScheduleActivityTypes(types) {
      this.scheduleActivityTypes = types;
  }

  setRolesList(roles:any=[]) {
    this.rolesList = roles;
  }
  setExitCriteria(conditions){
      this.exitConditions = conditions;
  }
  selectedExitCriteria(instance){
      this.conditions = instance;
      this.data.condition = instance;
  }

}
