import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DagrunsComponent } from './dagruns/dagruns.component';
import { WorkflowhttpService } from "./services/workflowhttp.service";
import { AppCommonModule } from "@app/shared";
import { TranslateModule} from "@ngx-translate/core";
import {
    ModalModule,
    ButtonsModule,
    BsDatepickerModule,
    BsDropdownModule,
    BsDropdownConfig,
    DatepickerModule,
    PopoverModule, PopoverConfig
} from "ngx-bootstrap";
import { DiagramHistoryComponent } from './diagram-history/diagram-history.component';
import { AddBlockPanelComponent } from './add-block-panel/add-block-panel.component';
import { DelayBlockComponent } from './add-block-panel/delay-block/delay-block.component';
import { ConditionalBlockComponent } from './add-block-panel/conditional-block/conditional-block.component';
import { EmailBlockComponent } from './add-block-panel/email-block/email-block.component';
import { ConditionEdgeComponent } from './add-block-panel/condition-edge/condition-edge.component';
import { TriggerRulesPanelComponent } from './trigger-rules-panel/trigger-rules-panel.component';
import { HistoryTimelinePanelComponent } from './history-timeline-panel/history-timeline-panel.component';
import {TriggerDatasetService} from "./services/trigger-dataset.service";
import { SidepanelModule } from "@app/sidepanel/sidepanel.module";
import { MxGraphService } from "@app/workflow-common/services/mx-graph.service";
import { DiagramViewComponent } from './diagram-view/diagram-view.component';
import { OfficetaskBlockComponent } from './add-block-panel/officetask-block/officetask-block.component';
import { AddjobBlockComponent } from './add-block-panel/addjob-block/addjob-block.component';
import { ChatmessageBlockComponent } from './add-block-panel/chatmessage-block/chatmessage-block.component';
import { QuillModule } from "ngx-quill";
import { SmsBlockComponent } from './add-block-panel/sms-block/sms-block.component';
import { UntilconditionBlockComponent } from './add-block-panel/untilcondition-block/untilcondition-block.component';
import { NotificationBlockComponent } from './add-block-panel/notification-block/notification-block.component';
import {WorkflowCommonService} from "@app/workflow-common/services/workflow.common.service";
import {DatePipe, LongDatePipe, PipesModule} from "@app/pipes";
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SetcustomfieldvalueBlockComponent } from './add-block-panel/setcustomfieldvalue-block/setcustomfieldvalue-block.component';
import { ScheduledActivityBlockComponent } from './add-block-panel/scheduled-activity-block/scheduled-activity-block.component';
import { DatefilterComponent } from './dagruns/datefilter/datefilter.component';
import { SequenceContainerComponent } from './sequence-container/sequence-container.component';
import { DiagramViewSequenceComponent } from './diagram-view-sequence/diagram-view-sequence.component';
import {MxGraphSequenceService} from "@app/workflow-common/services/mx-graph-sequence";
import { FullScreenHeaderComponent } from './full-screen-header/full-screen-header.component';
import { SendSequenceActionComponent } from './send-sequence-action/send-sequence-action.component';
import { SendSequencePopupComponent } from './send-sequence-popup/send-sequence-popup.component';
import { SequenceAddContactComponent } from './sequence-add-contact/sequence-add-contact.component';
import { SequenceEmailWidgetComponent } from './sequence-email-widget/sequence-email-widget.component';
import { SequenceViewsComponent } from './sequence-views/sequence-views.component';
import { SequenceViewItemComponent } from './sequence-views/sequence-view-item/sequence-view-item.component';
import { SequenceSmsWidgetComponent } from './sequence-sms-widget/sequence-sms-widget.component';
import {
    ConditionalDelayBlockComponent
} from "@app/workflow-common/add-block-panel/conditional-delay-block/conditional-delay-block-component";
import { ExitConditionViewComponent } from './exit-condition-view/exit-condition-view.component';
import { ExitCriteriaSidePanelComponent } from './exit-criteria-side-panel/exit-criteria-side-panel.component';
import {DirectivesModule} from "@app/directives";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {CommonFolderStructureModule} from "@app/shared/common-folder-structure/common-folder-structure.module";
import {SmartTableModule} from "@app/smart_table";
import { AuditHistoryComponent } from './workflow-history/audit-history/audit-history.component';
import { WorkflowAuditListComponent } from './workflow-history/workflow-audit-list/workflow-audit-list.component';
import {CsComponentsModule} from "@app/shared/cs-components/cs-components.module";

@NgModule({
  declarations: [
      DagrunsComponent,
      DiagramHistoryComponent,
      AddBlockPanelComponent,
      DelayBlockComponent,
      ConditionalBlockComponent,
      EmailBlockComponent,
      ConditionEdgeComponent,
      TriggerRulesPanelComponent,
      HistoryTimelinePanelComponent,
      DiagramViewComponent,
      OfficetaskBlockComponent,
      AddjobBlockComponent,
      ChatmessageBlockComponent,
      SmsBlockComponent,
      UntilconditionBlockComponent,
      NotificationBlockComponent,
      SetcustomfieldvalueBlockComponent,
      ScheduledActivityBlockComponent,
      DatefilterComponent,
      SequenceContainerComponent,
      DiagramViewSequenceComponent,
      FullScreenHeaderComponent,
      SendSequenceActionComponent,
      SendSequencePopupComponent,
      SequenceAddContactComponent,
      SequenceEmailWidgetComponent,
      SequenceViewsComponent,
      SequenceViewItemComponent,
      SequenceSmsWidgetComponent,
      ConditionalDelayBlockComponent,
      ExitConditionViewComponent,
      ExitCriteriaSidePanelComponent,
      AuditHistoryComponent,
      WorkflowAuditListComponent
  ],
    imports: [
        CommonModule,
        //AppCommonModule,
        SidepanelModule,
        ModalModule,
        ButtonsModule,
        BsDatepickerModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        QuillModule,
        InfiniteScrollModule,
        BsDropdownModule,
        DatepickerModule,
        PopoverModule,
        DirectivesModule,
        TooltipModule,
        PipesModule,
        CommonFolderStructureModule,
        SmartTableModule,
        CsComponentsModule,
    ],
  providers:[
      WorkflowhttpService,
      TriggerDatasetService,
      MxGraphService,
      MxGraphSequenceService,
      WorkflowCommonService,
      DatePipe,
      LongDatePipe,
      PopoverConfig,
      { provide: BsDropdownConfig, useValue: { autoClose: true } },
  ],
    exports: [
        DagrunsComponent,
        DiagramHistoryComponent,
        AddBlockPanelComponent,
        HistoryTimelinePanelComponent,
        TriggerRulesPanelComponent,
        DiagramViewComponent,
        DatepickerModule,
        SequenceContainerComponent,
        DiagramViewSequenceComponent,
        FullScreenHeaderComponent,
        SendSequenceActionComponent,
        SendSequencePopupComponent,
        SequenceAddContactComponent,
        SequenceEmailWidgetComponent,
        SequenceViewsComponent,
        SequenceViewItemComponent,
        ExitCriteriaSidePanelComponent,
        AuditHistoryComponent
    ],
  entryComponents: [
      DagrunsComponent,
      DiagramHistoryComponent,
      AddBlockPanelComponent,
      HistoryTimelinePanelComponent,
      TriggerRulesPanelComponent,
      FullScreenHeaderComponent,
      SendSequencePopupComponent,
      SequenceAddContactComponent,
      SequenceEmailWidgetComponent,
      SequenceSmsWidgetComponent,
      ExitCriteriaSidePanelComponent
  ]
})
export class WorkflowCommonModule { }
