import { EventEmitter, OnInit } from '@angular/core';
import { WorkflowhttpService } from "@app/workflow-common/services/workflowhttp.service";
import { TimelineService } from "@app/shared/services/timeline.service";
import { Subject } from "rxjs";
var WorkflowAuditListComponent = /** @class */ (function () {
    function WorkflowAuditListComponent(workflowService, ts) {
        this.workflowService = workflowService;
        this.ts = ts;
        this.loading = false;
        this.currentPage = 1;
        this.pageLimit = 10;
        this.firstTimeLoad = false;
        this.selectedItem = {};
        this.appliedViewsFilter = [];
        this.widthChangeEventSubject = new Subject();
        this.activeAuditDataItem = new EventEmitter();
    }
    WorkflowAuditListComponent.prototype.ngOnInit = function () {
        this.loading = true;
        this.childCurrentPage = 1;
        if (this.data) {
            this.param = {
                page: this.currentPage,
                limit: this.pageLimit
            };
            this.getAuditData(true, true);
        }
    };
    WorkflowAuditListComponent.prototype.ngAfterViewInit = function () {
        var left = $('#add-filter-dropdown').position().left - $('.aside-inner').width() + 'px';
        setTimeout(function () {
            $(".dropdown-menu").css("left", left + ' !important');
        }, 200);
    };
    WorkflowAuditListComponent.prototype.getAuditData = function (resetPage, isInitialCall) {
        var _this = this;
        if (resetPage === void 0) { resetPage = false; }
        if (isInitialCall === void 0) { isInitialCall = false; }
        if (resetPage) {
            this.currentPage = 1;
            this.firstTimeLoad = true;
        }
        this.workflowService.workflowAuditRecords(this.data['id'], this.data['type'], this.param).subscribe(function (data) {
            _this.results = data['auditRecords'];
            _this.totalEntriesCount = data['totalCount'];
            if (isInitialCall) {
                _this.filterList = data['defaultFilters'];
            }
            _this.hasResult = _this.results.length;
            _this.loading = false;
        });
    };
    WorkflowAuditListComponent.prototype.auditHistoryHandler = function (item) {
        var _this = this;
        if (this.cursor == 'pointer') {
            this.selectedItem = item;
            this.activeCursor = 'pointer';
            if (item.eventType.includes('_task')) {
                this.workflowService.getAuditDagsource(item.id).
                    subscribe(function (resp) {
                    setTimeout(function () {
                        _this.activeAuditDataItem.emit({
                            'auditRecord': item,
                            'xmlsource': _this.data.xmlSource,
                            'dagSource': resp['dagSource'],
                        });
                    }, 300);
                });
            }
            else {
                this.activeAuditDataItem.emit({
                    'auditRecord': item,
                });
            }
        }
    };
    WorkflowAuditListComponent.prototype.getDisplayDate = function () {
        this.dateToDisplay = this.ts.convertTimeLineDateTime(this.results.dateToDisplay);
    };
    WorkflowAuditListComponent.prototype.fetchAuditRecord = function (event) {
        this.currentPage = event['page'];
        this.param.page = this.currentPage;
        if (this.firstTimeLoad && event['page'] == 1) {
            return;
        }
        this.firstTimeLoad = false;
        this.getAuditData(false);
    };
    WorkflowAuditListComponent.prototype.addToAppliedFilters = function (filter) {
        this.appliedViewsFilter.push(filter);
        this.setParam(this.appliedViewsFilter);
        this.getAuditData(true);
    };
    WorkflowAuditListComponent.prototype.setParam = function (filters) {
        this.param = {
            page: this.currentPage,
            limit: this.pageLimit,
        };
        this.param.filter = filters;
    };
    WorkflowAuditListComponent.prototype.removeFilter = function (filter) {
        this.appliedViewsFilter.splice(filter, 1);
        this.setParam(this.appliedViewsFilter);
        this.getAuditData(true);
    };
    WorkflowAuditListComponent.prototype.onMouseover = function (data) {
        if (data.eventType == 'activate_workflow' || data.eventType == 'deactivate_workflow' || data.eventType == 'enable_workflow' || data.eventType == 'disable_workflow' || data.eventType == 'add_workflow' || data.eventType == 'edit_workflow' || data.eventType == 'add_criteria' || data.eventType == 'delete_criteria') {
            this.cursor = 'not-allowed';
        }
        else {
            this.cursor = 'pointer';
        }
        return this.cursor;
    };
    return WorkflowAuditListComponent;
}());
export { WorkflowAuditListComponent };
