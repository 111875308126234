import {
    ElementRef,
    Injectable,
    ComponentFactoryResolver,
    Type,
    Injector,
    EmbeddedViewRef,
    ApplicationRef,
    SimpleChange } from '@angular/core';
import { mxgraph, mxgraphFactory } from "mxgraph-factory";
import { TriggerDatasetService } from "./trigger-dataset.service";
import { WorkflowCommonService } from "@app/workflow-common/services/workflow.common.service";
import { SidepanelModalService} from "@app/sidepanel/services/sidepanel-modal.service";
import { BsModalRef } from "ngx-bootstrap";
import { TriggerRulesPanelComponent } from "../trigger-rules-panel/trigger-rules-panel.component";
import { AddBlockPanelComponent } from "../add-block-panel/add-block-panel.component";
import { HistoryTimelinePanelComponent } from "../history-timeline-panel/history-timeline-panel.component";
import * as moment from 'moment';
import { Subject } from "rxjs/index";
import { WorkflowhttpService } from "@app/workflow-common/services/workflowhttp.service";
import {SequenceEmailWidgetComponent} from "@app/workflow-common/sequence-email-widget/sequence-email-widget.component";
import {SequenceSmsWidgetComponent} from "@app/workflow-common/sequence-sms-widget/sequence-sms-widget.component";

declare var _;
declare var $;

const {
    mxGraph,
    mxCodec,
    mxImage,
    mxUtils,
    mxCellOverlay,
    mxConstants,
    mxPoint,
    mxCell,
    mxEvent,
    mxKeyHandler,
    mxHierarchicalLayout,
    mxMorphing,
    mxEdgeStyle,
    mxCompactTreeLayout,
    mxLayoutManager,
    mxOutline,
    mxDivResizer,
    mxCellTracker,
    mxCellHighlight,
    mxRubberband,
} = mxgraphFactory({
    mxLoadResources: false,
    mxLoadStylesheets: true,
});

const HIGHLIGHT_COMPLETED = '#0FA90C';
const HIGHLIGTT_SKIPPED_OR_FAILED = '#A90C0C';
const HIGHLIGTT_SKIPPED = '#FFA500';
const HIGHLIGHT_RUNNING = '#00ff00';
const HIGHLIGHT_BLOCK_ADD = '#477596';
const HIGHLIGHT_BLOCK_EDIT = '#F5FD0DD7';
const HIGHLIGHT_BLOCK_DELETE = '#BE6D6D';

// const HIGHLIGHT_RESCHEDULE = '#30D5C8';

const addBtnUrl = '/images/mx-graph/add.svg';
const editBtnUrl = '/images/mx-graph/edit.svg';

@Injectable({
    providedIn: 'root'
})
export class MxGraphService {
    public onAfterRender: Subject<any>;
    mxGraph: mxgraph.mxGraph;
    element: ElementRef;
    outlineContainer: ElementRef;
    diagramComponent:any;
    options:any={};
    xmlSource:string=null;
    modalRef: BsModalRef;
    modalRefHistory: BsModalRef;
    mxVertexHandler: mxgraph.mxVertexHandler;
    selectedMainObjectFields:any = null;
    mxOutline:mxgraph.mxOutline = null;
    unsaveConfirm:boolean=false;

    blockWidth:number = 240;
    blockHeight:number = 100;
    emailBlockWidth = 450;
    // emailBlockHeight = 140;
    officeTaskBlockWidth = 450;
    /*officeTaskBlocHeight = 140;
    notificationBlockWidth = 450;
    notificationBlockHeight = 140;

    delayBlockWidth = 260;
    delayBlockHeight = 140;*/

    conditionBlockSize = 280;
    viewMode: boolean = false;
    isMarketPlaceView: boolean = false;
    isScheduler: boolean;

    //view diagram
    fieldsData:any = null;
    debugMode:boolean = false;
    tagName:any[] = [];
    cellTracker :mxgraph.mxCellTracker = null;

    cellHighlighter:any={};
    mxLayout:mxgraph.mxCompactTreeLayout;
    doRegenerateDag:boolean = false;
    firstTimeRender: boolean = true;

    blocksSize:any = {
        initializer: {
            width: 280,
            height: 130
        },
        addjobBlock: {
            width: 280,
            height: 130
        },
        emailBlock: {
            width: 450,
            height: 160
        },
        officeTaskBlock: {
            width: 450,
            height: 150
        },
        delayBlock: {
            width: 260,
            height: 130
        },
        chatmessageBlock: {
            width: 450,
            height: 140
        },
        smsBlock: {
            width: 420,
            height: 160
        },
        untilConditionBlock: {
            width:240,
            height:240
        },
        notificationBlock: {
            width: 410,
            height: 160
        },
        setcustomfieldvalueBlock: {
            width: 260,
            height: 140
        },
        scheduledActivityBlock: {
            width: 410,
            height: 160
        },
        dummyBlock: {
            width: 200,
            height: 80,
        },
        conditionalDelayBlock: {
            width: 260,
            height: 130
        },
    };
    mainObjectName: any = null;
    blocksList: any[] = [];
    workflowType: string = 'Custom';
    workflowStatus: any = null;
    deactivateMessage:string = 'The workflow must be deactivated before adding or deleting items. Do you want to deactivate?';
    blocksMetaList: any[] = [];
    constructor(
        public modalService: SidepanelModalService,
        public triggerService: TriggerDatasetService,
        public commonService: WorkflowCommonService,
        public componentFactoryResolver: ComponentFactoryResolver,
        public injector: Injector,
        public appRef: ApplicationRef,
        public workflowService: WorkflowhttpService,
    ) {
        this.onAfterRender = new Subject<any>();
        this.onAfterRender.subscribe( (cell:mxgraph.mxCell) => {
            if(cell && cell.isVertex()) {
                if (cell['block_name'] === 'elseBlock') {
                    this.addDummyBlock(cell);
                }
                else {
                    const blockMeta = this.blocksMetaList.find( block => cell['block_name'] == block['id']);
                    let containerInfo:any = this.commonService.blockHeightCalculation(cell, {
                        blockSize: this.blocksSize,
                        title: blockMeta['title'],
                        mxGraphService: this
                    });
                    if(!containerInfo) {
                        return false;
                    }
                    let current = cell.getGeometry();
                    current.width = containerInfo.width;
                    current.height = containerInfo.height;
                    cell.setGeometry(current);
                    this.mxGraph.updateCellSize(cell, true);
                }
            }
        });
    }

    setDiagramComponent(comp:any) {
        this.diagramComponent=comp;
    }

    addComponent(componentClass: Type<any>, componentProps?: object) {
        const componentRef = this.componentFactoryResolver
            .resolveComponentFactory(componentClass)
            .create(this.injector);
        if (componentProps && typeof componentRef.instance === 'object') {
            Object.assign(componentRef.instance as object, componentProps);
        }
        this.appRef.attachView(componentRef.hostView);
        const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
            .rootNodes[0] as HTMLElement;
        document.body.appendChild(domElem);
        const changes = {
            showActivityModal: new SimpleChange(false, this.showScheduleActivityModel, false),
        }
        componentRef.instance.closeModal = () => {
            const closeCB = (cb:Function) => {
                $('app-schedule-activity-modal .activityModal__').modal('hide');
                $("app-schedule-activity-modal .modal-dialog").removeClass("modal-dialog-open");
                setTimeout(cb,300);
            };
            closeCB(() => {
                this.appRef.detachView(componentRef.hostView);
                componentRef.destroy();
            });
        }
        componentRef.instance.ngOnChanges(changes);
    }

    showScheduleActivityModel:boolean = false;
    openScheduledActivity(show:boolean = false) {
        this.showScheduleActivityModel = show;
        /*setTimeout(()=>{
            this.addComponent(ScheduleActivityModalComponent, {
                showActivityModal: this.showScheduleActivityModel,
                listActivity: this.options['scheduleActivityTypes'],
                backdrop: true
            })
        },300);*/
    }

    /*prepareEmailTaskTemplate(cell:mxgraph.mxCell) {
        const value = cell.getValue();
        const {model} = value;
        let toTagName = [];
        _.forEach(model.toEmailTag, (item: any) => {
            toTagName.push(item.displayName);
        });
        let ccTagName = [];
        _.forEach(model.ccEmailTag, (item: any) => {
            ccTagName.push(item.displayName);
        });
        let bccTagName = [];
        _.forEach(model.bccEmailTag, (item: any) => {
            bccTagName.push(item.displayName);
        });
        let toTagAddress = toTagName.join(', ');
        let ccTagAddress = ccTagName.join(', ');
        let bccTagAddress = bccTagName.join(', ');
        let mainContainer = this.options.viewMode == true ? $('<div></div>').addClass('view-task-block email-task-block').css({width: this.emailBlockWidth, textAlign:'center', padding:'20px', fontSize:'14px'}) : $('<div></div>').addClass('task-block email-task-block').css({width: this.emailBlockWidth, textAlign:'center', padding:'20px', fontSize:'14px'});
        let titleDiv = $('<div></div>').css({fontSize:'14px', paddingBottom: 10}).text('What do you want to happen?');
        let taskDiv = $('<div></div>').addClass('task-title');
        let img = $('<img/>').addClass('mr-5p').attr('src', 'images/mx-graph/email.svg');
        let span = $('<span></span>').css({fontWeight:'bold'}).text('Send an email');
        let separator = $('<div/>').css({marginBottom: 10, height:10, borderBottom:'1px solid #DADADA'}).addClass('d-block');
        let bodyLine = $('<div></div>').addClass('text-truncate d-block').css('padding-bottom','5px');
        let bodyLineBcc = $('<div></div>').addClass('text-truncate d-block').css('padding-bottom','5px');
        let bodyLineCc = $('<div></div>').addClass('text-truncate d-block').css('padding-bottom','5px');
        let emailToTitle = $('<span></span>').text('To: ');
        let emailToContent = $('<span></span>').css({fontWeight:'bold', paddingLeft:5}).text(toTagAddress);
        let emailCcTitle = $('<span></span>').text('CC: ');
        let emailCcContent = $('<span></span>').css({fontWeight:'bold', paddingLeft:5}).text(ccTagAddress);
        let emailBccTitle = $('<span></span>').css({paddingLeft:10}).text('BCC: ');
        let emailBccContent = $('<span></span>').css({fontWeight:'bold', paddingLeft:5}).text(bccTagAddress);
        let bodyLine2 = $('<div></div>').addClass('text-truncate d-block');
        let subTitle = $('<span></span>').text('Subject: ');
        let subjectMessage = model['subject'];
        if(model['customMessage'] && typeof model['customMessage'] == 'object') {
            subjectMessage = model['customMessage']['displaySubjectMessage'];
        }
        let subContent = $('<span></span>').css({fontWeight:'bold', paddingLeft:5}).text(subjectMessage);
        if(this.workflowType == 'Sequence' && this.options['mode'] === 'sequence_config') {
            const initValue = this.getInitBlockValue();
            const enabled = initValue['model'] && initValue['model']['displayContactInfo']['emailAddress'];
            if(enabled && model['current_task_status'] && model['current_task_status'] == 'skip') {
                mainContainer.css('background', '#F2F2F2');
                mainContainer.css('border', '1px solid #DADADA');
            }
            if(enabled) {
                mainContainer.append(this.skipOrUndoButton(cell));
                emailToContent.text(initValue['model']['displayContactInfo']['emailAddress']);
            }
            else {
                mainContainer.css('background', '#ECC7C7');
                emailToContent.text('--not set--');
            }
        }
        bodyLine.append(emailToTitle);
        bodyLine.append(emailToContent);
        if(ccTagName.length>0) {
            bodyLineBcc.append(emailCcTitle);
            bodyLineBcc.append(emailCcContent);
        }
        if(bccTagName.length>0) {
            bodyLineBcc.append(emailBccTitle);
            bodyLineBcc.append(emailBccContent);
        }
        bodyLine2.append(subTitle);
        bodyLine2.append(subContent);
        taskDiv.append(img);
        taskDiv.append(span);
        mainContainer.append(titleDiv);
        mainContainer.append(taskDiv);
        mainContainer.append(separator);
        mainContainer.append(bodyLine);
        if(ccTagName.length>0 || bccTagName.length>0){
              mainContainer.append(bodyLineBcc);
        }
        mainContainer.append(bodyLine2);
        return mainContainer;
    }*/

    /*prepareSmsTaskTemplate(cell:mxgraph.mxCell){
        const value = cell.getValue();
        const {model} = value;
        let tagName = [];
        const { smsBlock } = this.blocksSize;
        _.forEach(model['toSmsTag'], (item: any) => {
            tagName.push(item.displayName);
        });

        let tagAddress = tagName.join(', ');
        let mainContainer = this.options.viewMode == true ? $('<div></div>').addClass('view-task-block email-task-block')
            .css({width: smsBlock['width'], textAlign:'center', padding:'20px', fontSize:'14px'}).attr('id', 'block-id-'+cell.getId()) :
            $('<div></div>').addClass('task-block email-task-block').css({width: smsBlock['width'], textAlign:'center', padding:'20px', fontSize:'14px'}).attr('id', 'block-id-'+cell.getId());
        let titleDiv = $('<div></div>').css({fontSize:'14px', paddingBottom: 10}).text('What do you want to happen?');
        let taskDiv = $('<div></div>').addClass('task-title');
        let img = '<i class="mr-10 far fa-sms fa-lg" aria-hidden="true"></i>';
        let span = $('<span></span>').css({fontWeight:'bold'}).text('Send SMS');
        let separator = $('<div/>').css({marginBottom: 10, height:10, borderBottom:'1px solid #DADADA'}).addClass('d-block');
        let bodyLine = $('<div></div>').addClass('text-truncate d-block').css('padding-bottom','5px');
        let emailToTitle = $('<span></span>').text('To: ');
        let emailToContent = $('<span></span>').css({fontWeight:'bold', paddingLeft:5}).text(tagAddress);
        let bodyLine2 = $('<div></div>').addClass('text-truncate d-block');
        let subTitle = $('<span></span>').text('Title: ');
        let subContent = $('<span></span>').css({fontWeight:'bold', paddingLeft:5}).text(model['title']);
        if(this.workflowType == 'Sequence' && this.options['mode'] === 'sequence_config') {
            const initValue = this.getInitBlockValue();
            const enabled = initValue['model'] && initValue['model']['displayContactInfo']['mobile'];
            if(enabled && model['current_task_status'] && model['current_task_status'] == 'skip') {
                mainContainer.css('background', '#F2F2F2');
                mainContainer.css('border', '1px solid #DADADA');
            }
            if(enabled) {
                mainContainer.append(this.skipOrUndoButton(cell));
                emailToContent.text(initValue['model']['displayContactInfo']['mobile']);
            }
            else {
                mainContainer.css('background', '#ECC7C7');
                emailToContent.text('--not set--');
            }
            if(model['customMessage'] && typeof model['customMessage'] == 'object') {
                subTitle.text('Message: ');
                subContent.text(model['customMessage']['displayContent']);
            }
        }

        bodyLine.append(emailToTitle);
        bodyLine.append(emailToContent);
        bodyLine2.append(subTitle);
        bodyLine2.append(subContent);
        taskDiv.append(img);
        taskDiv.append(span);
        mainContainer.append(titleDiv);
        mainContainer.append(taskDiv);
        mainContainer.append(separator);
        mainContainer.append(bodyLine);
        mainContainer.append(bodyLine2);
        return mainContainer;
    }*/

    prepareConditionalEdageTemplate(cell:mxgraph.mxCell) {
        const value = cell.getValue();
        const operatorsDisplayText = this.triggerService.getOperatorsDisplayText();
        let mainContainer = this.options.viewMode == true ? $('<div></div>').addClass('view-task-block condition-edge-task-block').css({
            textAlign:'center', padding:'3px 8px', backgroundColor: '#fff', border: '1px dashed #ccc', fontSize:'14px'}) :
            $('<div></div>').addClass('task-block condition-edge-task-block').css({textAlign:'center', padding:'3px 8px', backgroundColor: '#fff', border: '1px dashed #ccc', fontSize:'14px'});
        const operatorsDisplay = value['text'].charAt(value['text'].length - 1) == '?' ? this.triggerService.opertatorSymbols[value['operator']] : operatorsDisplayText[value['operator']];
        const displayText = this.truncateMiddle(this.selectedMainObjectFields[value.col_name].text,30) + ' ' +  operatorsDisplay + ' ' +  this.getLabelByText(value['col_name'], value['value']);
        mainContainer.text(displayText);
        return mainContainer;
    };

    prepareAddJobTaskTemplate(cell:mxgraph.mxCell) {
        const value = cell.getValue();
        const { model } = value;
        const { addjobBlock } = this.blocksSize;
        let mainContainer = this.options.viewMode == true ? $('<div></div>').addClass('view-task-block email-task-block')
            .css({width: addjobBlock['width'], textAlign:'center', padding:'20px', fontSize:'14px'}) :
            $('<div></div>').addClass('task-block email-task-block').css({width: addjobBlock['width'], textAlign:'center', padding:'20px', fontSize:'14px'});
        let titleDiv = $('<div></div>').css({fontSize:'14px', paddingBottom: 10}).text('What do you want to happen?');
        let taskDiv = $('<div></div>').addClass('task-title');
        let img = '<i class="mr-10 far fa-truck fa-lg" aria-hidden="true"></i>';
        let span = $('<span></span>').css({fontWeight:'bold'}).text('Create a job');
        let separator = $('<div/>').css({marginBottom: 10, height:10, borderBottom:'1px solid #DADADA'}).addClass('d-block');
        let bodyLine = $('<div></div>').addClass('text-truncate d-block').css('padding-bottom','5px');
        let contentTitle = $('<span></span>').text('Description: ');
        let Content = $('<span></span>').css({fontWeight:'bold', paddingLeft:5}).html(model['jobDescriptionDisplay']);
        bodyLine.append(contentTitle);
        bodyLine.append(Content);
        taskDiv.append(img);
        taskDiv.append(span);
        mainContainer.append(titleDiv);
        mainContainer.append(taskDiv);
        mainContainer.append(separator);
        mainContainer.append(bodyLine);
        return mainContainer;
    }

    prepareUntilConditionTemplate(cell:mxgraph.mxCell) {
        let value = cell.getValue();
        let isConditionalBlock = cell['block_name'] == 'conditionalBlock';
        let {model} = value;
        let $_column:any = this.selectedMainObjectFields[model['col_name']];
        const {untilConditionBlock} = this.blocksSize;
        let intervalUnitFormat = value['model']['interval'] == 1 ? value['model']['unit'].slice(0, -1) : value['model']['unit'];
        let untilPeriod = '';
        if(model['untilType'] == '1') {
            untilPeriod = moment(model['untilPeriod']).format('MMM Do YYYY');
        }
        else {
            let untilManualPeriod = model['untilManualPeriod'] == 1 ? value['model']['untilUnit'].slice(0, -1) : value['model']['untilUnit'];
            untilPeriod = '+' + model['untilManualPeriod'] + ' ' + untilManualPeriod;
        }
        let mainContainer = this.options.viewMode == true ? $('<div></div>').addClass('view-task-block until-condition-block white-space-normal')
            .css({width: untilConditionBlock['width'], textAlign:'center', padding:'0 10px 15px 10px', fontSize:'14px'}) :
            $('<div></div>').addClass('task-block until-condition-block white-space-normal').css({width: untilConditionBlock['width'], textAlign:'center', padding:'0 10px 15px 10px', fontSize:'14px'});

        let taskDiv = $('<div></div>').addClass('task-title flex-col');
        let img = isConditionalBlock ? '<i class="mr-10 far fa-code-branch fa-rotate-180 fa-lg" aria-hidden="true"></i>' : '<i class="mr-10 far fa-solid fa-repeat-1 fa-lg" aria-hidden="true"></i>';
        let span = $('<span></span>').css({fontWeight:'normal'}).text(isConditionalBlock ? 'If statement' : 'If... until');
        let separator = $('<div/>').css({marginBottom: 10, marginLeft:30, marginRight:30,  height:10, borderBottom:'1px solid #DADADA'}).addClass('d-block');
        let bodyLine = $('<div></div>').addClass('d-block').css('padding-bottom','5px').css('font-weight', 'bold');
        let bodyLine1, bodyLine2;
        if(!isConditionalBlock) {
            bodyLine1 = $('<div></div>').addClass('d-block').css('padding-bottom', '5px');
            let bodySpan1 = $('<span></span>').css({fontWeight: 'normal'}).text('Repeat every ');
            let bodySpan1A = $('<span></span>').css({fontWeight: 'bold'}).text(value['model']['interval'] + ' ' + intervalUnitFormat);
            bodyLine1.append(bodySpan1);
            bodyLine1.append(bodySpan1A);
            bodyLine2 = $('<div></div>').addClass('d-block').css('padding-bottom', '5px');
            let bodySpan2 = $('<span></span>').css({fontWeight: 'normal'}).text('until ');
            let bodySpan2A = $('<span></span>').css({fontWeight: 'bold'}).text(untilPeriod);
            let bodySpan2B = $('<div></div>').css({fontWeight: 'normal'}).html('after the execution <br/> date');
            bodyLine2.append(bodySpan2);
            bodyLine2.append(bodySpan2A);
            if(model['untilType'] == 2) {
                bodyLine2.append(bodySpan2B);
            }
        }
        taskDiv.append(img);
        taskDiv.append(span);
        bodyLine.text($_column['text']);
        mainContainer.append(taskDiv);
        mainContainer.append(separator);
        mainContainer.append(bodyLine);
        if(!isConditionalBlock) {
            mainContainer.append(bodyLine1);
            mainContainer.append(bodyLine2);
        }
        return mainContainer;
    }

    /*prepareChatmessageTemplate(cell:mxgraph.mxCell) {
        const value = cell.getValue();
        const {model} = value;
        let channelTo = model['channelName'];
        let mainContainer = $('<div></div>').addClass('task-block office-task-block').css({width: this.officeTaskBlockWidth, textAlign:'center', padding:'15px', fontSize:'14px'});
        let titleDiv = $('<div></div>').css({fontSize:'14px', paddingBottom: 10}).text('What do you want to happen?');
        let taskDiv = $('<div></div>').addClass('task-title');
        let img = $('<img/>').addClass('mr-10').attr('src', 'images/mx-graph/chatmessage.svg');
        let span = $('<span></span>').css({fontWeight:'bold'}).text('Create a chat message');
        let separator = $('<div/>').css({marginBottom: 10, height:10, borderBottom:'1px solid #DADADA'}).addClass('d-block');
        let bodyLine = $('<div></div>').addClass('text-truncate d-block').css('padding-bottom','5px');
        let assignTitle = $('<span></span>').text('Channel: ');
        let assignContent = $('<span></span>').css({fontWeight:'bold', paddingLeft:5}).text(channelTo);
        let bodyLine2 = $('<div></div>').addClass('text-truncate d-block');
        let tagTitle = $('<span></span>').text('Message: ');
        let tagContent = $('<span></span>').css({fontWeight:'bold', paddingLeft:5}).html(model['textMessage']);
        bodyLine.append(assignTitle);
        bodyLine.append(assignContent);
        bodyLine2.append(tagTitle);
        bodyLine2.append(tagContent);
        taskDiv.append(img);
        taskDiv.append(span);
        mainContainer.append(titleDiv);
        mainContainer.append(taskDiv);
        mainContainer.append(separator);
        mainContainer.append(bodyLine);
        mainContainer.append(bodyLine2);
        return mainContainer;
    }*/

    /*prepareNotificationTemplate(cell: mxgraph.mxCell) {
        const value = cell.getValue();
        let {model} = value;
        let displayRoles = model['rolesDisplay'].join(', ');
        const {notificationBlock} = this.blocksSize;
        let mainContainer = this.options.viewMode == true ? $('<div></div>').addClass('view-task-block office-task-block')
            .css({width: notificationBlock['width'], textAlign:'center', padding:'20px', fontSize:'14px'}) :
            $('<div></div>').addClass('task-block office-task-block').css({width: notificationBlock['width'], textAlign:'center', padding:'20px', fontSize:'14px'});
        let titleDiv = $('<div></div>').css({fontSize:'14px', paddingBottom: 10}).text('What do you want to happen?');
        let taskDiv = $('<div></div>').addClass('task-title');
        let img = '<i class="mr-10 far fa-globe-americas fa-lg" aria-hidden="true"></i>';
        let span = $('<span></span>').css({fontWeight:'bold'}).text('Notification task');
        let separator = $('<div/>').css({marginBottom: 10, height:10, borderBottom:'1px solid #DADADA'}).addClass('d-block');
        taskDiv.append(img);
        taskDiv.append(span);
        if(model['current_task_status'] && model['current_task_status'] == 'skip') {
            mainContainer.css('background', '#F2F2F2');
            mainContainer.css('border', '1px solid #DADADA');
        }
        let bodyLine = $('<div></div>').addClass('text-truncate d-block').css('padding-bottom','5px');
        let assignTitle = $('<span></span>').text('Roles: ');
        let assignContent = $('<span></span>').css({fontWeight:'bold', paddingLeft:5}).text(displayRoles);

        let bodyLine2 = $('<div></div>').addClass('text-truncate d-block');
        let tagTitle = $('<span></span>').text('Message: ');
        let tagContent = $('<span></span>').css({fontWeight:'bold', paddingLeft:5}).html(model['textMessage']);

        if(this.workflowType == 'Sequence' && this.options['mode'] === 'sequence_config') {
            mainContainer.append(this.skipOrUndoButton(cell));
        }

        bodyLine.append(assignTitle);
        bodyLine.append(assignContent);
        bodyLine2.append(tagTitle);
        bodyLine2.append(tagContent);
        mainContainer.append(titleDiv);
        mainContainer.append(taskDiv);
        mainContainer.append(separator);
        mainContainer.append(bodyLine);
        mainContainer.append(bodyLine2);
        return mainContainer;
    }*/
    /*prepareOfficeTaskTemplate(cell:mxgraph.mxCell) {
        const value = cell.getValue();
        const {model} = value;
        let assignedTo = model['assignToDisplay'];
        let tagsDisplay = '--';
        let mainContainer = this.options.viewMode == true ? $('<div></div>').addClass('view-task-block office-task-block')
            .css({width: this.officeTaskBlockWidth, textAlign:'center', padding:'20px', fontSize:'14px'}) : $('<div></div>')
            .addClass('task-block office-task-block')
            .css({width: this.officeTaskBlockWidth, textAlign:'center', padding:'20px', fontSize:'14px'});
        let titleDiv = $('<div></div>').css({fontSize:'14px', paddingBottom: 10}).text('What do you want to happen?');
        let taskDiv = $('<div></div>').addClass('task-title');
        let img = '<i class="mr-10 far fa-list fa-lg" aria-hidden="true"></i>';
        let span = $('<span></span>').css({fontWeight:'bold'}).text('Create a office task');
        let separator = $('<div/>').css({marginBottom: 10, height:10, borderBottom:'1px solid #DADADA'}).addClass('d-block');
        let bodyLine = $('<div></div>').addClass('text-truncate d-block').css('padding-bottom','5px');
        let assignTitle = $('<span></span>').text('Assigned to: ');
        let assignContent = $('<span></span>').css({fontWeight:'bold', paddingLeft:5}).text(assignedTo);
        let bodyLine2 = $('<div></div>').addClass('text-truncate d-block');
        let tagTitle = $('<span></span>').text('Message: ');
        let tagContent = $('<span></span>').css({fontWeight:'bold', paddingLeft:5}).html(model['textMessage']);
        bodyLine.append(assignTitle);
        bodyLine.append(assignContent);
        bodyLine2.append(tagTitle);
        bodyLine2.append(tagContent);
        taskDiv.append(img);
        taskDiv.append(span);
        mainContainer.append(titleDiv);
        mainContainer.append(taskDiv);
        mainContainer.append(separator);
        if(assignedTo) {
            mainContainer.append(bodyLine);
        }
        mainContainer.append(bodyLine2);
        return mainContainer;
    };*/

    prepareInitBlockContainer(ModalData:any, callback:any = null) {
        const selectedMainObjectFields = this.selectedMainObjectFields;
        const operatorsDisplayText = this.triggerService.getOperatorsDisplayText();
        const {rules} = ModalData;
        const padding = 20;
        const containerBottom = 5;
        const maxWidth = 400;
        const fixedWidth = 270;
        let data:any = {}, titleText = '';
        if(this.workflowType == 'Custom') {
            titleText = 'How do you want to trigger this ' + ModalData.mainObject + ' workflow?';
        }
        else {
            titleText = 'This workflow would be executed as a sequence';
        }
        let titleDiv = $('<div></div>').css({fontSize:'14px', paddingBottom: containerBottom+5}).text(titleText);

        let mainContainer = $('<div></div>').css({
            //width: (text.length * 8) > maxWidth ? maxWidth : fixedWidth,
            whiteSpace: 'normal',
            padding: padding,
            textAlign: 'center',
            fontSize: '14px'
        }).addClass('box-sizing').attr('id', 'cs-init-block-container')
            .append(titleDiv);
        if(this.isScheduler) {
            let divHead  = document.createElement('div');
            divHead.style.textAlign = 'center';
            divHead.className = 'init-block';
            divHead.style.fontWeight = 'bold';
            divHead.style.padding = '0 4px';
            mxUtils.write(divHead, 'Scheduled workflow');
            mainContainer.prepend(divHead);
        }

        let cnt=0;
        let textCollection:any = [];
        _.forEach(rules, (rule:any) => {
            cnt++;
            let $fieldContainer = $('<div></div>').css('padding-bottom', cnt == rules.length ? 0 : containerBottom).css('font-weight', 'bold');
            let $andDiv = $('<div></div>').css('padding-bottom', containerBottom).text('and');
            let $fieldDisplay = $('<span></span>').css('padding-right', '5px');
            let $operatorDisplay = $('<span></span>').css('padding-right', '5px').css('font-weight', 'normal');
            let $valueDisplay = $('<span></span>');
            let valueDisplayText = rule['value'];
            const fieldData = selectedMainObjectFields[rule['col_name']];
            if(_.has(fieldData, 'options')) {
                let tmp = [];
                if(_.isArray(rule['value'])) {
                    _.forEach(rule['value'], (id) => {
                        const activeOption = _.find(fieldData.options, (item) => {
                            return item.id == id;
                        });
                        if(activeOption) {
                            tmp.push(activeOption['text']);
                        }
                    });
                    valueDisplayText=tmp.join(', ');
                }
                else {
                    const activeOption = _.find(fieldData.options, (item) => {
                        return item.id == rule['value'];
                    });
                    if(activeOption) {
                        valueDisplayText = activeOption['text'];
                    }
                }
            }
            const tmpDisplayText = rule['text'] + ' ' + rule['operator'] + ' ' + valueDisplayText;
            textCollection.push(tmpDisplayText.length);
            $fieldDisplay.text(rule['text']);
            $operatorDisplay.text(operatorsDisplayText[rule['operator']]);
            $valueDisplay.text(valueDisplayText);
            $fieldContainer.append($fieldDisplay).append($operatorDisplay).append($valueDisplay);
            mainContainer.append($fieldContainer);
            if(cnt !== rules.length) {
                mainContainer.append($andDiv);
            }
        });
        const maxDisplayText = _.max(textCollection);
        textCollection=[];
        mainContainer.css('width', (maxDisplayText * 8) > maxWidth ? maxWidth : fixedWidth);

        // ModalData['displayData'] = data;
        if(typeof callback === 'function') {
            mainContainer.appendTo('body');
            data = {
                width: mainContainer.outerWidth(),
                height: mainContainer.outerHeight()-10,
                container: mainContainer
            };
            // mainContainer.css('height', mainContainer.outerHeight());
            mainContainer.remove();
            callback(ModalData, data);
        }
        else {
            data['container'] = mainContainer;
        }
        return data;
    }

    renderInitBlockContainer(cell:mxgraph.mxCell, result:any) {
        const objValue:any = cell.getValue();
        const labelText = this.isScheduler ? objValue['cronText'] : objValue.mainObject + ' trigger';
        let titleText;
        if(this.workflowType == 'Custom') {
            titleText = 'How do you want to trigger this '+labelText+' workflow?';
        }
        else {
            titleText = 'This workflow would be executed as a sequence';
        }
        let updatedData:any = result['formData'];
        updatedData['block_name'] = 'initializer';

        let container = this.commonService.blockHeightCalculation(updatedData, {
            blockSize: this.blocksSize,
            selectedMainObjectFields: this.selectedMainObjectFields,
            getOperatorsDisplayText: this.triggerService.getOperatorsDisplayText(),
            mxGraphService: this,
            isScheduler: this.isScheduler,
            title: titleText,
            id: cell.getId()
        });

        if(container) {
            this.mxGraph.labelChanged(cell, result['formData'], event);
            let current = cell.getGeometry();
            current.width = container['width'];
            current.height = container['height'];
            cell.setGeometry(current);
            this.mxGraph.updateCellSize(cell, true);
        }
        /*this.prepareInitBlockContainer(result['formData'], (formData, displayInfo) => {
            this.mxGraph.labelChanged(cell, formData, event);
            let current = cell.getGeometry();
            current.width = displayInfo.width;
            current.height = displayInfo.height;
            cell.setGeometry(current);
            this.mxGraph.updateCellSize(cell, true);
        })*/
    }

    initialize(container: ElementRef, options:object={}) {
        this.element = container;
        this.options = options;
        this.unsaveConfirm = false;

        if(this.options.hasOwnProperty('workflowStatus')) {
            this.workflowStatus = this.options.workflowStatus;
        }

        if(!this.options.hasOwnProperty('workflowType')) {
            throw new Error("Workflow type not defined.");
        }
        this.workflowType = this.options.workflowType;
        this.blocksList = this.commonService.getBlocksList(this.workflowType);
        let blockMeta = [];
        this.blocksList.forEach( (section: any) => {
            blockMeta = blockMeta.concat(section.blocks);
        });
        this.blocksMetaList = blockMeta;
        // console.log('blockMeta', blockMeta);

        if(_.has(options, 'isMarketPlaceView')) {
            this.isMarketPlaceView = options['isMarketPlaceView'];
        }
        this.isScheduler = options['isScheduler'];
        if(this.options.hasOwnProperty('xmlSource')) {
            this.xmlSource = this.options.xmlSource;
        }
        if(this.options.hasOwnProperty('outlineContainer')) {
            this.outlineContainer = this.options.outlineContainer;
            //console.log('this.outlineContainer', this.outlineContainer)
        }
        if(this.options.hasOwnProperty('mainObjectsFields')) {
            this.fieldsData = this.options.mainObjectsFields;
        }

        mxEvent.disableContextMenu(this.element.nativeElement);

        this.mxGraph = new mxGraph(this.element.nativeElement);
        this.mxGraph.setCellsSelectable(false);
        this.mxGraph.setCellsMovable(!this.options.viewMode);
        this.mxGraph.setEdgeLabelsMovable(!this.options.viewMode);
        this.mxGraph.setVertexLabelsMovable(false);
        this.mxGraph.setEnabled(false);
        this.mxGraph.setCellsLocked(this.options.viewMode);
        //this.mxGraph.setAutoSizeCells(true);
        this.mxGraph.setPanning(true);
        this.mxGraph.centerZoom = false;
        this.mxGraph.panningHandler.useLeftButtonForPanning = true;
        this.mxGraph.setTooltips(!this.options.viewMode);
        this.mxGraph.setConnectable(true);
        this.mxGraph.connectionHandler.setCreateTarget(!this.options.viewMode);
        //this.mxGraph.setAllowDanglingEdges(false);
        //this.mxGraph.connectionHandler.select = false;
        //this.mxGraph.view.setTranslate(20, 20);

        let _mxOutline, _mxDivResizer;

        if(this.outlineContainer) {
            this.outlineContainer.nativeElement.innerHTML='';
            this.mxOutline = new mxOutline(this.mxGraph, this.outlineContainer.nativeElement);
            this.mxOutline['sizer'].stroke = '#746ca6';
            this.mxOutline['sizer'].fill = '#746ca6';
            this.mxOutline['selectionBorder'].stroke = '#746ca6';
            this.mxOutline.setEnabled(false);
            setTimeout( () => {
                this.mxOutline.setEnabled(true);
                this.mxOutline.update(true);
                this.mxOutline.refresh();
                const resizer = new mxDivResizer(this.outlineContainer.nativeElement, this.element.nativeElement);
                resizer.resize();
            },100)
        }

        new mxKeyHandler(this.mxGraph);
        this.overrides();
        this.setStyle();
        this.setEdgeStyle();
        this.setVertexElse();
        this.setEdgeElseStyle();
        this.treeLayout();
        // let event = new mxEvent();
        let layout:mxgraph.mxCompactTreeLayout = new mxCompactTreeLayout(this.mxGraph, false);
        //layout.useBoundingBox = false;
        layout.edgeRouting = false;
        layout.levelDistance = 60;
        layout.nodeDistance = 32;

        this.mxGraph.addListener(mxEvent.REFRESH, () => {
            console.log('refreshed')
        })

        this.mxGraph.addMouseListener({
            mouseDown: function(sender, evt) {
                $('.mx-container').addClass('cur-grab-active');
            },
            mouseMove: function(sender, evt) {},
            mouseUp: function(sender, evt) {
                $('.mx-container').removeClass('cur-grab-active');
            }
        });

        // Allows the layout to move cells even though cells
        // aren't movable in the graph
        layout.isVertexMovable = function(cell)
        {
            return true;
        };

        let layoutMgr = new mxLayoutManager(this.mxGraph);
        layoutMgr.getLayout = function(cell)
        {
            if (cell.getChildCount() > 0)
            {
                return layout;
            }
        };
        //new mxRubberband(this.mxGraph);

        this.mxGraph.addListener(mxEvent.UPDATE_CELL_SIZE, (sender, evt) => {
            const graph = this.mxGraph;
            //const layout:mxgraph.mxHierarchicalLayout = new mxHierarchicalLayout(this.mxGraph);
            this.mxLayout.execute(graph.getDefaultParent());
            graph.view.refresh();
        });

        this.mxGraph.addListener(mxEvent.CELLS_REMOVED, (graph:mxgraph.mxGraph) => {
            //this.mxLayout.execute(graph.getDefaultParent());
            graph.view.refresh();
        });

        if(!this.options.viewMode) {
            //this.cellTracker = new mxCellTracker(this.mxGraph, '#6E66A2', null);

            this.mxGraph.addListener(mxEvent.CLICK, mxUtils.bind(this,(sender, evt) => {
                let cell: mxgraph.mxCell = evt.getProperty('cell');
                if(this.workflowType === 'Sequence'
                    && this.options['mode']
                    && this.options['mode'] === 'sequence_config'
                    && cell && (cell.isVertex() && cell.getId() == 'cs_initializer' || (evt && evt['properties']['event']['target'].tagName == 'BUTTON')) ) {
                    return false;
                }

                if(cell && cell.isVertex() && cell['block_name'] == 'dummyBlock') {
                    return false;
                }
                if (cell && cell.isVertex() && cell.getValue() !== '') {
                    if (cell.getId() === 'cs_initializer') {
                        const bsModal: BsModalRef = this.modalService.show(TriggerRulesPanelComponent, {
                            initialState: {
                                title: 'Update a trigger flow',
                                mxGraph: this.mxGraph,
                                selectedCell: cell,
                                isEdit: true,
                                triggerObjects: this.fieldsData,
                                isScheduler: this.isScheduler,
                                isMarketPlaceView: this.isMarketPlaceView,
                                diagramOptions: this.options
                            },
                            'size': 'sm',
                            'class': 'workflow-sidepanel'
                        });
                        bsModal.content.onClose.subscribe(result => {
                            //console.log('updare result', result);
                            this.workflowService.triggerEvent({'actionName':'beforeSave','data': cell,'initializer':true});
                            this.selectedMainObjectFields = result['fields'];
                            this.mainObjectName = result['formData']['mainObject'];
                            try {
                                this.renderInitBlockContainer(cell, result);
                                this.mxGraph.labelChanged(cell, result['formData']);
                            }
                            finally {
                                this.mxGraph.getModel().endUpdate();
                                this.workflowService.triggerEvent('autoSave');
                            }
                        });
                    }
                    else if(cell['block_name'] == 'emailBlock' && this.workflowType == 'Sequence' && this.options['mode'] == 'sequence_config') {
                        const bsModalEmailBlock = this.modalService.show(SequenceEmailWidgetComponent, {
                            initialState: {
                                cell: cell,
                                mxGraph: this.mxGraph,
                                sender: sender,
                                diagramOptions: this.options,
                                workflowType: this.workflowType,
                            },
                            size: 'xl',
                            'class': 'ng-addblock-sidepanel'
                        });
                        bsModalEmailBlock.content.onClose.subscribe(formData => {
                            let cellValue = cell.getValue();
                            cellValue['model']['customMessage'] = formData;
                            if(cellValue['model']['message']) {
                                cellValue['model']['message'] = '';
                            }
                            this.mxGraph.labelChanged(cell, cellValue);
                        })
                    }
                    else if(cell['block_name'] == 'smsBlock' && this.workflowType == 'Sequence' && this.options['mode'] == 'sequence_config') {
                        const bsModalSmsBlock = this.modalService.show(SequenceSmsWidgetComponent, {
                            initialState: {
                                cell: cell,
                                mxGraph: this.mxGraph,
                                sender: sender,
                                diagramOptions: this.options,
                                workflowType: this.workflowType,
                            },
                            size: 'standard',
                            'class': 'ng-addblock-sidepanel'
                        });
                        bsModalSmsBlock.content.onClose.subscribe(formData => {
                            let cellValue = cell.getValue();
                            cellValue['model']['customMessage'] = formData;
                            this.mxGraph.labelChanged(cell, cellValue);
                        })
                    }
                    else {
                        const bsModalBlock = this.modalService.show(AddBlockPanelComponent, {
                            initialState: {
                                cell: cell,
                                mxGraph: this.mxGraph,
                                sender: sender,
                                event: event,
                                selectedTriggerFields: this.selectedMainObjectFields,
                                options: this.options,
                                title: 'sc',
                                isEdit: true,
                                isScheduler: this.isScheduler,
                                isMarketPlaceView: this.isMarketPlaceView,
                                mainObjectName: this.mainObjectName,
                                mxGraphService: this,
                                blocksList: this.blocksList,
                                workflowType: this.workflowType,
                            },
                            size: 'sm',
                            'class': 'ng-addblock-sidepanel'
                        });
                        bsModalBlock.content.onClose.subscribe(result => {
                            this.workflowService.triggerEvent({'actionName':'beforeSave','data': cell,'initializer':false});
                            //console.log('update block', result, cell.getEdgeAt(0));
                            try {
                                this.unsaveConfirm=true;
                                this.mxGraph.getModel().beginUpdate();
                                if(result === 'deleteBlock') {
                                    // console.log('remove block');
                                    let _cells = [];
                                    let _edges = [];
                                    let topParentCell = this.mxGraph.getModel().getCell(cell['parentId']);
                                    this.mxGraph.traverse(cell, true , (vertex, edges) => {
                                        _cells.push(vertex);
                                        _edges.push(edges);
                                        return true;
                                    });
                                    this.mxGraph.removeCells(_cells, true);
                                    this.doRegenerateDag=false;
                                    if(topParentCell['block_name'] == 'elseBlock') {
                                        this.addDummyBlock(topParentCell);
                                    }
                                }
                                else {
                                let vertexUpdatedValue: any = {};
                                vertexUpdatedValue['name'] = result['name'];
                                vertexUpdatedValue['model'] = result['model'];
                                let ed: mxgraph.mxCell = cell.getEdgeAt(0);
                                if (_.has(result, 'condition') && result['condition']['col_name'] !== '' && (result['condition']['value'] || Object.is(result['condition']['value'], 0))) {
                                    vertexUpdatedValue['condition'] = result['condition'];
                                    ed.setValue(vertexUpdatedValue['condition']);
                                }
                                if (_.has(result, 'isConditional')) {
                                    vertexUpdatedValue['isConditional'] = result['isConditional'];
                                }
                                this.mxGraph.labelChanged(cell, vertexUpdatedValue, event);
                                //this.mxGraph.labelChanged(cell, formData, event);
                                if(this.blocksSize[result['name']]) {
                                    let scale = _.clone(this.blocksSize[result['name']]);
                                    if(result['name'] === 'untilConditionBlock' || result['name'] === 'conditionalBlock') {
                                        scale['width'] = this.conditionBlockSize;
                                        scale['height'] = this.conditionBlockSize;
                                    }
                                    let current = cell.getGeometry();
                                    this.onAfterRender.next(cell);
                                }
                                this.refresh();
                                }

                            }
                            finally {
                                this.mxGraph.getModel().endUpdate();
                                this.workflowService.triggerEvent('autoSave');

                            }
                        });
                    }
                }
            }))
        }
    }

    getLabelByText(fieldName:any, value:any, optionKeyName:string = 'id', optionTextName:string = 'text') {
        const fieldObject = this.selectedMainObjectFields[fieldName];
        if(_.has(fieldObject, 'options')) {
            const options = fieldObject['options'];
            if(typeof value == 'object') {
                const optionValue = options.filter( i => value.includes( String(i.id) ));
                return optionValue.map(t=>t.text);
            }
            const activeObj = _.find(options, (item) => {
                return item[optionKeyName] == value;
            })
            if(activeObj) {
                return activeObj[optionTextName];
            }
            if(typeof value == 'object') {
                return _.chain(fieldObject)
                    .filter( (item) => {
                        return value.indexOf(item.id) > -1;
                    })
                    .map(item => item.text)
                    .value()
                    .join(', ')
            }
            return value;
        }
        return value;
    };

    overrides() {
        this.mxGraph.getLabel = (cell:mxgraph.mxCell):any => {
            let self = this;
            //let c = mxGraph.prototype.getLabel.apply(this.mxGraph, arguments);
            let label = this.mxGraph.labelsVisible ? this.mxGraph.convertValueToString(cell) : '';
            const blockName:string = cell['block_name'];
            /*if(!blockName && cell.isVertex()) {
                return label;
            }*/
            //console.log('getLabel', label);
            const blockMeta = this.blocksMetaList.find( block => blockName == block['id']);
            if(blockName === 'initializer' && cell.getValue() == '') {
                let divP = document.createElement('div');
                divP.setAttribute('id', 'cs_initializer_01_container');
                let div  = document.createElement('div');
                div.style.textAlign = 'center';
                div.style.fontSize = '14px';
                divP.style.width =  this.blockWidth+'px';
                div.className = 'init-block';
                let labelText = this.isScheduler ? 'How do you want to schedule  this workflow?' : 'How do you want to trigger  this workflow?';
                if(this.workflowType == 'Sequence') {
                    labelText = 'This workflow would be executed as a sequence';
                    div.style.padding = '20px';
                    div.style.marginBottom = '0';
                    div.style.cursor = 'default';
                }
                mxUtils.write(div, labelText);
                divP.appendChild(div);
                if(this.workflowType == 'Custom') {
                    const btnText = this.isScheduler  ? 'Set up scheduler' : 'Set up trigger';
                    let btn  = document.createElement('button');
                    btn.className = 'btn btn-primary';
                    mxUtils.write(btn, btnText);
                    btn.addEventListener(mxEvent.CLICK, (e:any) => {
                        self.setupWorkflowTrigger(cell, e);
                    });
                    divP.appendChild(btn);
                }
                return divP;
            }
            else if(blockName === 'initializer' && typeof cell.getValue() == 'object' ) {
                // console.log('initializer2', cell.getValue(), this.options.additionalData)
                /*const objValue:any = cell.getValue();
                let labelContainer = document.getElementById('cs_initializer_01_container');
                if(labelContainer) {
                    labelContainer.remove();
                }
                let divP = document.createElement('div'), divId = 'cs_initializer_trigger';
                if(this.workflowType == 'Sequence') {
                    divId = 'cs_initializer_trigger_sequence'
                }else if(this.options.viewMode == true){
                    divId = 'dummy-task-block'
                }

                divP.setAttribute('id', divId);

                let div  = document.createElement('div');
                div.style.textAlign = 'center';
                div.className = 'init-block';
                const labelText = this.isScheduler ? objValue['cronText'] : objValue.mainObject + ' trigger';
                const a = this.prepareInitBlockContainer(objValue, cell)
                divP.appendChild(a.container.get(0));
                return divP;*/
                const objValue:any = cell.getValue();
                const labelText = this.isScheduler ? objValue['cronText'] : objValue.mainObject + ' trigger';
                let titleText;
                if(this.workflowType == 'Custom') {
                    titleText = 'How do you want to trigger this '+labelText+' workflow?';
                }
                else {
                    titleText = 'This workflow would be executed as a sequence';
                }
                let divP = document.createElement('div');
                const container = this.commonService.renderInitializer(cell, {
                    blockSize: this.blocksSize,
                    selectedMainObjectFields: this.selectedMainObjectFields,
                    getOperatorsDisplayText: this.triggerService.getOperatorsDisplayText(),
                    mxGraphService: this,
                    isScheduler: this.isScheduler,
                    title: titleText,
                    id: cell.getId()
                });
                divP.appendChild(container.get(0));
                return divP;
            }
            else if(blockName === 'officeTaskBlock' && typeof cell.getValue() == 'object') {
                let divP = document.createElement('div');
                const container = this.commonService.renderOfficeTaskBlock(cell, {
                    blockSize: this.blocksSize,
                    mxGraphService: this,
                    title: blockMeta['title'],
                    id: cell.getId()
                });
                divP.appendChild(container.get(0));
                return divP;
            }
            /*else if(blockName === 'chatmessageBlock' && typeof cell.getValue() == 'object') {
                let divP = document.createElement('div');
                const container = this.prepareChatmessageTemplate(cell);
                divP.appendChild(container.get(0));
                return divP;
            }*/
            else if(blockName === 'addjobBlock' && typeof cell.getValue() == 'object') {
                let divP = document.createElement('div');
                const container = this.prepareAddJobTaskTemplate(cell);
                divP.appendChild(container.get(0));
                return divP;
            }
            else if(blockName === 'conditionalBlock' && typeof cell.getValue() == 'object') {
                let divP = document.createElement('div');
                const container = this.prepareUntilConditionTemplate(cell);
                divP.appendChild(container.get(0));
                return divP;
            }
            else if(blockName === 'untilConditionBlock' && typeof cell.getValue() == 'object') {
                let divP = document.createElement('div');
                const container = this.prepareUntilConditionTemplate(cell);
                divP.appendChild(container.get(0));
                return divP;
            }
            else if(blockName === 'emailBlock' && typeof cell.getValue() == 'object') {
                let divP = document.createElement('div');
                const container = this.commonService.renderEmailBlock(cell, {
                    blockSize: this.blocksSize,
                    mxGraphService: this,
                    title: blockMeta['title'],
                    id: cell.getId()
                });
                divP.appendChild(container.get(0));
                return divP;
            }
            else if(blockName === 'condition_edge' && cell.isEdge()) {
                let divP = document.createElement('div');
                const container = this.prepareConditionalEdageTemplate(cell);
                divP.appendChild(container.get(0));
                return divP;
            }
            else if(blockName === 'else_edge' && cell.isEdge() ) {
                let divP = document.createElement('div');
                const container = this.commonService.renderElseBlockEdge(cell, {
                    'service': self,
                    id: cell.getId()
                });
                divP.appendChild(container.get(0));
                return divP;
            }
            else if(blockName === 'smsBlock' && typeof cell.getValue() == 'object') {
                let divP = document.createElement('div');
                const container = this.commonService.renderSmsBlock(cell, {
                    blockSize: this.blocksSize,
                    mxGraphService: this,
                    title: blockMeta['title'],
                    id: cell.getId()
                });
                divP.appendChild(container.get(0));
                return divP;
            }
            else if(blockName === 'notificationBlock' && typeof cell.getValue() == 'object') {
                let divP = document.createElement('div');
                const container = this.commonService.renderNotificationBlock(cell, {
                    blockSize: this.blocksSize,
                    mxGraphService: this,
                    title: blockMeta['title'],
                    id: cell.getId()
                });
                divP.appendChild(container.get(0));
                return divP;
            }
            else if(blockName === 'setcustomfieldvalueBlock' && typeof cell.getValue() == 'object') {
                let divP = document.createElement('div');
                const container = this.commonService.renderSetcustomfieldvalueBlock(cell, {
                    blockSize: this.blocksSize,
                    mxGraphService: this,
                    title: blockMeta['title'],
                    id: cell.getId()
                });
                divP.appendChild(container.get(0));
                return divP;
            }
            else if(blockName === 'scheduledActivityBlock' && typeof cell.getValue() == 'object') {
                let divP = document.createElement('div');
                const container = this.commonService.renderScheduledActivityBlock(cell, {
                    blockSize: this.blocksSize,
                    title: blockMeta['title'],
                    mxGraphService: this,
                    id: cell.getId()
                });
                divP.appendChild(container.get(0));
                return divP;
            }
            else if(blockName === 'dummyBlock' && typeof cell.getValue() == 'object') {
                let divP = document.createElement('div');
                const container = this.commonService.renderDummyBlock(cell, {
                    blockSize: this.blocksSize.dummyBlock,
                    title: 'End',
                    id: cell.getId()
                });
                divP.appendChild(container.get(0));
                return divP;
            }
            else if(blockName === 'conditionalDelayBlock' && typeof cell.getValue() == 'object') {
                let divP = document.createElement('div');
                const container = this.commonService.renderConditionalDelayBlock(cell, {
                    'service': self,
                    title: blockMeta['title'],
                    blockSize: this.blocksSize,
                    id: cell.getId()
                });
                divP.appendChild(container.get(0));
                return divP;
            }
            else if(blockName === 'delayBlock' && typeof cell.getValue() == 'object') {
                let divP = document.createElement('div');
                const value = cell.getValue();
                const container = this.commonService.renderDelayBlock(cell, {
                    'service': self,
                    title: value['model']['type'] === 'conditional' ? 'Field based delay' : 'Delay',
                    blockSize: this.blocksSize,
                    id: cell.getId()
                });
                divP.appendChild(container.get(0));
                return divP;
            }
            else if(blockName === 'elseBlock' && typeof cell.getValue() == 'object' ) {
                return '';
            }
            return label;
        };

        if(!this.options.viewMode) {
            this.mxGraph.getTooltipForCell = (cell:mxgraph.mxCell) => {
                if( cell.isEdge() ||
                    (this.workflowType =='Sequence' && this.options['mode'] && this.options['mode'] == 'sequence_config' && cell['block_name'] !== "emailBlock") ||
                    (cell['block_name'] === "initializer" && cell.getValue() === "") ||
                    cell['block_name'] === "dummyBlock" ) {
                    this.mxGraph.tooltipHandler.hide();
                    return;
                }
                let s = cell['block_name'] === "initializer" ? 'trigger' : cell['block_name'];
                return 'Click to edit ' + s.replace('Block', '') + ' block';
            };
        }

        let graphGetPreferredSizeForCell = this.mxGraph.getPreferredSizeForCell;
        this.mxGraph.getPreferredSizeForCell = (cell:mxgraph.mxCell) => {
            let result = graphGetPreferredSizeForCell.apply(this.mxGraph, [cell]);
            //let style = this.mxGraph.getCellStyle(cell);
            let current = cell.getGeometry();
            result['width'] = current.width;
            result['height'] = current.height;
            return result;
        };
    }

    getGraph() {
        return this.mxGraph;
    }

    truncateMiddle(fullStr, strLen, separator:string = '...') {
        if (fullStr.length <= strLen) return fullStr;
        separator = separator || '...';
        let sepLen = separator.length,
            charsToShow = strLen - sepLen,
            frontChars = Math.ceil(charsToShow/2),
            backChars = Math.floor(charsToShow/2);
        return fullStr.substring(0, frontChars) +
            separator +
            fullStr.substring(fullStr.length - backChars);
    }

    getContainerWidth() {
        return this.element.nativeElement.offsetWidth;
    }

    addOverlay(cell: mxgraph.mxCell, isElseBlock:boolean = false) {
        if(!cell.getGeometry()) {
            const geo = this.mxGraph.getCellGeometry(cell);
            return;
        }
        const offsetY = !isElseBlock ? 0 : 24/2;
        let overLay: mxgraph.mxCellOverlay = new mxCellOverlay(
            new mxImage(addBtnUrl, 24, 24),
            'Add',
            mxConstants.ALIGN_CENTER,
            mxConstants.ALIGN_CENTER,
            new mxPoint(0,offsetY),
            'pointer'
        );
        const geo = this.mxGraph.getCellGeometry(cell);

        overLay.cursor = 'hand';

        overLay.addListener(mxEvent.CLICK, (sender, event) => {
            if(this.options.hasOwnProperty('confirmationBoxHelper') && this.options.hasOwnProperty('ajsScope') && this.workflowStatus) {
                this.options.confirmationBoxHelper.getConfirmation(this.deactivateMessage, this.options.ajsScope)
                    .then( () => {
                        this.workflowService.triggerEvent('deactivate_action');
                    })
            }
            else {
                const initialState = {
                    cell: cell,
                    mxGraph: this.mxGraph,
                    sender:sender,
                    event: event,
                    selectedTriggerFields: this.selectedMainObjectFields,
                    options: this.options,
                    title: 'sc',
                    isMarketPlaceView: this.isMarketPlaceView,
                    mainObjectName: this.mainObjectName,
                    mxGraphService: this,
                    blocksList: this.blocksList,
                    workflowType: this.workflowType,
                };
                this.modalRef = this.modalService.show(AddBlockPanelComponent, {
                    initialState: initialState,
                    size: 'sm',
                    'class': 'ng-addblock-sidepanel'
                });
                //let $parent = this.mxGraph.getDefaultParent();
                this.modalRef.content.onClose.subscribe( result => {
                    this.unsaveConfirm=true;
                    const blockSizes = this.blocksSize;
                    let bn = result['name'];
                    this.mxGraph.clearSelection();
                    let vertexOptions={}, vertexModel=result['model'], delayBlock:any={}, newVertex ;
                    if(blockSizes[bn]) {
                        vertexOptions['width'] = blockSizes[bn]['width'];
                        vertexOptions['height'] = blockSizes[bn]['height'];
                    }
                    newVertex = this.addChild(result, cell, vertexOptions, false);
                    this.doRegenerateDag=true;
                    this.refresh();
                });
            }



        });
        this.mxGraph.addCellOverlay(cell, overLay);
    }

    executeLayout(change, post) {
        this.mxGraph.getModel().beginUpdate();
        const layout:mxgraph.mxHierarchicalLayout = new mxHierarchicalLayout(this.mxGraph);
        try {
            if (change != null) {
                change();
            }
            layout.execute(this.mxGraph.getDefaultParent());
        }
        catch (e) {
            throw e;
        }
        finally {
            const morph: mxgraph.mxMorphing = new mxMorphing(this.mxGraph);
            morph.addListener(mxEvent.DONE, mxUtils.bind(this, () => {
                if(post != null) {
                    post();
                }
            }));
            morph.startAnimation();
            this.mxGraph.getModel().endUpdate();
            this.workflowService.triggerEvent('autoSave');
        }
    }

    treeLayout(parent: mxgraph.mxCell = null) {
        let layout = this.mxLayout = new mxCompactTreeLayout(this.mxGraph, false);
        //layout.useBoundingBox = false;
        layout.edgeRouting = false;
        layout.levelDistance = 40;
        layout.nodeDistance = 40;
        layout.isVertexMovable = function(cell:mxgraph.mxCell)
        {
            return true;
        };
        let layoutMgr = new mxLayoutManager(this.mxGraph);
        let graph = this.mxGraph;

        //layout.execute(graph.getDefaultParent());

        layoutMgr.getLayout = function(cell){
            //setTimeout(()  => {
            graph.view.refresh();
            //},300);
            if (cell.getChildCount() > 0) {
                return layout;
            }
        };
    }

    getSourceEdge(edges:mxgraph.mxCell[], id:any) {
        let sourcedEdge:any = null;
        edges.forEach( (edge) => {
            if(edge.source.getId() === id) {
                sourcedEdge = edge;
                return true;
            }
        });
        return sourcedEdge;
    }

    addDummyBlock(cell:mxgraph.mxCell) {
        let vertexDummy:mxgraph.mxCell;
        let cellValue = cell.getValue(), edgeDummy;
        const parent = this.mxGraph.getDefaultParent();
        const geo = this.mxGraph.getCellGeometry(cell);
        const {width, height} = this.blocksSize.dummyBlock;
        try {
            vertexDummy = this.mxGraph.insertVertex(parent, null, {
                name: 'dummyBlock',
                model: cellValue['model']
            }, width, geo.y, width, height);
            vertexDummy['block_name'] = 'dummyBlock';
            vertexDummy['parentId'] = cell.getId();

            edgeDummy = this.mxGraph.insertEdge(parent, null, '', cell, vertexDummy);
            edgeDummy['block_name'] = 'dummy_edge';
            edgeDummy.geometry.x = 1;
            edgeDummy.geometry.y = 0;
            edgeDummy.geometry.offset = new mxPoint(0, -20);
        }
        finally {
            this.mxGraph.getModel().endUpdate();
            // this.workflowService.triggerEvent('autoSave');
        }
    }

    addChild(data:any, cell:mxgraph.mxCell, options:any={},isHideOverlay:boolean=false) {
        const parent = this.mxGraph.getDefaultParent();
        let vertex:mxgraph.mxCell, vertexElse:mxgraph.mxCell, vertexDummy:mxgraph.mxCell;
        let blockName = data['name'], blockModel = data['model'],isConditional = data['isConditional'], vertexValue:any = {}, style='';

        vertexValue['name'] = blockName;
        vertexValue['model'] = blockModel;
        vertexValue['isConditional'] = data['isConditional'];
        if(isConditional) {
            vertexValue['condition'] = data['condition'];
        }

        this.mxGraph.getModel().beginUpdate();
        const geo = this.mxGraph.getCellGeometry(cell);
        let vertexWidth = this.blockWidth,
            vertexHeight = this.blockHeight;
        if(_.has(options,'width')) {
            vertexWidth = options['width'];
            vertexHeight = options['height'];
        }
        if(blockName==='conditionalBlock' || blockName === 'untilConditionBlock') {
            style='shape=rhombus';
            vertexWidth = this.conditionBlockSize;
            vertexHeight = this.conditionBlockSize;
        }
        try{
            const blockMeta = this.blocksMetaList.find( block => blockName == block['id']);

            let _container;
            if(blockMeta) {
                _container = this.commonService.blockHeightCalculation(data, {
                    blockSize: this.blocksSize,
                    title: blockMeta['title'],
                    mxGraphService: this
                });
                if(_container) {
                    vertexWidth = _container.width;
                    vertexHeight = _container.height;
                }
            }
            /*if(blockName === 'scheduledActivityBlock') {
                let $container = this.commonService.renderScheduledActivityBlock(data, {
                    blockSize: this.blocksSize,
                    title: 'Schedule activity',
                    mxGraphService: this,
                })
                $container.appendTo('body');
                vertexWidth = $container.outerWidth();
                vertexHeight = $container.outerHeight();
                $container.remove();
            }*/

            let edge, edgeElse;
            const $children = this.getChildren(cell);

            if($children.length && $children.length==1 && $children[0]['block_name'] == 'dummyBlock') {
                this.mxGraph.removeCells($children);
            }

            if(isConditional) {
                if($children.length == 0) {
                    const conditionData = vertexValue['condition'];
                    const elseVertexModel = {
                        col_name: conditionData['col_name'],
                        text: conditionData['text'],
                        fieldType: conditionData['fieldType'],
                        pageType: conditionData['pageType']
                    };
                    vertexElse = this.mxGraph.insertVertex(parent, null, {
                        name: 'elseBlock',
                        isConditional: isConditional,
                        model: elseVertexModel
                    }, vertexWidth, geo.y, vertexWidth, 0, 'elseStyle');
                    vertexElse['block_name'] = 'elseBlock';
                    vertexElse['parentId'] = cell.getId();

                    edgeElse = this.mxGraph.insertEdge(parent, null, elseVertexModel, cell, vertexElse, 'elseEdgeStyle');
                    edgeElse['block_name'] = 'else_edge';
                    edgeElse.geometry.x = 1;
                    edgeElse.geometry.y = 0;
                    edgeElse.geometry.offset = new mxPoint(0, -20);
                    this.addOverlay(vertexElse, true);
                }
                vertex = this.mxGraph.insertVertex(parent, null, vertexValue, vertexWidth, geo.y, vertexWidth, vertexHeight, style);
                vertex['block_name'] = blockName;
                vertex['parentId'] = cell.getId();
                edge = this.mxGraph.insertEdge(parent, null, vertexValue['condition'], cell, vertex);
                edge['block_name'] = 'condition_edge';
            }
            else {
                vertex = this.mxGraph.insertVertex(parent, null, vertexValue, vertexWidth, geo.y, vertexWidth, vertexHeight, style);
                vertex['block_name'] = blockName;
                vertex['parentId'] = cell.getId();
                _.forEach($children, (child:mxgraph.mxCell) => {
                    child['parentId'] = vertex.getId();
                })
                const cellEdges = this.mxGraph.getModel().getEdges(cell);
                const sourceEdge = this.getSourceEdge(cellEdges, cell.getId());
                edge = this.mxGraph.insertEdge(parent, null, '', cell, vertex);

                this.mxGraph.splitEdge(sourceEdge, [vertex], edge);
                // this.mxGraph.connectCell(sourceEdge, vertex, true);
                // this.mxGraph.view.refresh()
                //edge = this.mxGraph.insertEdge(parent, null, '', source, vertex);
            }
            this.workflowService.triggerEvent({'actionName':'beforeSave','data': vertex,'initializer':false});
            edge.geometry.x = 1;
            edge.geometry.y = 0;
            edge.geometry.offset = new mxPoint(0, -20);
            if(!isHideOverlay) {
                this.addOverlay(vertex);
                //if(blockName !== 'conditionalBlock') this.editOverlay(vertex);
            }
        }
        finally {
            this.mxGraph.getModel().endUpdate();
            this.onAfterRender.next(vertexElse);
            this.workflowService.triggerEvent('autoSave');

        }
        return vertex;
    }

    setupWorkflowTrigger(cell: mxgraph.mxCell, e:any) {
        const initialState = {
            title: this.isScheduler ? 'Setup a scheduler' : 'Setup a trigger flow',
            mxGraph: this.mxGraph,
            selectedCell: cell,
            triggerObjects: this.fieldsData,
            isScheduler: this.isScheduler,
            isMarketPlaceView: this.isMarketPlaceView,
            diagramOptions: this.options
        };
        this.modalRef = this.modalService.show(TriggerRulesPanelComponent, {
            'initialState': initialState,
            'size': 'sm',
            'class': 'workflow-sidepanel'
        });

        this.modalRef.content.onClose.subscribe( result => {
            //cell.setAttribute('mainObject', result);
            this.selectedMainObjectFields = result['fields'];
            this.mainObjectName = result['formData']['mainObject'];
            try {
                this.addOverlay(cell);
                //this.editOverlay(cell);
                this.renderInitBlockContainer(cell, result);
                //this.mxGraph.labelChanged(cell, result['formData'], e);
            }
            finally {
                this.mxGraph.getModel().endUpdate();
                this.workflowService.triggerEvent('autoSave');
            }
        });
    }

    setVertexElse() {
        let style = new Object();
        style[mxConstants.STYLE_FONTSIZE] = '12';
        style[mxConstants.STYLE_FONTCOLOR] = '#505b65';
        style[mxConstants.STYLE_FILLCOLOR] = 'rgba(0,0,0,0)';
        style[mxConstants.STYLE_STROKECOLOR] = 'rgba(0,0,0,0)';
        style[mxConstants.STYLE_STROKEWIDTH] = 0;
        style[mxConstants.STYLE_SHAPE] = 'label';
        style[mxConstants.STYLE_SHADOW] = '0';
        style[mxConstants.STYLE_ROUNDED] = '0';
        style[mxConstants.STYLE_EDGE] = mxEdgeStyle.TopToBottom;
        style[mxConstants.EDGESTYLE_TOPTOBOTTOM] = 'topToBottomEdgeStyle';
        this.mxGraph.getStylesheet().putCellStyle('elseStyle', style);
    }

    setEdgeElseStyle() {
        let style = new Object();
        style[mxConstants.STYLE_ARCSIZE] = 10;
        style[mxConstants.STYLE_ABSOLUTE_ARCSIZE] = 1;
        style[mxConstants.STYLE_SOURCE_PERIMETER_SPACING] = 'sourcePerimeterSpacing';
        style[mxConstants.STYLE_ROUNDED] = true;
        style[mxConstants.STYLE_STROKECOLOR] = '#DADADA';
        style[mxConstants.STYLE_STROKEWIDTH] = 6;
        style[mxConstants.STYLE_EXIT_X] = 0.5; // center
        style[mxConstants.STYLE_EXIT_Y] = 1.0; // bottom
        style[mxConstants.STYLE_EXIT_PERIMETER] = 0; // disabled
        style[mxConstants.STYLE_ENTRY_X] = 0.5; // center
        style[mxConstants.STYLE_ENTRY_Y] = 0; // top
        style[mxConstants.STYLE_ENTRY_PERIMETER] = 0; // disabled
        style[mxConstants.STYLE_EDGE] = mxEdgeStyle.TopToBottom;
        style[mxConstants.STYLE_ENDARROW] = mxConstants.NONE;
        style[mxConstants.STYLE_STARTARROW] = mxConstants.NONE;
        // style[mxConstants.EDGESTYLE_TOPTOBOTTOM] = 'topToBottomEdgeStyle';
        this.mxGraph.getStylesheet().putCellStyle('elseEdgeStyle', style);
        // style[mxConstants.STYLE_EDGE]
        //console.log('style', style);
    }

    setStyle() {
        let style = this.mxGraph.getStylesheet().getDefaultVertexStyle();
        // mxConstants.RECTANGLE_ROUNDING_FACTOR = 0.03;
        mxConstants.SHADOWCOLOR = 'rgba(0, 0, 0, 0.05)';
        mxConstants.SHADOW_OFFSET_X = 0;
        mxConstants.SHADOW_OFFSET_Y = 4;

        style[mxConstants.STYLE_FONTSIZE] = '12';
        style[mxConstants.STYLE_FONTCOLOR] = '#505b65';
        style[mxConstants.STYLE_FILLCOLOR] = '#fff';
        style[mxConstants.STYLE_STROKECOLOR] = '#dadada';
        style[mxConstants.STYLE_STROKEWIDTH] = 1;
        style[mxConstants.STYLE_CURVED] = 0;
        style[mxConstants.STYLE_SHAPE] = 'label';
        style[mxConstants.STYLE_SHADOW] = '1';
        style[mxConstants.STYLE_ROUNDED] = '0';
    }

    setEdgeStyle() {
        let style = this.mxGraph.getStylesheet().getDefaultEdgeStyle();
        style[mxConstants.STYLE_ARCSIZE] = 10;
        style[mxConstants.STYLE_ABSOLUTE_ARCSIZE] = 1;
        style[mxConstants.STYLE_SOURCE_PERIMETER_SPACING] = 'sourcePerimeterSpacing';
        style[mxConstants.STYLE_ROUNDED] = true;
        style[mxConstants.STYLE_STROKECOLOR] = '#DADADA';
        style[mxConstants.STYLE_STROKEWIDTH] = 6;
        style[mxConstants.STYLE_EXIT_X] = 0.5; // center
        style[mxConstants.STYLE_EXIT_Y] = 1.0; // bottom
        style[mxConstants.STYLE_EXIT_PERIMETER] = 0; // disabled
        style[mxConstants.STYLE_ENTRY_X] = 0.5; // center
        style[mxConstants.STYLE_ENTRY_Y] = 0; // top
        style[mxConstants.STYLE_ENTRY_PERIMETER] = 0; // disabled

        style[mxConstants.STYLE_EDGE] = mxEdgeStyle.TopToBottom;
        style[mxConstants.STYLE_ENDARROW] = mxConstants.NONE;
        style[mxConstants.STYLE_STARTARROW] = mxConstants.NONE;

        // style[mxConstants.STYLE_EDGE]
        //console.log('style', style);
    }

    registerHistoryView(items:any):void {
        const tasks = items.tasks;
        this.mxGraph.addListener(mxEvent.CLICK, (sender, evt) => {
            if(_.isUndefined(evt.getProperty('cell'))) {
                return false;
            }
            let cell: mxgraph.mxCell = evt.getProperty('cell');
            if(!cell.isVertex()) {
                return false;
            }
            let blockId = cell['block_name'] === "initializer" ? cell.getId() : cell['block_name'] + '_' + cell.getId();
            if(_.has(tasks, blockId)) {
                const initialState = {
                    cell: cell,
                    mxGraph: this.mxGraph,
                    taskItem: tasks[blockId],
                    title: 'sc'
                };
                this.modalRefHistory = this.modalService.show(HistoryTimelinePanelComponent, {
                    initialState: initialState,
                    size: 'sm',
                    'class': 'ng-taks-history-sidepanel'
                });
                this.modalRefHistory.content.onClose.subscribe( result => {
                    // console.log(cell, result);
                });

            }

        });
    }

    setHightlightCells(items,isAudit: boolean = false):void {
        this.resetHightlightedCells(() => {

            if (isAudit) {
                let block_name = items.auditRecord.taskId.split('_')[0];
                let block_id = items.auditRecord.taskId.split('_')[1];
                let cells = this.mxGraph.getModel().cells;
                let key: any;
                if(items.auditRecord.taskId === 'cs_initializer') {
                    key = items.auditRecord.taskId;
                }else{
                    key = Object.keys(cells).find(k => cells[k].block_name === block_name && cells[k].id === block_id);
                }
                let cell = cells[key];
                let blockId = items.auditRecord.taskId === 'cs_initializer' ? cell.getId() : cell['block_name'] + '_' + cell.getId();
                let colorCode = items.auditRecord.actionType == 'Add' ? HIGHLIGHT_BLOCK_ADD : items.auditRecord.actionType == 'Edit' ? HIGHLIGHT_BLOCK_EDIT : HIGHLIGHT_BLOCK_DELETE;

                if (cell['block_name'] === 'elseBlock') {
                    return;
                }
                if (_.has(this.cellHighlighter, blockId)) {
                    this.cellHighlighter[blockId].destroy();
                }
                setTimeout(() => {
                    if (colorCode) {
                        this.cellHighlighter[blockId] = new mxCellHighlight(this.mxGraph, colorCode, 2);
                        this.cellHighlighter[blockId].highlight(this.mxGraph.view.getState(cell));
                    }
                }, 100);

                console.log('audit highlighted cells')
            } else {
                this.registerHistoryView(items);
                _.forEach(this.mxGraph.getModel().cells, (cell: mxgraph.mxCell) => {
                    const tasks = items.tasks;
                    if (!_.isUndefined(cell.getValue()) && cell.isVertex()) {
                        let cellValue = cell.getValue();
                        let blockId = cell['block_name'] === "initializer" ? cell.getId() : cell['block_name'] + '_' + cell.getId();
                        /* overlap conditional delay */
                        if (cell['block_name'] == 'delayBlock' && cellValue['model']['type'] === 'conditional' && !_.has(tasks[blockId])) {
                            blockId = 'conditionalDelayBlock_' + cell.getId();
                        }
                        let status = _.has(tasks[blockId], 'status') ? tasks[blockId]['status'] : null;
                        let colorCode = status === 'success' ? HIGHLIGHT_COMPLETED : status === 'running' ? HIGHLIGHT_RUNNING : status === 'failed' ? HIGHLIGTT_SKIPPED_OR_FAILED : status === 'skipped' ? HIGHLIGTT_SKIPPED : null;
                        if (cell['block_name'] === 'elseBlock') {
                            return;
                        }
                        if (_.has(this.cellHighlighter, blockId)) {
                            this.cellHighlighter[blockId].destroy();
                        }

                        setTimeout(() => {
                            if (colorCode) {
                                this.cellHighlighter[blockId] = new mxCellHighlight(this.mxGraph, colorCode, 2);
                                this.cellHighlighter[blockId].highlight(this.mxGraph.view.getState(cell));
                            }
                        }, 100);
                    }

                })
            }

        });

    }

    resetHightlightedCells(setHighLight:Function = ()=>{}) {
        if(_.size(this.cellHighlighter) > 0 ) {
            const highlights = Object.values(this.cellHighlighter);
            _.forEach(highlights, ( highlight:mxgraph.mxCellHighlight ) => {
                highlight.destroy();
            });
        }
        this.mxGraph.removeListener(mxEvent.CLICK);
        return setHighLight();
    }

    reset() {
        this.selectedMainObjectFields = null;
    }

    destroyMxGraph(afterDestroy:Function = _.noop) {
        if(this.mxGraph) {
            this.mxGraph.destroy();
        }
        this.firstTimeRender = true;
        afterDestroy();
    }

    refresh(interval:number = 0) {
        setTimeout( () => {
            this.mxGraph.view.refresh();
        }, interval)
    }

    prepareActiveInput(activeMainObject:any, MainObjectValue:any, cb:Function) {
        // console.log('prepareActiveInput', activeMainObject, MainObjectValue);
        this.selectedMainObjectFields = {};

        activeMainObject.fields.forEach((value) => {
            this.selectedMainObjectFields[value.col_name] = {
                input: value.input,
                type: value.type,
                text: value.text,
                operators: value.operators
            };
            if(value.hasOwnProperty('options') && value.options.length > 0) {
                this.selectedMainObjectFields[value.col_name]['options'] = value.options;
            }
        });
        if(activeMainObject.id === 'certificates') {
            let selectedDependentId = MainObjectValue['dependentId'];
            if(Object.keys(activeMainObject['dynamic_fields']).length) {
                let dynamicFields = activeMainObject['dynamic_fields'][selectedDependentId]['fields'];
                dynamicFields.forEach((item, index) => {
                    this.selectedMainObjectFields[item.col_name] = {
                        input: item.input,
                        type: item.type,
                        text: item.text,
                        operators: item.operators,
                        fieldType: item.fieldType,
                        pageType: item.pageType,
                    };
                    if (item.hasOwnProperty('options') && item.options.length > 0) {
                        this.selectedMainObjectFields[item.col_name]['options'] = item.options;
                    }
                });
            }
        }

        if(activeMainObject.hasOwnProperty('custom_fields')) {
            activeMainObject['custom_fields'].forEach( (template:string) => {
                template['fields'].forEach( (value:any) => {
                    this.selectedMainObjectFields[value.col_name] = value;
                    if(value.hasOwnProperty('options') && value.options.length > 0) {
                        this.selectedMainObjectFields[value.col_name]['options'] = value.options;
                    }
                });
            })
        }
        cb();
        // console.log('active', this.selectedMainObjectFields);
    }

    getXml(uriEncode:boolean=false) {
        const codec = new mxCodec();
        let result = codec.encode(this.mxGraph.getModel());
        let xml = mxUtils.getXml(result);
        if(uriEncode) {
            return encodeURIComponent(xml);
        }
        return xml;
    }

    parseXml(xml:string) {
        let doc = mxUtils.parseXml(xml);
        let codec = new mxCodec(doc);
    }

    render(blockObjects:any = null) {
        if(! this.xmlSource ) {
            this.mxGraph.getModel().beginUpdate();
            try {
                const parent = this.mxGraph.getDefaultParent();
                const blockWidth = this.blockWidth, blockHeight = this.blockHeight;
                const x = (this.getContainerWidth() / 2) - (blockWidth / 2);
                //const x = 30;
                const y = 20;
                const startBlock = this.mxGraph.insertVertex(parent, 'cs_initializer', '', x, y, blockWidth, blockHeight);
                startBlock['block_name']='initializer';
                startBlock['parentId']='0';
                //const endBlock = this.mxGraph.insertVertex(parent, 'cs_end_block', 'End block', x, 220, blockWidth, blockHeight);
                //const connector = this.mxGraph.insertEdge(parent, null, '', startBlock, endBlock);
                //this.addOverlay(startBlock, true);
            }
            finally {
                this.mxGraph.getModel().endUpdate();
            }
        }
        else {
            let doc = mxUtils.parseXml(this.xmlSource);
            let codec = new mxCodec(doc);
            let initializerBlock:mxgraph.mxCell = codec.getObject('cs_initializer');
            let blockValue:any = initializerBlock.getValue();
            if(!this.fieldsData) {
                return;
            }
            let activeMainObject = this.fieldsData[blockValue.mainObject];
            this.mainObjectName = blockValue.mainObject;
            this.prepareActiveInput(activeMainObject, blockValue, () => {
                codec.decode(doc.documentElement, this.mxGraph.getModel());
                let isInitialBlock=false, initializerTitle='';
                let bounds: mxgraph.mxRectangle[] = [];
                _.forEach(this.mxGraph.getModel().cells, (cell:mxgraph.mxCell) => {
                    if(blockObjects && cell.isVertex() && (blockObjects[cell['block_name']+'_'+cell.getId()] || 'cs_initializer' == cell.getId()) )  {
                        if(cell.getId() == 'cs_initializer') {
                            cell.setValue(blockObjects['cs_initializer']);
                        }
                        else {
                            cell.setValue(blockObjects[cell['block_name']+'_'+cell.getId()]);
                        }
                        let options = {
                            blockSize: this.blocksSize,
                            mxGraphService: this,
                            id: cell.getId()
                        };
                        let blockMeta = this.blocksMetaList.find( block => cell['block_name'] == block['id']);
                        if(!blockMeta && 'cs_initializer' == cell.getId()) {
                            //console.log('cccccccc', cell, options)
                            let objValue = cell.getValue();
                            const labelText = this.isScheduler ? objValue['cronText'] : objValue.mainObject + ' trigger';
                            if(this.workflowType == 'Custom') {
                                initializerTitle = 'How do you want to trigger this '+labelText+' workflow?';
                            }
                            else {
                                initializerTitle = 'This workflow would be executed as a sequence';
                            }
                            isInitialBlock = true;
                            options['title'] = initializerTitle;
                            options['selectedMainObjectFields'] = this.selectedMainObjectFields;
                            options['getOperatorsDisplayText'] = this.triggerService.getOperatorsDisplayText();
                            options['isScheduler'] = this.isScheduler;
                        }
                        else if( !['elseBlock', 'dummyBlock'].includes(cell['block_name']) ) {
                            options['title'] = blockMeta['title'];
                        }

                        if(this.options['doAutoHeightAdjustment'] && this.options['doAutoHeightAdjustment'] == true && (blockMeta || isInitialBlock)) {
                            let containerInfo:any = this.commonService.blockHeightCalculation(cell, options);
                            // console.log('cccccccc', cell, options)
                            if(containerInfo) {
                                let current = cell.getGeometry();
                                current.width = containerInfo.width;
                                current.height = containerInfo.height;
                                cell.setGeometry(current);

                            }
                        }
                    }
                    if(cell.isVertex() && !this.options.viewMode && !this.isMarketPlaceView && (cell['block_name'] !== 'dummyBlock')) {
                        const isElseBlock = cell['block_name'] == 'elseBlock';
                        this.addOverlay(cell, isElseBlock);
                    }
                });
                if(this.options['doAutoHeightAdjustment']) {
                    isInitialBlock = false;
                    setTimeout( () => {this.mxGraph.resizeCells(this.mxGraph.getModel().cells, [], true);
                        this.mxGraph.refresh();}, 300);
                }
            });
        }

        setTimeout( () => {
            this.mxGraph.zoomActual();
        },300)
    }

    xmlValidator() {
        return true;
    }

    getChildren(cell:mxgraph.mxCell) {
        let children = [];
        let edges = cell.edges;
        if(edges != null) {
            for(let i = 0; i < edges.length; i++){
                if(edges[i].target.getId() != cell.getId()){
                    let cellCopy = edges[i].target;
                    children.push(cellCopy);
                }
            }
        }
        return children;
    }

    skipOrUndoButton(cell: mxgraph.mxCell) {}

    getInitBlockValue() {
        const initBlock: mxgraph.mxCell = this.mxGraph.getModel().getCell('cs_initializer');
        if(initBlock) {
            return initBlock.getValue();
        }
        return {};
    }
}


