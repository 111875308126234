import { EventEmitter, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { WorkflowhttpService } from "@app/workflow-common/services/workflowhttp.service";
var ConditionalBlockComponent = /** @class */ (function () {
    function ConditionalBlockComponent(cdr, confirmationBoxHelper, ajsScope, workflowService) {
        this.cdr = cdr;
        this.confirmationBoxHelper = confirmationBoxHelper;
        this.ajsScope = ajsScope;
        this.workflowService = workflowService;
        this.isEdit = false;
        this.activeField = {};
        this.isConditional = false;
        this.isMarketPlaceView = false;
        this.hasChild = false;
        this.isReadOnlyMode = false;
        this.outputModel = new EventEmitter();
        this.close = new EventEmitter();
        this.conditionalFields = [];
        this.saving = false;
    }
    ConditionalBlockComponent.prototype.ngOnInit = function () {
        this.oldModel = JSON.stringify(Object.assign({}, this.inputModel));
        this.showDeleteBtn = this.isMarketPlaceView ? false : this.isEdit;
        //console.log(this.isConditional)
    };
    ConditionalBlockComponent.prototype.removeCell = function () {
        var _this = this;
        this.confirmationBoxHelper
            .getConfirmation(this.deleteMessage, this.ajsScope)
            .then(function () {
            _this.close.emit('remove');
        });
    };
    ConditionalBlockComponent.prototype.ngAfterViewInit = function () {
    };
    ConditionalBlockComponent.prototype.validateItems = function () {
        if (!this.inputModel.model.col_name) {
            return true;
        }
        return this.isConditional ? this.inputModel.condition.operator === '' || this.inputModel.condition.value === '' : false;
    };
    ConditionalBlockComponent.prototype.addValue = function () {
        var _this = this;
        /*let selectedItem = _.find(this.inputModel.config.fields, (field) => {
            return field.id == this.inputModel.model['col_name']
        });*/
        this.saving = true;
        var selectedItem = this.inputModel.config.fieldsByColName[this.inputModel.model['col_name']];
        //console.log('selectedItem', selectedItem);
        if (selectedItem && selectedItem['fieldType'] && selectedItem['pageType']) {
            this.inputModel.model['fieldType'] = selectedItem['fieldType'];
            this.inputModel.model['pageType'] = selectedItem['pageType'];
        }
        if (selectedItem && selectedItem['templateId']) {
            this.inputModel.model['templateId'] = selectedItem['templateId'];
        }
        this.newModel = JSON.stringify(Object.assign({}, this.inputModel));
        if (this.oldModel === this.newModel) {
            this.workflowService.triggerEvent({ 'isUpdated': false, 'isEdit': this.isEdit, 'action': 'valueChanged' });
        }
        else {
            this.workflowService.triggerEvent({ 'isUpdated': true, 'isEdit': this.isEdit, 'action': 'valueChanged' });
        }
        setTimeout(function () {
            _this.outputModel.emit(_this.inputModel);
        }, 0);
    };
    ConditionalBlockComponent.prototype.closeModal = function () {
        this.close.emit(this.isEdit);
    };
    return ConditionalBlockComponent;
}());
export { ConditionalBlockComponent };
