import {Component, EventEmitter, HostListener, Inject, Input, OnInit, Output, forwardRef} from '@angular/core';
import {CommonDataService} from "@app/services/http-services/common-data.service";
import { Router } from '@angular/router';
import { AuthService } from '@app/core';
declare let window: any;
@Component({
  selector: 'color-palatte',
  templateUrl: './color-palatte.component.html',
    styleUrls: ['./color-palatte.component.scss']
})
export class ColorPalatteComponent implements OnInit {
    public color;
    colorPalatte: any = true;
    savedColors: any = false;
    modal: any = false;
    cpToggle: any;
    percentage:number = 100;
    recentColors : any = [];
    background = [
        {
            name:'Primary',
            value:'#505565'
        },
        {
            name:'Secondary',
            value:'#979797'
        },
        {
            name:'Disabled',
            value:'#c9c9c9'
        },
        {
            name:'Form',
            value:'#ebebeb'
        },
        {
            name:'Border',
            value:'#eeeeee'
        },
    ]
    brand = [
        {
            name:'Accent',
            value:'#f2994a'
        },
        {
            name:'Accent-Transparent 20',
            value:'#f2994a33'
        },
        {
            name:'Secondary',
            value:'#3883c1'
        },
        {
            name:'Tersier',
            value:'#746ca6'
        },
        {
            name:'Border',
            value:'#eeeeee'
        },
    ]
    searchValues: boolean = false;
    searchResult: any = [];
    userSearchNotFound: boolean= false;
    @Output() colorValue = new EventEmitter()
    @Input() data;
    // @Input() dynamicaClass = '';
    @Input() myValue;
    errorMessage: boolean = false;
    colorDisplay: any;
    red: number;
    green: number;
    blue: number;
    collection: any;
    eventSavedColorWatch:any;
    savedColorLoading= false;
    savedColorTab:number = 1;
    showCloseIcon:boolean = false;
    searchColor:any='';
    savedColourAccess:boolean = false;

    constructor(
        private commonService: CommonDataService,
        private router: Router,
        @Inject(forwardRef( () => AuthService)) private authService: AuthService,
        // @Inject('$scope') private ajsScope,
    ) {}

    ngOnInit(){
        this.savedColourAccess = this.authService.permitted(['SetupSavedColours.writeaccess']) === true;
        var localColors = localStorage.getItem('Recent-colors')
        if(localColors){
            this.recentColors = JSON.parse(localColors);
        }
        $('app-hue').css('height', 'auto');
        this.commonService.savedColorsData$.subscribe( data => {
            this.collection = data
        })
        // let self = this;
        // this.eventSavedColorWatch = this.ajsScope.$on('event:saved_color_updated', function (event, data) {
        //     if (data) {
        //         self.loadSavedColors();
        //     }
        // });
    }

    // loadSavedColors(){
    //     this.commonService.fetchSavedColorsData();
    // }

    ngOnChanges(){
        var localColors = localStorage.getItem('Recent-colors')
        if(localColors){
            this.recentColors = JSON.parse(localColors);
        }
        if(this.data){
            if(this.data[0] != '#'){
                this.color = { hexString: '#ffffff'};
            } else {
                this.color = { hexString: this.data};
            }
            this.colorDisplay = this.data;
            this.checkInputValue(this.data,'recentColors')
        }
        this.setCursorPosition();
        this.savedColors = false;
        this.colorPalatte = true;
        this.searchValues = false;
    }

    //overwrites plugin rendered position of the cursor
    setCursorPosition(){
        let that = this;
        setTimeout(() => {
            if(this.data) {
                if (this.data[0] != '#') {
                    this.color = {hexString: '#ffffff'};
                } else {
                    this.color = {hexString: this.data};
                }
            } else {
                this.color = {hexString: '#ffffff'};
            }
            let cursor = $('app-cursor .cursor.offset:visible')[0];
            if(cursor){
                let cursorTop = parseInt(cursor.style.top.replace('px', '')),
                    cursorLeft = parseInt(cursor.style.left.replace('px', ''));
                if (that.color && (cursorTop <= 0 || cursorLeft <= 0)) {
                    let rgb = that.hexToRGB(that.color);
                    let hsv = that.rgbToHsv(rgb);
                    let canvas = $('.main-canvas:visible');
                    if (hsv && hsv.hasOwnProperty('s') && hsv.hasOwnProperty('v')) {
                        $(cursor).css({
                            left: hsv.s / 100 * $(canvas).width() + 'px',
                            top: (1 - hsv.v / 100) * $(canvas).height() + 'px'
                        });
                    }
                }
            }
        }, 100);
    }

    hexToRGB(color){
        if(color && color.hasOwnProperty('hexString') && color.hexString!='#'){
            var rgbHex = color.hexString.replace('#','').match(/.{1,2}/g);
            var rgb = [
                parseInt(rgbHex[0], 16),
                parseInt(rgbHex[1], 16),
                parseInt(rgbHex[2], 16)
            ];
            return rgb;
        }
    }

    rgbToHsv(rgb) {
        if(rgb){
            let redAbs, greenAbs, blueAbs, red, green, blue, hue, saturation, value, difference, roundColor;
            redAbs = rgb[0] / 255;
            greenAbs = rgb[1] / 255;
            blueAbs = rgb[2] / 255;
            value = Math.max(redAbs, greenAbs, blueAbs),
            difference = value - Math.min(redAbs, greenAbs, blueAbs);
            roundColor = colorAbs => (value - colorAbs) / 6 / difference + 1 / 2;
            if (difference == 0) {
                hue = saturation = 0;
            } else {
                saturation = difference / value;
                red = roundColor(redAbs);
                green = roundColor(greenAbs);
                blue = roundColor(blueAbs);
                if (redAbs === value) {
                    hue = blue - green;
                } else if (greenAbs === value) {
                    hue = (1 / 3) + red - blue;
                } else if (blueAbs === value) {
                    hue = (2 / 3) + green - red;
                }
                if (hue < 0) {
                    hue += 1;
                }else if (hue > 1) {
                    hue -= 1;
                }
            }
            return {
                h: Math.round(hue * 360),
                s: Math.round(saturation * 100),
                v: Math.round(value * 100)
            };
        }
    }

    // To toggle between color picker and saved colors
    displayContents(event: any, toggle: any) {
        if (toggle == 'savedColors') {
            this.searchColor = '';
            this.userSearchNotFound = false;
            this.showCloseIcon = false;
            if(this.savedColorTab == 1) {
                this.savedColorLoading = true;
                this.savedColors = true;
                this.colorPalatte = false
                this.cpToggle = false;
                setTimeout(() => {
                    this.savedColorLoading = false;
                    this.savedColorTab++;
                }, 1000)
            }else{
                this.savedColors = true;
                this.colorPalatte = false
                this.cpToggle = false;
            }
        } else {
            this.colorPalatte = true;
            this.savedColors = false;
            this.cpToggle = true;
            this.searchValues = false
            this.searchResult = []
        }
    }

    // To search the colors in saved colors
    searchValue(event: any){
        if(event.target.value){
            this.searchColor = event.target.value
            this.searchValues = true
            this.userSearchNotFound = false;
            let searchValue: any = [];
            this.searchResult = [];
            // @ts-ignore
            var colorArray = []
            for (let i = 0; i < this.collection.length; i++) {
                for (let j = 0; j < this.collection[i].value.length; j++) {
                    let data = {
                        'collectionName': this.collection[i]['collectionName'],
                        'value': this.collection[i].value[j]
                    };
                    colorArray.push(data)
                }
            }
            colorArray.filter((data: any,index) => {
                let one = data['value']["name"].toLowerCase();
                let two = data['value']["color"];
                let three = data['collectionName'].toLowerCase();
                if (one.includes(event.target.value.toLowerCase()) || three.includes(event.target.value.toLowerCase())) {
                    if(this.searchResult.length == 0) {
                        let result = {
                            'collectionName': data['collectionName'],
                            'value': [data['value']]
                        };
                        searchValue.push(result)
                        this.searchResult = searchValue;
                    }else{
                        const findIndex = this.searchResult.findIndex(x => x.collectionName == data['collectionName'])
                        if (findIndex !== -1){
                            this.searchResult[findIndex]['value'].push(data['value']);
                        } else {
                            let result = {
                                'collectionName': data['collectionName'],
                                'value': [data['value']]
                            };
                            this.searchResult.push(result);
                        }

                    }
                }
                if (searchValue.length == 0) {
                    this.userSearchNotFound = true;
                } else {
                    this.userSearchNotFound = false;
                }
            })
        } else {
            this.searchValues = false;
            this.userSearchNotFound = false;
            this.searchResult = [];
        }
    }

    onFocus(event){
        if(event){
            this.showCloseIcon = true;
        } else{
            this.showCloseIcon = false;
        }
    }

    clearInput(event){
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
        this.searchColor ='';
        this.searchValues = false;
        this.searchResult = [];
        this.showCloseIcon = false;
    }

    // To emit the selected color in saved colors
    colorPicker(selectedColor:any,event){
        this.color.hexString = selectedColor;
        this.colorDisplay = selectedColor;
        this.checkInputValue(selectedColor,'savedColors')
        this.colorValue.emit(this.color.hexString);
        setTimeout(function (){
            $(event.srcElement).closest('.cs-color-palatte').children('.picker-color').trigger('click');
        }, 100);
    }

    // To close the modal and to send the selected color to local storage
    closeModal(e) {
        let index = this.recentColors.indexOf(this.color.hexString)
        if(index < 0){
            if (this.recentColors.length < 18) {
                this.recentColors.splice(0, 0, this.color.hexString);
                setTimeout(()=>{
                    $('.preset-color').css({borderRadius: "50%"})
                },50)
            } else {
                this.recentColors.splice(17, 1);
                this.recentColors.splice(0, 0, this.color.hexString);
                setTimeout(()=>{
                    $('.preset-color').css({borderRadius: "50%"})
                },50)
            }
        }
        localStorage.setItem('Recent-colors',JSON.stringify(this.recentColors));
        $(e.srcElement).closest('.cs-color-palatte').children('.picker-color').trigger('click');
    }


    recentColor(data, e){
        this.color = { hexString: data};
        let index = this.recentColors.indexOf(this.color.hexString);
        if(index > 0){
            this.recentColors.splice(index,1)
            this.recentColors.unshift(this.color.hexString)
        }
        this.colorValue.emit(this.color.hexString);
        this.colorDisplay = this.color.hexString;
        this.checkInputValue(data,'recentColors');
        setTimeout(function (){
            $(e.srcElement).closest('.cs-color-palatte').children('.picker-color').trigger('click');
        }, 100);
    }

    eyeDropperOpen(){
        if ('EyeDropper' in window) {
            const eyeDropper = new window.EyeDropper();
            const abortController = new AbortController();
            const that = this;
            eyeDropper.open({ signal: abortController.signal }).then((result) => {
                that.color =  { hexString: result.sRGBHex };
                that.colorDisplay = result.sRGBHex;
                that.colorValue.emit(this.color.hexString);
            }).catch((e) => {
                console.log(e);
            });
        } else {
            return;
        }
    }
    getDetails(){
        return this.color.hexString;
    }
    colorChange(data){
        this.colorDisplay = data['hexString']
        this.colorValue.emit(data['hexString'])
        this.percentage = 100
    }

    alphaValue(event){
        let alpha;
        let alphaValues;
        this.percentage = event.target.value
        if(this.percentage > 100){
            this.percentage = 100;
        }
        this.colorDisplay = this.colorDisplay.split('').splice(0,7).join('')
        if(this.percentage == 5){
            alphaValues = '0D'
        } else if (this.percentage == 4){
            alphaValues = '0A'
        } else if (this.percentage == 3){
            alphaValues = '08'
        } else if (this.percentage == 2){
            alphaValues = '05'
        } else if (this.percentage == 1){
            alphaValues = '03'
        } else if (this.percentage == 0){
            alphaValues = '00'
        } else {
            alpha = (Number(this.percentage)/100)
            alphaValues =  Math.ceil(255 * alpha).toString(16).toUpperCase();
        }
        this.colorDisplay = this.colorDisplay + alphaValues
        this.color.hexString = this.colorDisplay
        this.colorValue.emit(this.colorDisplay)
    }
    colorInputValue(event){
        var color = event.target.value
        if(color[0] == '#' && color[1] == '#'){
            color = color.substring(1)
            this.color = { hexString: color};
        } else if(color[0] == '#'){
            this.color = { hexString: color};
        } else{
            color = "#" + color;
            this.color = { hexString: color};
        }
        this.data = color
        this.colorValue.emit(this.color.hexString)
        this.colorDisplay = this.color.hexString
        this.setCursorPosition();
    }
    checkInputValue(event,type){
        var color
        var alpha
        if (type == 'input'){
             color = this.color.hexString;
        } else {
            color = event;
        }
        if(color[0] == '#' && color.length == 8 ) {
            alpha = color.slice(-1)
        } else if(color[0] != '#' && color.length > 8){
            this.percentage = 100;
            this.color.hexString = '#ffffff'
            this.colorDisplay = '#ffffff';
            return;
        } else if(color[0] == '#' && color.length == 7){
            this.percentage = 100
            this.color.hexString = this.colorDisplay
            return;
        } else {
            alpha = color.slice(-2)
        }
        this.color.hexString = this.colorDisplay
        let alphaValue = parseInt(alpha, 16);
        let percentage = Math.round((alphaValue/255)*100)
        this.percentage = percentage;
    }
    preventDecimal(event){
        if(event.key==='.'){event.preventDefault();}
    }

    @HostListener('document:keydown.enter')
    onDocumentKeydownEnter() {
        event.preventDefault();
    }
    ngOnDestroy(){
        this.savedColorTab = 0;
        this.savedColors = false;
    }

    routeToSettingsScreen(){
        let cancel = $('#cancel-styles')
        cancel ? $('#cancel-styles').trigger('click') : null
        this.router.navigateByUrl('system_settings/saved_colors')
    }
}
