import {Component, OnInit, ChangeDetectorRef, AfterViewInit, OnDestroy} from '@angular/core';
import {BsModalRef } from "ngx-bootstrap";
import { Subject } from "rxjs/index";
import { mxgraph, mxgraphFactory } from "mxgraph-factory";
import { TriggerDatasetService} from "@app/workflow-common/services/trigger-dataset.service";
import {MxGraphService} from "@app/workflow-common/services/mx-graph.service";
import {WorkflowhttpService} from "@app/workflow-common/services/workflowhttp.service";
import {Subscription} from "rxjs";

declare var _;


@Component({
    selector: 'app-add-block-panel',
    templateUrl: './add-block-panel.component.html',
    styles: ['.disabled{background: #DBDDE2; opacity: .4; cursor: not-allowed !important;}']
})
export class AddBlockPanelComponent implements OnInit, AfterViewInit,OnDestroy {

    public onClose: Subject<any>;
    showBlockConfig:string = '';
    selectedBlock:string = '';
    isConditional:boolean = false;
    activeField:any = {};
    selectedBlockModel:any = {};
    operators:any;
    cellModel: any;
    deleteMessage:string;

    //sidepanel data
    cell: mxgraph.mxCell;
    mxGraph: mxgraph.mxGraph;
    sender: any;
    event: any;
    title: string;
    selectedTriggerFields:any = null;
    isEdit:boolean = false;
    isMarketPlaceView: boolean = false;
    actionBlocks:any[] = [];
    options:any;
    mainObjectName:string='';
    hasChild: boolean = false;
    showDeleteBtn:boolean;
    activeMainObjects: any = {};
    isDisabled: any[] = [];
    mxGraphService: MxGraphService;
    blocksList: any[] = [];
    workflowType: string = 'Custom'
    toolTipMessage: string;
    warningMessage:string;
    editBlockWarningMessage:string;
    type:string;
    isReadOnlyMode: boolean = false;
    whitelistBlocks: any = [];
    subscription: Subscription;
    showTaskBlock: boolean = true;

    constructor(
        public modalRef: BsModalRef,
        private cd: ChangeDetectorRef,
        private triggerService: TriggerDatasetService,
        public httpService: WorkflowhttpService,
    ) { }

    ngOnInit() {
        this.isReadOnlyMode = this.isMarketPlaceView;
        this.options.objectName= this.mainObjectName;
        if(this.mainObjectName) {
            this.activeMainObjects = this.options ['mainObjectsFields'][this.mainObjectName];
        }
        let typeName = this.workflowType == 'Custom' ? 'workflow' : 'sequence';
        this.warningMessage = 'The current '+typeName+' template is running in the background. so you can not add or delete blocks until the '+typeName+' is executed completely.';
        this.editBlockWarningMessage = 'The current '+typeName+' template is running in the background. If you edit the '+typeName+', it will affect the running '+typeName+'.';
        this.httpService.getDagrunnerStatus(this.options.workflowId)
            .subscribe( {
                next: (resp: any) => {
                    if(resp.status == 'running'){
                        this.options.isRunningStatus = true;
                    }else{
                        this.options.isRunningStatus = false;
                    }
                }
            })
        this.showDeleteBtn = this.isMarketPlaceView ? false : this.isEdit;
        this.onClose = new Subject();
        this.hasChild = this.cell.getEdgeCount() > 1;
        const mainObjectCell:mxgraph.mxCell = this.mxGraph.getModel().getCell('cs_initializer');
        const {mainObject}  = mainObjectCell.getValue();
        this.mainObjectName = mainObject;
        let cellValue:any = this.cell.getValue();
        let cellModel = cellValue['model'];
        this.cellModel = cellModel;
        this.operators = this.triggerService.getOperators();
        this.isConditional = cellValue['name'] === 'conditionalBlock' || cellValue['name'] === 'untilConditionBlock' ? true : false;
        this.deleteMessage = this.workflowType == 'Custom' ? 'This will delete this block and all the following blocks in this workflow.' : 'This will delete this block and all the following blocks in this sequence.';

        if(this.mxGraphService.workflowStatus) {
            this.deleteMessage = this.mxGraphService.deactivateMessage;
        }
        const childNodes:any[] = this.mxGraphService.getChildren(this.cell);
        // console.log('child', this.mxGraph.getModel().getCell(this.cell['parentId']), this.cell['parentId']);
        if(childNodes.length && !this.isEdit) {
            childNodes.forEach( (node: mxgraph.mxCell) => {
                if(this.cell.getId() == node['parentId'] && (node['block_name'] == 'conditionalBlock' || node['block_name'] == 'untilConditionBlock')) {
                    this.isDisabled = ['untilConditionBlock', 'conditionalBlock'];
                    this.toolTipMessage = 'You cannot add the If blocks because the subsequent \n block is already a branch block';
                }
            })
        }

        if(this.isConditional) {
            this.activeField = this.selectedTriggerFields[cellModel['col_name']];
        }
        //set dummy structure
        this.selectedBlockModel = this.prepareBlockModel(this.isConditional);

        if(this.options.hasOwnProperty('whitelist')) {
            this.whitelistBlocks = !this.options['whitelist']['blocks'] ? [] : this.options['whitelist']['blocks'];
            // console.log('whitelistBlocks', this.whitelistBlocks);
        }

        // console.log('isEdit', this.isEdit, this.activeField, this.cell);
        if(this.isEdit) {
            this.isReadOnlyMode = this.isMarketPlaceView && !this.whitelistBlocks.includes(cellValue['name']+'_'+this.cell.getId());
            if(cellValue['name'] == 'delayBlock' && cellValue['model']['type'] === 'conditional') {
                this.openCloseBlockConfig('conditionalDelayBlock', cellModel);
            }
            else {
                this.openCloseBlockConfig(cellValue['name'], cellModel);
            }

            // console.log('cellValue', cellValue);
            this.isConditional = false;
            if(cellValue['condition'] && cellValue['condition']['col_name'] != '') {
                this.isConditional = true;
                this.selectedBlockModel['condition'] = cellValue['condition'];
                this.selectedBlockModel['isConditional'] = this.isConditional;
                this.activeField = this.selectedTriggerFields[cellValue['condition']['col_name']];
                // console.log('edit', this.selectedBlockModel);
            }
        }
        this.subscription = this.httpService.workflowEventTrigger$.subscribe((action:any) => {
            if (action === 'autoSave') {
                this.showTaskBlock = false;
            }
        });
    }

    ngAfterViewInit() {
        this.cd.detectChanges();
    }

    saveBlock() {
        if(this.selectedBlock === 'delayBlock') {
            if(this.selectedBlockModel['model']['type'] === 'conditional') {
                this.selectedBlockModel['model']['fieldLabel'] = this.selectedTriggerFields[this.selectedBlockModel['model']['col_name']]['text'];
            }
        }
        else if (this.selectedBlock === 'conditionalDelayBlock') {
            this.selectedBlockModel['model']['fieldLabel'] = this.selectedTriggerFields[this.selectedBlockModel['model']['col_name']]['text'];
        }
        // console.log('selectedBlockModel', this.selectedBlockModel);
        this.onClose.next(this.selectedBlockModel);
        this.modalRef.hide();
        //this.showBlockConfig='';
    }

    addBlockConfiguration(event:any = null) {
        this.saveBlock();
    }

    closeBlockModal(closeAll) {
        if(closeAll === 'remove') {
            if(this.mxGraphService.workflowStatus) {
                this.modalRef.hide();
                this.httpService.triggerEvent('deactivate_action')
            }
            else {
                this.onClose.next('deleteBlock');
            }
        }
        if(closeAll) {
            this.modalRef.hide();
        }
        else {
            this.selectedBlock = this.showBlockConfig='';
        }
    }

    openCloseBlockConfig(blockName:string='', modelValue:any=null, isDisabledMode:boolean = false){
        if(this.isDisabled.length && (blockName == 'conditionalBlock' || blockName == 'untilConditionBlock')) {
            return;
        }
        if(isDisabledMode) {
            return;
        }
        if(this.isConditional && blockName === 'conditionalBlock' && !this.isEdit) {
            //return false;
        }
        if(blockName==='') {
            this.selectedBlockModel = this.prepareBlockModel(this.isConditional);
        }
        else {
            this.selectedBlockModel['name'] = blockName;
            this.selectedBlockModel['config'] = this.getBlockConfiguration(blockName);
            this.selectedBlockModel['model'] = this.getBlockModel(blockName, modelValue);
            if(modelValue && modelValue['condition']) {
                this.selectedBlockModel['condition'] = modelValue;
            }
        }
        this.showBlockConfig = blockName;
        this.selectedBlock = blockName
    }

    getBlockConfiguration(blockName:string) {
        let config = {
            delayBlock: {
                intervalList: _.range(0, 16),
                fields: this.getFields(),
                customFields: this.getCustomFields(),
                fieldsByColName: this.selectedTriggerFields,
                units: ['minutes','hours', 'days', 'months']
            },
            conditionalDelayBlock: {
                intervalList: _.range(0, 16),
                fields: this.getFields(),
                customFields: this.getCustomFields(),
                fieldsByColName: this.selectedTriggerFields,
                units: ['minutes','hours', 'days', 'months']
            },
            conditionalBlock: {
                fields: this.getFields(),
                customFields: this.getCustomFields(),
                fieldsByColName: this.selectedTriggerFields
            },
            untilConditionBlock: {
                fields: this.getFields(),
                customFields: this.getCustomFields(),
                fieldsByColName: this.selectedTriggerFields,
                intervalList: _.range(0, 16),
                units: ['hours', 'days'],
                untilUnits: ['hours', 'weeks', 'days', 'months']
            },
            emailBlock: {
                intervalList: _.range(0, 16),
                units: ['minutes','hours', 'days', 'months']
            },
            officeTaskBlock: {
                fields: this.getFields(),
                fieldsByColName: this.selectedTriggerFields,
                customFields: this.getCustomFields(),
            },
            smsBlock: {
                intervalList: _.range(0, 16),
                units: ['minutes','hours', 'days', 'months']
            },
            notificationBlock: {
                fields: this.getFields(),
                fieldsByColName: this.selectedTriggerFields,
                customFields: this.getCustomFields(),
            },
            setcustomfieldvalueBlock: {
                customFields: this.getCustomFields(),
            },
            scheduledActivityBlock: {

            }
        };
        return config.hasOwnProperty(blockName) ? config[blockName] : null;
    }

    prepareBlockModel(isConditional: boolean = false) {
        let inputs = {
            name: '',
            config: {},
            model: {},
            isConditional: this.isConditional
        };
        // forming model of conditional type field
        if(isConditional) {
            inputs['condition'] = {
                operator: '',
                value: '',
                col_name: this.cellModel['col_name'],
                text: this.activeField.text,
                type: this.activeField.type,
                fieldType: this.activeField.hasOwnProperty('fieldType') ?  this.activeField['fieldType'] : '',
                pageType: this.activeField.hasOwnProperty('pageType') ?  this.activeField['pageType'] : '',
            }
        }
        return inputs;
    }

    getBlockModel(blockName:string, value:any = null) {
        // console.log('value', value);
        let config = {
            delayBlock: {
                interval: value===null ? 0 : value.hasOwnProperty('interval') ? value['interval'] : 0,
                unit: value===null ? 'hours' : value.hasOwnProperty('unit') ? value['unit'] : 'hours',
                type: value===null ? 'default' : value.hasOwnProperty('type') ? value['type'] : 'default',
            },
            conditionalDelayBlock: {
                interval: value===null ? 0 : value.hasOwnProperty('interval') ? value['interval'] : 0,
                unit: value===null ? 'days' : value.hasOwnProperty('unit') ? value['unit'] : 'days',
                col_name: value===null ? '' : value.hasOwnProperty('col_name') ? value['col_name'] : '',
                type: 'conditional',
                event_method: value===null ? 'pass on before' : value.hasOwnProperty('event_method') ? value['event_method'] : 'pass on before',
            },
            conditionalBlock: {
                col_name: value===null ? '' : value.hasOwnProperty('col_name') ? value['col_name'] : ''
            },
            untilConditionBlock: {
                col_name: value===null ? '' : value.hasOwnProperty('col_name') ? value['col_name'] : '',
                interval: value===null ? 1 : value.hasOwnProperty('interval') ? value['interval'] : 1,
                unit: value===null || !value['unit'] ? 'days' : value.hasOwnProperty('unit') ? value['unit'] : 'days',
                untilUnit: value===null || !value['untilUnit'] ? 'days' : value.hasOwnProperty('untilUnit') ? value['untilUnit'] : 'days',
                untilPeriod: value===null ? null : value.hasOwnProperty('untilPeriod') ? value['untilPeriod'] : null,
                untilType: value===null || !value['untilType'] ? 2 : value.hasOwnProperty('untilType') ? value['untilType'] : 2,
                untilManualPeriod: value===null ? null : value.hasOwnProperty('untilManualPeriod') ? value['untilManualPeriod'] : null,
            },
            emailBlock: {
                subject: value===null ? '' : value.hasOwnProperty('subject') ? value['subject'] : '',
                message: value===null ? '' : value.hasOwnProperty('message') ? value['message'] : '',
                templateId: value===null ? '' : value.hasOwnProperty('templateId') ? value['templateId']  : '',
                toEmailTag: value===null ? '' : value.hasOwnProperty('toEmailTag') ? value['toEmailTag']  : '',
                ccEmailTag: value===null ? '' : value.hasOwnProperty('ccEmailTag') ? value['ccEmailTag']  : '',
                bccEmailTag: value===null ? '' : value.hasOwnProperty('bccEmailTag') ? value['bccEmailTag']  : '',
                fromEmail: value===null ? '' : value.hasOwnProperty('fromEmail') ? value['fromEmail'] : [],
                //isRequiredEndBlock: false
            },
            officeTaskBlock: {
                assignTo: value===null ? '' : value.hasOwnProperty('assignTo') ? value['assignTo'] : '',
                assignToDisplay: value===null ? '' : value.hasOwnProperty('assignToDisplay') ? value['assignToDisplay'] : '',
                setAssignedTo: value===null ? '' : value.hasOwnProperty('setAssignedTo') ? value['setAssignedTo'] : '',
                message: value===null ? '' : value.hasOwnProperty('message') ? value['message'] : '',
                textMessage: value===null ? '' : value.hasOwnProperty('textMessage') ? value['textMessage'] : '',
                messageDelta: value===null ? '' : value.hasOwnProperty('messageDelta') ? value['messageDelta'] : '',
                tagsDisplay: value===null ? [] : value.hasOwnProperty('tagsDisplay') ? value['tagsDisplay'] : [],
            },
            addjobBlock: {
                jobDescriptionId: value===null ? '' : value.hasOwnProperty('jobDescriptionId') ? value['jobDescriptionId'] : null,
                jobDescriptionDisplay: value===null ? '' : value.hasOwnProperty('jobDescriptionDisplay') ? value['jobDescriptionDisplay'] : '',
            },
            chatmessageBlock: {
                textMessage: value===null ? '' : value.hasOwnProperty('textMessage') ? value['textMessage'] : '',
                messageDelta: value===null ? '' : value.hasOwnProperty('messageDelta') ? value['messageDelta'] : '',
                channelName: value===null ? '' : value.hasOwnProperty('channelName') ? value['channelName'] : '',
            },
            addDairyBlock: {
                eventType: '',
                eventStartDate: '',
                eventTime: '',
                assignEngg: ''
            },
            smsBlock: {
                message: value===null ? '' : value.hasOwnProperty('message') ? value['message'] : '',
                title: value===null ? '' : value.hasOwnProperty('title') ? value['title'] : '',
                templateId: value===null ? '' : value.hasOwnProperty('templateId') ? value['templateId']  : '',
                toSmsTag: value===null ? '' : value.hasOwnProperty('toSmsTag') ? value['toSmsTag']  : '',
            },
            notificationBlock: {
                roles: value===null ? [] : value.hasOwnProperty('roles') ? value['roles'] : [],
                rolesDisplay: value===null ? [] : value.hasOwnProperty('rolesDisplay') ? value['rolesDisplay'] : [],
                assignTo: value===null ? '' : value.hasOwnProperty('assignTo') ? value['assignTo'] : '',
                assignToDisplay: value===null ? '' : value.hasOwnProperty('assignToDisplay') ? value['assignToDisplay'] : '',
                message: value===null ? '' : value.hasOwnProperty('message') ? value['message'] : '',
                textMessage: value===null ? '' : value.hasOwnProperty('textMessage') ? value['textMessage'] : '',
                messageDelta: value===null ? '' : value.hasOwnProperty('messageDelta') ? value['messageDelta'] : '',
                tagsDisplay: value===null ? [] : value.hasOwnProperty('tagsDisplay') ? value['tagsDisplay'] : [],
            },
            setcustomfieldvalueBlock: {
                col_name: value===null ? '' : value.hasOwnProperty('col_name') ? value['col_name'] : '',
                templateId: value===null ? '' : value.hasOwnProperty('templateId') ? value['templateId'] : '',
                value: value===null ? '' : value.hasOwnProperty('value') ? value['value'] : '',
                displayColumnName: value===null ? '' : value.hasOwnProperty('displayColumnName') ? value['displayColumnName'] : '',
                textField: value===null ? '' : value.hasOwnProperty('textField') ? value['textField'] : '',
            },
            scheduledActivityBlock: {
                assigneeIds: value === null ? [] : value.hasOwnProperty('assigneeIds') ? value['assigneeIds'] : [],
                unit: value===null || !value['unit'] ? 'days' : value.hasOwnProperty('unit') ? value['unit'] : 'days',
                notes: value===null ? '' : value.hasOwnProperty('notes') ? value['notes'] : '',
                description: value===null ? '' : value.hasOwnProperty('description') ? value['description'] : '',
                activityType: value === null ? '' : value.hasOwnProperty('activityType') ? value['activityType'] : '',
                scheduleInterval: value === null ? 0 : value.hasOwnProperty('scheduleInterval') ? value['scheduleInterval'] : 0,
                scheduleTime: value === null ? '' : value.hasOwnProperty('scheduleTime') ? value['scheduleTime'] : '',
            }
        };
        return config.hasOwnProperty(blockName) ? config[blockName] : null;
    }

    getCustomFields() {
        if(!this.activeMainObjects.hasOwnProperty('custom_fields')) {
            return [];
        }
        return this.activeMainObjects['custom_fields'];
    }

    getFields() {
        let fields=[];
        _.forEach(this.activeMainObjects['fields'], (field:any) => {
            fields.push({
                id:field['col_name'],
                text: field.text,
                type: field.type,
                fieldType: field.hasOwnProperty('fieldType') ? field['fieldType'] : null,
                pageType: field.hasOwnProperty('pageType') ? field['pageType'] : null
            });
        });
        return fields;
    }

    isValid() {
        let isValid = [];
        if(this.selectedBlock === 'conditionalBlock') {
            isValid.push(this.selectedBlockModel.model['col_name'] === '' ? false : true);
        }
        else if(this.selectedBlock === 'delayBlock') {
            isValid.push(this.selectedBlockModel.model['interval'] === '' ? false : true);
        }
        else if(this.selectedBlock === 'emailBlock') {
            isValid.push(true);
        }else if(this.selectedBlock === 'smsBlock') {
            isValid.push(true);
        }
        else {
            isValid.push(false);
        }

        if(this.isConditional) {
            let valid = this.selectedBlockModel.condition['operator'] != ''
                && this.selectedBlockModel.condition['value'] != '';
            isValid.push(valid);
        }

        //console.log('isvalid', isValid);

        if(_.contains(isValid, false)) {
            return false;
        }
        else {
            return isValid.length === 0 ? false : true;
        }

    }

    ngOnDestroy(): void {
        if(this.subscription) {
            this.subscription.unsubscribe();
            this.showTaskBlock = true;
        }
    }
}
