<div class="cs-d-flex flex-column cs-pos-relative cs-divider slim-scroll thin">
    <div class="pos-abt-full bg-white cs-border-right-1">

        <div class="pos-abt cs-header-divider filter-comp-container">
            <app-filter class="workflow-filter-margin"
                [availableFields]="filterList"
                [appliedFilters]="appliedViewsFilter"
                (applyFilter)="addToAppliedFilters($event)"
                (deleteFilter)="removeFilter($event)"
                [widthChangeEvent]="widthChangeEventSubject.asObservable()"
                [screenType]="'workflow'" >
            </app-filter>
        </div>

        <div class="cs-content-divider pos-abt overflow-auto" #dagItemsContainer>
            <div class="wrap-container cs-d-flex flex-column">

                <ng-container *ngIf="loading">   <!--  *ngIf="loading"-->
                    <div class="row-item cursor-pointer" *ngFor="let line of [1,2,3,5,6,7,8,9,10]">
                        <div class="cs-d-flex flex-column dr-list-item">
                            <div class="text-layout animated-background small mb-05"  style="width:180px"></div>
                            <div class="text-layout animated-background long"></div>
                            <div class="text-layout animated-background small mt-05" style="width:80px"></div>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="!hasResult  && !loading">  <!--*ngIf="!dagrunsItems.length && !loading"-->
                    <div class="no-results-wrap">
                        <div class="warning-icon-wrap with-info-icon">
                            <span class="ss-info icon fix-left">&nbsp;</span>
                        </div>
                        <div class="warning-message">No records found</div>
                    </div>
                </ng-container>
                <ng-container *ngIf="results">
                    <div class="row-item cursor-pointer" [ngClass]="{'cursor-pointer ': cursor === 'pointer', 'cursor-not-allowed': cursor === 'not-allowed', 'active': activeCursor === 'pointer' && result.id===selectedItem.id}" *ngFor="let result of results" (click)="auditHistoryHandler(result)" (mouseover)="onMouseover(result)">
                        <div *ngIf="result.eventType != 'update_revision'" class="cs-d-flex flex-column dr-list-item" > <!--*ngIf=trigger -->
                            <div class="fs-bold row-item-title">{{result.auditChanges.title}}:</div>
                            <div class="">{{result.auditChanges.subTitle}}</div>
                            <div *ngFor="let data of result.auditChanges.changes | keyvalue" >
                                <div *ngIf="result.eventType == 'add_workflow' || result.eventType == 'edit_workflow'">
                                    <ul class="list-position" *ngIf="result.eventType == 'add_workflow'">
                                        <li><b>{{data.key}}</b> was added as <b>{{data.value.newData}}</b></li>
                                    </ul>
                                    <ul class="list-position" *ngIf="result.eventType == 'edit_workflow'">
                                        <li *ngIf = "data.value.oldData != data.value.newData"><b>{{data.key}}</b> was edited from <b>{{data.value.oldData}}</b> as <b>{{data.value.newData}}</b></li>
                                    </ul>
                                </div>
                                <div *ngIf="result.eventType == 'add_task'">
                                    <div *ngIf="result.taskId == 'cs_initializer'">
                                        <ul class="list-position">
                                            <li><b>{{data.key}}</b> was added as <b>{{ objectField | filterFieldValuePipe : data.key : data.value.newData}}</b></li>
                                        </ul>
                                    </div>
                                </div>
                                <div *ngIf="result.eventType == 'edit_task'">
                                    <div *ngIf="result.taskId == 'cs_initializer'">
                                        <ul class="list-position">
                                            <li *ngIf="data.value.action == 'add'"><b>{{data.key}}</b> was added as <b>{{ objectField | filterFieldValuePipe : data.key : data.value.newData}}</b></li>
                                            <li *ngIf="data.value.action == 'edit'"><b>{{data.key}}</b> was edited from <b>{{ objectField | filterFieldValuePipe : data.key : data.value.oldData}}</b> to <b>{{ objectField | filterFieldValuePipe : data.key : data.value.newData}}</b></li>
                                            <li *ngIf="data.value.action == 'delete'"><b>{{data.key}}</b> was deleted </li>
                                        </ul>
                                    </div>
                                    <div *ngIf="result.modifiedTaskId == 'emailBlock' || result.modifiedTaskId == 'smsBlock'">
                                        <ul class="list-position">
                                                <li *ngIf="data.key == 'templateName'">The email template was changed from <b>{{data.value.old}}</b> to <b>{{data.value.new}}</b> </li>
                                        </ul>
                                        <div *ngIf="data.key == 'toSmsTag'">
                                            <ul class="list-position" *ngFor="let toSms of data.value.add | keyvalue">
<!--                                                <li><b>{{toSms.key}}</b> was added in the To address</li>-->
                                                <li><b>{{toSms.value.displayName}}</b> was added in the To address</li>
                                            </ul>
                                            <ul class="list-position" *ngFor="let toSms of data.value.remove | keyvalue">
<!--                                                <li><b>{{toSms.key}}</b> was removed from the To address</li>-->
                                                <li><b>{{toSms.value.displayName}}</b> was removed from the To address</li>
                                            </ul>
                                        </div>
                                        <div *ngIf="data.key == 'toEmailTag'">
                                            <ul class="list-position" *ngFor="let toEmail of data.value.add | keyvalue">
                                                <li ><b>{{toEmail.value.displayName}}</b> was added in the To address</li>
                                            </ul>
                                            <ul class="list-position" *ngFor="let toEmail of data.value.remove | keyvalue">
                                                <li><b>{{toEmail.value.displayName}}</b> was removed from the To address</li>
                                            </ul>
                                        </div>
                                        <div *ngIf="data.key == 'ccEmailTag'">
                                            <ul class="list-position" *ngFor="let ccEmail of data.value.add | keyvalue">
                                                <li><b>{{ccEmail.value.displayName}}</b> was added in the CC address</li>
                                            </ul>
                                            <ul class="list-position" *ngFor="let ccEmail of data.value.remove | keyvalue">
                                                <li><b>{{ccEmail.value.displayName}}</b> was removed from the CC address</li>
                                            </ul>
                                        </div>
                                        <div *ngIf="data.key == 'bccEmailTag'">
                                            <ul class="list-position" *ngFor="let bccEmail of data.value.add | keyvalue">
                                                <li><b>{{bccEmail.value.displayName}}</b> was added in the Bcc address</li>
                                            </ul>
                                            <ul class="list-position" *ngFor="let bccEmail of data.value.remove | keyvalue">
                                                <li><b>{{bccEmail.value.displayName}}</b> was removed from Bcc To address</li>
                                            </ul>
                                        </div>

                                    </div>
                                    <div *ngIf="result.modifiedTaskId == 'delayBlock'">
                                        <ul class="list-position">
                                            <li>Delay value was changed from <b>{{data.value.old}}</b> to <b>{{data.value.new}}</b></li>
                                        </ul>
                                    </div>
                                    <div *ngIf="result.modifiedTaskId == 'conditionalDelayBlock'">
                                        <ul class="list-position">
                                            <li>Conditional delay value was changed from <b>{{data.value.old}}</b> to <b>{{data.value.new}}</b></li>
                                        </ul>
                                    </div>
                                    <div *ngIf="result.modifiedTaskId == 'officeTaskBlock'">
                                        <ul class="list-position">
                                            <li><b>Message</b> value was changed from <b>{{data.value.old}}</b></li>
                                        </ul>
                                    </div>
                                    <div *ngIf="result.modifiedTaskId == 'notificationBlock'">
                                        <div *ngIf="data.key == 'message'">
                                            <ul class="list-position">
                                                <li><b>Message</b> value was changed from <b>{{data.value.old}}</b></li>
                                            </ul>
                                        </div>
                                        <div *ngIf="data.key == 'roles'">
                                            <ul class="list-position" *ngFor="let addedRole of data.value.add | keyvalue">
                                                <li><b>{{addedRole.value}}</b> was added in the user role</li>
                                            </ul>
                                            <ul class="list-position" *ngFor="let removedRole of data.value.remove | keyvalue">
                                                <li><b>{{removedRole.value}}</b> was removed from the user role</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div *ngIf="result.modifiedTaskId == 'conditionalBlock' || result.modifiedTaskId == 'untilConditionBlock'">
                                        <ul class="list-position">
                                            <li>Condition was changed from <b>{{data.value.old}}</b> to <b>{{data.value.new}}</b></li>
                                        </ul>
                                    </div>
                                    <div *ngIf="result.modifiedTaskId == 'addjobBlock'">
                                        <ul class="list-position">
                                            <li>Job description was changed from <b>{{data.value.old}}</b> to <b>{{data.value.new}}</b></li>
                                        </ul>
                                    </div>
                                    <div *ngIf="result.modifiedTaskId == 'setcustomfieldvalueBlock'">
                                        <ul class="list-position">
                                            <li>Custom field was changed from <b>{{data.value.old}}</b> to <b>{{data.value.new}}</b></li>
                                        </ul>
                                    </div>
                                    <div *ngIf="result.modifiedTaskId == 'scheduledActivityBlock'">
                                        <ul class="list-position">
                                            <li *ngIf="data.key == 'description'"><b>Description</b> was changed from <b>{{data.value.old}}</b> to <b>{{data.value.new}}</b></li>
                                            <li *ngIf="data.key == 'notes'"><b>Notes</b> was changed from <b>{{data.value.old}}</b> to <b>{{data.value.new}}</b></li>
                                            <li *ngIf="data.key == 'activityType'"><b>Activity type</b> was changed from <b>{{data.value.old}}</b> to <b>{{data.value.new}}</b></li>
                                            <li *ngIf="data.key == 'dueDate'"><b>Due Date</b> was changed from <b>{{data.value.old}}</b> to <b>{{data.value.new}}</b></li>
                                        </ul>
                                        <div *ngIf="data.key == 'assignTo'">
                                            <ul class="list-position" *ngFor="let assignTo of data.value.add | keyvalue">
                                                <li><b>{{assignTo.value}}</b> was added in the assignTo </li>
                                            </ul>
                                            <ul class="list-position" *ngFor="let assignTo of data.value.remove | keyvalue">
                                                <li><b>{{assignTo.value}}</b> was removed from the assignTo</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="result.eventType == 'add_criteria' || result.eventType == 'delete_criteria'">
                                    <div *ngIf="result.modifiedTaskId == 'exitCriteria'">
                                        <div>
                                            <ul class="list-position" *ngFor="let addCriteria of data.value.add | keyvalue">
                                                <li *ngIf="addCriteria.key"><b>{{addCriteria.value}}</b> event was added in the exit criteria </li>
                                            </ul>
                                            <ul class="list-position" *ngFor="let removedCriteria of data.value.remove | keyvalue">
                                                <li *ngIf="removedCriteria.key"><b>{{removedCriteria.value}}</b> event was removed from the exit criteria</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="result.actionType == 'Activate' || result.actionType == 'Deactivate' || result.actionType == 'Enable' || result.actionType == 'Disable' ||  result.actionType == 'Delete' ; else elsePart" class="user-margin">{{result.actionType+'d'}} by:</div>
                            <ng-template #elsePart class="user-margin">{{result.actionType+'ed'}} by:</ng-template>
                            <div class="scheduled-time-width img-text-valign ">
                                <img *ngIf="result?.createdByInfo?.profile != null && result?.createdByInfo?.profile != ''; else defaultProfile"
                                     src="{{ result?.createdByInfo?.profile }}" alt="Avatar"
                                     class="profile-radius pointer-cursor img-width img-style" placement="bottom"
                                     tooltip="{{result.createdByInfo.name}}">
                                <ng-template #defaultProfile>
                                    <img src="images/profile_avatar_small.jpg" alt="Avatar"
                                         class="profile-radius pointer-cursor img-width img-style" placement="bottom"
                                         tooltip="{{result.createdByInfo.name}}">
                                </ng-template>
                                <span class="pad-5">{{result.dateToDisplay}}</span>
                            </div>
                        </div>
<!--                        <div *ngIf="result.eventType == 'update_revision'" style="display: flex">-->
<!--                            <p>{{result.auditChanges.subTitle}}</p>-->
<!--                            <div style="display: flex">-->
<!--                                <i class="fas fa-solid fa-flag" aria-hidden="true"></i>-->
<!--                            </div>-->
<!--                        </div>-->
                    </div>
                </ng-container>
            </div>
        </div>
        <div class="pos-abt cs-footer-divider" *ngIf="totalEntriesCount > 0">
            <div class="cs-d-flex justify-content-center align-items-center h-100">
                <div class="ml-10">
                    <span>{{currentPage == 1 ? currentPage : (pageLimit * currentPage + 1) - pageLimit }} to {{ (pageLimit * currentPage) >=  totalEntriesCount ? totalEntriesCount : (pageLimit * currentPage)}} of {{ totalEntriesCount }} entries</span>
                </div>
                <cspagination-migrated class="flex-grow-1"
                                       (fetchData)="fetchAuditRecord($event)"
                                       [totalEntries]="totalEntriesCount"
                                       [currentPage]="currentPage"
                                       [limit]="pageLimit">
                </cspagination-migrated>
            </div>

        </div>
    </div>
</div>
