import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filterFieldValuePipe'
})
export class FilterFieldValuePipe implements PipeTransform{
    transform(value: any, fieldName: string, key: number): any {
        let fieldData = value.fields.find(field => field.col_name == fieldName);
        let fieldValue: { text: any; };
        if(fieldData.input == "select"){
            fieldValue = fieldData.options.find(option => option.id == key)
            return fieldValue.text;
        }else{
            return key;
        }
    }
}
