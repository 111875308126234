import * as tslib_1 from "tslib";
import { EventEmitter, OnInit, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { WorkflowhttpService } from "../../services/workflowhttp.service";
import { from } from "rxjs";
import { map } from "rxjs/operators";
var format = function (item) {
    return item.text;
};
var ɵ0 = format;
var removeTags = function (str) {
    if ((str === null) || (str === ''))
        return '';
    else
        str = str.toString();
    return str.replace(/(<([^>]+)>)/ig, '');
};
var ɵ1 = removeTags;
var EmailBlockComponent = /** @class */ (function () {
    function EmailBlockComponent(cdr, workflowService, confirmationBoxHelper, ajsScope) {
        this.cdr = cdr;
        this.workflowService = workflowService;
        this.confirmationBoxHelper = confirmationBoxHelper;
        this.ajsScope = ajsScope;
        this.emailTemplateList = [];
        this.emailTemplates = [];
        this.loading = true;
        this.isSmtp = false;
        this.hidden = true;
        this.ccShow = false;
        this.bccShow = false;
        this.messages = {};
        this.isEdit = false;
        this.isConditional = false;
        this.activeField = {};
        this.isMarketPlaceView = false;
        this.workflowType = 'Custom';
        this.diagramOptions = {};
        this.isReadOnlyMode = false;
        this.outputModel = new EventEmitter();
        this.close = new EventEmitter();
        this.userList = [];
        this.allEmailTags = [];
        this.toEmailDetails = [];
        this.emailAddress = [];
        this.data = {};
        this.userTags = [];
        this.previewSubject = '';
        this.previewMessage = '';
        this.saving = false;
        this.key = ['toEmailTag', 'ccEmailTag', 'bccEmailTag'];
        this.dataFunction = function (item) { return item.text; };
        this.communicationTags = {
            'Custom': [
                { 'id': 't_0', 'text': '[customer_email]', 'name': 'TAGS' },
                { 'id': 't_3', 'text': '[job_contact_email]', 'name': 'TAGS' },
                { 'id': 't_4', 'text': '[invoice_address_email]', 'name': 'TAGS' },
                { 'id': 't_2', 'text': '[job_address_primary_contact_email]', 'name': 'TAGS' },
            ],
            'Sequence': [
                { 'id': 't_0', 'text': '[sequence_contact_email]', 'name': 'TAGS' },
            ]
        };
        this.tagTypes = [];
        this.emailScreenLabel = 'email.templates';
    }
    EmailBlockComponent.prototype.getData = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.workflowService.getCommunicationTemplateConfig()
                .toPromise()
                .then(function (resp) {
                var data = resp['data'];
                if (data) {
                    _this.isSmtp = data.smtpDetails.smtp;
                    _this.fromEmail = data.smtpDetails.smtpList;
                    _this.userList = data.users;
                    _this.userList.forEach(function (line, index) {
                        _this.userTags.push({
                            id: parseInt(line.id),
                            text: line.text,
                            name: 'USERS'
                        });
                        _this.allEmailTags.push(line);
                    });
                    _this.tagTypes.forEach(function (line, index) {
                        _this.allEmailTags.push(line);
                    });
                    if (_this.workflowType != 'Sequence' && _this.inputModel.model.toEmailTag) {
                        _this.key.forEach(function (emailType) {
                            var dropDownData = _this.inputModel.model[emailType];
                            if (dropDownData) {
                                _this.inputModel.model[emailType] = [];
                                dropDownData.forEach(function (value, key) {
                                    var selectedItem = _this.allEmailTags.find(function (items) { return items.id == value.value; });
                                    if (_this.allEmailTags.indexOf(value.value) === -1) {
                                        if (!selectedItem) {
                                            var data_1 = { id: 'e_' + key, text: value.displayName };
                                            _this.allEmailTags.push(data_1);
                                            _this.emailAddress.push(data_1);
                                            _this.userTags.push(data_1);
                                            _this.inputModel.model[emailType].push('e_' + key);
                                        }
                                        else {
                                            _this.inputModel.model[emailType].push(value.value);
                                        }
                                    }
                                });
                            }
                        });
                    }
                    var createSearchChoiceFn = function (term, data) {
                        var exists = false;
                        data.forEach(function (idText) {
                            if (idText.text == term)
                                exists = true;
                        });
                        var regex = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$', 'g');
                        return term.indexOf(' ') >= 0 || exists || !term.match(regex) ? null :
                            {
                                id: term + " (Create New)",
                                text: term + " (Create New)"
                            };
                    };
                    _this.select2LabelConfig = {
                        width: '100%',
                        data: _this.groupByTags(true),
                        tags: true,
                        createSearchChoice: createSearchChoiceFn,
                        formatSelection: format,
                        formatResult: format,
                        multiple: true
                    };
                    _this.select2LabelConfig2 = {
                        width: '100%',
                        data: _this.groupByTags(false),
                        tags: true,
                        createSearchChoice: createSearchChoiceFn,
                        formatSelection: format,
                        formatResult: format,
                        multiple: true
                    };
                    resolve(true);
                }
            }, function (err) {
                reject(err);
            });
        });
    };
    EmailBlockComponent.prototype.ngOnInit = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.oldModel = JSON.stringify(Object.assign({}, this.inputModel));
                        if (this.diagramOptions.objectName == 'estimate') {
                            this.communicationTags.Custom.push({ 'id': 't_7', 'text': '[estimate_contact_email]', 'name': 'TAGS' });
                        }
                        if (this.diagramOptions.objectName == 'opportunity' || this.diagramOptions.objectName == 'proposal') {
                            this.communicationTags.Custom.push({ 'id': 't_8', 'text': '[opportunity_site_contact_email]', 'name': 'TAGS' });
                        }
                        this.tagTypes = this.communicationTags[this.workflowType];
                        this.showDeleteBtn = this.isMarketPlaceView ? false : this.isEdit;
                        if (this.workflowType == 'Sequence' && this.diagramOptions.mode == 'sequence_config') {
                            this.showDeleteBtn = false;
                        }
                        if (!this.isEdit) {
                            this.previewSubject = this.inputModel.model.subject;
                            this.previewMessage = this.inputModel.model.message;
                            this.activeTemplateTitle = 'Please choose';
                        }
                        //console.log('inputModel.model.templateId', this.inputModel.model.templateId);
                        this.loading = true;
                        return [4 /*yield*/, this.getData()];
                    case 1:
                        _a.sent();
                        if (!this.inputModel.model.templateId) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getTemplateContent(this.inputModel.model.templateId)];
                    case 2:
                        _a.sent();
                        this.subject = "<p>" + this.previewSubject + "</p>";
                        this.message = this.previewMessage;
                        _a.label = 3;
                    case 3:
                        this.hidden = (this.inputModel.model.subject == '') ? true : false;
                        this.loading = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    EmailBlockComponent.prototype.getTemplateContent = function (templateId) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                return [2 /*return*/, new Promise(function (resolve, reject) {
                        _this.workflowService.getTemplateById(templateId)
                            .toPromise()
                            .then(function (resp) {
                            var data = resp['data'];
                            if (data) {
                                _this.previewSubject = data['subject'];
                                _this.previewMessage = data['message'];
                                _this.activeTemplateTitle = data['title'];
                            }
                            resolve(resp);
                        }, function (err) {
                            reject(err);
                        });
                    })];
            });
        });
    };
    EmailBlockComponent.prototype.setTemplateDetails = function (event, type) {
        //console.log(event, type);
        this.inputModel.model.templateId = event['templateId'];
        this.previewSubject = event['subject'];
        this.previewMessage = event['message'];
        this.subject = "<p>" + this.previewSubject + "</p>";
        this.message = this.previewMessage;
    };
    EmailBlockComponent.prototype.ngAfterViewInit = function () {
    };
    EmailBlockComponent.prototype.groupByTags = function (includeTagTypes) {
        if (includeTagTypes === void 0) { includeTagTypes = false; }
        var allEmailTag = [];
        var newItems = [];
        newItems.push(this.userTags);
        if (includeTagTypes) {
            newItems.push(this.tagTypes);
        }
        var source = from(newItems);
        var tags = source.pipe(map(function (groupItems) {
            var _item = { text: '', children: [] };
            var cnt = 0;
            groupItems.forEach(function (group) {
                if (cnt === 0) {
                    _item.text = group.name;
                }
                _item.children.push({
                    id: group.id,
                    text: group.text
                });
                cnt++;
            });
            return _item;
        }));
        var subscribe = tags.subscribe(function (val) { return allEmailTag.push(val); });
        return allEmailTag;
    };
    EmailBlockComponent.prototype.addValue = function () {
        var _this = this;
        this.saving = true;
        this.inputModel.model.templateId = this.inputModel.model.templateId.toString();
        //let selectedItem = this.emailTemplates.find((item:any) => item.id == this.inputModel.model.templateId);
        this.inputModel.model.subject = removeTags(this.previewSubject);
        this.inputModel.model.message = this.previewMessage;
        this.toEmailDetails = this.workflowType == 'Sequence' ? ['t_0'] : this.inputModel.model;
        var regex = new RegExp('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$', 'g');
        var that = this;
        this.key.forEach(function (emailType) {
            var emailTagType = _this.toEmailDetails[emailType];
            _this.inputModel.model[emailType] = [];
            _this.data = {};
            if (emailTagType) {
                emailTagType.forEach(function (emailData, key) {
                    var selectedUser = _this.userList.find(function (items) { return items.id == emailData; });
                    var selectedTag = _this.tagTypes.find(function (items) { return items.id == emailData; });
                    var selectedEmail = _this.emailAddress.find(function (items) { return items.id == emailData; });
                    if ((!that.inputModel.model.toEmailTag.some(function (item) { return item.value === emailData; }) || emailData !== 'toEmailTag') && selectedUser) {
                        _this.data = { type: "user", value: emailData, displayName: selectedUser.text };
                    }
                    else if ((!that.inputModel.model.toEmailTag.some(function (item) { return item.value === emailData; }) || emailData !== 'toEmailTag') && selectedTag) {
                        _this.data = { type: "tag", value: emailData, displayName: selectedTag.text };
                    }
                    else if ((!that.inputModel.model.toEmailTag.some(function (item) { return item.value === emailData; }) || emailData !== 'toEmailTag') && (selectedEmail || emailData.match(regex))) {
                        emailData = (selectedEmail) ? selectedEmail.text : emailData;
                        _this.data = { type: "normal", value: emailData, displayName: emailData };
                    }
                    if (_this.data) {
                        _this.inputModel.model[emailType].push(_this.data);
                    }
                });
            }
            _this.inputModel.model.templateId = Number(_this.inputModel.model.templateId);
            _this.newModel = JSON.stringify(Object.assign({}, _this.inputModel));
            if (_.isEqual(_this.oldModel, _this.newModel)) {
                _this.workflowService.triggerEvent({ 'isUpdated': false, 'isEdit': _this.isEdit, 'action': 'valueChanged' });
            }
            else {
                _this.workflowService.triggerEvent({ 'isUpdated': true, 'isEdit': _this.isEdit, 'action': 'valueChanged' });
            }
        });
        setTimeout(function () {
            _this.outputModel.emit(_this.inputModel);
        }, 0);
    };
    EmailBlockComponent.prototype.closeModal = function () {
        this.close.emit(this.isEdit);
    };
    EmailBlockComponent.prototype.validateItems = function () {
        if (this.workflowType == 'Sequence') {
            return !this.inputModel.model.templateId;
        }
        else {
            if (!this.inputModel.model.templateId || !this.inputModel.model.toEmailTag || (this.isSmtp && !this.inputModel.model.fromEmail)) {
                return true;
            }
        }
        return this.isConditional ? this.inputModel.condition.operator === '' || this.inputModel.condition.value === '' : false;
    };
    EmailBlockComponent.prototype.uploadTemplateDetails = function (id) {
        this.hidden = false;
        var selectedItem = this.emailTemplates.find(function (item) { return item.id == id; });
        this.previewSubject = this.inputModel.model.subject = selectedItem.subject;
        this.previewMessage = this.inputModel.model.message = selectedItem.message;
        this.subject = "<p>" + this.previewSubject + "</p>";
        this.message = this.previewMessage;
        return true;
    };
    EmailBlockComponent.prototype.selectedEmailAddress = function (event, data, type) {
        if (event === void 0) { event = null; }
        if (data === void 0) { data = null; }
        var lab = event[event.length - 1];
        if (lab && lab.includes(" (Create New)")) {
            lab = lab.replace(" (Create New)", '');
            var valueArray = $("#additional-email").select2("data");
            if (valueArray) {
                valueArray[valueArray.length - 1]['id'] = lab;
                valueArray[valueArray.length - 1]['text'] = lab;
            }
            $("#additional-email").select2('data', valueArray);
            event[event.length - 1] = lab;
        }
        if (type == 'to') {
            this.inputModel.model.toEmailTag = event;
        }
        else if (type == 'cc') {
            this.inputModel.model.ccEmailTag = event;
        }
        else {
            this.inputModel.model.bccEmailTag = event;
        }
    };
    EmailBlockComponent.prototype.removeCell = function () {
        var _this = this;
        this.confirmationBoxHelper
            .getConfirmation(this.deleteMessage, this.ajsScope)
            .then(function () {
            _this.close.emit('remove');
        });
    };
    EmailBlockComponent.prototype.showAdditioalInput = function (type) {
        if (type == 'cc') {
            this.ccShow = true;
        }
        if (type == 'bcc') {
            this.bccShow = true;
        }
    };
    return EmailBlockComponent;
}());
export { EmailBlockComponent };
export { ɵ0, ɵ1 };
