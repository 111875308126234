<div class="modal-header">
    <h4 class="modal-title pull-left">Office task settings</h4>
</div>
<div class="modal-body">
    <div class="loading-wrap" *ngIf="isLoading">
        <span class="circle1"></span>
        <span class="circle2"></span>
        <span class="circle3"></span>
    </div>
    <div *ngIf="status && isEdit && !isLoading" class="wfe-block-notification-bar">
        <div cs-information-bar [icon]="'ss-info'" [message]="warningMessage"></div>
    </div>
    <ng-container >
        <app-condition-edge *ngIf="isConditional && !isLoading"
            [inputModel]="inputModel"
            [isMarketPlaceView]="isMarketPlaceView"
            [operators]="operators"
            [isReadOnlyMode]="isReadOnlyMode"
            [activeField] = "activeField">
        </app-condition-edge>
        <div class="item clearfix p-15" [hidden]="isLoading">
            <quill-editor
                    [modules]="modules"
                    (onContentChanged)="onChange($event)"
                    placeholder="Assign the task using '@', tag the task using '#'"> </quill-editor>
        </div>
    </ng-container>
</div>
<div class="modal-footer" [hidden]="isLoading">
    <a href="javascript:void(0)" class="btn" (click)="closeModal()">Close</a>
    <button [hidden]="!showDeleteBtn" class="btn btn-danger btn-medium float-left" (click)="removeCell()" type="button">Delete </button>
    <button class="btn btn-primary" (click)="addValue()" [disabled]="disableSaveBtn || saving || validateItems()">
        <span *ngIf="!saving" translate>Save</span>
        <span *ngIf="saving" translate>Saving</span>
    </button>
</div>
