<form *ngIf="listForm" [formGroup]="listForm" (submit)="createList()">
	<!-- Header -->
	<div class="modal-header">
		<h4 class="modal-title pull-left" translate>{{title}}</h4>
	</div>
	<!-- Content -->
	<div class="modal-body">
		<div class="help_section">
			<div class="help-text" translate>List.create.model.help.text
			</div>
		</div>
		<div class="body-content-section">
            <div *ngIf="loading">
                <div class="loading-wrap">
                    <span class="circle1"></span>
                    <span class="circle2"></span>
                    <span class="circle3"></span>
                    <p class="loading-text">
                        Loading...
                    </p>
                </div>
            </div>
			<div class="row-fluid" [hidden]="loading">
				<div class="span12">
					<!-- List name -->
					<div class="control-group clearfix">
						<label class="control-label required_field" translate>List.name</label>
						<div class="controls select2-form-container">
							<input type="text" class="control-input" formControlName="listName">
						</div>
					</div>
					<!-- Suppliers -->
					<ng-container>
						<div class="control-group clearfix">
							<label class="control-label" translate>Suppliers</label>
							<div class="controls select2-form-container">
                                <select #select2Skills csSelect2 class="full-width-select2 suppliers"
                                        [select2Config]="select2Config"
                                        [multiple]="true"
                                        (selected)="getselectedSuppliers($event)">
                                    <option value=""  [disabled]='true' translate>Please.Choose</option>
                                    <option *ngFor="let supplier of suppliers" value="{{supplier.id}}">{{supplier.name}}</option>
                                </select>
							</div>
						</div>
					</ng-container>
					<!-- Order frame -->
					<div class="control-group clearfix" *ngIf = "false" >
						<label class="control-label required_field" translate>Order.timeframe</label>
						<div class="controls select2-form-container">
							<select class="full-width-select2" class="full-width-select"
								formControlName="orderTimeFrame">
								<option value="" translate="">Please.Choose</option>
								<option *ngFor="let time of orderFrame" value="{{time.id}}">
									{{time.value}}</option>
							</select>
						</div>
					</div>
					<!-- Days to complete the order -->
					<div class="control-group clearfix" *ngIf="listForm.value.orderTimeFrame==2">
						<label class="control-label required_field" translate>Number.of.days</label>
						<div class="controls days-width">
							<input type="number" class="control-input" formControlName="expiry" (keypress)="validateNumber($event)">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- Footer -->
	<div class="modal-footer" [hidden]="loading">
		<a href="javascript:void(0)" class="btn" (click)="bsModalRef.hide()">Cancel</a>
		<button  class="btn btn-primary" [disabled]="listForm.invalid || saving || listForm.value.expiry <= 0 && listForm.value.orderTimeFrame == 2 || listForm.value.expiry == null && listForm.value.orderTimeFrame == 2">
			<span [hidden]="saving" translate>Save</span>
			<span [hidden]="!saving" translate>Saving</span>
		</button>
	</div>
</form>

<form *ngIf="!listForm">
    <!-- Header -->
    <div class="modal-header">
        <h4 class="modal-title pull-left" translate>{{title}}</h4>
    </div>
    <!-- Content -->
    <div class="modal-body">
        <div class="help_section help-text-height">
            <div class="help-text" translate>

            </div>
        </div>
        <div class="body-content-section">
            <div *ngIf="loading">
                <div class="loading-wrap">
                    <span class="circle1"></span>
                    <span class="circle2"></span>
                    <span class="circle3"></span>
                    <p class="loading-text">
                        Loading...
                    </p>
                </div>
            </div>
        </div>
    </div>
    <!-- Footer -->
    <div class="modal-footer">
        <a href="javascript:void(0)" class="btn" (click)="bsModalRef.hide()">Cancel</a>
    </div>
</form>
