/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../directives/material-directives/cs-information-bar/cs-information-bar.component.ngfactory";
import * as i2 from "../../../directives/material-directives/cs-information-bar/cs-information-bar.component";
import * as i3 from "../condition-edge/condition-edge.component.ngfactory";
import * as i4 from "../condition-edge/condition-edge.component";
import * as i5 from "@ngx-translate/core";
import * as i6 from "@angular/common";
import * as i7 from "../../../../../node_modules/ngx-quill/ngx-quill.ngfactory";
import * as i8 from "@angular/forms";
import * as i9 from "ngx-quill";
import * as i10 from "@angular/platform-browser";
import * as i11 from "./officetask-block.component";
import * as i12 from "../../services/workflowhttp.service";
import * as i13 from "../../services/workflow.common.service";
var styles_OfficetaskBlockComponent = [];
var RenderType_OfficetaskBlockComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_OfficetaskBlockComponent, data: {} });
export { RenderType_OfficetaskBlockComponent as RenderType_OfficetaskBlockComponent };
function View_OfficetaskBlockComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "loading-wrap"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 0, "span", [["class", "circle1"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 0, "span", [["class", "circle2"]], null, null, null, null, null)), (_l()(), i0.ɵeld(3, 0, null, null, 0, "span", [["class", "circle3"]], null, null, null, null, null))], null, null); }
function View_OfficetaskBlockComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "div", [["class", "wfe-block-notification-bar"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [["cs-information-bar", ""]], null, null, null, i1.View_CsInformationBarComponent_0, i1.RenderType_CsInformationBarComponent)), i0.ɵdid(2, 114688, null, 0, i2.CsInformationBarComponent, [], { message: [0, "message"], icon: [1, "icon"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.warningMessage; var currVal_1 = "ss-info"; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_OfficetaskBlockComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-condition-edge", [], null, null, null, i3.View_ConditionEdgeComponent_0, i3.RenderType_ConditionEdgeComponent)), i0.ɵdid(1, 114688, null, 0, i4.ConditionEdgeComponent, [], { activeField: [0, "activeField"], inputModel: [1, "inputModel"], operators: [2, "operators"], isMarketPlaceView: [3, "isMarketPlaceView"], isReadOnlyMode: [4, "isReadOnlyMode"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.activeField; var currVal_1 = _co.inputModel; var currVal_2 = _co.operators; var currVal_3 = _co.isMarketPlaceView; var currVal_4 = _co.isReadOnlyMode; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_OfficetaskBlockComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i0.ɵdid(1, 8536064, null, 0, i5.TranslateDirective, [i5.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(-1, null, ["Save"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
function View_OfficetaskBlockComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["translate", ""]], null, null, null, null, null)), i0.ɵdid(1, 8536064, null, 0, i5.TranslateDirective, [i5.TranslateService, i0.ElementRef, i0.ChangeDetectorRef], { translate: [0, "translate"] }, null), (_l()(), i0.ɵted(-1, null, ["Saving"]))], function (_ck, _v) { var currVal_0 = ""; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_OfficetaskBlockComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { editor: 0 }), (_l()(), i0.ɵeld(1, 0, null, null, 2, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h4", [["class", "modal-title pull-left"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Office task settings"])), (_l()(), i0.ɵeld(4, 0, null, null, 12, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OfficetaskBlockComponent_1)), i0.ɵdid(6, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OfficetaskBlockComponent_2)), i0.ɵdid(8, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(9, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OfficetaskBlockComponent_3)), i0.ɵdid(11, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵeld(12, 0, null, null, 4, "div", [["class", "item clearfix p-15"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵeld(13, 0, null, null, 3, "quill-editor", [["placeholder", "Assign the task using '@', tag the task using '#'"]], null, [[null, "onContentChanged"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onContentChanged" === en)) {
        var pd_0 = (_co.onChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_QuillEditorComponent_0, i7.RenderType_QuillEditorComponent)), i0.ɵprd(5120, null, i8.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i9.QuillEditorComponent]), i0.ɵprd(5120, null, i8.NG_VALIDATORS, function (p0_0) { return [p0_0]; }, [i9.QuillEditorComponent]), i0.ɵdid(16, 4898816, [[1, 4]], 0, i9.QuillEditorComponent, [i0.ElementRef, i10.DomSanitizer, i6.DOCUMENT, i0.PLATFORM_ID, i0.Renderer2, i0.NgZone, i9.QUILL_CONFIG_TOKEN], { modules: [0, "modules"], placeholder: [1, "placeholder"] }, { onContentChanged: "onContentChanged" }), (_l()(), i0.ɵeld(17, 0, null, null, 9, "div", [["class", "modal-footer"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵeld(18, 0, null, null, 1, "a", [["class", "btn"], ["href", "javascript:void(0)"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeModal() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Close"])), (_l()(), i0.ɵeld(20, 0, null, null, 1, "button", [["class", "btn btn-danger btn-medium float-left"], ["type", "button"]], [[8, "hidden", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeCell() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(-1, null, ["Delete "])), (_l()(), i0.ɵeld(22, 0, null, null, 4, "button", [["class", "btn btn-primary"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addValue() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OfficetaskBlockComponent_4)), i0.ɵdid(24, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OfficetaskBlockComponent_5)), i0.ɵdid(26, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 6, 0, currVal_0); var currVal_1 = ((_co.status && _co.isEdit) && !_co.isLoading); _ck(_v, 8, 0, currVal_1); var currVal_2 = (_co.isConditional && !_co.isLoading); _ck(_v, 11, 0, currVal_2); var currVal_4 = _co.modules; var currVal_5 = "Assign the task using '@', tag the task using '#'"; _ck(_v, 16, 0, currVal_4, currVal_5); var currVal_9 = !_co.saving; _ck(_v, 24, 0, currVal_9); var currVal_10 = _co.saving; _ck(_v, 26, 0, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.isLoading; _ck(_v, 12, 0, currVal_3); var currVal_6 = _co.isLoading; _ck(_v, 17, 0, currVal_6); var currVal_7 = !_co.showDeleteBtn; _ck(_v, 20, 0, currVal_7); var currVal_8 = ((_co.disableSaveBtn || _co.saving) || _co.validateItems()); _ck(_v, 22, 0, currVal_8); }); }
export function View_OfficetaskBlockComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-officetask-block", [], null, null, null, View_OfficetaskBlockComponent_0, RenderType_OfficetaskBlockComponent)), i0.ɵdid(1, 114688, null, 0, i11.OfficetaskBlockComponent, [i12.WorkflowhttpService, i13.WorkflowCommonService, "confirmationBoxHelper", "$scope"], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OfficetaskBlockComponentNgFactory = i0.ɵccf("app-officetask-block", i11.OfficetaskBlockComponent, View_OfficetaskBlockComponent_Host_0, { inputModel: "inputModel", activeField: "activeField", isConditional: "isConditional", isEdit: "isEdit", operators: "operators", isMarketPlaceView: "isMarketPlaceView", taggingList: "taggingList", mainObjectName: "mainObjectName", deleteMessage: "deleteMessage", warningMessage: "warningMessage", workflowType: "workflowType", diagramOptions: "diagramOptions", status: "status", isReadOnlyMode: "isReadOnlyMode" }, { outputModel: "outputModel", close: "close" }, []);
export { OfficetaskBlockComponentNgFactory as OfficetaskBlockComponentNgFactory };
