import { EventEmitter, OnInit } from '@angular/core';
import * as moment from 'moment';
import { WorkflowhttpService } from "@app/workflow-common/services/workflowhttp.service";
var UntilconditionBlockComponent = /** @class */ (function () {
    function UntilconditionBlockComponent(confirmationBoxHelper, ajsScope, workflowService) {
        this.confirmationBoxHelper = confirmationBoxHelper;
        this.ajsScope = ajsScope;
        this.workflowService = workflowService;
        this.isEdit = false;
        this.activeField = {};
        this.isConditional = false;
        this.isMarketPlaceView = false;
        this.hasChild = false;
        this.isReadOnlyMode = false;
        this.outputModel = new EventEmitter();
        this.close = new EventEmitter();
        this.conditionalFields = [];
        this.untilMin = new Date();
        this.untilMax = new Date();
        this.select2 = null;
    }
    UntilconditionBlockComponent.prototype.ngOnInit = function () {
        this.oldModel = JSON.stringify(Object.assign({}, this.inputModel));
        if (typeof this.inputModel.model.untilType == 'string') {
            this.inputModel.model.untilType = parseInt(this.inputModel.model.untilType);
        }
        this.showDeleteBtn = this.isMarketPlaceView ? false : this.isEdit;
        this.untilMin.setDate(this.untilMin.getDate() + 1);
        this.untilMax.setDate(this.untilMax.getDate() + 180);
        if (this.inputModel.model['untilPeriod']) {
            this.untilPeriodModel = new Date(this.inputModel.model['untilPeriod']);
        }
        else {
            this.untilPeriodModel = null;
        }
        //console.log('isConditional',this.isConditional, this.inputModel);
    };
    UntilconditionBlockComponent.prototype.removeCell = function () {
        var _this = this;
        this.confirmationBoxHelper
            .getConfirmation(this.deleteMessage, this.ajsScope)
            .then(function () {
            _this.close.emit('remove');
        });
    };
    UntilconditionBlockComponent.prototype.addValue = function () {
        /*let selectedItem = _.find(this.inputModel.config.fields, (field) => {
            return field.id == this.inputModel.model['col_name']
        });*/
        var selectedItem = this.inputModel.config.fieldsByColName[this.inputModel.model['col_name']];
        //console.log('selectedItem', selectedItem);
        if (selectedItem && selectedItem['fieldType'] && selectedItem['pageType']) {
            this.inputModel.model['fieldType'] = selectedItem['fieldType'];
            this.inputModel.model['pageType'] = selectedItem['pageType'];
        }
        if (selectedItem && selectedItem['templateId']) {
            this.inputModel.model['templateId'] = selectedItem['templateId'];
        }
        this.inputModel.model['untilPeriod'] = this.untilPeriodModel ? moment(this.untilPeriodModel).format() : null;
        this.newModel = JSON.stringify(Object.assign({}, this.inputModel));
        if (_.isEqual(this.oldModel, this.newModel)) {
            this.workflowService.triggerEvent({ 'isUpdated': false, 'isEdit': this.isEdit, 'action': 'valueChanged' });
        }
        else {
            this.workflowService.triggerEvent({ 'isUpdated': true, 'isEdit': this.isEdit, 'action': 'valueChanged' });
        }
        this.outputModel.emit(this.inputModel);
    };
    UntilconditionBlockComponent.prototype.validateItems = function () {
        if (!this.inputModel.model.col_name
            || this.inputModel.model.interval == 0
            || (this.inputModel.model.untilType == 2 && !this.inputModel.model.untilManualPeriod)
            || (this.inputModel.model.untilType == 1 && !this.untilPeriodModel)) {
            return true;
        }
        return this.isConditional ? this.inputModel.condition.operator === '' || this.inputModel.condition.value === '' : false;
    };
    UntilconditionBlockComponent.prototype.setSelect2 = function (instance) {
        this.select2 = instance;
    };
    UntilconditionBlockComponent.prototype.closeModal = function () {
        if (this.select2) {
            this.select2.select2('val', '');
            this.select2.trigger('change');
        }
        this.untilPeriodModel = null;
        this.close.emit(this.isEdit);
    };
    return UntilconditionBlockComponent;
}());
export { UntilconditionBlockComponent };
