import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CsFullScreenModalComponent} from "@app/directives";
import {MxGraphService} from "@app/workflow-common/services/mx-graph.service";
import {mxgraph} from "mxgraph-factory";
import {MxGraphSequenceService} from "@app/workflow-common/services/mx-graph-sequence";

@Component({
  selector: 'app-audit-history',
  templateUrl: './audit-history.component.html',
  styles: []
})
export class AuditHistoryComponent implements OnInit {

    @Input() activeMenu;
    @Input() data:any;
    @Input() mainObjectsFields:any;
    @Input() tags:any[] = [];
    @Input() csFullModal:CsFullScreenModalComponent;
    @Input() userRoles:any[] = [];
    @Input() viewMode:boolean=false;
    @Input() usersList:any[] = [];
    @Input() scheduleActivityTypes:any[] = [];
    @Input() isMarketPlaceView:boolean=false;
    @Input() sequenceObjectName: string;
    @Input() additionalData: object = {};
    @Input() mode:string = 'sequence_setup'; // sequence_config
    @Output() afterRenderDiagram: EventEmitter<any> = new EventEmitter<any>();
    @Output() beforeRenderDiagram: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('mx_container') container: ElementRef<HTMLElement>;
    @ViewChild('outlineContainer') outlineContainer: ElementRef<HTMLElement>;

    mxGraph: mxgraph.mxGraph;
    isEmpty:boolean = true;
    activeAuditDataItemsItems: any;
    viewDiagram:boolean =false;
    objectName: string;
    objectFields: any;
    type: any;
  constructor(
      private diagramService: MxGraphService,
      private sequenceDiagramService: MxGraphSequenceService,
  ) { }

  ngOnInit() {
      this.objectName = this.data['objectName'].toLowerCase();
      this.type = this.data['type'].toLowerCase();
      this.objectFields = this.type != 'sequence' ? this.mainObjectsFields[this.objectName] : {};
  }
  ngAfterViewInit() {
      this.setContainerHeight();
  }

  setContainerHeight(){
      setTimeout(() => {
          const height = window.innerHeight - (this.container.nativeElement.offsetTop + 10);
          this.container.nativeElement.style.minHeight= height + 'px';
      },100);
  }

    setActiveAuditDataItem(item: any){
      if(this.data.type == 'sequence'){
          this.sequenceDiagramService.mxOutline.destroy();
          this.sequenceDiagramService.destroyMxGraph();
          // this.beforeRenderDiagram.emit(this);
          if(!item.auditRecord.eventType.includes('_task')){
              this.isEmpty = true;
          }else{
              this.isEmpty = false;
              this.sequenceDiagramService.initialize(this.container, {
                  xmlSource: item.auditRecord.xmlSource,
                  workflowId: this.data.id,
                  workflowStatus: this.data.status == 1,
                  isScheduler: false,
                  outlineContainer: this.outlineContainer,
                  viewMode: this.viewMode,
                  userRoles: this.userRoles,
                  tags: this.tags,
                  scheduleActivityTypes: this.scheduleActivityTypes,
                  usersList: this.usersList,
                  workflowType: this.data.type,
                  additionalData: this.additionalData,
                  mode: this.mode,
                  sequenceObjectName: this.sequenceObjectName,
                  mainObjectName: this.sequenceObjectName,
                  // confirmationBoxHelper: this.confirmationBoxHelper,
                  // ajsScope: this.ajsScope
              });
              this.sequenceDiagramService.setDiagramComponent(this);
              this.mxGraph = this.sequenceDiagramService.getGraph();
              this.sequenceDiagramService.render( (mxGraph:mxgraph.mxGraph) => {
                  this.afterRenderDiagram.emit(mxGraph);
              });
              setTimeout(()=>{
                  this.sequenceDiagramService.setHightlightCells(item, true);
              },10);
          }

      }else{
          this.diagramService.destroyMxGraph(() => {
              if(!item.auditRecord.eventType.includes('_task')){
                  this.isEmpty = true;
              }else{
                  this.isEmpty = false;
                  this.activeAuditDataItemsItems = item;
                  this.diagramService.initialize(this.container, {
                      xmlSource: item.auditRecord.xmlSource,
                      isScheduler: this.data.scheduler,
                      outlineContainer: this.outlineContainer,
                      mainObjectsFields: this.mainObjectsFields,
                      viewMode: this.viewMode,
                      isMarketPlaceView: this.isMarketPlaceView,
                      tags: this.tags,
                      userRoles: this.userRoles,
                      scheduleActivityTypes: this.scheduleActivityTypes,
                      usersList: this.usersList,
                      workflowType: this.data.type,
                      doAutoHeightAdjustment: true
                  });
                  this.mxGraph = this.diagramService.getGraph();
                  this.diagramService.render(item.dagSource.blockObjects);
                  setTimeout(()=>{
                      this.diagramService.setHightlightCells(item, true);
                  },10);
              }
          })
      }
    }
}
