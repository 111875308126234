import { ElementRef, EventEmitter, OnInit } from '@angular/core';
import { CsFullScreenModalComponent } from "@app/directives";
import { MxGraphService } from "@app/workflow-common/services/mx-graph.service";
import { MxGraphSequenceService } from "@app/workflow-common/services/mx-graph-sequence";
var AuditHistoryComponent = /** @class */ (function () {
    function AuditHistoryComponent(diagramService, sequenceDiagramService) {
        this.diagramService = diagramService;
        this.sequenceDiagramService = sequenceDiagramService;
        this.tags = [];
        this.userRoles = [];
        this.viewMode = false;
        this.usersList = [];
        this.scheduleActivityTypes = [];
        this.isMarketPlaceView = false;
        this.additionalData = {};
        this.mode = 'sequence_setup'; // sequence_config
        this.afterRenderDiagram = new EventEmitter();
        this.beforeRenderDiagram = new EventEmitter();
        this.isEmpty = true;
        this.viewDiagram = false;
    }
    AuditHistoryComponent.prototype.ngOnInit = function () {
        this.objectName = this.data['objectName'].toLowerCase();
        this.type = this.data['type'].toLowerCase();
        this.objectFields = this.type != 'sequence' ? this.mainObjectsFields[this.objectName] : {};
    };
    AuditHistoryComponent.prototype.ngAfterViewInit = function () {
        this.setContainerHeight();
    };
    AuditHistoryComponent.prototype.setContainerHeight = function () {
        var _this = this;
        setTimeout(function () {
            var height = window.innerHeight - (_this.container.nativeElement.offsetTop + 10);
            _this.container.nativeElement.style.minHeight = height + 'px';
        }, 100);
    };
    AuditHistoryComponent.prototype.setActiveAuditDataItem = function (item) {
        var _this = this;
        if (this.data.type == 'sequence') {
            this.sequenceDiagramService.mxOutline.destroy();
            this.sequenceDiagramService.destroyMxGraph();
            // this.beforeRenderDiagram.emit(this);
            if (!item.auditRecord.eventType.includes('_task')) {
                this.isEmpty = true;
            }
            else {
                this.isEmpty = false;
                this.sequenceDiagramService.initialize(this.container, {
                    xmlSource: item.auditRecord.xmlSource,
                    workflowId: this.data.id,
                    workflowStatus: this.data.status == 1,
                    isScheduler: false,
                    outlineContainer: this.outlineContainer,
                    viewMode: this.viewMode,
                    userRoles: this.userRoles,
                    tags: this.tags,
                    scheduleActivityTypes: this.scheduleActivityTypes,
                    usersList: this.usersList,
                    workflowType: this.data.type,
                    additionalData: this.additionalData,
                    mode: this.mode,
                    sequenceObjectName: this.sequenceObjectName,
                    mainObjectName: this.sequenceObjectName,
                });
                this.sequenceDiagramService.setDiagramComponent(this);
                this.mxGraph = this.sequenceDiagramService.getGraph();
                this.sequenceDiagramService.render(function (mxGraph) {
                    _this.afterRenderDiagram.emit(mxGraph);
                });
                setTimeout(function () {
                    _this.sequenceDiagramService.setHightlightCells(item, true);
                }, 10);
            }
        }
        else {
            this.diagramService.destroyMxGraph(function () {
                if (!item.auditRecord.eventType.includes('_task')) {
                    _this.isEmpty = true;
                }
                else {
                    _this.isEmpty = false;
                    _this.activeAuditDataItemsItems = item;
                    _this.diagramService.initialize(_this.container, {
                        xmlSource: item.auditRecord.xmlSource,
                        isScheduler: _this.data.scheduler,
                        outlineContainer: _this.outlineContainer,
                        mainObjectsFields: _this.mainObjectsFields,
                        viewMode: _this.viewMode,
                        isMarketPlaceView: _this.isMarketPlaceView,
                        tags: _this.tags,
                        userRoles: _this.userRoles,
                        scheduleActivityTypes: _this.scheduleActivityTypes,
                        usersList: _this.usersList,
                        workflowType: _this.data.type,
                        doAutoHeightAdjustment: true
                    });
                    _this.mxGraph = _this.diagramService.getGraph();
                    _this.diagramService.render(item.dagSource.blockObjects);
                    setTimeout(function () {
                        _this.diagramService.setHightlightCells(item, true);
                    }, 10);
                }
            });
        }
    };
    return AuditHistoryComponent;
}());
export { AuditHistoryComponent };
