import {Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {WorkflowhttpService} from '../../services/workflowhttp.service';
import { QuillEditorComponent } from "ngx-quill";
import "quill-mention";
import { WorkflowCommonService } from "@app/workflow-common/services/workflow.common.service";

declare var $;
declare  var _;

const isNumeric =(str) => {
    // if (typeof str != "string") return typeof str === "number";
    // return !isNaN(str) && !isNaN(parseFloat(str));
    let reg = /^\d+$/;
    return reg.test(str)
};

@Component({
  selector: 'app-officetask-block',
  templateUrl: './officetask-block.component.html',
  styles: []
})
export class OfficetaskBlockComponent implements OnInit {

    name:string = 'officeTaskBlock';
    showDeleteBtn:boolean;
    oldModel: any;
    newModel: any
    @Input() inputModel:any;
    @Input() activeField:any = {};
    @Input() isConditional:boolean = false;
    @Input() isEdit:boolean = false;
    @Input() operators:any;
    @Input() isMarketPlaceView:boolean = false;
    @Input() taggingList:any={};
    @Input() mainObjectName:string;
    @Input() deleteMessage:string;
    @Input() warningMessage:string;
    @Input() workflowType: string = 'Custom';
    @Input() diagramOptions: any = {};
    @Input() status:any;
    @Input() isReadOnlyMode:boolean = false;


    @Output() outputModel:EventEmitter<any> = new EventEmitter<any>();
    @Output() close:EventEmitter<any> = new EventEmitter<any>();

    conditionalFields:any = [];
    actionDisabled: boolean = true;
    isLoading:boolean = true;
    userData: any = [];
    tagsData: any = [];
    select2TagsOptions:any = {
        multiple: true,
    };
    mentionContent:string = '';
    editorSettings:any = {};
    assignedUser:any = null;
    messageDelta:any = [];
    csTaggingList:any[]=[];
    saving: boolean = false;
    disableSaveBtn: boolean = false;
    @ViewChild(QuillEditorComponent) editor: QuillEditorComponent;

    modules = {
        mention: {
            mentionListClass: "ql-mention-list mat-elevation-z8",
            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
            showDenotationChar: true,
            spaceAfterInsert: true,
            onSelect: (item: DOMStringMap, insertItem) => {
                // const editor = this.editor.quillEditor as Quill;
                if(item.denotationChar === '[') {
                    item['value'] = item['value']+']';
                }

                //console.log('itemmmm',item)
                insertItem(item)
                if(item.denotationChar === '@') {
                    this.assignedUser = item.id;
                }
            },
            mentionDenotationChars: ["@", "#", "["],
            source: (searchTerm, renderList, mentionChar) => {
                let values = [];
                if(mentionChar === '#') {
                    values = this.tagsData;
                }
                if(mentionChar === '[') {
                    values = this.csTaggingList;
                }
                else if(mentionChar === '@') {
                    values = this.assignedUser ? [] : this.userData;
                }

                if (searchTerm.length === 0) {
                    renderList(values, searchTerm)
                }
                else {
                    const matches = [];
                    for (let i = 0; i < values.length; i++)
                        if (
                            ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
                        )
                            matches.push(values[i]);
                    renderList(matches, searchTerm);
                }
            }
        },
        toolbar: false
    };

    constructor(
        private workflowService: WorkflowhttpService,
        private commonService: WorkflowCommonService,
        @Inject('confirmationBoxHelper') private confirmationBoxHelper,
        @Inject('$scope') private ajsScope,
    ) { }

    ngOnInit() {
        this.oldModel = JSON.stringify(Object.assign({}, this.inputModel));
        this.showDeleteBtn = this.isMarketPlaceView ? false : this.isEdit;
        if(this.workflowType =='Sequence' && this.diagramOptions.mode == 'sequence_config') {
            this.showDeleteBtn = false;
        }
        this.csTaggingList = this.commonService.getCsTaggingList(this.mainObjectName, this.taggingList);
        this.resolveData();
    }

    oldData:any = null;
    onChange(event) {
        this.disableSaveBtn = event.content.ops.length <= 1 && event.text.replace('\n', '').trim().length == 0;
        if(!event.html) {
            this.messageDelta=null;
            this.assignedUser=null;
            this.mentionContent='';
            return;
        }
        const delta = event.content;
        this.messageDelta = JSON.stringify(delta);
        this.mentionContent = event.html;
        //console.log('content', this.messageDelta, );
        //console.log('text', event.html.replace(/(<([^>]+)>)/gi, ""))
        const contentEl = $(event.html).find('[data-denotation-char="@"]');
        if(contentEl.length) {
            this.oldData = contentEl.data('id');
        }
        if(this.oldData && contentEl.length===0) {
            this.oldData = this.assignedUser = null;
        }
    }

    removeCell() {
        this.confirmationBoxHelper
            .getConfirmation(this.deleteMessage, this.ajsScope)
            .then(() => {
                this.close.emit('remove');
            });
    }

    addValue() {
        this.saving=true;
        this.inputModel.model.assignTo = this.assignedUser;
        this.inputModel.model.message = this.mentionContent;
        this.inputModel.model.textMessage = this.mentionContent.replace(/(<([^>]+)>)/gi, "");
        this.inputModel.model.messageDelta = this.messageDelta;
        this.inputModel.model.setAssignedTo = this.setAssignedTo(this.assignedUser);
        this.newModel = JSON.stringify(Object.assign({}, this.inputModel));
        if(_.isEqual(this.oldModel, this.newModel) ){
            this.workflowService.triggerEvent({'isUpdated' : false ,'isEdit' : this.isEdit,'action' : 'valueChanged'});
        }else{
            this.workflowService.triggerEvent({'isUpdated' : true ,'isEdit' : this.isEdit,'action' : 'valueChanged'});
        }
        // console.log('save',this.inputModel.model);
        setTimeout(() =>{
            this.outputModel.emit(this.inputModel);
        },0)
    }

    closeModal() {
        this.close.emit(this.isEdit);
    }

    getUser() {
        const promise = new Promise( (resolve) => {
            this.workflowService.getUsersList()
                .subscribe(resp => {
                    this.userData = resp['users'].map( (item) => {
                        return { id: item.id, value: item.username, username: item.username }
                    });
                    resolve();
                });
        });
        return promise;
    }

    setAssignedTo(value) {
        const dupUserData = Object.assign([], this.userData);
        let objectUser = _.indexBy(dupUserData, 'id');
        let selectedData = objectUser[value];
        return this.inputModel.model.assignToDisplay = selectedData ? selectedData['username'] : '';
    }

    setTagItems(value) {
        this.inputModel.model.tags = value;
    }

    getTags() {
        const promise = new Promise( (resolve) => {
            this.workflowService.getHashTagsList()
                .subscribe(resp => {
                    this.tagsData = resp['hashtags'].map( (item) => {
                        //return { id: item.id, text: item.description }
                        return { id: item.id, value: item.description }
                    });
                    resolve();
                });
        });
        return promise;
    }

    validateItems() {
        if(this.mentionContent == ''){
            return true;
        }
        return this.isConditional ? this.inputModel.condition.operator ==='' || this.inputModel.condition.value==='' : false;
    }

    async resolveData() {
        this.isLoading = true;
        await this.getUser();
        await this.getTags();
        this.isLoading = false;
        if(this.isEdit) {
            //console.log('isEdit', this.inputModel)
            const {assignTo, messageDelta} = this.inputModel.model;
            this.assignedUser = messageDelta ? assignTo : null;
            this.messageDelta = messageDelta ? JSON.parse(messageDelta) : [];
            this.editor.quillEditor.setContents(this.messageDelta);
        }
    }

}
