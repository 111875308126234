import {Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef, AfterViewInit, Inject} from '@angular/core';
import {WorkflowhttpService} from "@app/workflow-common/services/workflowhttp.service";
declare var _;

@Component({
    selector: 'app-conditional-block',
    templateUrl: './conditional-block.component.html',
    styles: []
})
export class ConditionalBlockComponent implements OnInit, AfterViewInit {

    name: 'conditionalBlock';
    isRequiredDelay:false;
    showDeleteBtn:boolean;
    @Input() isEdit:boolean  = false;
    @Input() inputModel:any;
    @Input() activeField:any = {};
    @Input() isConditional:boolean = false;
    @Input() operators:any;
    @Input() isMarketPlaceView: boolean = false;
    @Input() hasChild:boolean = false;
    @Input() deleteMessage:string;
    @Input() warningMessage:string;
    @Input() status:any;
    @Input() isReadOnlyMode:boolean = false;


    @Output() outputModel:EventEmitter<any> = new EventEmitter<any>();
    @Output() close:EventEmitter<any> = new EventEmitter<any>();

    conditionalFields:any = [];
    saving: boolean = false;
    oldModel: any;
    newModel: any;

    constructor(
        private cdr: ChangeDetectorRef,
        @Inject('confirmationBoxHelper') private confirmationBoxHelper,
        @Inject('$scope') private ajsScope,
        private workflowService: WorkflowhttpService,
    ) { }

    ngOnInit() {
        this.oldModel = JSON.stringify(Object.assign({}, this.inputModel));
        this.showDeleteBtn = this.isMarketPlaceView ? false : this.isEdit;
        //console.log(this.isConditional)
    }

    removeCell() {
        this.confirmationBoxHelper
            .getConfirmation(this.deleteMessage, this.ajsScope)
            .then(() => {
                this.close.emit('remove');
            });
    }

    ngAfterViewInit() {

    }

    validateItems() {
        if(!this.inputModel.model.col_name ){
            return true;
        }
        return this.isConditional ? this.inputModel.condition.operator ==='' || this.inputModel.condition.value==='' : false;
    }

    addValue() {
        /*let selectedItem = _.find(this.inputModel.config.fields, (field) => {
            return field.id == this.inputModel.model['col_name']
        });*/
        this.saving=true;
        let selectedItem:any = this.inputModel.config.fieldsByColName[this.inputModel.model['col_name']];
        //console.log('selectedItem', selectedItem);
        if(selectedItem && selectedItem['fieldType'] && selectedItem['pageType']) {
            this.inputModel.model['fieldType'] = selectedItem['fieldType'];
            this.inputModel.model['pageType'] = selectedItem['pageType'];
        }
        if(selectedItem && selectedItem['templateId']) {
            this.inputModel.model['templateId'] = selectedItem['templateId'];
        }
        this.newModel = JSON.stringify(Object.assign({}, this.inputModel));
        if(this.oldModel ===  this.newModel){
            this.workflowService.triggerEvent({'isUpdated' : false ,'isEdit' : this.isEdit,'action' : 'valueChanged'});
        }else{
            this.workflowService.triggerEvent({'isUpdated' : true ,'isEdit' : this.isEdit,'action' : 'valueChanged'});
        }
        setTimeout(() =>{
            this.outputModel.emit(this.inputModel);
        },0)
    }

    closeModal() {
        this.close.emit(this.isEdit);
    }
}

