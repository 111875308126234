import { mxgraphFactory } from "mxgraph-factory";
import { AuthService } from "@app/core";
import * as i0 from "@angular/core";
import * as i1 from "../../core/auth/auth.service";
var _a = mxgraphFactory({
    mxLoadResources: false,
    mxLoadStylesheets: true,
}), mxCell = _a.mxCell, mxUtils = _a.mxUtils;
var WorkflowCommonService = /** @class */ (function () {
    function WorkflowCommonService(authService) {
        this.authService = authService;
    }
    WorkflowCommonService.prototype.getBlocksList = function (type) {
        if (type === void 0) { type = 'Custom'; }
        var filterBySequence = [
            'delayBlock',
            'emailBlock',
            'smsBlock',
            'notificationBlock',
            'scheduledActivityBlock',
        ];
        var blocks = [{
                title: 'Delay blocks',
                id: 'cs_building_blocks',
                blocks: [{
                        id: 'delayBlock',
                        title: 'Delay',
                        visible: true,
                        iconClass: 'fal fa-clock',
                    },
                    {
                        id: 'conditionalDelayBlock',
                        title: 'Field based delays',
                        visible: true,
                        iconClass: 'fal fa-chess-clock-alt',
                    }]
            },
            {
                title: 'Branching blocks',
                id: 'cs_building_blocks',
                blocks: [{
                        id: 'conditionalBlock',
                        title: 'If statement',
                        visible: true,
                        iconClass: 'fal fa-code-branch fa-rotate-180',
                    }, {
                        id: 'untilConditionBlock',
                        title: 'If... until',
                        visible: true,
                        iconClass: 'fal fa-sharp fa-solid fa-repeat-1',
                    }]
            }, {
                title: 'Communication blocks',
                id: 'cs_communication_blocks',
                blocks: [{
                        id: 'emailBlock',
                        title: 'Email',
                        visible: true,
                        iconClass: 'fal fa-envelope',
                    }, {
                        id: 'smsBlock',
                        title: 'SMS',
                        visible: this.authService.hasModuleAccess('Sms'),
                        iconClass: 'fal fa-sms',
                    }]
            }, {
                title: 'Actions blocks',
                id: 'cs_actions_blocks',
                blocks: [{
                        id: 'officeTaskBlock',
                        title: 'Office task',
                        visible: true,
                        iconClass: 'fal fa-list',
                    }, {
                        id: 'addjobBlock',
                        title: 'Add job',
                        visible: true,
                        iconClass: 'fal fa-truck',
                    }, {
                        id: 'setcustomfieldvalueBlock',
                        title: 'Set custom fields',
                        visible: this.authService.permitted(['SetupCustomFieldsToObjects.writeaccess']) === true,
                        iconClass: 'fal fa-file-edit',
                    }, {
                        id: 'scheduledActivityBlock',
                        title: 'Scheduled activity',
                        visible: this.authService.permitted(['ScheduleActivity.writeaccess']) === true,
                        iconClass: 'fal fa-clipboard-list-check',
                    }, {
                        id: 'notificationBlock',
                        title: 'Notification',
                        visible: true,
                        iconClass: 'fal fa-globe-americas',
                    }]
            }];
        if (type == 'Custom' || type == 'Marketplace') {
            return blocks;
        }
        else if (type.toLowerCase() == 'sequence') {
            return blocks.filter(function (section) {
                section.blocks = section.blocks.filter(function (item) {
                    return filterBySequence.indexOf(item.id) > -1;
                });
                return section.blocks.length > 0;
            });
        }
    };
    WorkflowCommonService.prototype.getCsTaggingList = function (mainObjectName, taggingList, workflowType) {
        if (workflowType === void 0) { workflowType = 'Custom'; }
        var cstagKeys = [];
        if (mainObjectName === 'job') {
            cstagKeys = ['Customers', 'jobs', 'work_address'];
        }
        else if (mainObjectName === 'invoice') {
            cstagKeys = ['Customers', 'jobs', 'work_address', 'invoice'];
        }
        else if (mainObjectName === 'diaryEvents') {
            cstagKeys = ['Customers', 'jobs', 'work_address', 'diary_events'];
        }
        else if (mainObjectName === 'certificates') {
            cstagKeys = ['Customers', 'jobs', 'work_address'];
        }
        else if (mainObjectName === 'customer') {
            cstagKeys = ['Customers', 'work_address'];
        }
        else if (mainObjectName === 'payment') {
            cstagKeys = ['Customers', 'jobs', 'work_address', 'invoice', 'payment'];
        }
        else if (mainObjectName === 'Opportunity' && workflowType === 'Sequence') {
            cstagKeys = ['Customers', 'opportunities'];
        }
        var __csTaggingList = [];
        cstagKeys.forEach(function (tagName) {
            var tagNameList = Object.values(taggingList[tagName]);
            __csTaggingList = __csTaggingList.concat(tagNameList);
        });
        var tagList = this.removeItem('Payment_invoice_list', __csTaggingList);
        var csTaggingList = tagList.map(function (item) {
            return { id: item, value: item };
        });
        return csTaggingList;
    };
    WorkflowCommonService.prototype.getCustomFieldsGroup = function (name) {
        var groupLists = {
            'job': ['CustomerType', 'JobDescription', 'InvoicesCategory'],
            'invoice': ['Invoices'],
            'diaryEvents': [],
            'certificates': []
        };
        return name != '' ? groupLists[name] : groupLists;
    };
    WorkflowCommonService.prototype.removeItem = function (value, Array) {
        var index = Array.indexOf(value);
        var removedTag = Array.splice(index, 1);
        return Array;
    };
    WorkflowCommonService.prototype.getBlockMainContainer = function (name, options, cell) {
        if (options === void 0) { options = {}; }
        if (cell === void 0) { cell = null; }
        var cellValue = {};
        /* if(cell) {
             cellValue = cell.getValue();
             options['id'] = cell.getId()
         }*/
        var blockSize = options.blockSize, title = options.title;
        var headerText = 'What do you want to happen?';
        if (options['id'] == 'cs_initializer') {
            headerText = options.title;
        }
        var viewMode = options.mxGraphService ? options.mxGraphService.options.viewMode : false;
        var mainContainer = viewMode ? $('<div></div>')
            .addClass('view-task-block cs-' + name).css({ width: blockSize[name]['width'], textAlign: 'center', padding: '20px', fontSize: '14px', boxSizing: 'border-box', whiteSpace: 'normal' }) :
            $('<div></div>').addClass('task-block cs-' + name).css({ width: blockSize[name]['width'], textAlign: 'center', padding: '20px', fontSize: '14px', boxSizing: 'border-box', whiteSpace: 'normal' });
        mainContainer.attr('id', 'cs-block-' + name + '-' + options['id']);
        var titleDiv = $('<div></div>').css({ fontSize: '14px', paddingBottom: options['id'] == 'cs_initializer' ? 0 : 10 }).text(headerText);
        var separator = $('<div/>').css({ marginBottom: 10, height: 10, borderBottom: '1px solid #DADADA' }).addClass('d-block');
        var taskDiv = $('<div></div>').addClass('task-title');
        var imgTag = '';
        if (name == 'scheduledActivityBlock')
            imgTag = '<i class="mr-10 far fa-clipboard-list-check fa-lg" aria-hidden="true"></i>';
        else if (name == 'emailBlock')
            imgTag = '<i class="mr-10 fal fa-envelope fa-lg" aria-hidden="true"></i>';
        else if (name == 'smsBlock')
            imgTag = '<i class="mr-10 fal fa-sms fa-lg" aria-hidden="true"></i>';
        else if (name == 'notificationBlock')
            imgTag = '<i class="mr-10 fal fa-globe-americas fa-lg" aria-hidden="true"></i>';
        else if (name == 'officeTaskBlock')
            imgTag = '<i class="mr-10 fal fa-list fa-lg" aria-hidden="true"></i>';
        else if (name == 'conditionalDelayBlock')
            imgTag = '<i class="mr-10 fal fa-chess-clock-alt fa-lg" aria-hidden="true"></i>';
        else if (name == 'delayBlock')
            if (cellValue.hasOwnProperty('model') && cellValue['model']['type'] === 'conditional')
                imgTag = '<i class="mr-10 fal fa-chess-clock-alt fa-lg" aria-hidden="true"></i>';
            else
                imgTag = '<i class="mr-10 fal fa-clock fa-lg" aria-hidden="true"></i>';
        else
            imgTag = '<i class="mr-10 far fa-file-edit fa-lg" aria-hidden="true"></i>';
        // let img = options.title == "Schedule activity" ? '<i class="mr-10 far fa-clipboard-list-check fa-lg" aria-hidden="true"></i>' : '<i class="mr-10 far fa-file-edit fa-lg" aria-hidden="true"></i>';
        if (options['id'] != 'cs_initializer') {
            var span = $('<span></span>').css({ fontWeight: 'bold' }).text(title);
            taskDiv.append(imgTag);
            taskDiv.append(span);
        }
        mainContainer.append(titleDiv);
        mainContainer.append(taskDiv);
        mainContainer.append(separator);
        return mainContainer;
    };
    WorkflowCommonService.prototype.renderInitializer = function (cell, options) {
        if (options === void 0) { options = {}; }
        var value = cell instanceof mxCell ? cell.getValue() : cell;
        if (!cell['block_name']) {
            cell['block_name'] = cell['name'];
        }
        var rules = value.rules, mainObject = value.mainObject;
        var selectedMainObjectFields = options.selectedMainObjectFields;
        var operatorsDisplayText = options.getOperatorsDisplayText;
        var maxWidth = 400;
        var fixedWidth = 270;
        var data = {}, titleText = '';
        var containerBottom = 5;
        var mainContainer = this.getBlockMainContainer(cell['block_name'], options);
        if (options['isScheduler']) {
            var divHead = document.createElement('div');
            divHead.style.textAlign = 'center';
            divHead.className = 'init-block';
            divHead.style.fontWeight = 'bold';
            divHead.style.padding = '0 4px';
            mxUtils.write(divHead, 'Scheduled workflow');
            mainContainer.append(divHead);
        }
        var cnt = 0;
        var textCollection = [];
        _.forEach(rules, function (rule) {
            cnt++;
            var $fieldContainer = $('<div></div>').css('padding-bottom', cnt == rules.length ? 0 : containerBottom).css('font-weight', 'bold')
                .addClass('word-break');
            var $andDiv = $('<div></div>').css('padding-bottom', containerBottom).text('and');
            var $fieldDisplay = $('<span></span>').css('padding-right', '5px');
            var $operatorDisplay = $('<span></span>').css('padding-right', '5px').css('font-weight', 'normal');
            var $valueDisplay = $('<span></span>');
            var valueDisplayText = rule['value'];
            var fieldData = selectedMainObjectFields[rule['col_name']];
            if (_.has(fieldData, 'options')) {
                var tmp_1 = [];
                if (_.isArray(rule['value'])) {
                    _.forEach(rule['value'], function (id) {
                        var activeOption = _.find(fieldData.options, function (item) {
                            return item.id == id;
                        });
                        if (activeOption) {
                            tmp_1.push(activeOption['text']);
                        }
                    });
                    valueDisplayText = tmp_1.join(', ');
                }
                else {
                    var activeOption = _.find(fieldData.options, function (item) {
                        return item.id == rule['value'];
                    });
                    if (activeOption) {
                        valueDisplayText = activeOption['text'];
                    }
                }
            }
            var tmpDisplayText = rule['text'] + ' ' + rule['operator'] + ' ' + valueDisplayText;
            textCollection.push(tmpDisplayText.length);
            $fieldDisplay.text(rule['text']);
            $operatorDisplay.text(operatorsDisplayText[rule['operator']]);
            $valueDisplay.text(valueDisplayText);
            $fieldContainer.append($fieldDisplay).append($operatorDisplay).append($valueDisplay);
            mainContainer.append($fieldContainer);
            if (cnt !== rules.length) {
                mainContainer.append($andDiv);
            }
        });
        var maxDisplayText = _.max(textCollection);
        textCollection = [];
        mainContainer.css('width', (maxDisplayText * 8) > maxWidth ? maxWidth : fixedWidth);
        return mainContainer;
    };
    WorkflowCommonService.prototype.renderSetcustomfieldvalueBlock = function (cell, options) {
        if (options === void 0) { options = {}; }
        var value = cell instanceof mxCell ? cell.getValue() : cell;
        if (!cell['block_name']) {
            cell['block_name'] = cell['name'];
        }
        var model = value.model;
        var mainContainer = this.getBlockMainContainer(cell['block_name'], options);
        var bodyLine = $('<div></div>').addClass('text-truncate d-block').css('padding-bottom', '5px');
        var fieldName = $('<span></span>').css({ fontWeight: 'normal' }).text(model['textField']);
        bodyLine.append(fieldName);
        var bodyLine2 = $('<div></div>').addClass('text-truncate d-block');
        var fieldValue = $('<span></span>').css({ fontWeight: 'bold', paddingLeft: 0 }).html(model['displayColumnValue']);
        bodyLine2.append(fieldValue);
        mainContainer.append(bodyLine);
        mainContainer.append(bodyLine2);
        return mainContainer;
    };
    WorkflowCommonService.prototype.renderDelayBlock = function (cell, options) {
        if (options === void 0) { options = {}; }
        var value = cell instanceof mxCell ? cell.getValue() : cell;
        if (!cell['block_name']) {
            cell['block_name'] = cell['name'];
        }
        var mainContainer = this.getBlockMainContainer(cell['block_name'], options);
        var bodyLine = $('<div></div>').addClass('text-truncate d-block').css('padding-bottom', '0');
        var content = $('<span></span>').css({ fontWeight: 'bold', paddingLeft: 0 });
        var intervalUnitFormat = value['model']['interval'] === 1 ? value['model']['unit'].slice(0, -1) : value['model']['unit'];
        content.text(value['model']['interval'] + ' ' + intervalUnitFormat);
        if (value['model']['type'] === 'conditional') {
            var eventText = value['model']['event_method'].indexOf('after') > -1 ? 'after' : 'before';
            var span1 = $('<span/>').css({ fontWeight: 'bold' }).text(value['model']['interval'] + ' ' + intervalUnitFormat);
            var span2 = $('<span/>').css({ fontWeight: 'bold', padding: '0 5px' }).text(eventText);
            var span3 = $('<span/>').css({ fontWeight: 'bold' }).text(value['model']['fieldLabel']);
            bodyLine.append(span1).append(span2).append(span3);
        }
        else {
            bodyLine.append(content);
        }
        mainContainer.append(bodyLine);
        return mainContainer;
    };
    WorkflowCommonService.prototype.renderConditionalDelayBlock = function (cell, options) {
        if (options === void 0) { options = {}; }
        var value = cell instanceof mxCell ? cell.getValue() : cell;
        if (!cell['block_name']) {
            cell['block_name'] = cell['name'];
        }
        var mainContainer = this.getBlockMainContainer(cell['block_name'], options);
        var bodyLine = $('<div></div>').addClass('d-block').css('padding-bottom', '0');
        var intervalUnitFormat = value['model']['interval'] === 1 ? value['model']['unit'].slice(0, -1) : value['model']['unit'];
        var eventName = value['model']['event_method'].indexOf('after') > -1 ? 'after' : 'before';
        var span1 = $('<span/>').css({ fontWeight: 'bold' }).text(value['model']['interval'] + ' ' + intervalUnitFormat);
        var span2 = $('<span/>').css({ fontWeight: 'bold', padding: '0 5px' }).text(eventName);
        var span3 = $('<span/>').css({ fontWeight: 'bold' }).text(value['model']['fieldLabel']);
        bodyLine.append(span1).append(span2).append(span3);
        mainContainer.append(bodyLine);
        return mainContainer;
    };
    WorkflowCommonService.prototype.renderElseBlockEdge = function (cell, options) {
        if (options === void 0) { options = {}; }
        var value = cell.getValue();
        var mainContainer = $('<div></div>').addClass('task-block else-edge-task-block').css({
            textAlign: 'center',
            padding: '3px 8px',
            backgroundColor: '#fff',
            border: '1px dashed #ccc',
            fontSize: '14px'
        });
        var displayText = options['service'].truncateMiddle(options.service.selectedMainObjectFields[cell.value.col_name].text, 30) + ' is not matched by any conditions';
        mainContainer.text(displayText);
        return mainContainer;
    };
    WorkflowCommonService.prototype.renderDummyBlock = function (cell, options) {
        if (options === void 0) { options = {}; }
        var value = cell instanceof mxCell ? cell.getValue() : cell;
        var blockSize = options.blockSize, title = options.title;
        var mainContainer = $('<div></div>').addClass('task-block dummy-task-block cs-d-flex align-items-center').css({
            width: blockSize.width,
            height: blockSize.height,
            textAlign: 'center',
            padding: '15px',
            fontSize: '14px',
            cursor: 'default'
        }).attr('id', 'block-id-' + cell.getId());
        var bodyLine = $('<div></div>').addClass('text-truncate d-block cs-flex-1').css('padding-bottom', '5px').text(title);
        mainContainer.append(bodyLine);
        return mainContainer;
    };
    WorkflowCommonService.prototype.renderStartBlock = function (cell, options) {
        if (options === void 0) { options = {}; }
        var blockSize = options.blockSize, title = options.title;
        var mainContainer = this.getBlockMainContainer(cell['block_name'], options);
    };
    WorkflowCommonService.prototype.renderScheduledActivityBlock = function (cell, options) {
        if (options === void 0) { options = {}; }
        var iconClassNames = {
            'Call': 'fa fa-phone',
            'Email': 'fa fa-envelope',
            'Meeting': 'fas fa-calendar-alt',
            'Task': 'fa fa-list-ul'
        };
        var value = cell instanceof mxCell ? cell.getValue() : cell;
        if (!cell['block_name']) {
            cell['block_name'] = cell['name'];
        }
        var model = value.model;
        options.id = cell instanceof mxCell ? cell.getId() : '99999';
        options.title = 'Schedule activity';
        var mainContainer = this.getBlockMainContainer(cell['block_name'], options);
        if (options.mxGraphService.workflowType == 'Sequence' && options.mxGraphService.options['mode'] === 'sequence_config') {
            if (value['model'] && value['model']['current_task_status'] && value['model']['current_task_status'] == 'skip') {
                mainContainer.css('background', '#F2F2F2');
                mainContainer.css('border', '1px solid #DADADA');
            }
            mainContainer.append(options.mxGraphService.skipOrUndoButton(cell));
        }
        var bodyLine = $('<div></div>').addClass('d-block').css('padding-bottom', '5px');
        var descTitle = $('<span></span>').text('Description: ');
        var descText = $('<span></span>').addClass('word-break').css({ fontWeight: 'bold' }).text(model['description']);
        bodyLine.append(descTitle);
        bodyLine.append(descText);
        mainContainer.append(bodyLine);
        var bodyLine2 = $('<div></div>').addClass('d-block').css('padding-bottom', '5px');
        var typeTitle = $('<span></span>').text('Activity type: ');
        var typeIcon = $('<i></i>').addClass(iconClassNames[model['activityTypeDisplay']]).css('margin-right', '10px');
        var activityTypeDisplay = $('<span></span>').css({ fontWeight: 'bold', marginLeft: '5px' }).text(model['activityTypeDisplay']);
        activityTypeDisplay.prepend(typeIcon);
        bodyLine2.append(typeTitle);
        bodyLine2.append(activityTypeDisplay);
        mainContainer.append(bodyLine2);
        var bodyLine3 = $('<div></div>').addClass('d-block').css('padding-bottom', '5px');
        var dueDateTitle = $('<span></span>').text('Due date: ');
        var unit = model['scheduleInterval'] == 1 ? model['unit'].slice(0, -1) : model['unit'];
        var dueDateText = 'Current date +' + model['scheduleInterval'] + ' ' + unit + ' at ' + model['displayTime'];
        var dueDateDisplay = $('<span></span>').css({ fontWeight: 'bold' }).text(dueDateText);
        bodyLine3.append(dueDateTitle);
        bodyLine3.append(dueDateDisplay);
        mainContainer.append(bodyLine3);
        if (model['assigneeIds'].length) {
            var bodyLine4 = $('<div></div>').addClass('d-block').css('padding-bottom', '0px');
            var assigneeTitle = $('<span></span>').text('Assign to: ');
            var assigneeText = $('<span></span>').css({ fontWeight: 'bold' }).text(model['assigneeDisplay'].join(', '));
            bodyLine4.append(assigneeTitle);
            bodyLine4.append(assigneeText);
            mainContainer.append(bodyLine4);
        }
        return mainContainer;
    };
    WorkflowCommonService.prototype.renderOfficeTaskBlock = function (cell, options) {
        if (options === void 0) { options = {}; }
        var value = cell instanceof mxCell ? cell.getValue() : cell;
        if (!cell['block_name']) {
            cell['block_name'] = cell['name'];
        }
        var model = value.model;
        var assignedTo = model['assignToDisplay'];
        var mainContainer = this.getBlockMainContainer(cell['block_name'], options);
        var bodyLine = $('<div></div>').addClass('d-block').css('padding-bottom', '5px');
        var assignTitle = $('<span></span>').text('Assigned to: ');
        var assignContent = $('<span></span>').css({ fontWeight: 'bold', paddingLeft: 5 }).text(assignedTo);
        var bodyLine2 = $('<div></div>').addClass('d-block');
        var tagTitle = $('<span></span>').text('Message: ');
        var tagContent = $('<span></span>').addClass('word-break').css({ fontWeight: 'bold', paddingLeft: 5 }).html(model['textMessage']);
        bodyLine.append(assignTitle);
        bodyLine.append(assignContent);
        bodyLine2.append(tagTitle);
        bodyLine2.append(tagContent);
        if (assignedTo) {
            mainContainer.append(bodyLine);
        }
        mainContainer.append(bodyLine2);
        return mainContainer;
    };
    WorkflowCommonService.prototype.renderNotificationBlock = function (cell, options) {
        if (options === void 0) { options = {}; }
        var value = cell instanceof mxCell ? cell.getValue() : cell;
        if (!cell['block_name']) {
            cell['block_name'] = cell['name'];
        }
        var model = value.model;
        var displayRoles = model['rolesDisplay'].join(', ');
        var mainContainer = this.getBlockMainContainer(cell['block_name'], options);
        if (model['current_task_status'] && model['current_task_status'] == 'skip') {
            mainContainer.css('background', '#F2F2F2');
            mainContainer.css('border', '1px solid #DADADA');
        }
        var bodyLine = $('<div></div>').addClass('d-block').css('padding-bottom', '5px');
        var assignTitle = $('<span></span>').text('Roles: ');
        var assignContent = $('<span></span>').css({ fontWeight: 'bold', paddingLeft: 5 }).text(displayRoles);
        var bodyLine2 = $('<div></div>').addClass('d-block');
        var tagTitle = $('<span></span>').text('Message: ');
        var tagContent = $('<span></span>').addClass('word-break').css({ fontWeight: 'bold', paddingLeft: 5 }).html(model['textMessage']);
        if (options.mxGraphService.workflowType == 'Sequence' && options.mxGraphService.options['mode'] === 'sequence_config') {
            mainContainer.append(options.mxGraphService.skipOrUndoButton(cell));
        }
        bodyLine.append(assignTitle);
        bodyLine.append(assignContent);
        bodyLine2.append(tagTitle);
        bodyLine2.append(tagContent);
        mainContainer.append(bodyLine);
        mainContainer.append(bodyLine2);
        return mainContainer;
    };
    WorkflowCommonService.prototype.renderSmsBlock = function (cell, options) {
        if (options === void 0) { options = {}; }
        var value = cell instanceof mxCell ? cell.getValue() : cell;
        if (!cell['block_name']) {
            cell['block_name'] = cell['name'];
        }
        var tagName = [];
        var model = value.model;
        _.forEach(model['toSmsTag'], function (item) {
            tagName.push(item.displayName);
        });
        var tagAddress = tagName.join(', ');
        var mainContainer = this.getBlockMainContainer(cell['block_name'], options);
        var bodyLine = $('<div></div>').addClass('d-block').css('padding-bottom', '5px');
        var emailToTitle = $('<span></span>').text('To: ');
        var emailToContent = $('<span></span>').css({ fontWeight: 'bold', paddingLeft: 5 }).text(tagAddress);
        var bodyLine2 = $('<div></div>').addClass('d-block');
        var subTitle = $('<span></span>').text('Title: ');
        var subContent = $('<span></span>').css({ fontWeight: 'bold', paddingLeft: 5 }).text(model['title']);
        if (options.mxGraphService.workflowType == 'Sequence' && options.mxGraphService.options['mode'] === 'sequence_config') {
            var initValue = options.mxGraphService.getInitBlockValue();
            var enabled = initValue['model'] && initValue['model']['displayContactInfo']['mobile'];
            if (enabled && model['current_task_status'] && model['current_task_status'] == 'skip') {
                mainContainer.css('background', '#F2F2F2');
                mainContainer.css('border', '1px solid #DADADA');
            }
            if (enabled) {
                mainContainer.append(options.mxGraphService.skipOrUndoButton(cell));
                emailToContent.text(initValue['model']['displayContactInfo']['mobile']);
            }
            else {
                mainContainer.css('background', '#ECC7C7');
                emailToContent.text('--not set--');
            }
            if (model['customMessage'] && typeof model['customMessage'] == 'object') {
                subTitle.text('Message: ');
                subContent.text(model['customMessage']['displayContent']);
            }
        }
        bodyLine.append(emailToTitle);
        bodyLine.append(emailToContent);
        bodyLine2.append(subTitle);
        bodyLine2.append(subContent);
        mainContainer.append(bodyLine);
        mainContainer.append(bodyLine2);
        return mainContainer;
    };
    WorkflowCommonService.prototype.renderEmailBlock = function (cell, options) {
        if (options === void 0) { options = {}; }
        var value = cell instanceof mxCell ? cell.getValue() : cell;
        if (!cell['block_name']) {
            cell['block_name'] = cell['name'];
        }
        // const value = cell.getValue();
        var model = value.model;
        var toTagName = [];
        _.forEach(model.toEmailTag, function (item) {
            toTagName.push(item.displayName);
        });
        var ccTagName = [];
        _.forEach(model.ccEmailTag, function (item) {
            ccTagName.push(item.displayName);
        });
        var bccTagName = [];
        _.forEach(model.bccEmailTag, function (item) {
            bccTagName.push(item.displayName);
        });
        var toTagAddress = toTagName.join(', ');
        var ccTagAddress = ccTagName.join(', ');
        var bccTagAddress = bccTagName.join(', ');
        var mainContainer = this.getBlockMainContainer(cell['block_name'], options);
        var bodyLine = $('<div></div>').addClass('d-block').css('padding-bottom', '5px');
        var bodyLineBcc = $('<div></div>').addClass('d-block').css('padding-bottom', '5px');
        var bodyLineCc = $('<div></div>').addClass('d-block').css('padding-bottom', '5px');
        var emailToTitle = $('<span></span>').text('To: ');
        var emailToContent = $('<span></span>').css({ fontWeight: 'bold', paddingLeft: 5 }).text(toTagAddress);
        var emailCcTitle = $('<span></span>').text('CC: ');
        var emailCcContent = $('<span></span>').css({ fontWeight: 'bold', paddingLeft: 5 }).text(ccTagAddress);
        var emailBccTitle = $('<span></span>').css({ paddingLeft: 10 }).text('BCC: ');
        var emailBccContent = $('<span></span>').css({ fontWeight: 'bold', paddingLeft: 5 }).text(bccTagAddress);
        var bodyLine2 = $('<div></div>').addClass('d-block');
        var subTitle = $('<span></span>').text('Subject: ');
        var subjectMessage = model['subject'];
        if (model['customMessage'] && typeof model['customMessage'] == 'object') {
            subjectMessage = model['customMessage']['displaySubjectMessage'];
        }
        var subContent = $('<span></span>').css({ fontWeight: 'bold', paddingLeft: 5 }).text(subjectMessage);
        if (options.mxGraphService.workflowType == 'Sequence' && options.mxGraphService.options['mode'] === 'sequence_config') {
            var initValue = options.mxGraphService.getInitBlockValue();
            var enabled = initValue['model'] && initValue['model']['displayContactInfo']['emailAddress'];
            if (enabled && model['current_task_status'] && model['current_task_status'] == 'skip') {
                mainContainer.css('background', '#F2F2F2');
                mainContainer.css('border', '1px solid #DADADA');
            }
            if (enabled) {
                mainContainer.append(options.mxGraphService.skipOrUndoButton(cell));
                emailToContent.text(initValue['model']['displayContactInfo']['emailAddress']);
            }
            else {
                mainContainer.css('background', '#ECC7C7');
                emailToContent.text('--not set--');
            }
        }
        bodyLine.append(emailToTitle);
        bodyLine.append(emailToContent);
        if (ccTagName.length > 0) {
            bodyLineCc.append(emailCcTitle);
            bodyLineCc.append(emailCcContent);
        }
        if (bccTagName.length > 0) {
            bodyLineBcc.append(emailBccTitle);
            bodyLineBcc.append(emailBccContent);
        }
        bodyLine2.append(subTitle);
        bodyLine2.append(subContent);
        mainContainer.append(bodyLine);
        if (ccTagName.length > 0) {
            mainContainer.append(bodyLineCc);
        }
        if (bccTagName.length > 0) {
            mainContainer.append(bodyLineBcc);
        }
        mainContainer.append(bodyLine2);
        return mainContainer;
    };
    WorkflowCommonService.prototype.blockHeightCalculation = function (cell, options) {
        if (options === void 0) { options = {}; }
        var mainContainer, name = !cell['block_name'] ? cell['name'] : cell['block_name'];
        var callBack = "render" + this.capitalizeFirstLetter(name);
        if (this[callBack] && typeof this[callBack] == 'function') {
            mainContainer = this[callBack](cell, options);
        }
        /*if(name== 'emailBlock') {
            mainContainer = this.renderEmailBlock(cell, options);
        }
        else if(name == 'scheduledActivityBlock') {
            mainContainer = this.renderScheduledActivityBlock(cell, options);
        }
        else if(name == 'smsBlock') {
            mainContainer = this.renderSmsBlock(cell, options);
        }
        else if(name == 'notificationBlock') {
            mainContainer = this.renderNotificationBlock(cell, options);
        }
        else if(name == 'officeTaskBlock') {
            mainContainer = this.renderOfficeTaskBlock(cell, options);
        }*/
        if (!mainContainer) {
            return false;
        }
        mainContainer.appendTo('body');
        var data = {
            width: mainContainer.outerWidth(),
            height: mainContainer.outerHeight(),
            content: mainContainer
        };
        mainContainer.remove();
        return data;
    };
    WorkflowCommonService.prototype.capitalizeFirstLetter = function (string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };
    WorkflowCommonService.ngInjectableDef = i0.defineInjectable({ factory: function WorkflowCommonService_Factory() { return new WorkflowCommonService(i0.inject(i1.AuthService)); }, token: WorkflowCommonService, providedIn: "root" });
    return WorkflowCommonService;
}());
export { WorkflowCommonService };
