import { PipeTransform } from '@angular/core';
var FilterFieldValuePipe = /** @class */ (function () {
    function FilterFieldValuePipe() {
    }
    FilterFieldValuePipe.prototype.transform = function (value, fieldName, key) {
        var fieldData = value.fields.find(function (field) { return field.col_name == fieldName; });
        var fieldValue;
        if (fieldData.input == "select") {
            fieldValue = fieldData.options.find(function (option) { return option.id == key; });
            return fieldValue.text;
        }
        else {
            return key;
        }
    };
    return FilterFieldValuePipe;
}());
export { FilterFieldValuePipe };
