export const conditions =
    {
        "date": [
            {
                "condition": "equal",
                "replaceText": "Is equal to",
                "showDatePicker": true,
                "comparison": true
            },
            {
                "condition": "notEqual",
                "replaceText": "Is not equal to",
                "showDatePicker": true,
                "comparison": false
            },
            {
                "condition": "greater",
                "replaceText": "Is greater",
                "showDatePicker": true,
                "comparison": false
            },
            {
                "condition": "greaterOrEqual",
                "replaceText": "Is greater than or equal to",
                "showDatePicker": true,
                "comparison": false
            },
            {
                "condition": "lesser",
                "replaceText": "Is lesser",
                "showDatePicker": true,
                "comparison": false
            },
            {
                "condition": "lesserOrEqual",
                "replaceText": "Is less than or equal to",
                "showDatePicker": true,
                "comparison": false
            },
            {
                "condition": "range",
                "replaceText": "Range",
                "showDatePicker": true,
                "comparison": true
            },
            {
                "condition": "today",
                "replaceText": "Today",
                "showDatePicker": false,
                "comparison": true
            },
            {
                "condition": "lessThanToday",
                "replaceText": "Less than today",
                "showDatePicker": false,
                "comparison": true
            },
            {
                "condition": "greaterThanToday",
                "replaceText": "Greater than today",
                "showDatePicker": false,
                "comparison": true
            },
            {
                "condition": "yesterday",
                "replaceText": "Yesterday",
                "showDatePicker": false,
                "comparison": true
            },
            {
                "condition": "thisWeek",
                "replaceText": "This week",
                "showDatePicker": false,
                "comparison": true
            },
            {
                "condition": "lastWeek",
                "replaceText": "Last week",
                "showDatePicker": false,
                "comparison": true
            },
            {
                "condition": "last2Week",
                "replaceText": "Last 2 weeks",
                "showDatePicker": false,
                "comparison": true
            },
            {
                "condition": "nextWeek",
                "replaceText": "Next week",
                "showDatePicker": false,
                "comparison": true
            },
            {
                "condition": "thisMonth",
                "replaceText": "This month",
                "showDatePicker": false,
                "comparison": true
            },
            {
                "condition": "lastMonth",
                "replaceText": "Last month",
                "showDatePicker": false,
                "comparison": true
            },
            {
                "condition": "last2Month",
                "replaceText": "Last 2 months",
                "showDatePicker": false,
                "comparison": true
            },
            {
                "condition": "last3Month",
                "replaceText": "Last 3 months",
                "showDatePicker": false,
                "comparison": true
            },
            {
                "condition": "last6Month",
                "replaceText": "Last 6 months",
                "showDatePicker": false,
                "comparison": true
            },
            {
                "condition": "nextMonth",
                "replaceText": "Next month",
                "showDatePicker": false,
                "comparison": true
            },
            {
                "condition": "thisQuarter",
                "replaceText": "This quarter",
                "showDatePicker": false,
                "comparison": true
            },
            {
                "condition": "lastQuarter",
                "replaceText": "Last quarter",
                "showDatePicker": false,
                "comparison": true
            },
            {
                "condition": "nextQuarter",
                "replaceText": "Next quarter",
                "showDatePicker": false,
                "comparison": true
            },
            {
                "condition": "thisYear",
                "replaceText": "This year",
                "showDatePicker": false,
                "comparison": true
            },
            {
                "condition": "lastYear",
                "replaceText": "Last year",
                "showDatePicker": false,
                "comparison": true
            },
            {
                "condition": "nextYear",
                "replaceText": "Next year",
                "showDatePicker": false,
                "comparison": true
            },
            {
                "condition": "prev7Day",
                "replaceText": "Last 7 days (excluding today)",
                "showDatePicker": false,
                "comparison": true
            },
            {
                "condition": "prev14Day",
                "replaceText": "Last 14 days (excluding today)",
                "showDatePicker": false,
                "comparison": true
            },
            {
                "condition": "prev30Day",
                "replaceText": "Last 30 days (excluding today)",
                "showDatePicker": false,
                "comparison": true
            },
            {
                "condition": "prev60Day",
                "replaceText": "Last 60 days (excluding today)",
                "showDatePicker": false,
                "comparison": true
            },
            {
                "condition": "prev90Day",
                "replaceText": "Last 90 days (excluding today)",
                "showDatePicker": false,
                "comparison": true
            },
            {
                "condition": "prev365Day",
                "replaceText": "Last 365 days (excluding today)",
                "showDatePicker": false,
                "comparison": 
                true
            },
            {
                "condition": "anytime",
                "replaceText": "Anytime",
                "showDatePicker": false,
                "comparison": false
            }
        ],
        "dynamicMultiSelect": [
            {
                "condition": "contains",
                "replaceText": "Is equal to"
            },
            {
                "condition": "notContains",
                "replaceText": "Is not equal to"
            }
        ],
        "staticMultiSelect": [
            {
                "condition": "contains",
                "replaceText": "Is equal to"
            },
            {
                "condition": "notContains",
                "replaceText": "Is not equal to"
            }
        ],
        "numeric": [
            {
                "condition": "equal",
                "replaceText": "Is equal to"
            },
            {
                "condition": "notEqual",
                "replaceText": "Is not equal to"
            },
            {
                "condition": "greater",
                "replaceText": "Is greater than"
            },
            {
                "condition": "greaterOrEqual",
                "replaceText": "Is greater than or equal to"
            },
            {
                "condition": "lesser",
                "replaceText": "Is lesser than"
            },
            {
                "condition": "lesserOrEqual",
                "replaceText": "Is less than or equal to"
            }
        ],
        "subStringSearch": [
            {
                "condition": "like",
                "replaceText": "Like"
            },
            {
                "condition": "notLike",
                "replaceText": "Not like"
            }
        ],
        "multipleOwnFilter": [
            {
                "condition": "like",
                "replaceText": "Like"
            },
            {
                "condition": "notLike",
                "replaceText": "Not like"
            }
        ],
        "staticSingleSelect": [
            {
                "condition": "no",
                "replaceText": "No"
            },
            {
                "condition": "yes",
                "replaceText": "Yes"
            }
        ],
        "masterDate": [
            {
                "id": "removeFilter",
                "text": "Remove filter",
                "fromDate": "",
                "toDate": "",
                "selected": false,
                "show": true,
                "showDatePicker": false
            },
            {
                "id": "anytime",
                "text": "Anytime",
                "fromDate": "",
                "toDate": "",
                "selected": false,
                "show": true,
                "showDatePicker": false
            },
            {
                "id": "today",
                "text": "Today",
                "fromDate": "",
                "toDate": "",
                "selected": false,
                "show": true,
                "showDatePicker": false
            },
            {
                "id": "yesterday",
                "text": "Yesterday",
                "fromDate": "",
                "toDate": "",
                "selected": false,
                "show": true,
                "showDatePicker": false
            },
            {
                "id": "thisWeek",
                "text": "This week",
                "fromDate": "",
                "toDate": "",
                "selected": false,
                "show": true,
                "showDatePicker": false
            },
            {
                "id": "lastWeek",
                "text": "Last week",
                "fromDate": "",
                "toDate": "",
                "selected": false,
                "show": true,
                "showDatePicker": false
            },
            {
                "id": "nextWeek",
                "text": "Next week",
                "fromDate": "",
                "toDate": "",
                "selected": false,
                "show": true,
                "showDatePicker": false
            },
            {
                "id": "thisMonth",
                "text": "This month",
                "fromDate": "",
                "toDate": "",
                "selected": false,
                "show": true,
                "showDatePicker": false
            },
            {
                "id": "lastMonth",
                "text": "Last month",
                "fromDate": "",
                "toDate": "",
                "selected": false,
                "show": true,
                "showDatePicker": false
            },
            {
                "id": "nextMonth",
                "text": "Next month",
                "fromDate": "",
                "toDate": "",
                "selected": false,
                "show": true,
                "showDatePicker": false
            },
            {
                "id": "thisQuarter",
                "text": "This quarter",
                "fromDate": "",
                "toDate": "",
                "selected": false,
                "show": true,
                "showDatePicker": false
            },
            {
                "id": "lastQuarter",
                "text": "Last quarter",
                "fromDate": "",
                "toDate": "",
                "selected": false,
                "show": true,
                "showDatePicker": false
            },
            {
                "id": "nextQuarter",
                "text": "Next quarter",
                "fromDate": "",
                "toDate": "",
                "selected": false,
                "show": true,
                "showDatePicker": false
            },
            {
                "id": "thisYear",
                "text": "This year",
                "fromDate": "",
                "toDate": "",
                "selected": false,
                "show": true,
                "showDatePicker": false
            },
            {
                "id": "lastYear",
                "text": "Last year",
                "fromDate": "",
                "toDate": "",
                "selected": false,
                "show": true,
                "showDatePicker": false
            },
            {
                "id": "nextYear",
                "text": "Next year",
                "fromDate": "",
                "toDate": "",
                "selected": false,
                "show": true,
                "showDatePicker": false
            },
            {
                "id": "range",
                "text": "Date range",
                "fromDate": "",
                "toDate": "",
                "selected": false,
                "show": true,
                "showDatePicker": true
            },
            {
                "id": "lesser",
                "text": "Before",
                "fromDate": "",
                "toDate": "",
                "selected": false,
                "show": true,
                "showDatePicker": true
            },
            {
                "id": "greater",
                "text": "After",
                "fromDate": "",
                "toDate": "",
                "selected": false,
                "show": true,
                "showDatePicker": true
            }
        ],
        "multiValue": [
            {
                "condition": "equals",
                "replaceText": "Equals"
            },
            {
                "condition": "hasAllOf",
                "replaceText": "Has all of"
            },
            {
                "condition": "hasAnyOf",
                "replaceText": "Has any of"
            }
        ],
        "dynamicFilterWithSearch": [
            {
                "condition": "equal",
                "replaceText": "Is equal to",
                "showValueOption": true
            },
            {
                "condition": "notEqual",
                "replaceText": "Is not equal to",
                "showValueOption": true
            }
        ],
        "dynamicMultipleSelect": [
            {
                "condition": "contains",
                "replaceText": "Is equal to"
            },
            {
                "condition": "notContains",
                "replaceText": "Is not equal to"
            }
        ],
    }
